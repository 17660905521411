import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications';
import { RoutesApi } from '../api';
import { RouteActions } from '../redux';
import { Route } from '../types';

const useDeleteRoute = (
  closeModal: () => void,
  route: Route | null,
): {
  loading: boolean;
  handleDelete: () => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    if (!route) return;
    setLoading(true);
    try {
      await RoutesApi.deleteRoute({ id: route.id });
      dispatch(RouteActions.deleteRoute(route.id));
      dispatch(
        addNotification({
          type: NotificationTypes.Success,
          message: `Route has been deleted successfully.`,
        }),
      );
      closeModal();
    } catch (err) {
      dispatch(
        addNotification({
          type: NotificationTypes.Error,
          message: `Error while deleting route.`,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, closeModal, route]);

  return {
    loading,
    handleDelete,
  };
};

export default useDeleteRoute;
