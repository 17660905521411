import { RootState } from 'modules/main/redux';
import { DownloadModel, HistoryModel } from '../types';

export const activeDownloadsSelector = (state: RootState): DownloadModel[] =>
  state.download.active;

export const downloadsTotalSizeSelector = (state: RootState): number =>
  state.download.downloadedSize;

export const activeDownloadsCountSelector = (state: RootState): number =>
  state.download.active.length;

export const isAnyDownloadActiveSelector = (state: RootState): boolean =>
  !!activeDownloadsCountSelector(state);

export const historyDownloadsSelector = (state: RootState): HistoryModel[] =>
  state.download.history;

export const isDownloadModalOpenSelector = (state: RootState): boolean =>
  state.download.isModalOpen;

export const downloadedPackagesSelector = (state: RootState): DownloadModel[] =>
  state.download.active.filter((d) => d.downloaded);
