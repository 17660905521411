import React, { memo, useCallback, useRef, useState } from 'react';
import { Map } from 'mapbox-gl';

import { useModal } from 'modules/main';
import {
  DeleteModal,
  MapSelectingControls,
  MapInstructions,
} from 'modules/ui/components';

import {
  useCreateRouteOnMap,
  useCreateOrUpdateRoute,
  useDeleteRoute,
} from '../../hooks';
import RouteCreationModal from '../RouteCreationModal';
import { Route } from '../../types';

type Props = {
  map: Map;
  cancelRouteFlow: () => void;
  initialRoute?: Route;
};

const RouteCreationFlow: React.FC<Props> = ({
  map,
  cancelRouteFlow,
  initialRoute,
}) => {
  const distance = useRef<number>(0);
  const [isCreatesModalOpen, , openCreateModal, closeCreateModal] = useModal(
    false,
  );
  const { loading, handleUpdateOrCreate } = useCreateOrUpdateRoute(
    cancelRouteFlow,
  );
  const {
    handleUndo,
    isNotSinglePoint,
    getRoute,
    isEmpty,
  } = useCreateRouteOnMap(map, distance, initialRoute);

  const [routeForDeletion, setRouteForDeletion] = useState<Route | null>(null);
  const onDeletePress = useCallback(() => {
    setRouteForDeletion(initialRoute || null);
  }, [initialRoute]);
  const closeModal = useCallback(() => setRouteForDeletion(null), []);
  const { handleDelete, loading: deleting } = useDeleteRoute(
    cancelRouteFlow,
    routeForDeletion,
  );

  return (
    <>
      <DeleteModal
        text={`Are you sure you want to delete route "${routeForDeletion?.name}"?`}
        title="Delete route"
        isOpen={!!routeForDeletion}
        closeModal={closeModal}
        handleDelete={handleDelete}
        loading={deleting}
      />
      <RouteCreationModal
        isOpen={isCreatesModalOpen}
        closeModal={closeCreateModal}
        loading={loading}
        handleUpdateOrCreate={handleUpdateOrCreate}
        getRoute={getRoute}
        initialRoute={initialRoute}
      />
      <MapSelectingControls
        onCancel={cancelRouteFlow}
        onConfirm={openCreateModal}
        undoDisabled={isEmpty}
        confirmDisabled={!isNotSinglePoint}
        onUndo={handleUndo}
        onDelete={initialRoute && onDeletePress}
      />
      <MapInstructions
        title="New route"
        text="Click to add waypoints. Move waypoints to adjust them."
      />
    </>
  );
};

export default memo(RouteCreationFlow);
