import React, { memo } from 'react';

import { useGetBoats, BoatDataModal } from 'modules/boats';
import { useModal } from 'modules/main';
import { UploadSmallIcon } from 'modules/ui/assets';
import { StatusItem } from '../StatusItem';
import { Subtitle } from '../Subtitle';
import { ActionItem } from '../ActionItem';

type Props = {
  addPadding: boolean;
};

const BoatSideMenuSectionUi: React.FC<Props> = ({ addPadding }) => {
  const [
    isBoatDataModalOpen,
    ,
    openBoatDataModal,
    closeBoatDataModal,
  ] = useModal(false);
  const { boat } = useGetBoats();
  return (
    <>
      {isBoatDataModalOpen && (
        <BoatDataModal
          isOpen={isBoatDataModalOpen}
          closeModal={closeBoatDataModal}
        />
      )}
      <Subtitle addTopPadding={addPadding}>{`Data status (${
        boat ? boat.name : 'No boat'
      })`}</Subtitle>
      <StatusItem isOk={!!boat?.polarsFile} label="Polars" />
      <StatusItem isOk={!!boat?.sailsFile} label="Sail configuration table" />
      <StatusItem isOk={!!boat?.waveFile} label="Wave correction table" />
      <ActionItem
        onClick={openBoatDataModal}
        Icon={<UploadSmallIcon />}
        label={boat ? 'Update boat' : 'Create a boat'}
      />
    </>
  );
};

export const BoatSideMenuSection = memo(BoatSideMenuSectionUi);
