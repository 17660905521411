import { combineReducers, createReducer } from '@reduxjs/toolkit';
import db from 'modules/localForage/db';
import { persistReducer, PersistConfig, createMigrate } from 'redux-persist';

import { TimeResolution } from 'modules/meteo/consts';
import {
  setSettingsDefaults,
  setSettingsWindSpeedCorrection,
  setTimeResolutionDefault,
} from './actions';
import { SettingsDefaults, SettingsWindSpeedCorrection } from '../types';

const initialSettingsDefaults: SettingsDefaults = {
  polarEfficiency: 98,
  waveCorrection: 100,
  sailChangePenalty: 5,
  tackPenalty: 1,
  gybePenalty: 2,
  waveHeightLimit: 6,
  url: '',
};

const initialWindSpeedCorrection: SettingsWindSpeedCorrection = {
  '1ktECMWF': 100,
  '1ktGFS': 100,
  '5ktECMWF': 102,
  '5ktGFS': 102,
  '8ktECMWF': 105,
  '8ktGFS': 105,
  '12ktECMWF': 110,
  '12ktGFS': 110,
  '20ktECMWF': 115,
  '20ktGFS': 115,
  '25ktECMWF': 116,
  '25ktGFS': 116,
  '30ktECMWF': 118,
  '30ktGFS': 118,
  '40ktECMWF': 118,
  '40ktGFS': 118,
  '50ktECMWF': 120,
  '50ktGFS': 120,
};

const defaults = createReducer(initialSettingsDefaults, (builder) => {
  builder.addCase(
    setSettingsDefaults,
    (state, action) => action.payload.values,
  );
});

const windSpeedCorrection = createReducer(
  initialWindSpeedCorrection,
  (builder) => {
    builder.addCase(
      setSettingsWindSpeedCorrection,
      (state, action) => action.payload.values,
    );
  },
);

const timeResolution = createReducer(TimeResolution.high, (builder) => {
  builder.addCase(setTimeResolutionDefault, (state, action) => action.payload);
});

const reducer = combineReducers({
  defaults,
  windSpeedCorrection,
  timeResolution,
});

const migrations = {
  1: (state: any): any => {
    return {
      ...state,
      defaults: {
        ...state.defaults,
        waveCorrection: 100,
      },
    };
  },
};

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'settings',
  version: 1,
  storage: db.app,
  whitelist: ['windSpeedCorrection', 'defaults', 'timeResolution'],
  migrate: createMigrate(migrations, { debug: false }),
};

export default persistReducer(persistConfig, reducer);
