import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Checkbox } from 'modules/ui/components';
import { toDegreesMinutesAndSeconds } from 'modules/coordinates';
import { LocationIcon } from 'modules/ui/assets';

import { RegularSm, HighlightedBase } from 'modules/ui/typography';
import { downloadFullMeteogramInPoint } from '../../redux/thunks';

import {
  Footer,
  ColumnContainer,
  IconAndLabelText,
  FooterLabel,
  MeteogramTypes,
} from './styledComponents';

type Props = {
  lat: number;
  lon: number;
  closeModal: () => void;
};

type Meteograms = {
  [key: string]: boolean;
  gfs: boolean;
  ecmwf: boolean;
};

const DownloadMeteogram: React.FC<Props> = ({ lat, lon, closeModal }) => {
  const [meteograms, setMeteograms] = useState<Meteograms>({
    ecmwf: true,
    gfs: true,
  });
  const dispatch = useDispatch();

  const onClick = (): void => {
    dispatch(downloadFullMeteogramInPoint({ lat, lon }, meteograms));
    closeModal();
  };

  const handler = useCallback(
    (type: string) => {
      const x = { ...meteograms };
      x[type] = !meteograms[type];
      setMeteograms(x);
    },
    [meteograms, setMeteograms],
  );

  return (
    <div>
      <IconAndLabelText>
        <LocationIcon />
        <ColumnContainer>
          <RegularSm>Chosen point</RegularSm>
          <div>
            <HighlightedBase>
              {`${toDegreesMinutesAndSeconds(lat, true)} `}
            </HighlightedBase>
            <HighlightedBase>{toDegreesMinutesAndSeconds(lon)}</HighlightedBase>
          </div>
        </ColumnContainer>
      </IconAndLabelText>
      <MeteogramTypes>
        <Checkbox
          label="ECMWF"
          onChange={(): void => handler('ecmwf')}
          isSecondary={false}
          value={meteograms.ecmwf}
          name="ecmwf"
        />
        <Checkbox
          label="GFS"
          onChange={(): void => handler('gfs')}
          isSecondary={false}
          value={meteograms.gfs}
          name="gfs"
        />
      </MeteogramTypes>
      <Footer>
        <FooterLabel>
          Estimated download size:
          {meteograms.ecmwf && meteograms.gfs && ' 8 KB'}
          {!meteograms.ecmwf && meteograms.gfs && ' 4 KB'}
          {meteograms.ecmwf && !meteograms.gfs && ' 4 KB'}
          {!meteograms.ecmwf && !meteograms.gfs && ' 0 KB'}
        </FooterLabel>
        <Button
          disabled={!meteograms.ecmwf && !meteograms.gfs}
          label="Download"
          clickHandler={onClick}
        />
      </Footer>
    </div>
  );
};

export default React.memo(DownloadMeteogram);
