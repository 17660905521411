import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

import db from 'modules/localForage/db';
import { listToObject } from 'modules/main/service';
import { setBoats } from './actions';
import { Boat } from '../types';

const storage = createReducer({} as { [key: number]: Boat }, (builder) => {
  builder.addCase(setBoats, (state, action) => listToObject(action.payload));
});

const hasLoaded = createReducer(false, (builder) => {
  builder.addCase(setBoats, () => true);
});

const reducer = combineReducers({
  storage,
  hasLoaded,
});

const boatsReducerConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'boat',
  storage: db.app,
  whitelist: ['storage'],
};

export const boatsReducer = persistReducer(boatsReducerConfig, reducer);
