export enum Colors {
  Black = '#010724',
  SolidGray = '#676A7C',
  PrimaryDark = '#031A6E',
  Primary = '#3A4ADE',
  PrimaryLight = '#8992EB',
  Secondary = '#39B5B2',
  Destructive = '#E6185B',
  LightGray = '#F7F8FA',
  Gray = '#E8EAF1',
  DarkGray = '#D8DCE8',
  White = '#FFFFFF',
}
