import styled from 'styled-components';

export const Container = styled.div<{
  opacity?: number;
  isVisible: boolean;
  showBottomPadding: boolean;
}>`
  display: ${({ isVisible }): string => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  padding: 2px 16px
    ${({ showBottomPadding }): number => (showBottomPadding ? 16 : 0)}px 16px;
  background: ${({ opacity }): string => `rgba(255,255,255,${opacity || 1})`};
  box-shadow: 0px 4px 8px rgba(45, 54, 67, 0.05);
  border-radius: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const RightTitleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentContainer = styled.div<{ showTopPadding: boolean }>`
  padding-top: ${({ showTopPadding }): number => (showTopPadding ? 24 : 0)}px;
  display: flex;
  flex-direction: column;
`;
