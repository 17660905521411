import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

const size = 16;

export default styled.div`
  height: ${size}px;
  width: ${size}px;
  border-radius: ${size}px;
  background-color: ${Colors.Gray};
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.SolidGray};
  font-size: 12px;
`;
