import { Map } from 'mapbox-gl';
import { useCallback } from 'react';

import { MapCustomLayer } from '../consts';

export const useChangeLayerPaint = (map: Map) => {
  const changeFillColor = useCallback(
    (color: string, layer: MapCustomLayer) => {
      map.setPaintProperty(layer, 'fill-color', color);
    },
    [map],
  );

  const changeLineColor = useCallback(
    (color: string, layer: MapCustomLayer) => {
      map.setPaintProperty(layer, 'line-color', color);
    },
    [map],
  );

  const changeCircleColor = useCallback(
    (color: string, layer: MapCustomLayer) => {
      map.setPaintProperty(layer, 'circle-color', color);
    },
    [map],
  );

  return { changeFillColor, changeLineColor, changeCircleColor };
};
