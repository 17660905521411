import React from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';

import { CollapsibleContainer } from 'modules/ui/components';
import { isUiVisibleSelector } from 'modules/metadata';
import { LocationListener } from 'modules/location';
// import Header from './Header';
import RouteList from '../RouteList';

type Props = {
  onVisibilityChange: (value: string | null) => void;
  id: string;
  isOpened: boolean;
  map: Map;
};

const RoutingSideMenu: React.FC<Props> = ({
  isOpened,
  onVisibilityChange,
  id,
  map,
}) => {
  const isUiVisible = useSelector(isUiVisibleSelector);
  return (
    <>
      <LocationListener />
      <CollapsibleContainer
        isVisible={isUiVisible}
        opacity={0.8}
        isOpened={isOpened}
        onVisibilityChange={onVisibilityChange}
        id={id}
        title="Routing"
        // HeaderComponent={<Header />}
      >
        <RouteList map={map} />
      </CollapsibleContainer>
    </>
  );
};

export default React.memo(RoutingSideMenu);
