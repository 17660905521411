import { useEffect, useState } from 'react';
import { PlotData } from 'plotly.js';

import { Options } from '../types';
import { generateGraphDataFromGeojson } from '../service/geojsonData';

export const useGetGeojsonData = (
  geojson: GeoJSON.FeatureCollection | null,
  options: Options,
): [Partial<PlotData>[]] => {
  const [data, setData] = useState<Partial<PlotData>[]>([]);
  useEffect(() => {
    if (geojson) {
      const values = generateGraphDataFromGeojson(geojson, options);
      setData([
        {
          ...values,
          type: 'bar',
        },
      ]);
    }
  }, [geojson, options]);
  return [data];
};
