import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationTypes } from 'modules/notifications';
import { addNotification } from 'modules/notifications/redux';
import { BoatsSelectors } from '../redux';

export const useCheckIfBoatExist = (): { checkIfBoatExists: () => boolean } => {
  const dispatch = useDispatch();
  const boatExists = useSelector(BoatsSelectors.hasBoat);
  const checkIfBoatExists = useCallback(() => {
    if (!boatExists) {
      dispatch(
        addNotification({
          type: NotificationTypes.Error,
          message:
            'To create a new routing, you must first create your boat and upload polars.',
        }),
      );
    }
    return boatExists;
  }, [boatExists, dispatch]);

  return {
    checkIfBoatExists,
  };
};
