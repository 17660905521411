/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { ChartGroups, MeteogramTypes } from 'modules/meteogram/consts';
import {
  generateWindDirection,
  defaultLayout,
  defaultAnnotation,
} from 'modules/meteogram/utils';

const generateAnnotations = (dates: any, type: string, speed: string) => {
  const keys = Object.keys(dates);
  const annotations = keys.map((key: string) => {
    return {
      ...defaultAnnotation,
      x: key,
      text:
        dates[key][type] !== undefined
          ? generateWindDirection(dates[key][speed])
          : '',
      textangle: 180 + dates[key][type],
    };
  });

  return [...annotations];
};

// TODO: Refactor needed
const checkForAnnotations = (data: any, type: string) => {
  if (
    type === 'WIND' ||
    type === 'SWELL_WAVES' ||
    type === 'WIND_WAVES' ||
    type === 'CURRENTS'
  ) {
    if (type === 'WIND') {
      return generateAnnotations(data, 'wdir', 'ws');
    }

    if (type === 'SWELL_WAVES') {
      return generateAnnotations(data, 'mdts', '');
    }

    if (type === 'WIND_WAVES') {
      return generateAnnotations(data, 'mdww', '');
    }

    if (type === 'CURRENTS') {
      return generateAnnotations(data, 'cdir', 'cspeed');
    }
  }
  return [];
};

const useCustomLayout = (data: any, type: string) => {
  const [layout, setLayout] = useState(defaultLayout);

  useEffect(() => {
    const newLayout = {
      ...defaultLayout,
      title: ChartGroups[type]
        .map((t: string): string => MeteogramTypes[t])
        .join(' | '),
      annotations: checkForAnnotations(data, type),
      autosize: true,
    };

    const keys = Object.keys(data);
    // eslint-disable-next-line prefer-destructuring
    newLayout.xaxis.range = [keys[0], keys[keys.length - 1]];

    setLayout(newLayout);
  }, [data, type]);

  return layout;
};

export default useCustomLayout;
