import React from 'react';

import { Button, CountIndicator, IndicatorText } from './styledComponents';

type Props = {
  indicatorText?: string | number;
  handleClick: () => void;
  children: React.ReactChild;
  tooltipText?: string;
};

const MapSoloButton: React.FC<Props> = ({
  indicatorText,
  handleClick,
  children,
  tooltipText,
}) => (
  <>
    <Button data-tip={tooltipText} onClick={handleClick}>
      {children}
    </Button>
    {!!indicatorText && (
      <CountIndicator>
        <IndicatorText>{indicatorText}</IndicatorText>
      </CountIndicator>
    )}
  </>
);

export default React.memo(MapSoloButton);
