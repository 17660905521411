import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isWithinInterval } from 'date-fns';

import db from 'modules/localForage/db';
import { getGeoJsonFromLocalForage } from 'modules/localForage/utils';
import LoadingTitledComponent from 'modules/ui/components/LoadingTitledComponent';
import { RootState } from 'modules/main/redux';
import { downloadedRoutingByIdSelector } from 'modules/routing/redux/selectors';
import { WindSpeedScale } from 'modules/scales';
import { formatModelName } from 'modules/routing/utils/formater';
import {
  interpolateColor,
  formatUtcDate,
  isMidnight,
  formatUtcStartEnd,
} from '../../utils';

import {
  Table,
  DegreesCell,
  TwsCell,
  TwaCell,
  TableRow,
  InfoTitle,
} from './styledComponents';

const RoutingTable: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [, setState] = useState({});
  const [timedRoute, setTimedRoute] = useState<GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > | null>(null);
  const [
    meteogramAlongTheRoute,
    setMeteogramAlongTheRoute,
  ] = useState<GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > | null>(null);
  const routing = useSelector((state: RootState) =>
    downloadedRoutingByIdSelector(state, id),
  );

  const rerender = useCallback(() => {
    setState({});
  }, []);

  useEffect(() => {
    window.addEventListener('focus', rerender);
    return (): void => window.removeEventListener('focus', rerender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (routing?.metadata?.Internal?.routingName) {
      document.title = `${
        routing.metadata?.Internal?.routingName
      } (${formatModelName(
        routing.metadata?.Meteo?.Model,
        routing.metadata?.Meteo?.Type,
      )})`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routing?.metadata?.Internal?.routingName]);
  const handleLoadingGeojson = useCallback(
    async (timedRouteStorageId: string, meteogramStorageId: string) => {
      const [timedRouteGeojson, meteogramGeojson] = await Promise.all([
        getGeoJsonFromLocalForage(db.routings, timedRouteStorageId),
        getGeoJsonFromLocalForage(db.routings, meteogramStorageId),
      ]);
      setTimedRoute(timedRouteGeojson);
      setMeteogramAlongTheRoute(meteogramGeojson);
    },
    [],
  );

  useEffect(() => {
    if (routing?.timedOptimalRouteStorageId && routing?.meteogramStorageId) {
      handleLoadingGeojson(
        routing?.timedOptimalRouteStorageId,
        routing?.meteogramStorageId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    routing?.timedOptimalRouteStorageId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    routing?.meteogramStorageId,
    handleLoadingGeojson,
  ]);

  const generateWindSpeedColor = useCallback((value: number) => {
    let index = WindSpeedScale.findIndex(
      (speed) => typeof speed === 'number' && value < speed,
    );
    if (index === -1) index = WindSpeedScale.length - 2;
    const factor =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (WindSpeedScale[index] - value) /
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (WindSpeedScale[index] -
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        WindSpeedScale[index - 2] || 0);
    return interpolateColor(
      (WindSpeedScale[index - 1] || '#FFFFFF') as string,
      WindSpeedScale[index + 1] as string,
      1 - factor,
    );
  }, []);

  if (!timedRoute) {
    return <LoadingTitledComponent title="Generating routing table" />;
  }

  const now = new Date().getTime();

  return (
    <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
      <InfoTitle>
        {`Routing table: ${
          routing?.metadata?.Internal?.routingName
        } (${formatModelName(
          routing?.metadata?.Meteo?.Model,
          routing?.metadata?.Meteo?.Type,
        )}). ${formatUtcStartEnd(
          routing?.metadata?.Route?.StartTime ||
            routing?.metadata?.Statistics?.startTime,
          routing?.metadata?.Route?.time2finish,
        )} ${
          routing?.metadata?.Route?.DistGCL ||
          routing?.metadata?.Route?.DistSailed
            ? `${Number(
                routing?.metadata?.Route?.DistGCL ||
                  routing?.metadata?.Route?.DistSailed,
              ).toFixed(2)} Nm.`
            : ''
        } `}
      </InfoTitle>
      <Table>
        <thead>
          <tr>
            <th style={{ minWidth: 200 }}>Time</th>
            <th style={{ minWidth: 80 }}>SOG</th>
            <th style={{ minWidth: 80 }}>COG</th>
            <th style={{ minWidth: 80 }}>TWS</th>
            <th style={{ minWidth: 80 }}>Wind gusts</th>
            <th style={{ minWidth: 80 }}>TWD</th>
            <th style={{ minWidth: 50 }}>TWA</th>
            <th style={{ minWidth: 70 }}>AWA</th>
            <th style={{ minWidth: 80 }}>AWS</th>
            <th style={{ minWidth: 70 }}>Pressure</th>
            <th style={{ minWidth: 100 }}>Sail</th>
            <th style={{ minWidth: 70 }}>Total Wave Height</th>
            <th style={{ minWidth: 70 }}>Total Wave Direction</th>
            <th style={{ minWidth: 70 }}>Wind Wave Height</th>
            <th style={{ minWidth: 70 }}>Wind Wave Direction</th>
            <th style={{ minWidth: 70 }}>Wind Wave Period</th>
            <th style={{ minWidth: 70 }}>Swell Wave Height</th>
            <th style={{ minWidth: 70 }}>Swell Direction</th>
            <th style={{ minWidth: 70 }}>Swell Wave Period</th>
            <th style={{ minWidth: 70 }}>White Capping</th>
            <th style={{ minWidth: 70 }}>Current Speed</th>
            <th style={{ minWidth: 70 }}>Current Dir</th>
          </tr>
        </thead>
        <tbody>
          {timedRoute.features.map(({ properties }, index) => {
            if (index === timedRoute.features.length - 1) {
              return (
                <tr>
                  <td>
                    {properties?.DT && formatUtcDate(`${properties?.DT}Z`)}
                  </td>
                  <td>Routing End</td>
                </tr>
              );
            }

            return (
              <TableRow
                isNow={isWithinInterval(now, {
                  start: new Date(`${properties?.DT}Z` || ''),
                  end: Date.parse(
                    `${timedRoute.features[index + 1].properties?.DT}Z` || '',
                  ),
                })}
                isMidnight={isMidnight(properties?.DT || '')}
              >
                <td style={{ width: 200 }}>
                  {properties?.DT && formatUtcDate(properties?.DT)}
                </td>
                <TwsCell
                  style={{ width: 80 }}
                  color={generateWindSpeedColor(properties?.SOG)}
                >
                  {`${Number(properties?.SOG).toFixed(1)} kts`}
                </TwsCell>
                <DegreesCell style={{ width: 80 }} value={properties?.COG || 0}>
                  {`${properties?.COG}°`}
                </DegreesCell>
                <TwsCell
                  style={{ width: 80 }}
                  color={generateWindSpeedColor(properties?.TWS)}
                >
                  {`${Number(properties?.TWS).toFixed(1)} kts`}
                </TwsCell>
                <TwsCell
                  style={{ width: 80 }}
                  color={generateWindSpeedColor(properties?.WG)}
                >
                  {`${Number(properties?.WG).toFixed(1)} kts`}
                </TwsCell>
                <DegreesCell style={{ width: 80 }} value={properties?.TWD || 0}>
                  {`${properties?.TWD}°`}
                </DegreesCell>
                <TwaCell style={{ width: 50 }} value={properties?.TWA || 0}>
                  {`${Math.abs(properties?.TWA)}°`}
                </TwaCell>
                <DegreesCell style={{ width: 70 }} value={properties?.AWA || 0}>
                  {`${properties?.AWA}°`}
                </DegreesCell>
                <TwsCell
                  style={{ width: 80 }}
                  color={generateWindSpeedColor(properties?.AWS)}
                >
                  {`${Number(properties?.AWS).toFixed(1)} kts`}
                </TwsCell>
                <td style={{ width: 70 }}>
                  {Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.msl,
                  ).toFixed(1)}
                </td>
                <td style={{ width: 100 }}>{properties?.Sail || '---'}</td>
                <td style={{ width: 70 }}>
                  {Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.swh,
                  ).toFixed(1)}
                </td>
                <DegreesCell
                  style={{ width: 70 }}
                  value={
                    meteogramAlongTheRoute?.features[index]?.properties?.mwd ||
                    0
                  }
                >
                  {`${Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.mwd,
                  ).toFixed(0)}°`}
                </DegreesCell>
                <td style={{ width: 70 }}>
                  {Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.shww,
                  ).toFixed(1)}
                </td>
                <DegreesCell
                  style={{ width: 70 }}
                  value={
                    meteogramAlongTheRoute?.features[index]?.properties?.mdww ||
                    0
                  }
                >
                  {`${Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.mdww,
                  ).toFixed(0)}°`}
                </DegreesCell>
                <td style={{ width: 70 }}>
                  {`${Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.mpww,
                  ).toFixed(1)}s`}
                </td>
                <td style={{ width: 70 }}>
                  {Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.shts,
                  ).toFixed(1)}
                </td>
                <DegreesCell
                  style={{ width: 70 }}
                  value={
                    meteogramAlongTheRoute?.features[index]?.properties?.mdts ||
                    0
                  }
                >
                  {`${Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.mdts,
                  ).toFixed(0)}°`}
                </DegreesCell>
                <td style={{ width: 70 }}>
                  {`${Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.mpts,
                  ).toFixed(1)}s`}
                </td>
                <td style={{ width: 70 }}>
                  {meteogramAlongTheRoute?.features[index]?.properties?.wc}
                </td>
                <td style={{ width: 70 }}>
                  {`${Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.cspeed,
                  ).toFixed(1)} kts`}
                </td>
                <DegreesCell
                  style={{ width: 70 }}
                  value={
                    meteogramAlongTheRoute?.features[index]?.properties?.cdir ||
                    0
                  }
                >
                  {`${Number(
                    meteogramAlongTheRoute?.features[index]?.properties?.cdir,
                  ).toFixed(0)}°`}
                </DegreesCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(RoutingTable);
