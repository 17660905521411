import React, { memo, useCallback, useEffect, useState } from 'react';
import { Map } from 'mapbox-gl';

import { MapControlButton } from 'modules/ui/components';
import { PlusIcon, UploadIcon } from 'modules/ui/assets';
import {
  CreateExclusionZoneFlow,
  useOnExclusionAreaClick,
  UploadExclusionZoneFlow,
} from 'modules/exclusionZones';
import {
  CreateInclusionZoneFlow,
  useOnInclusionAreaClick,
  UploadInclusionZoneFlow,
} from 'modules/inclusionZones';
import { useUiVisibility } from 'modules/metadata';

import { Container, VisibleFlows } from './styledComponents';

type Props = {
  isOpen: boolean;
  map: Map;
  hideExclusionZones: () => void;
  showExclusionZones: () => void;
  hideInclusionZones: () => void;
  showInclusionZones: () => void;
  isExclusionZoneVisibilityOn: boolean;
  isInclusionZoneVisibilityOn: boolean;
};

enum Flow {
  UploadExclusionZone = 1,
  AddExclusionZone,
  UploadInclusionZone,
  AddInclusionZone,
}

const ZonesMenu: React.FunctionComponent<Props> = ({
  isOpen,
  map,
  hideExclusionZones,
  hideInclusionZones,
  showExclusionZones,
  showInclusionZones,
  isExclusionZoneVisibilityOn,
  isInclusionZoneVisibilityOn,
}) => {
  const [openedFlow, setOpenedFlow] = useState<null | Flow>(null);
  const [editedZoneId, setEditedZoneId] = useState<number | null>(null);

  const cancelFlow = useCallback(() => {
    setOpenedFlow((flow) => {
      if (flow === Flow.AddExclusionZone) {
        showExclusionZones();
      }
      if (flow === Flow.AddInclusionZone) {
        showInclusionZones();
      }
      setEditedZoneId(null);
      return null;
    });
  }, [setOpenedFlow, showExclusionZones, showInclusionZones]);
  const editExclusionZone = useCallback((id: number) => {
    setEditedZoneId(id);
    setOpenedFlow(Flow.AddExclusionZone);
  }, []);
  const editInclusionZone = useCallback((id: number) => {
    setEditedZoneId(id);
    setOpenedFlow(Flow.AddInclusionZone);
  }, []);
  useOnExclusionAreaClick(
    map,
    editExclusionZone,
    !!editedZoneId,
    isExclusionZoneVisibilityOn,
  );
  useOnInclusionAreaClick(
    map,
    editInclusionZone,
    !!editedZoneId,
    isInclusionZoneVisibilityOn,
  );
  const { hideUi, showUi } = useUiVisibility();

  useEffect(() => {
    if (
      openedFlow === Flow.AddExclusionZone ||
      openedFlow === Flow.AddInclusionZone
    ) {
      hideUi();
      hideExclusionZones();
      hideInclusionZones();
    } else {
      showUi();
    }
    return (): void => {
      showUi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedFlow]);

  return (
    <Container isOpen={isOpen}>
      <MapControlButton
        data-tip="Upload exclusion zones"
        onClick={(): void => setOpenedFlow(Flow.UploadExclusionZone)}
      >
        <UploadIcon />
      </MapControlButton>
      <MapControlButton
        data-tip="Add exclusion zones"
        onClick={(): void => setOpenedFlow(Flow.AddExclusionZone)}
      >
        <PlusIcon />
      </MapControlButton>
      <span style={{ padding: '0 4px', fontWeight: 600 }}>|</span>
      <MapControlButton
        data-tip="Upload inclusion zones"
        onClick={(): void => setOpenedFlow(Flow.UploadInclusionZone)}
      >
        <UploadIcon />
      </MapControlButton>
      <MapControlButton
        data-tip="Add inclusion zones"
        onClick={(): void => setOpenedFlow(Flow.AddInclusionZone)}
      >
        <PlusIcon />
      </MapControlButton>
      <VisibleFlows>
        {openedFlow === Flow.AddExclusionZone && (
          <CreateExclusionZoneFlow
            editedExclusionZoneId={editedZoneId}
            map={map}
            onCancel={cancelFlow}
          />
        )}
        {openedFlow === Flow.UploadExclusionZone && (
          <UploadExclusionZoneFlow onCancel={cancelFlow} />
        )}
        {openedFlow === Flow.AddInclusionZone && (
          <CreateInclusionZoneFlow
            editedInclusionZoneId={editedZoneId}
            map={map}
            onCancel={cancelFlow}
          />
        )}
        {openedFlow === Flow.UploadInclusionZone && (
          <UploadInclusionZoneFlow onCancel={cancelFlow} />
        )}
      </VisibleFlows>
    </Container>
  );
};

export default memo(ZonesMenu);
