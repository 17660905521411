import styled from 'styled-components';

export const MultiRow = styled.div<{
  marginRight?: number;
  hideMarginTop?: boolean;
  marginBottom?: number;
}>`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: ${({ hideMarginTop }): number => (hideMarginTop ? 0 : 24)}px;
  margin-bottom: ${({ marginBottom }): number => marginBottom || 0}px;
  align-items: center;
  > div {
    margin-right: ${({ marginRight }): number => marginRight || 24}px;
  }
  & > span {
    width: 60px;
    margin-right: 8px;
  }
`;

export const ScaleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterContainer = styled.div`
  height: 60px;
  min-height: 60px;
  background: #f8f9fb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > button {
    margin-left: 8px;
  }
  padding-right: 24px;
`;

export const RadioButtonWrapper = styled.div`
  width: 200px;
`;
