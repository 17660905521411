import styled from 'styled-components';

export const Info = styled.span<{
  marginLeft?: number;
  inline: boolean;
  marginTop?: number;
}>`
  display: ${({ inline }): string => (inline ? 'inline-block' : 'block')};
  margin-left: ${({ marginLeft }): number => marginLeft || 5}px;
  margin-right: ${({ marginLeft }): number => marginLeft || 5}px;
  margin-top: ${({ marginTop }): number => marginTop || 0}px;
`;

export const TooltipContainer = styled.span`
  display: flex;
  align-items: center;
`;
