import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RegularBase } from 'modules/ui/typography';
import { ToggleSwitch } from 'modules/ui/components';
import { TrashBinIcon } from 'modules/ui/assets';
import { DownloadedRoute } from '../../types';
import { formatRoutingSubtitle } from '../../utils/formater';
import { removeDownloadedRoutingThunk } from '../../redux/thunk';
import { toggleSideMenuRouting } from '../../redux/actions';

import {
  Container,
  InfoContainer,
  Subtitle,
  ActionsContainer,
} from './styledComponents';

type Props = {
  route: DownloadedRoute;
  includedInSideMenu: boolean;
};

const RowUi: React.FC<Props> = ({ route, includedInSideMenu }) => {
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);
  const onMouseEnter = useCallback(() => setIsHovering(true), []);
  const onMouseExit = useCallback(() => setIsHovering(false), []);
  const deleteRoute = useCallback(() => {
    dispatch(removeDownloadedRoutingThunk(route.id));
  }, [route.id, dispatch]);
  const handleRouteToggle = useCallback(() => {
    dispatch(toggleSideMenuRouting(route.id));
  }, [route.id, dispatch]);
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}>
      <InfoContainer>
        <RegularBase>
          {route.metadata?.Internal?.routingName || `Route ID: ${route.id}`}
        </RegularBase>
        <Subtitle>
          {formatRoutingSubtitle(
            route.metadata?.Meteo?.Model,
            route.metadata?.Meteo?.Type,
            route.polarEfficiency || 0,
            !!route.hasPolarSpread,
            route.metadata?.Route?.DistGCL || route.metadata?.Route?.DistSailed,
            route.metadata?.Route?.StartTime ||
              route.metadata?.Statistics?.startTime,
          )}
        </Subtitle>
      </InfoContainer>
      <ActionsContainer>
        {isHovering && <TrashBinIcon onClick={deleteRoute} />}
        <ToggleSwitch
          checked={includedInSideMenu}
          onChange={handleRouteToggle}
        />
      </ActionsContainer>
    </Container>
  );
};

export default React.memo(RowUi);
