import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main/redux';
import { SettingsDefaults, SettingsWindSpeedCorrection } from '../types';

export const settingsDefaultsSelector = (state: RootState): SettingsDefaults =>
  state.settings.defaults;

export const settingsDefaultsWithDefaultValuesSelector = createSelector(
  [settingsDefaultsSelector],
  (defaults) => ({
    ...defaults,
    speedLimit: defaults.speedLimit || undefined,
  }),
);

export const settingsTimeResoulutionDefaultSelector = (
  state: RootState,
): number => state.settings.timeResolution;

export const boatLocationUrlSelector = (state: RootState): string =>
  state.settings.defaults.url;

export const settingsWindSpeedCorrectionSelector = (
  state: RootState,
): SettingsWindSpeedCorrection => state.settings.windSpeedCorrection;
