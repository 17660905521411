import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';

import { CollapsibleContainer, Button } from 'modules/ui/components';
import { isUiVisibleSelector } from 'modules/metadata';
import { NewRoutingModal } from 'modules/routing';
import { useCheckIfBoatExist } from 'modules/boats';

import RouteCreationFlow from '../RouteCreationFlow';
import RoutesList from '../RoutesList';
import { useDisplayRoutesOnMap, useGetRoutes } from '../../hooks';
import { Route } from '../../types';

type Props = {
  onVisibilityChange: (value: string | null) => void;
  id: string;
  isOpened: boolean;
  map: Map;
};

const HomeSideMenu: React.FC<Props> = ({
  isOpened,
  onVisibilityChange,
  id,
  map,
}) => {
  const isUiVisible = useSelector(isUiVisibleSelector);
  const {
    activeIds,
    toggleRouting,
    hideAll: hideAllRoutes,
  } = useDisplayRoutesOnMap(map);
  const [isCreatingFlowActive, setIsCreatingFlowActive] = useState(false);
  const [editedRoute, setEditedRoute] = useState<Route | undefined>(undefined);
  const [newRoutingRoute, setNewRoutingRoute] = useState<Route | undefined>(
    undefined,
  );
  const { checkIfBoatExists } = useCheckIfBoatExist();

  const closeNewRoutingModal = useCallback(() => {
    setNewRoutingRoute(undefined);
  }, []);

  const openNewRoutingModal = useCallback(
    (route: Route | undefined) => {
      if (!checkIfBoatExists()) {
        return;
      }
      setNewRoutingRoute(route);
    },
    [checkIfBoatExists],
  );

  const startRouteFlow = useCallback(
    (route?: Route) => {
      if (route) {
        setEditedRoute(route);
        hideAllRoutes();
      }
      setIsCreatingFlowActive(true);
    },
    [hideAllRoutes],
  );

  const startNewRouteFlow = useCallback(() => {
    startRouteFlow(undefined);
    hideAllRoutes();
  }, [startRouteFlow, hideAllRoutes]);

  const cancelRouteFlow = useCallback(() => {
    setEditedRoute(undefined);
    setIsCreatingFlowActive(false);
  }, []);

  useGetRoutes();

  return (
    <>
      {isCreatingFlowActive && (
        <RouteCreationFlow
          map={map}
          cancelRouteFlow={cancelRouteFlow}
          initialRoute={editedRoute}
        />
      )}
      {!!newRoutingRoute && (
        <NewRoutingModal
          isOpen={!!newRoutingRoute}
          inputRoute={newRoutingRoute}
          closeModal={closeNewRoutingModal}
        />
      )}
      <CollapsibleContainer
        isVisible={isUiVisible}
        opacity={0.8}
        isOpened={isOpened}
        onVisibilityChange={onVisibilityChange}
        id={id}
        title="My routes"
      >
        <RoutesList
          startRouteFlow={startRouteFlow}
          setNewRoutingRoute={openNewRoutingModal}
          activeIds={activeIds}
          toggleRouting={toggleRouting}
        />
        <Button
          stretch
          isSecondary
          clickHandler={startNewRouteFlow}
          label="New route"
        />
      </CollapsibleContainer>
    </>
  );
};

export default React.memo(HomeSideMenu);
