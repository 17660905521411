import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useToggle } from 'modules/main';
import { mergeGeoJSONs } from 'modules/geojson/utils';
import { ModelLayers, ModelLayersSources } from 'modules/mapLayers';
import { MeteoSources } from '../consts';
import {
  wamDataSetsSourceKeysSelector,
  gfsWamDataSetsSourceKeysSelector,
} from '../redux/selectors';
import { SourcesForFiltering } from '../types';
import { getSwaveDataFromJpeg } from '../utils/swave';
import { getWaveDataFromJpeg } from '../utils/wave';
import { getWwaveDataFromJpeg } from '../utils/wwave';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useWamSourceAndLayers = (
  dataSource: MeteoSources,
  map: Map,
  showMeteoData: boolean,
  addToScaleArray: (layer: ModelLayers) => void,
  removeFromScaleArray: (layer: ModelLayers) => void,
  turnOnShowMeteoData: () => void,
) => {
  const isEcmwf = useMemo(() => dataSource === MeteoSources.ECMWFWAM, [
    dataSource,
  ]);
  const sources = useSelector(
    isEcmwf ? wamDataSetsSourceKeysSelector : gfsWamDataSetsSourceKeysSelector,
  );

  const [isWwaveHeightVisible, setIsWwaveHeightVisible] = useToggle();
  const [isWwaveDirectionVisible, setIsWwaveDirectionVisible] = useToggle();
  const [isWwavePeriodVisible, setIsWwavePeriodVisible] = useToggle();
  const [isSwaveHeightVisible, setIsSwaveHeightVisible] = useToggle();
  const [isSwaveDirectionVisible, setIsSwaveDirectionVisible] = useToggle();
  const [isSwavePeriodVisible, setIsSwavePeriodVisible] = useToggle();
  const [isWaveHeightVisible, setIsWaveHeightVisible] = useToggle();
  const [isWaveDirectionVisible, setIsWaveDirectionVisible] = useToggle();
  const [isWaveWhiteCappingVisible, setIsWaveWhiteCappingVisible] = useToggle();

  const handleSwaveSources = useCallback(
    async (swaveSources: SourcesForFiltering[]) => {
      const jsons = await Promise.all(
        swaveSources.map(({ fileName, constraints }) =>
          getSwaveDataFromJpeg(fileName, { constraints }),
        ),
      );
      const polygonGeojson1 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson1).filter((_) => _),
      );
      const polygonGeojson2 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson2).filter((_) => _),
      );
      const pointGeoJson1 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson1).filter((_) => _),
      );
      const pointGeoJson2 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson2).filter((_) => _),
      );
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMSwavePoint1
            : ModelLayersSources.GFSWAMSwavePoint1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMSwavePoint2
            : ModelLayersSources.GFSWAMSwavePoint2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson2);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMSwavePolygon1
            : ModelLayersSources.GFSWAMSwavePolygon1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMSwavePolygon2
            : ModelLayersSources.GFSWAMSwavePolygon2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleWwaveSources = useCallback(
    async (wwaveSources: SourcesForFiltering[]) => {
      const jsons = await Promise.all(
        wwaveSources.map(({ fileName, constraints }) =>
          getWwaveDataFromJpeg(fileName, { constraints }),
        ),
      );
      const polygonGeojson1 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson1).filter((_) => _),
      );
      const polygonGeojson2 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson2).filter((_) => _),
      );
      const pointGeoJson1 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson1).filter((_) => _),
      );
      const pointGeoJson2 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson2).filter((_) => _),
      );
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWwavePoint1
            : ModelLayersSources.GFSWAMWwavePoint1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWwavePoint2
            : ModelLayersSources.GFSWAMWwavePoint2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson2);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWwavePolygon1
            : ModelLayersSources.GFSWAMWwavePolygon1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWwavePolygon2
            : ModelLayersSources.GFSWAMWwavePolygon2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleWaveSources = useCallback(
    async (waveSources: SourcesForFiltering[]) => {
      const jsons = await Promise.all(
        waveSources.map(({ fileName, constraints }) =>
          getWaveDataFromJpeg(fileName, { constraints }),
        ),
      );
      const polygonGeojson1 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson1).filter((_) => _),
      );
      const polygonGeojson2 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson2).filter((_) => _),
      );
      const pointGeoJson1 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson1).filter((_) => _),
      );
      const pointGeoJson2 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson2).filter((_) => _),
      );
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWavePoint1
            : ModelLayersSources.GFSWAMWavePoint1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWavePoint2
            : ModelLayersSources.GFSWAMWavePoint2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson2);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWavePolygon1
            : ModelLayersSources.GFSWAMWavePolygon1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.WAMWavePolygon2
            : ModelLayersSources.GFSWAMWavePolygon2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const changeLayerVisibility = useCallback(
    (hide: boolean, layerName: ModelLayers) => {
      if (hide) {
        removeFromScaleArray(layerName);
        map.setLayoutProperty(layerName, 'visibility', 'none');
      } else {
        addToScaleArray(layerName);
        map.setLayoutProperty(layerName, 'visibility', 'visible');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const swellSourcesNeeded = useMemo(
    () =>
      showMeteoData &&
      (isSwaveDirectionVisible || isSwaveHeightVisible || isSwavePeriodVisible),
    [
      showMeteoData,
      isSwaveDirectionVisible,
      isSwaveHeightVisible,
      isSwavePeriodVisible,
    ],
  );
  useDeepCompareEffect(() => {
    if (swellSourcesNeeded) {
      handleSwaveSources(sources.swellWavesSources);
    }
  }, [sources.swellWavesSources, swellSourcesNeeded, handleSwaveSources]);

  const windWaveSourcesNeeded = useMemo(
    () =>
      showMeteoData &&
      (isWwaveDirectionVisible || isWwaveHeightVisible || isWwavePeriodVisible),
    [
      showMeteoData,
      isWwaveDirectionVisible,
      isWwaveHeightVisible,
      isWwavePeriodVisible,
    ],
  );
  useDeepCompareEffect(() => {
    if (windWaveSourcesNeeded) {
      handleWwaveSources(sources.windWavesSources);
    }
  }, [sources.windWavesSources, windWaveSourcesNeeded, handleWwaveSources]);

  const waveSourcesNeeded = useMemo(
    () =>
      showMeteoData &&
      (isWaveDirectionVisible ||
        isWaveHeightVisible ||
        isWaveWhiteCappingVisible),
    [
      showMeteoData,
      isWaveDirectionVisible,
      isWaveHeightVisible,
      isWaveWhiteCappingVisible,
    ],
  );
  useDeepCompareEffect(() => {
    if (waveSourcesNeeded) {
      handleWaveSources(sources.significantWavesSources);
    }
  }, [sources.significantWavesSources, waveSourcesNeeded, handleWaveSources]);

  // SWAVE
  useEffect(() => {
    changeLayerVisibility(
      !isSwaveHeightVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMSwaveHeight1 : ModelLayers.GFSWAMSwaveHeight1,
    );
    changeLayerVisibility(
      !isSwaveHeightVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMSwaveHeight2 : ModelLayers.GFSWAMSwaveHeight2,
    );
  }, [isSwaveHeightVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isSwaveHeightVisible) {
      turnOnShowMeteoData();
    }
  }, [isSwaveHeightVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isSwavePeriodVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMSwavePeriod1 : ModelLayers.GFSWAMSwavePeriod1,
    );
    changeLayerVisibility(
      !isSwavePeriodVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMSwavePeriod2 : ModelLayers.GFSWAMSwavePeriod2,
    );
  }, [isSwavePeriodVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isSwavePeriodVisible) {
      turnOnShowMeteoData();
    }
  }, [isSwavePeriodVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isSwaveDirectionVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.WAMSwaveDirection1
        : ModelLayers.GFSWAMSwaveDirection1,
    );
    changeLayerVisibility(
      !isSwaveDirectionVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.WAMSwaveDirection2
        : ModelLayers.GFSWAMSwaveDirection2,
    );
  }, [isSwaveDirectionVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isSwaveDirectionVisible) {
      turnOnShowMeteoData();
    }
  }, [isSwaveDirectionVisible, turnOnShowMeteoData]);

  // WWAVE
  useEffect(() => {
    changeLayerVisibility(
      !isWwaveHeightVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMWwaveHeight1 : ModelLayers.GFSWAMWwaveHeight1,
    );
    changeLayerVisibility(
      !isWwaveHeightVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMWwaveHeight2 : ModelLayers.GFSWAMWwaveHeight2,
    );
  }, [isWwaveHeightVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isWwaveHeightVisible) {
      turnOnShowMeteoData();
    }
  }, [isWwaveHeightVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isWwavePeriodVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMWwavePeriod1 : ModelLayers.GFSWAMWwavePeriod1,
    );
    changeLayerVisibility(
      !isWwavePeriodVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMWwavePeriod2 : ModelLayers.GFSWAMWwavePeriod2,
    );
  }, [isWwavePeriodVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isWwavePeriodVisible) {
      turnOnShowMeteoData();
    }
  }, [isWwavePeriodVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isWwaveDirectionVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.WAMWwaveDirection1
        : ModelLayers.GFSWAMWwaveDirection1,
    );
    changeLayerVisibility(
      !isWwaveDirectionVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.WAMWwaveDirection2
        : ModelLayers.GFSWAMWwaveDirection2,
    );
  }, [isWwaveDirectionVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isWwaveDirectionVisible) {
      turnOnShowMeteoData();
    }
  }, [isWwaveDirectionVisible, turnOnShowMeteoData]);

  // WAVE
  useEffect(() => {
    changeLayerVisibility(
      !isWaveHeightVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMWaveHeight1 : ModelLayers.GFSWAMWaveHeight1,
    );
    changeLayerVisibility(
      !isWaveHeightVisible || !showMeteoData,
      isEcmwf ? ModelLayers.WAMWaveHeight2 : ModelLayers.GFSWAMWaveHeight2,
    );
  }, [isWaveHeightVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isWaveHeightVisible) {
      turnOnShowMeteoData();
    }
  }, [isWaveHeightVisible, turnOnShowMeteoData]);
  useEffect(() => {
    if (!isEcmwf) return;

    changeLayerVisibility(
      !isWaveWhiteCappingVisible || !showMeteoData,
      ModelLayers.WAMWaveWhiteCapping1,
    );
    changeLayerVisibility(
      !isWaveWhiteCappingVisible || !showMeteoData,
      ModelLayers.WAMWaveWhiteCapping2,
    );
  }, [
    isWaveWhiteCappingVisible,
    showMeteoData,
    changeLayerVisibility,
    isEcmwf,
  ]);
  useEffect(() => {
    if (isWaveWhiteCappingVisible) {
      turnOnShowMeteoData();
    }
  }, [isWaveWhiteCappingVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isWaveDirectionVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.WAMWaveDirection1
        : ModelLayers.GFSWAMWaveDirection1,
    );
    changeLayerVisibility(
      !isWaveDirectionVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.WAMWaveDirection2
        : ModelLayers.GFSWAMWaveDirection2,
    );
  }, [isWaveDirectionVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isWaveDirectionVisible) {
      turnOnShowMeteoData();
    }
  }, [isWaveDirectionVisible, turnOnShowMeteoData]);

  const componentsCount = useMemo(() => {
    if (!showMeteoData) return 0;
    return [
      isWwaveHeightVisible,
      isWwaveDirectionVisible,
      isWwavePeriodVisible,
      isSwaveHeightVisible,
      isSwaveDirectionVisible,
      isSwavePeriodVisible,
      isWaveHeightVisible,
      isWaveDirectionVisible,
      isWaveWhiteCappingVisible,
    ].filter((_) => _).length;
  }, [
    showMeteoData,
    isWwaveHeightVisible,
    isWwaveDirectionVisible,
    isWwavePeriodVisible,
    isSwaveHeightVisible,
    isSwaveDirectionVisible,
    isSwavePeriodVisible,
    isWaveHeightVisible,
    isWaveDirectionVisible,
    isWaveWhiteCappingVisible,
  ]);

  return {
    componentsCount,
    isWwaveHeightVisible,
    setIsWwaveHeightVisible,
    isWwaveDirectionVisible,
    setIsWwaveDirectionVisible,
    isWwavePeriodVisible,
    setIsWwavePeriodVisible,
    isSwaveHeightVisible,
    setIsSwaveHeightVisible,
    isSwaveDirectionVisible,
    setIsSwaveDirectionVisible,
    isSwavePeriodVisible,
    setIsSwavePeriodVisible,
    isWaveHeightVisible,
    setIsWaveHeightVisible,
    isWaveDirectionVisible,
    setIsWaveDirectionVisible,
    isWaveWhiteCappingVisible,
    setIsWaveWhiteCappingVisible,
  };
};

export default useWamSourceAndLayers;
