import React, { memo } from 'react';

import { RegularSm } from 'modules/ui/typography';
import { CheckedIcon, UncheckedIcon } from 'modules/ui/assets';

import { StatusContainer } from './styledComponents';

type Props = {
  isOk: boolean;
  label: string;
};

const StatusItemUi: React.FC<Props> = ({ isOk, label }) => (
  <StatusContainer>
    {isOk ? <CheckedIcon /> : <UncheckedIcon />}
    <RegularSm>{label}</RegularSm>
  </StatusContainer>
);

export const StatusItem = memo(StatusItemUi);
