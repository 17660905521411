import styled from 'styled-components';

import { RegularSm } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';

export const ListContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.div`
  height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  :hover {
    background-color: ${Colors.Primary}12;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Subtitle = styled(RegularSm)`
  opacity: 0.5;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > label {
    margin-left: 16px;
  }
  & > svg {
    cursor: pointer;
  }
`;
