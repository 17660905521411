import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

import db from 'modules/localForage/db';
import { deleteFromObject, listToObject } from 'modules/main/service';
import { setInclusionZones, deleteInclusionZone } from './actions';
import { InclusionZone } from '../types';

const storage = createReducer(
  {} as { [key: number]: InclusionZone },
  (builder) => {
    builder
      .addCase(setInclusionZones, (state, action) =>
        listToObject(action.payload, state),
      )
      .addCase(deleteInclusionZone, (state, action) =>
        deleteFromObject(state, action.payload),
      );
  },
);

const hasLoaded = createReducer(false, (builder) => {
  builder.addCase(setInclusionZones, () => true);
});

const reducer = combineReducers({
  storage,
  hasLoaded,
});

const inclusionZonesReducerConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'inclusionZone',
  storage: db.app,
  whitelist: ['storage'],
};

export const inclusionZonesReducer = persistReducer(
  inclusionZonesReducerConfig,
  reducer,
);
