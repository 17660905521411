import React, { memo } from 'react';

import Button from '../Button';
import { Colors } from '../../theme/colors';

import { ButtonsContainer, ExtraButtonsContainer } from './styledComponents';

type Props = {
  undoDisabled?: boolean;
  confirmDisabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onUndo?: () => void;
  onDelete?: () => void;
};

const MapSelectingControls: React.FC<Props> = ({
  confirmDisabled,
  onConfirm,
  onCancel,
  onUndo,
  onDelete,
  undoDisabled,
}) => (
  <>
    <ButtonsContainer>
      <Button
        isSecondary
        label="Cancel"
        clickHandler={onCancel}
        borderColor={Colors.SolidGray}
      />
      <Button
        stopPropagation
        disabled={confirmDisabled}
        label="Confirm"
        clickHandler={onConfirm}
      />
    </ButtonsContainer>
    <ExtraButtonsContainer>
      {!!onUndo && (
        <Button
          stopPropagation
          isSecondary
          label="Undo"
          clickHandler={onUndo}
          disabled={undoDisabled}
          borderColor={Colors.SolidGray}
        />
      )}
      {!!onDelete && (
        <Button
          label="Delete"
          clickHandler={onDelete}
          borderColor={Colors.SolidGray}
        />
      )}
    </ExtraButtonsContainer>
  </>
);

export default memo(MapSelectingControls);
