export enum Layers {
  Coastline = 'coastline',
  Graticules1 = 'graticules1',
  MinorIslands = 'minorIslands',
  Ocean = 'ocean',
  Land = 'land',
  IceZone = 'iceZone',
}

export enum LayersSources {
  Coastline = 'coastline-source',
  Graticules1 = 'graticules1-source',
  MinorIslands = 'minorIslands-source',
  Ocean = 'ocean-source',
  Land = 'land-source',
  IceZone = 'iceZone-source',
}

export enum ModelLayers {
  ECMWFWindSpeed1 = 'ecmwf-wind-speed1',
  ECMWFWindSpeed2 = 'ecmwf-wind-speed2',
  ECMWFWindDirection1 = 'ecmwf-wind-direction1',
  ECMWFWindDirection2 = 'ecmwf-wind-direction2',
  ECMWFGustsSpeed1 = 'ecmwf-gusts-speed1',
  ECMWFGustsSpeed2 = 'ecmwf-gusts-speed2',
  ECMWFGustsDirection1 = 'ecmwf-gusts-direction1',
  ECMWFGustsDirection2 = 'ecmwf-gusts-direction2',
  ECMWFWeatherTemp1 = 'ecmwf-weather-temp1',
  ECMWFWeatherTemp2 = 'ecmwf-weather-temp2',
  ECMWFWeatherCloud1 = 'ecmwf-weather-clouds1',
  ECMWFWeatherCloud2 = 'ecmwf-weather-clouds2',
  ECMWFWeatherPerception1 = 'ecmwf-weather-perception1',
  ECMWFWeatherPerception2 = 'ecmwf-weather-perception2',
  ECMWFMslp = 'ecmwf-mslp',
  GFSWindSpeed1 = 'gfs-wind-speed1',
  GFSWindSpeed2 = 'gfs-wind-speed2',
  GFSWindDirection1 = 'gfs-wind-direction1',
  GFSWindDirection2 = 'gfs-wind-direction2',
  GFSGustsSpeed1 = 'gfs-gusts-speed1',
  GFSGustsSpeed2 = 'gfs-gusts-speed2',
  GFSGustsDirection1 = 'gfs-gusts-direction1',
  GFSGustsDirection2 = 'gfs-gusts-direction2',
  GFSWeatherTemp1 = 'gfs-weather-temp1',
  GFSWeatherTemp2 = 'gfs-weather-temp2',
  GFSWeatherCloud1 = 'gfs-weather-clouds1',
  GFSWeatherCloud2 = 'gfs-weather-clouds2',
  GFSWeatherPerception1 = 'gfs-weather-perception1',
  GFSWeatherPerception2 = 'gfs-weather-perception2',
  GFSMslp = 'gfs-mslp',
  WAMSwaveHeight1 = 'wam-swave-height1',
  WAMSwaveHeight2 = 'wam-swave-height2',
  WAMSwaveDirection1 = 'wam-swave-direction1',
  WAMSwaveDirection2 = 'wam-swave-direction2',
  WAMSwavePeriod1 = 'wam-swave-period1',
  WAMSwavePeriod2 = 'wam-swave-period2',
  WAMWwaveHeight1 = 'wam-wwave-height1',
  WAMWwaveHeight2 = 'wam-wwave-height2',
  WAMWwaveDirection1 = 'wam-wwave-direction1',
  WAMWwaveDirection2 = 'wam-wwave-direction2',
  WAMWwavePeriod1 = 'wam-wwave-period1',
  WAMWwavePeriod2 = 'wam-wwave-period2',
  WAMWaveHeight1 = 'wam-wave-height1',
  WAMWaveHeight2 = 'wam-wave-height2',
  WAMWaveDirection1 = 'wam-wave-direction1',
  WAMWaveDirection2 = 'wam-wave-direction2',
  WAMWaveWhiteCapping1 = 'wam-wave-white-capping1',
  WAMWaveWhiteCapping2 = 'wam-wave-white-capping2',
  GFSWAMSwaveHeight1 = 'gfswam-swave-height1',
  GFSWAMSwaveHeight2 = 'gfswam-swave-height2',
  GFSWAMSwaveDirection1 = 'gfswam-swave-direction1',
  GFSWAMSwaveDirection2 = 'gfswam-swave-direction2',
  GFSWAMSwavePeriod1 = 'gfswam-swave-period1',
  GFSWAMSwavePeriod2 = 'gfswam-swave-period2',
  GFSWAMWwaveHeight1 = 'gfswam-wwave-height1',
  GFSWAMWwaveHeight2 = 'gfswam-wwave-height2',
  GFSWAMWwaveDirection1 = 'gfswam-wwave-direction1',
  GFSWAMWwaveDirection2 = 'gfswam-wwave-direction2',
  GFSWAMWwavePeriod1 = 'gfswam-wwave-period1',
  GFSWAMWwavePeriod2 = 'gfswam-wwave-period2',
  GFSWAMWaveHeight1 = 'gfswam-wave-height1',
  GFSWAMWaveHeight2 = 'gfswam-wave-height2',
  GFSWAMWaveDirection1 = 'gfswam-wave-direction1',
  GFSWAMWaveDirection2 = 'gfswam-wave-direction2',
  Meteograms = 'meteograms',
  NemoOceanSpeed1 = 'nemo-ocean-speed1',
  NemoOceanSpeed2 = 'nemo-ocean-speed2',
  NemoOceanDirection1 = 'nemo-ocean-direction1',
  NemoOceanDirection2 = 'nemo-ocean-direction2',
  NemoOceanTemperature1 = 'nemo-ocean-temperature1',
  NemoOceanTemperature2 = 'nemo-ocean-temperature2',
  WindSpeedComparison1 = 'wind-speed-comparison1',
  WindSpeedComparison2 = 'wind-speed-comparison2',
  WindSpeedComparisonEcmwfDirection1 = 'wind-speed-comparison-ecmwf-direction1',
  WindSpeedComparisonEcmwfDirection2 = 'wind-speed-comparison-ecmwf-direction2',
  WindSpeedComparisonGfsDirection1 = 'wind-speed-comparison-gfs-direction1',
  WindSpeedComparisonGfsDirection2 = 'wind-speed-comparison-gfs-direction2',
}

export enum ModelLayersSources {
  ECMWFWeather1 = 'ecmwf-weather-source1',
  ECMWFWeather2 = 'ecmwf-weather-source2',
  ECMWFWindPolygon1 = 'ecmwf-wind-polygon-source1',
  ECMWFWindPolygon2 = 'ecmwf-wind-polygon-source2',
  ECMWFWindPoint1 = 'ecmwf-wind-point-source1',
  ECMWFWindPoint2 = 'ecmwf-wind-point-source2',
  GFSWeather1 = 'gfs-weather-source1',
  GFSWeather2 = 'gfs-weather-source2',
  GFSWindPolygon1 = 'gfs-wind-polygon-source1',
  GFSWindPolygon2 = 'gfs-wind-polygon-source2',
  GFSWindPoint1 = 'gfs-wind-point-source1',
  GFSWindPoint2 = 'gfs-wind-point-source2',
  WAMWavePolygon1 = 'wam-wave-polygon-source1',
  WAMWavePolygon2 = 'wam-wave-polygon-source2',
  WAMWavePoint1 = 'wam-wave-point-source1',
  WAMWavePoint2 = 'wam-wave-point-source2',
  WAMSwavePolygon1 = 'wam-swave-polygon-source1',
  WAMSwavePolygon2 = 'wam-swave-polygon-source2',
  WAMSwavePoint1 = 'wam-swave-point-source1',
  WAMSwavePoint2 = 'wam-swave-point-source2',
  WAMWwavePolygon1 = 'wam-wwave-polygon-source1',
  WAMWwavePolygon2 = 'wam-wwave-polygon-source2',
  WAMWwavePoint1 = 'wam-wwave-point-source1',
  WAMWwavePoint2 = 'wam-wwave-point-source2',
  GFSWAMWavePolygon1 = 'gfswam-wave-polygon-source1',
  GFSWAMWavePolygon2 = 'gfswam-wave-polygon-source2',
  GFSWAMWavePoint1 = 'gfswam-wave-point-source1',
  GFSWAMWavePoint2 = 'gfswam-wave-point-source2',
  GFSWAMSwavePolygon1 = 'gfswam-swave-polygon-source1',
  GFSWAMSwavePolygon2 = 'gfswam-swave-polygon-source2',
  GFSWAMSwavePoint1 = 'gfswam-swave-point-source1',
  GFSWAMSwavePoint2 = 'gfswam-swave-point-source2',
  GFSWAMWwavePolygon1 = 'gfswam-wwave-polygon-source1',
  GFSWAMWwavePolygon2 = 'gfswam-wwave-polygon-source2',
  GFSWAMWwavePoint1 = 'gfswam-wwave-point-source1',
  GFSWAMWwavePoint2 = 'gfswam-wwave-point-source2',
  MeteogramPoint = 'meteogram-point-source',
  NemoOceanPolygon1 = 'nemo-ocean-polygon-source1',
  NemoOceanPolygon2 = 'nemo-ocean-polygon-source2',
  NemoOceanPoint1 = 'nemo-ocean-point-source1',
  NemoOceanPoint2 = 'nemo-ocean-point-source2',
  WindComparisonPoint1 = 'wind-comparison-point-source1',
  WindComparisonPoint2 = 'wind-comparison-point-source2',
  WindComparisonPolygon1 = 'wind-comparison-polygon-source1',
  WindComparisonPolygon2 = 'wind-comparison-polygon-source2',
}

export enum RoutingLayers {
  OptimalRoute = 'optimal-route',
  DashedOptimalRoute = 'dashed-optimal-route',
  Izochrones = 'izochrones',
  TimedOptimalRoute = 'timed-optimal-route',
  TimedOptimalRouteBarbs = 'timed-optimal-route-barbs',
  CurrentLocation = 'current-location',
  CurrentBoatLocation = 'boat-location',
  RoutingTimedLocation = 'routing-timed-location',
  RoutingPolarSpreadPoints = 'routing-polar-spread-points',
  RoutingPolarSpreadLine = 'routing-polar-spread-line',
  RoutingPolarSpreadTimedLocation = 'routing-polar-spread-location',
}

export enum RoutingLayersSources {
  OptimalRoute = 'optimal-route-source',
  Izochrones = 'izochrones-source',
  TimedOptimalRoute = 'timed-optimal-route-source',
  CurrentLocation = 'current-location-source',
  CurrentBoatLocation = 'boat-location-source',
  RoutingPolarSpreadPoints = 'routing-polar-spread-points-source',
  RoutingPolarSpreadLine = 'routing-polar-spread-line-source',
}

export enum FleetLayers {
  FleetRoutes = 'fleet-routes',
  FleetLastLocation = 'fleet-last-location',
}

export enum FleetLayersSources {
  FleetRoutes = 'fleet-routes-source',
  FleetLastLocation = 'fleet-last-location-source',
}

export enum InteractionLayers {
  MeasurementToolLine = 'measurement-tool-line',
  MeasurementToolPoints = 'measurement-tool-points',
  RouteCreationLine = 'route-creation-line',
  RouteCreationPoints = 'route-creation-points',
  PolygonCreationLine = 'polygon-creation-line',
  PolygonCreationPoints = 'polygon-creation-points',
  PolygonCreationFill = 'polygon-creation-fill',
}

export enum InteractionLayersSources {
  MeasurementTool = 'measurement-tool-source',
  RouteCreation = 'route-creation-source',
  PolygonCreation = 'polygon-creation-source',
}

export enum RoutesLayers {
  RoutePoints = 'routes-route-points',
  RouteLines = 'routes-route-lines',
}

export enum RoutesLayersSources {
  Route = 'routes-route-source',
}

export enum ZoneLayers {
  ExclusionZones = 'exclusionZones',
  InclusionZones = 'inclusionZones',
}

export enum ZoneLayersSources {
  ExclusionZones = 'exclusionZones-source',
  InclusionZones = 'inclusionZones-source',
}

export const getLayerSourceName = (layer: Layers | ModelLayers): string =>
  `${layer}-source`;

export const emptyGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
  type: 'FeatureCollection',
  features: [],
};

export const RoutingColors = [
  '#E5185B',
  '#2DBB66',
  '#7F59D0',
  '#E7900F',
  '#F925D7',
  '#39e6e0',
  '#39fa6d',
];

type LayersVersionsType = { [key in Layers]: number };

export const LayersVersions: LayersVersionsType = {
  coastline: 1,
  minorIslands: 1,
  graticules1: 1,
  ocean: 1,
  land: 1,
  iceZone: 1,
};

export type MapCustomLayer =
  | Layers
  | ModelLayers
  | RoutingLayers
  | FleetLayers
  | InteractionLayers
  | RoutesLayers;
