import styled from 'styled-components';

export const ContextMenuContainer = styled.ul`
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: auto;
  list-style: none;
  border-radius: 0px 8px 8px 8px;
  padding: 0px;
  margin: 0px;
`;

export const MenuItem = styled.li<{ available?: boolean }>`
  background-color: white;
  font-size: 13px;
  padding: 10px;
  white-space: nowrap;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: whitesmoke;
    border-left: 4px solid #2178ff;
    font-weight: 600;
    cursor: pointer;
  }
`;
