import { createAction } from '@reduxjs/toolkit';

import { InclusionZone } from '../types';

export const setInclusionZones = createAction<InclusionZone[]>(
  'SET_USER_INCLUSION_ZONES',
);
export const deleteInclusionZone = createAction<number>(
  'DELETE_USER_INCLUSION_ZONES',
);

export const InclusionZoneActions = {
  setInclusionZones,
  deleteInclusionZone,
};
