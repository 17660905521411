import React, { useCallback, useEffect, useMemo } from 'react';
import { ToggleSwitch } from 'modules/ui/components';
import {
  EventData,
  Map,
  MapboxGeoJSONFeature,
  MapMouseEvent,
  Popup,
} from 'mapbox-gl';
import { useSelector } from 'react-redux';

import { useToggle } from 'modules/main';
import {
  emptyGeojson,
  FleetLayers,
  FleetLayersSources,
} from 'modules/mapLayers/consts';
import {
  fleetBoatDataGeojsonSelector,
  fleetLineDataGeojsonSelector,
} from 'modules/fleet/redux/selectors';
import { HeaderContainer } from './styledComponents';

type Props = {
  map: Map;
};

const Header: React.FC<Props> = ({ map }) => {
  const [isFleetVisible, toggleIsFleetVisible] = useToggle();
  const fleetBoatDataGeojson = useSelector(fleetBoatDataGeojsonSelector);
  const fleetLineDataGeojson = useSelector(fleetLineDataGeojsonSelector);

  const popup = useMemo(
    () =>
      new Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    [],
  );

  const onMouseHover = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { lastTime, name, rank, speed } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Position time: ${lastTime}<br/>Name: ${name}<br/>Rank: ${rank}<br/>Speed: ${speed} kts<br/>`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseLeave = useCallback(() => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  }, [popup, map]);

  useEffect(() => {
    map.on('mouseenter', FleetLayers.FleetLastLocation, onMouseHover);
    map.on('mouseleave', FleetLayers.FleetLastLocation, onMouseLeave);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup, map]);

  const changeLayerVisibility = useCallback(
    (hide: boolean) => {
      if (hide) {
        map.setLayoutProperty(FleetLayers.FleetRoutes, 'visibility', 'none');
        map.setLayoutProperty(
          FleetLayers.FleetLastLocation,
          'visibility',
          'none',
        );
      } else {
        map.setLayoutProperty(FleetLayers.FleetRoutes, 'visibility', 'visible');
        map.setLayoutProperty(
          FleetLayers.FleetLastLocation,
          'visibility',
          'visible',
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    changeLayerVisibility(!isFleetVisible);
  }, [isFleetVisible, changeLayerVisibility]);

  useEffect(() => {
    try {
      map
        .getSource(FleetLayersSources.FleetRoutes)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(fleetLineDataGeojson);
    } catch (error) {
      if (map) {
        map
          .getSource(FleetLayersSources.FleetRoutes)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(emptyGeojson);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fleetLineDataGeojson]);

  useEffect(() => {
    try {
      map
        .getSource(FleetLayersSources.FleetLastLocation)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(fleetBoatDataGeojson);
    } catch (error) {
      if (map) {
        map
          .getSource(FleetLayersSources.FleetLastLocation)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(emptyGeojson);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fleetBoatDataGeojson]);

  return (
    <HeaderContainer>
      <ToggleSwitch
        checked={isFleetVisible}
        onChange={toggleIsFleetVisible}
        label="Show Vendée Globe fleet data"
      />
    </HeaderContainer>
  );
};

export default React.memo(Header);
