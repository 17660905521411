import styled from 'styled-components';
import { Colors } from 'modules/ui/theme/colors';

export const NotificationContainer = styled.div<{ borderColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 560px;
  border-bottom: ${({ borderColor }): string => `2px solid ${borderColor}`};
  min-height: 40px;
  background-color: ${Colors.White};
  padding: 0px 10px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(1, 7, 36, 0.07);

  & > svg:first-child {
    margin-right: auto;
  }

  span {
    margin: 0px auto 0px 40px;
  }

  .close-icon {
    margin-left: auto;
    cursor: pointer;
  }
`;
