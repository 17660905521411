import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Button } from 'modules/ui/components';
import {
  downloadsTotalSizeSelector,
  isAnyDownloadActiveSelector,
} from '../../redux/selectors';
import { clearHistory } from '../../redux/actions';
import { ActiveDownloads, History } from '..';
import { formatDownloadSize } from '../../utils';

import { Footer, DownloadedSizeCount } from './styledComponents';

type Props = {
  closeModal: () => void;
};

const Downloads: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const isAnyDownloadActive = useSelector(isAnyDownloadActiveSelector);
  const [activeTab, setActiveTab] = useState(isAnyDownloadActive ? 0 : 1);
  const totalDownloadedSize = useSelector(downloadsTotalSizeSelector);

  const handleClearDownloadHistory = useCallback(() => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete the downloads history and reset the data traffic stats?',
      )
    ) {
      dispatch(clearHistory());
    }
  }, [dispatch]);

  return (
    <>
      <Tabs onSelect={setActiveTab} defaultIndex={isAnyDownloadActive ? 0 : 1}>
        <TabList>
          <Tab>Active downloads</Tab>
          <Tab>History</Tab>
        </TabList>
        <TabPanel>
          <ActiveDownloads closeModal={closeModal} />
        </TabPanel>
        <TabPanel>
          <History />
        </TabPanel>
      </Tabs>
      <Footer>
        <DownloadedSizeCount>
          {`Downloads total - ${formatDownloadSize(totalDownloadedSize)}`}
        </DownloadedSizeCount>
        {activeTab === 1 && (
          <Button
            isTertiary
            label="Clear download history"
            clickHandler={handleClearDownloadHistory}
          />
        )}
      </Footer>
    </>
  );
};

export default React.memo(Downloads);
