import { RootState } from 'modules/main/redux';
import { createSelector } from '@reduxjs/toolkit';

import { WhatsNewVersions } from 'modules/main';
import { isVersionLower, parseVersion } from '../utils';

export const isMeteoDataOnSelector = (state: RootState): boolean =>
  state.metadata.showMeteoData;

export const isRoutingDataOnSelector = (state: RootState): boolean =>
  state.metadata.showRoutingData;

export const isUiVisibleSelector = (state: RootState): boolean =>
  state.metadata.uiVisible;

export const showTimelineSelector = (state: RootState): boolean =>
  (isMeteoDataOnSelector(state) || isRoutingDataOnSelector(state)) &&
  isUiVisibleSelector(state);

export const isOnlineSelector = (state: RootState): boolean =>
  state.metadata.isOnline;

export const isLiveBoatDataOkSelector = (state: RootState): boolean =>
  state.metadata.isLiveBoatDataOk;

export const appVersionInfoSeenSelector = (state: RootState): string =>
  state.metadata.appVersionInfoSeen;

export const whatsNewCountSelector = createSelector(
  [appVersionInfoSeenSelector],
  (lastSeenVersion) => {
    const parsedLastSeenVersion = parseVersion(lastSeenVersion);
    return WhatsNewVersions.filter((v) => {
      const parsedVersion = parseVersion(v);
      return isVersionLower(parsedLastSeenVersion, parsedVersion);
    }).length;
  },
);

export const MetadataSelectors = {
  isOnline: isOnlineSelector,
  isLiveBoatDataOk: isLiveBoatDataOkSelector,
};
