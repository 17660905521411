import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { WarningIcon, CloseIcon, CheckedIcon } from 'modules/ui/assets';
import { RegularSm } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';
import { removeNotification } from 'modules/notifications/redux';
import { NotificationContainer } from './styledComponents';
import { NotificationTypes } from '../../consts';

type Props = {
  id: string;
  message: string;
  type: NotificationTypes;
};

const getColor = (type: NotificationTypes): string => {
  if (type === NotificationTypes.Success) return Colors.Secondary;
  return Colors.Destructive;
};

const getIcon = (type: NotificationTypes): JSX.Element => {
  if (type === NotificationTypes.Success) return <CheckedIcon />;
  return <WarningIcon />;
};

const Notification: React.FC<Props> = ({ id, message, type }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(removeNotification(id));
  }, [dispatch, id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleClick();
    }, 4000);
    return (): void => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationContainer borderColor={getColor(type)}>
      {getIcon(type)}
      <RegularSm>{message}</RegularSm>
      <CloseIcon className="close-icon" onClick={handleClick} />
    </NotificationContainer>
  );
};

export default React.memo(Notification);
