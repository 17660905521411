/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
import db from 'modules/localForage/db';
import { getArrayBufferFromLocalForage } from 'modules/localForage/utils';
import { emptyGeojson } from 'modules/mapLayers/consts';
import { makeEmptyGeojson } from 'modules/geojson/utils';
import { addToResolveMap, getWorker } from './workers';
import { Constraint } from '../types';
import { getLatLngSteps, getSWaveMetadataFromExif } from './metadata';
import { getSWaveValueGetters, getSWaveValues } from './pixelToData';
import { MeteoDataTypes } from '../consts';

export const getSwaveDataFromJpeg = async (
  storageKey: string,
  options?: {
    constraints: Constraint[];
  },
): Promise<{
  fillGeoJson1: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  barbGeoJson1: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  fillGeoJson2: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  barbGeoJson2: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
}> => {
  try {
    const fileBuffer = await getArrayBufferFromLocalForage(
      db.meteo,
      storageKey,
    );
    const constraints = options?.constraints;
    if (!fileBuffer) throw new Error('No file buffer');
    const blob = new Blob([fileBuffer], { type: 'image/jpeg' });
    const url = URL.createObjectURL(blob);
    const metadata = getSWaveMetadataFromExif(fileBuffer);
    const { height, width } = metadata;
    const image = new Image(width, height);
    return new Promise((resolve) => {
      image.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        if (!canvas.getContext('2d')) {
          throw new Error('No canvas context');
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.getContext('2d').drawImage(this, 0, 0, width, height);
        const fillGeoJson1 = makeEmptyGeojson();
        const barbGeoJson1 = makeEmptyGeojson();
        const fillGeoJson2 = makeEmptyGeojson();
        const barbGeoJson2 = makeEmptyGeojson();
        addToResolveMap(storageKey, {
          fillGeoJson1,
          barbGeoJson1,
          fillGeoJson2,
          barbGeoJson2,
          count: 0,
          resolve,
        });
        for (let i = 0; i < height; i += 1) {
          const pixelArray: Uint8ClampedArray[] = [];
          for (let j = 0; j < width; j += 1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const pixelData = canvas
              .getContext('2d')
              .getImageData(j, height - (i + 1), 1, 1).data;
            pixelArray.push(pixelData);
          }
          getWorker(i).postMessage({
            array: pixelArray,
            metadata,
            i,
            constraints,
            storageKey,
            size: height,
            type: MeteoDataTypes.Swave,
          });
        }
      };
      image.src = url;
    });
  } catch {
    return {
      fillGeoJson1: emptyGeojson,
      barbGeoJson1: emptyGeojson,
      fillGeoJson2: emptyGeojson,
      barbGeoJson2: emptyGeojson,
    };
  }
};

export const getSwaveDataFromPixel = async (
  storageKey: string,
  options?: {
    constraints?: Constraint[];
    point?: number[];
  },
): Promise<any> => {
  try {
    const fileBuffer = await getArrayBufferFromLocalForage(
      db.meteo,
      storageKey,
    );
    // const hasConstrains = !!options?.constraints?.length;
    // const constraints = options?.constraints;
    if (!fileBuffer) throw new Error('No file buffer');
    const blob = new Blob([fileBuffer], { type: 'image/jpeg' });
    const url = URL.createObjectURL(blob);
    const metadata = getSWaveMetadataFromExif(fileBuffer);
    const getters = getSWaveValueGetters(metadata);
    const { lngStep, latStep } = getLatLngSteps(metadata);
    const { width, height, minLat, minLng } = metadata;
    const image = new Image(width, height);
    return new Promise((resolve) => {
      image.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        if (!canvas.getContext('2d')) {
          throw new Error('No canvas context');
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.getContext('2d').drawImage(this, 0, 0, width, height);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const [lat, lng] = options?.point;

        const pixelLat = Math.floor((lat - minLat) / latStep);
        const pixelLng = Math.floor((lng - minLng) / lngStep);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pixelData = canvas
          .getContext('2d')
          .getImageData(pixelLng, height - (pixelLat + 1), 1, 1).data;

        const values = getSWaveValues(getters, pixelData);

        resolve(values);
      };
      image.src = url;
    });
  } catch {
    return {};
  }
};
