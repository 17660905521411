import { createAction } from '@reduxjs/toolkit';

import { Route } from '../types';

export const setRoutes = createAction<Route[]>('SET_USER_ROUTES');
export const deleteRoute = createAction<number>('DELETE_USER_ROUTE');

export const RouteActions = {
  setRoutes,
  deleteRoute,
};
