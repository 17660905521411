import { useEffect } from 'react';
import { Map } from 'mapbox-gl';

export const useHandleClicksOnMap = (
  map: Map,
  onClick: (ev: mapboxgl.MapboxEvent & mapboxgl.EventData) => void,
  onCleanup?: () => void,
  isDisabled?: boolean,
): void => {
  useEffect(() => {
    if (!isDisabled) {
      map.getCanvas().style.cursor = 'crosshair';
      // map.dragPan.disable();
      map.on('click', onClick);
    }
    return (): void => {
      if (!isDisabled) {
        map.getCanvas().style.cursor = '';
        // map.dragPan.enable();
        map.off('click', onClick);
        if (onCleanup) {
          onCleanup();
        }
      }
    };
  }, [map, onClick, onCleanup, isDisabled]);
};
