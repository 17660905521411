import { Map } from 'mapbox-gl';
import { MapColors } from 'modules/map/colors';
import { Layers } from '../consts';

export const toggleDarkMode = (map: Map, isOn: boolean): void => {
  map.setPaintProperty(
    `${Layers.Ocean}-layer`,
    'fill-color',
    isOn ? MapColors.DarkOcean : MapColors.LightOcean,
  );
  map.setPaintProperty(
    `${Layers.MinorIslands}-layer`,
    'fill-color',
    isOn ? MapColors.DarkLand : MapColors.LightLand,
  );
};
