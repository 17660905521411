import React, { memo } from 'react';

import { RegularBase, H2 } from '../../typography';

import { Container } from './styledComponents';

type Props = {
  title?: string;
  text: string;
};

const MapInstructions: React.FC<Props> = ({ title, text }) => (
  <Container>
    {!!title && <H2 style={{ fontWeight: 'bold' }}>{title}</H2>}
    <RegularBase>{text}</RegularBase>
  </Container>
);

export default memo(MapInstructions);
