import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { boatLocationUrlSelector } from 'modules/settings';
import {
  LocationRefreshInterval,
  LocalhostLocationRefreshInterval,
} from '../../consts';
import { startTrackingLocation } from '../../redux/thunks';

// TODO Module for getting current location from ARSO backend

const isEnabled = true;

const LocationListener: React.FC = () => {
  const boatLocationUrl = useSelector(boatLocationUrlSelector);
  const dispatch = useDispatch();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (boatLocationUrl && isEnabled) {
      const refreshRate = boatLocationUrl.includes('localhost')
        ? LocalhostLocationRefreshInterval
        : LocationRefreshInterval;
      dispatch(startTrackingLocation(boatLocationUrl));
      const id = setInterval(
        () => dispatch(startTrackingLocation(boatLocationUrl)),
        refreshRate,
      );
      return (): void => clearInterval(id);
    }
  }, [boatLocationUrl]); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

export default React.memo(LocationListener);
