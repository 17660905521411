import React, { useCallback } from 'react';

import { RegularBase } from 'modules/ui/typography';
import { Button, Checkbox, ColorIndicator } from 'modules/ui/components';
import { RoutingColors } from 'modules/mapLayers';
import { Route } from '../../types';

import {
  ButtonsContainer,
  ButtonsLinksContainer,
  NameAndCheckboxContainer,
  CheckboxContainer,
} from './styledComponents';

type Props = {
  route: Route;
  isActive: boolean;
  toggleRouting: (routeId: number) => void;
  sourceIndex: number;
  startRouteFlow: (route?: Route) => void;
  setNewRoutingRoute: (route: Route) => void;
};

const ItemUi: React.FC<Props> = ({
  route,
  toggleRouting,
  isActive,
  sourceIndex,
  startRouteFlow,
  setNewRoutingRoute,
}) => {
  const onEdit = useCallback(() => {
    if (isActive) {
      toggleRouting(route.id);
    }
    startRouteFlow(route);
  }, [startRouteFlow, route, isActive, toggleRouting]);
  const startNewRouting = useCallback(() => {
    setNewRoutingRoute(route);
  }, [setNewRoutingRoute, route]);
  const placeholderFunction = useCallback(() => {
    console.log('placeholder');
  }, []);
  const handleChange = useCallback(() => {
    toggleRouting(route.id);
  }, [toggleRouting, route.id]);
  return (
    <>
      <NameAndCheckboxContainer>
        <RegularBase>{route.name}</RegularBase>
        <CheckboxContainer>
          {sourceIndex !== -1 && (
            <ColorIndicator
              style={{ marginRight: '16px' }}
              color={RoutingColors[sourceIndex]}
            />
          )}
          <Checkbox
            label=""
            name=""
            value={isActive}
            isSecondary={false}
            onChange={handleChange}
          />
        </CheckboxContainer>
      </NameAndCheckboxContainer>
      <ButtonsContainer>
        <ButtonsLinksContainer>
          <Button
            isSmall
            isSecondary
            label="New routing"
            clickHandler={startNewRouting}
          />
          {false && (
            <Button
              isSmall
              isSecondary
              label="Best start"
              clickHandler={placeholderFunction}
            />
          )}
          <Button isSmall isSecondary label="Edit" clickHandler={onEdit} />
        </ButtonsLinksContainer>
      </ButtonsContainer>
    </>
  );
};

export default React.memo(ItemUi);
