import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

import db from 'modules/localForage/db';
import { deleteFromObject, listToObject } from 'modules/main/service';
import { setExclusionZones, deleteExclusionZone } from './actions';
import { ExclusionZone } from '../types';

const storage = createReducer(
  {} as { [key: number]: ExclusionZone },
  (builder) => {
    builder
      .addCase(setExclusionZones, (state, action) =>
        listToObject(action.payload, state),
      )
      .addCase(deleteExclusionZone, (state, action) =>
        deleteFromObject(state, action.payload),
      );
  },
);

const hasLoaded = createReducer(false, (builder) => {
  builder.addCase(setExclusionZones, () => true);
});

const reducer = combineReducers({
  storage,
  hasLoaded,
});

const exclusionZonesReducerConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'exclusionZone',
  storage: db.app,
  whitelist: ['storage'],
};

export const exclusionZonesReducer = persistReducer(
  exclusionZonesReducerConfig,
  reducer,
);
