import { ModelLayers } from 'modules/mapLayers/consts';

import {
  WhiteCappingScale,
  CloudCoverVisualizationScale,
  CurrentSpeedScale,
  RainPrecipitationVisualizationScale,
  SeaTemperatureScale,
  TemperatureVisualizationScale,
  WaveHeightScale,
  WavePeriodScale,
  WindSpeedScale,
  WindComparisonVisualizationScale,
} from './consts';

export const getLayerScale = (
  layer: ModelLayers,
): Array<string | number | null> | undefined => {
  if (
    layer === ModelLayers.WAMWaveWhiteCapping1 ||
    layer === ModelLayers.WAMWaveWhiteCapping2
  ) {
    return WhiteCappingScale;
  }
  if (
    layer === ModelLayers.NemoOceanSpeed1 ||
    layer === ModelLayers.NemoOceanSpeed2
  ) {
    return CurrentSpeedScale;
  }
  if (
    layer === ModelLayers.WAMSwaveHeight1 ||
    layer === ModelLayers.WAMSwaveHeight2 ||
    layer === ModelLayers.WAMWwaveHeight1 ||
    layer === ModelLayers.WAMWwaveHeight2 ||
    layer === ModelLayers.WAMWaveHeight1 ||
    layer === ModelLayers.WAMWaveHeight2 ||
    layer === ModelLayers.GFSWAMSwaveHeight1 ||
    layer === ModelLayers.GFSWAMSwaveHeight2 ||
    layer === ModelLayers.GFSWAMWwaveHeight1 ||
    layer === ModelLayers.GFSWAMWwaveHeight2 ||
    layer === ModelLayers.GFSWAMWaveHeight1 ||
    layer === ModelLayers.GFSWAMWaveHeight2
  ) {
    return WaveHeightScale;
  }
  if (
    layer === ModelLayers.WAMSwavePeriod1 ||
    layer === ModelLayers.WAMSwavePeriod2 ||
    layer === ModelLayers.WAMWwavePeriod1 ||
    layer === ModelLayers.WAMWwavePeriod2 ||
    layer === ModelLayers.GFSWAMSwavePeriod1 ||
    layer === ModelLayers.GFSWAMSwavePeriod2 ||
    layer === ModelLayers.GFSWAMWwavePeriod1 ||
    layer === ModelLayers.GFSWAMWwavePeriod2
  ) {
    return WavePeriodScale;
  }
  if (
    layer === ModelLayers.ECMWFWeatherPerception1 ||
    layer === ModelLayers.ECMWFWeatherPerception2 ||
    layer === ModelLayers.GFSWeatherPerception1 ||
    layer === ModelLayers.GFSWeatherPerception2
  ) {
    return RainPrecipitationVisualizationScale;
  }
  if (
    layer === ModelLayers.ECMWFWeatherCloud1 ||
    layer === ModelLayers.ECMWFWeatherCloud2 ||
    layer === ModelLayers.GFSWeatherCloud1 ||
    layer === ModelLayers.GFSWeatherCloud2
  ) {
    return CloudCoverVisualizationScale;
  }
  if (
    layer === ModelLayers.ECMWFWeatherTemp1 ||
    layer === ModelLayers.ECMWFWeatherTemp2 ||
    layer === ModelLayers.GFSWeatherTemp1 ||
    layer === ModelLayers.GFSWeatherTemp2
  ) {
    return TemperatureVisualizationScale;
  }
  if (
    layer === ModelLayers.NemoOceanTemperature1 ||
    layer === ModelLayers.NemoOceanTemperature2
  ) {
    return SeaTemperatureScale;
  }
  if (
    layer === ModelLayers.ECMWFWindSpeed1 ||
    layer === ModelLayers.ECMWFWindSpeed2 ||
    layer === ModelLayers.GFSWindSpeed1 ||
    layer === ModelLayers.GFSWindSpeed2 ||
    layer === ModelLayers.ECMWFGustsSpeed1 ||
    layer === ModelLayers.ECMWFGustsSpeed2 ||
    layer === ModelLayers.GFSGustsSpeed1 ||
    layer === ModelLayers.GFSGustsSpeed2
  ) {
    return WindSpeedScale;
  }
  if (
    layer === ModelLayers.WindSpeedComparison1 ||
    layer === ModelLayers.WindSpeedComparison2
  ) {
    return WindComparisonVisualizationScale;
  }
  return undefined;
};
