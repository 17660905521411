import { useState, useCallback } from 'react';

const useModal = (
  initialValue: boolean,
): [boolean, (value: boolean) => void, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue || false);
  const toggleModal = useCallback((value) => setIsOpen(value), []);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  return [isOpen, toggleModal, openModal, closeModal];
};

export default useModal;
