import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { RegularBase, HighlightedBase } from 'modules/ui/typography';
import { ProgressBar } from 'modules/ui/components';
import { downloadMapLayers } from '../../redux/thunks';

import {
  Container,
  WelcomeMessage,
  ProgressBarContainer,
} from './styledComponents';

const MapLayersDownloader: React.FC = () => {
  const dispatch = useDispatch();
  const [downloadCount, setDownloadCount] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState([0]);
  const incrementDownloadProgress = useCallback(
    (progress: number, index: number) => {
      setDownloadProgress((state) => {
        const newState = [...state];
        newState[index] = progress;
        return newState;
      });
    },
    [],
  );
  useEffect(() => {
    dispatch(downloadMapLayers(setDownloadCount, incrementDownloadProgress));
  }, [dispatch, incrementDownloadProgress]);
  const percentage =
    downloadProgress.reduce((agg, curr) => agg + curr, 0) / downloadCount;
  return (
    <Container>
      <WelcomeMessage>Dorado welcomes you aboard</WelcomeMessage>
      <RegularBase>
        Cloud based weather and routing solution for sailors
      </RegularBase>
      <ProgressBarContainer>
        <ProgressBar
          type="indeterminate"
          backgroundColor=" linear-gradient(90deg, #0B83D7 13.54%, #89C72C 76.56%, #FFE500 100%)"
          percentage={percentage}
          height={12}
        />
      </ProgressBarContainer>
      <HighlightedBase>Preparing app for initial use...</HighlightedBase>
    </Container>
  );
};

export default React.memo(MapLayersDownloader);
