/* eslint-disable @typescript-eslint/no-explicit-any */
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point as turfPoint, polygon } from '@turf/helpers';

export const generateWindDirection = (speed?: number): string => {
  if (speed !== undefined) {
    return speed > 0 ? `&#xa71b;` : ``; // &#9678;
  }
  return `&#xa71b;`;
};

export const convertToKnots = (value: number): number => {
  const knot = 1.944;

  if (typeof value === 'number') {
    return value * knot;
  }
  return value;
};

export const fixNumberToDecimal = (num: number, decimal: number): number => {
  if (typeof num === 'number') {
    return Number(num.toFixed(decimal));
  }
  return num;
};

export const isRelatedPolygon = (
  box: any,
  coords: { lat: number; lng: number },
): boolean => {
  const { maxLat, minLng, minLat, maxLng } = box;
  const point = turfPoint([coords.lng, coords.lat]);
  const poly = polygon([
    [
      [minLng, maxLat],
      [minLng, minLat],
      [maxLng, minLat],
      [maxLng, maxLat],
      [minLng, maxLat],
    ],
  ]);
  return booleanPointInPolygon(point, poly);
};
