import React, { useCallback } from 'react';

import { SmallCheckIcon } from 'modules/ui/assets';
import { ChipsContainer, Label } from './styledComponents';

type Props = {
  label: string;
  onClick: (name: string, value: boolean) => void;
  value: boolean;
  name: string;
  disabled?: boolean;
  tooltipText?: string;
};

const Chips: React.FC<Props> = ({
  label,
  onClick,
  value,
  name,
  disabled,
  tooltipText,
}) => {
  const eventHandler = useCallback(() => {
    onClick(name, !value);
  }, [onClick, name, value]);

  return (
    <ChipsContainer
      data-tip={tooltipText}
      role="button"
      onClick={disabled ? undefined : eventHandler}
      isChecked={value}
      disabled={!!disabled}
    >
      {value && <SmallCheckIcon />}
      <Label disabled={!!disabled} isChecked={value}>
        {label}
      </Label>
    </ChipsContainer>
  );
};

export default React.memo(Chips);
