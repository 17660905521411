import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const FormContainer = styled.div`
  width: 320px;
  background-color: ${Colors.White};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .field-container {
    margin-bottom: 16px;
    span {
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  .contact-info {
    margin-bottom: 50px;

    a {
      text-decoration: none;
      color: #3a4ade;
      font-weight: 500;
    }
  }

  h1 {
    margin-bottom: 32px;
  }

  .info {
    margin-top: 50px;
    .dummy {
      text-decoration: none;
      color: #3a4ade;
      font-weight: 500;
      cursor: pointer;
      padding-bottom: 8px;
    }
  }
`;

export const LogoImage = styled.img`
  width: 105px;
  margin-bottom: 60px;
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

export const DownloadLink = styled.a`
  text-decoration: none;
`;

export const BroughtByContainer = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-items: flex-end;
  align-items: center;
  > span {
    color: ${Colors.SolidGray};
  }
  > a {
    margin-left: 24px;
  }
  & > a:nth-child(3) {
    margin-bottom: 5px;
  }
`;
