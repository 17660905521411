import React, { memo } from 'react';

import { ActionContainer, LinkLabelText } from './styledComponents';

type Props = {
  label: string;
  Icon?: JSX.Element;
  onClick: () => void;
};

const ActionItemUi: React.FC<Props> = ({ Icon, label, onClick }) => (
  <ActionContainer onClick={onClick}>
    {Icon}
    <LinkLabelText>{label}</LinkLabelText>
  </ActionContainer>
);

export const ActionItem = memo(ActionItemUi);
