import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isUiVisibleSelector, showAllUi, hideAllUi } from '../redux';

export const useUiVisibility = (): {
  isUiVisible: boolean;
  showUi: () => void;
  hideUi: () => void;
} => {
  const dispatch = useDispatch();
  const isUiVisible = useSelector(isUiVisibleSelector);

  const hideUi = useCallback(() => {
    dispatch(hideAllUi());
  }, [dispatch]);

  const showUi = useCallback(() => {
    dispatch(showAllUi());
  }, [dispatch]);

  return {
    isUiVisible,
    showUi,
    hideUi,
  };
};
