import distance from '@turf/distance';

const clearCoordinatesForDisplay = (coordinate: number): number => {
  let copy = coordinate;
  while (copy < -180) {
    copy += 360;
  }
  while (copy > 180) {
    copy -= 360;
  }
  return copy;
};

export const clearCoordinatesForMeteoDownload = (
  minLat: number,
  maxLat: number,
): number[] => {
  const needCorrection = minLat < -180;
  if (!needCorrection) return [minLat, maxLat];
  let minLatCopy = minLat;
  let maxLatCopy = maxLat;
  while (minLatCopy < -180) {
    minLatCopy += 360;
    maxLatCopy += 360;
  }
  return [minLatCopy, maxLatCopy];
};

export const toDegreesMinutesAndSeconds = (
  coordinate: number,
  isLat?: boolean,
  decimalLength?: number,
): string => {
  const cleanCoordinate = isLat
    ? coordinate
    : clearCoordinatesForDisplay(coordinate);
  const absolute = Math.abs(cleanCoordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = minutesNotTruncated.toFixed(decimalLength || 3);
  const direction =
    // eslint-disable-next-line no-nested-ternary
    cleanCoordinate < 0 ? (isLat ? 'S' : 'W') : isLat ? 'N' : 'E';
  return `${degrees < 10 ? '0' : ''}${degrees}°${
    minutesNotTruncated < 10 ? '0' : ''
  }${minutes}'${direction}`;
};

export const toDegreesAndMinutes = (
  coordinate: number,
  isLat?: boolean,
): string => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  // eslint-disable-next-line no-nested-ternary
  const direction = coordinate < 0 ? (isLat ? 'S' : 'W') : isLat ? 'N' : 'E';
  return `${degrees < 10 ? '0' : ''}${degrees}°${
    minutes < 10 ? '0' : ''
  }${minutes}'${direction}`;
};

export const parseFraction = (fraction: string | number): number => {
  const parsedFraction = parseInt(fraction.toString(), 10);
  if (parsedFraction < 10) {
    return parsedFraction / 10;
  }
  if (parsedFraction < 100) {
    return parsedFraction / 100;
  }
  return parsedFraction / 1000;
};

export const getDegreesMinutesAndSecondsArray = (
  coordinate: number,
): number[] => {
  const formattedCoordinates = clearCoordinatesForDisplay(coordinate);
  const absolute = Math.abs(formattedCoordinates);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const minutesFraction = Number((minutesNotTruncated - minutes).toFixed(3));
  const minutesFractionValue = minutesFraction === 1 ? 0 : minutesFraction;
  return [degrees, minutes, minutesFractionValue * 1000];
};

export const getDirection = (coordinate: number, isLat?: boolean): string => {
  const formattedCoordinates = clearCoordinatesForDisplay(coordinate);
  // eslint-disable-next-line no-nested-ternary
  return formattedCoordinates < 0 ? (isLat ? 'S' : 'W') : isLat ? 'N' : 'E';
};

export const convertDMSToDD = (
  [degrees, minutes, minutesFraction]: number[],
  direction: string,
): number => {
  let dd = degrees + (minutes + parseFraction(minutesFraction)) / 60;

  if (direction === 'S' || direction === 'W') {
    dd *= -1;
  } // Don't do anything for N or E
  return dd;
};

export const roundCoordinates = (
  coordinate: number,
  isMax: boolean,
): number => {
  return isMax ? Math.ceil(coordinate * 4) / 4 : Math.floor(coordinate * 4) / 4;
};

export const getNauticalMilesDistance = (
  coordinatesFrom: number[],
  coordinatesTo: number[],
): number => {
  const distanceInMiles = distance(coordinatesFrom, coordinatesTo, {
    units: 'miles',
  });
  return Number((distanceInMiles / 1.151).toFixed(2));
};
