import ky from 'ky';
import { point, featureCollection, Feature, Point } from '@turf/helpers';
import { format } from 'date-fns';

import { FleetApiModule } from 'modules/api';
import { ThunkAction } from 'modules/main/redux';
import { NotificationTypes } from 'modules/notifications';
import { addNotification } from 'modules/notifications/redux';
import {
  addDownload,
  updateDownload,
  DownloadType,
  addToHistory,
  removeDownload,
  addToDownloadHistoryCounter,
} from 'modules/downloads';
import { boatLocationUrlSelector } from 'modules/settings';
import { startTrackingLocation } from 'modules/location';
import { setFleetLineGeojson, setFleetBoatGeojson } from './actions';

export const getFleetDataThunk = (): ThunkAction<void> => async (
  dispatch,
  getState,
): Promise<void> => {
  const state = getState();
  const boatLocationUrl = boatLocationUrlSelector(state);
  const date = new Date();
  const downloadName = `Fleet data ${format(date, "LL-dd HH:mm 'UTC'")}`;
  const downloadId = `fleet-data-${date.getTime()}`;
  try {
    const download = {
      name: downloadName,
      downloaded: false,
      id: downloadId,
      type: DownloadType.Meteogram,
    };
    dispatch(addDownload(download));
    let totalBytes = 0;
    const response = await ky.get(FleetApiModule.getFleet, {
      onDownloadProgress: (progress) => {
        totalBytes = progress.totalBytes;
        dispatch(
          updateDownload({
            id: downloadId,
            progress: progress.percent * 100,
          }),
        );
      },
    });
    const contentSize = Number((Number(totalBytes) / 1024).toFixed(2)) || 4;

    dispatch(
      addToHistory({
        ...download,
        size: contentSize,
      }),
    );
    dispatch(addToDownloadHistoryCounter(contentSize));
    const data: GeoJSON.FeatureCollection = await response.json();
    const boatPoints = data.features
      .map(
        ({ properties }) =>
          properties &&
          point([properties.lastLon, properties.lastLat], properties),
      )
      .filter((_) => _) as Feature<
      Point,
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [name: string]: any;
      }
    >[];
    const boatData = featureCollection(boatPoints);
    dispatch(setFleetLineGeojson(data));
    dispatch(setFleetBoatGeojson(boatData));
    dispatch(
      addNotification({
        type: NotificationTypes.Success,
        message: 'Fleet data has been updated successfully.',
      }),
    );
    if (boatLocationUrl) {
      dispatch(startTrackingLocation(boatLocationUrl));
    }
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'Error while getting fleet location.',
      }),
    );
  } finally {
    dispatch(
      removeDownload({
        id: downloadId,
      }),
    );
  }
};
