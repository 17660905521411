import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Map } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { ModelLayers, ModelLayersSources } from 'modules/mapLayers/consts';
import { MeteoTabProperties } from 'modules/meteo/types';
import {
  windComparisonOptionsSelector,
  windComparisonSourcesSelector,
} from 'modules/meteo/redux/selectors';
import { getWindComparisonDataFromJpegs } from 'modules/meteo/utils/wind';
import { Chips } from 'modules/ui/components';
import { mergeGeoJSONs } from 'modules/geojson/utils';
import ReactTooltip from 'react-tooltip';
import MeteoModelAccordion from '../MeteoModelAccordion';
import EmptyDataComponent from '../EmptyDataComponent';
import ModelCountIndicator from '../ModelCountIndicator';

type Props = {
  map: Map;
  showMeteoData: boolean;
  addToScaleArray: (layer: ModelLayers) => void;
  removeFromScaleArray: (layer: ModelLayers) => void;
  turnOnShowMeteoData: () => void;
  toggleTab: (tab: string) => void;
  tabName: string;
} & Omit<MeteoTabProperties, 'tabName' | 'toggleTab'>;

const WindComparisonData: React.FC<Props> = ({
  isOpened,
  toggleTab,
  tabName,
  map,
  showMeteoData,
  removeFromScaleArray,
  addToScaleArray,
  turnOnShowMeteoData,
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const isLayerVisible = useMemo(() => !!selectedKeys.length, [selectedKeys]);
  const handleToggleTab = useCallback(() => {
    toggleTab(tabName);
  }, [toggleTab, tabName]);
  const options = useSelector(windComparisonOptionsSelector);
  const sources = useSelector(windComparisonSourcesSelector);
  const handleChipsPress = useCallback((name: string) => {
    setSelectedKeys((state) => {
      if (state.includes(name)) {
        return state.filter((k) => k !== name);
      }
      return [...state, name];
    });
  }, []);

  const handleWindSources = useCallback(
    async (keys: Array<{ ecmwfStorageKey: string; gfsStorageKey: string }>) => {
      const jsons = await Promise.all(
        keys.map((k) =>
          getWindComparisonDataFromJpegs(k.ecmwfStorageKey, k.gfsStorageKey),
        ),
      );
      const polygonGeojson1 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson1).filter((_) => _),
      );
      const polygonGeojson2 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson2).filter((_) => _),
      );
      const pointGeoJson1 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson1).filter((_) => _),
      );
      const pointGeoJson2 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson2).filter((_) => _),
      );

      map
        .getSource(ModelLayersSources.WindComparisonPoint1)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson1);
      map
        .getSource(ModelLayersSources.WindComparisonPoint2)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson2);
      map
        .getSource(ModelLayersSources.WindComparisonPolygon1)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson1);
      map
        .getSource(ModelLayersSources.WindComparisonPolygon2)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useDeepCompareEffect(() => {
    if (selectedKeys.length) {
      const filteredSources = sources.filter(
        (source) =>
          selectedKeys.includes(source.id) &&
          source.ecmwfStorageKey &&
          source.gfsStorageKey,
      ) as {
        id: string;
        ecmwfStorageKey: string;
        gfsStorageKey: string;
      }[];
      handleWindSources(filteredSources);
    }
  }, [sources, selectedKeys]);

  useEffect(() => {
    if (!isLayerVisible || !showMeteoData) {
      removeFromScaleArray(ModelLayers.WindSpeedComparison1);
      removeFromScaleArray(ModelLayers.WindSpeedComparison2);
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparison1,
        'visibility',
        'none',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparison2,
        'visibility',
        'none',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonEcmwfDirection1,
        'visibility',
        'none',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonEcmwfDirection2,
        'visibility',
        'none',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonGfsDirection1,
        'visibility',
        'none',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonGfsDirection2,
        'visibility',
        'none',
      );
    } else {
      addToScaleArray(ModelLayers.WindSpeedComparison1);
      addToScaleArray(ModelLayers.WindSpeedComparison2);
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparison1,
        'visibility',
        'visible',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparison2,
        'visibility',
        'visible',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonEcmwfDirection1,
        'visibility',
        'visible',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonEcmwfDirection2,
        'visibility',
        'visible',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonGfsDirection1,
        'visibility',
        'visible',
      );
      map.setLayoutProperty(
        ModelLayers.WindSpeedComparisonGfsDirection2,
        'visibility',
        'visible',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLayerVisible, showMeteoData]);

  useEffect(() => {
    if (isLayerVisible) {
      turnOnShowMeteoData();
    }
  }, [isLayerVisible, turnOnShowMeteoData]);

  useDeepCompareEffect(() => {
    ReactTooltip.rebuild();
  }, [options]);

  return (
    <MeteoModelAccordion
      handleToggleTab={handleToggleTab}
      isOpened={isOpened}
      title="ECMWF/GFS wind comparison"
      titleComponent={
        selectedKeys.length ? (
          <ModelCountIndicator>{selectedKeys.length}</ModelCountIndicator>
        ) : undefined
      }
    >
      {!options.length && (
        <EmptyDataComponent text="Download both the ECMWF and GFS wind data to enable comparison." />
      )}
      {!!options.length && (
        <div>
          {options.map((option) => {
            const isActive = selectedKeys.includes(option.id);
            return (
              <Chips
                tooltipText={option.info}
                key={option.id}
                value={showMeteoData && isActive}
                name={option.id}
                label={option.label}
                onClick={handleChipsPress}
              />
            );
          })}
        </div>
      )}
    </MeteoModelAccordion>
  );
};

export default React.memo(WindComparisonData);
