import { apiClient } from 'modules/apiClient';
import { Boat } from '../types';

const getBoats = async (): Promise<Boat[]> => {
  const response = await apiClient.get('/v1/users/boats');
  return response.data;
};

type UpdateBoatProps = {
  id: number;
  name: string;
  files: {
    polars: File | null;
    sails: File | null;
    wave: File | null;
  };
};

const updateBoat = async ({
  id,
  files,
  name,
}: UpdateBoatProps): Promise<Boat> => {
  const formData = new FormData();
  formData.append('name', name);
  if (files.polars) {
    formData.append('polars', files.polars);
  }
  if (files.sails) {
    formData.append('sails', files.sails);
  }
  if (files.wave) {
    formData.append('wave', files.wave);
  }
  const response = await apiClient.patch(`/v1/boats/${id}`, formData);
  return response.data;
};

type CreateBoatProps = {
  name: string;
  files: {
    polars: File | null;
    sails: File | null;
    wave: File | null;
  };
};

const createBoat = async ({ files, name }: CreateBoatProps): Promise<Boat> => {
  const formData = new FormData();
  formData.append('name', name);
  if (files.polars) {
    formData.append('polars', files.polars);
  }
  if (files.sails) {
    formData.append('sails', files.sails);
  }
  if (files.wave) {
    formData.append('wave', files.wave);
  }
  const response = await apiClient.post(`/v1/boats/`, formData);
  return response.data;
};

export const BoatsApi = { getBoats, updateBoat, createBoat };
