import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';

import { Chips } from 'modules/ui/components';
import { ModelLayers } from 'modules/mapLayers/consts';
import { MeteoTabProperties } from 'modules/meteo/types';
import MeteoModelAccordion from '../MeteoModelAccordion';
import TimeOptions from '../TimeOptions';
import {
  infoGfsWamTimeOptionsSelector,
  gfsWamTimeSetSelector,
  currentGfsWamDataSetsComponentsSelector,
} from '../../redux/selectors';
import { setGfsWamTimeSet } from '../../redux/actions';
import { useWamSourceAndLayers } from '../../hooks';
import { MeteoSources } from '../../consts';
import ModelCountIndicator from '../ModelCountIndicator';
import EmptyDataComponent from '../EmptyDataComponent';

type Props = {
  map: Map;
  showMeteoData: boolean;
  addToScaleArray: (layer: ModelLayers) => void;
  removeFromScaleArray: (layer: ModelLayers) => void;
  turnOnShowMeteoData: () => void;
} & MeteoTabProperties;

const WamMeteoData: React.FC<Props> = ({
  map,
  showMeteoData,
  addToScaleArray,
  removeFromScaleArray,
  turnOnShowMeteoData,
  tabName,
  toggleTab,
  isOpened,
}) => {
  const dispatch = useDispatch();
  const selectedTime = useSelector(gfsWamTimeSetSelector);
  const options = useSelector(infoGfsWamTimeOptionsSelector);
  const { isSwellPresent, isWavePresent, isWwavePresent } = useSelector(
    currentGfsWamDataSetsComponentsSelector,
  );

  const {
    componentsCount,
    isSwaveDirectionVisible,
    isSwaveHeightVisible,
    isSwavePeriodVisible,
    isWaveDirectionVisible,
    isWaveHeightVisible,
    isWwaveDirectionVisible,
    isWwaveHeightVisible,
    isWwavePeriodVisible,
    setIsSwaveDirectionVisible,
    setIsSwaveHeightVisible,
    setIsSwavePeriodVisible,
    setIsWaveDirectionVisible,
    setIsWaveHeightVisible,
    setIsWwaveDirectionVisible,
    setIsWwaveHeightVisible,
    setIsWwavePeriodVisible,
  } = useWamSourceAndLayers(
    MeteoSources.ECMWFWAM,
    map,
    showMeteoData,
    addToScaleArray,
    removeFromScaleArray,
    turnOnShowMeteoData,
  );

  const handleTimeSetOption = useCallback(
    (value: string) => {
      dispatch(setGfsWamTimeSet(value));
    },
    [dispatch],
  );

  const handleToggleTab = useCallback(() => {
    toggleTab(tabName);
  }, [toggleTab, tabName]);

  return (
    <MeteoModelAccordion
      isOpened={isOpened}
      handleToggleTab={handleToggleTab}
      title="GFS Wavewatch III wave model"
      titleComponent={
        componentsCount ? (
          <ModelCountIndicator>{componentsCount}</ModelCountIndicator>
        ) : undefined
      }
    >
      <TimeOptions
        options={options}
        selectedTime={selectedTime}
        handleTimeSetOption={handleTimeSetOption}
      />
      {!options.length && <EmptyDataComponent />}
      {!!options.length && (
        <div>
          <Chips
            value={showMeteoData && isSwaveHeightVisible}
            name="swellHeight"
            label="Swell Height"
            onClick={setIsSwaveHeightVisible}
            disabled={!isSwellPresent}
          />
          <Chips
            value={showMeteoData && isSwaveDirectionVisible}
            name="swellDirection"
            label="Swell Direction"
            onClick={setIsSwaveDirectionVisible}
            disabled={!isSwellPresent}
          />
          <Chips
            value={showMeteoData && isSwavePeriodVisible}
            name="swellPeriod"
            label="Swell Period"
            onClick={setIsSwavePeriodVisible}
            disabled={!isSwellPresent}
          />
          <Chips
            value={showMeteoData && isWwaveHeightVisible}
            name="windHeight"
            label="Wind Waves Height"
            onClick={setIsWwaveHeightVisible}
            disabled={!isWwavePresent}
          />
          <Chips
            value={showMeteoData && isWwaveDirectionVisible}
            name="windDirection"
            label="Wind Waves Direction"
            onClick={setIsWwaveDirectionVisible}
            disabled={!isWwavePresent}
          />
          <Chips
            value={showMeteoData && isWwavePeriodVisible}
            name="windPeriod"
            label="Wind Waves Period"
            onClick={setIsWwavePeriodVisible}
            disabled={!isWwavePresent}
          />
          <Chips
            value={showMeteoData && isWaveHeightVisible}
            name="wavesHeight"
            label="Waves Height"
            onClick={setIsWaveHeightVisible}
            disabled={!isWavePresent}
          />
          <Chips
            value={showMeteoData && isWaveDirectionVisible}
            name="wavesDirection"
            label="Waves Direction"
            onClick={setIsWaveDirectionVisible}
            disabled={!isWavePresent}
          />
        </div>
      )}
    </MeteoModelAccordion>
  );
};

export default React.memo(WamMeteoData);
