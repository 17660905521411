import { createSelector } from '@reduxjs/toolkit';

import { RootState, Storage } from 'modules/main/redux';

import { InclusionZone } from '../types';

const inclusionZonesStorage = (state: RootState): Storage<InclusionZone> =>
  state.inclusionZones.storage;

const hasLoaded = (state: RootState): boolean =>
  !!state.inclusionZones.hasLoaded;

const inclusionZonesList = createSelector([inclusionZonesStorage], (storage) =>
  Object.values(storage),
);

const inclusionZonesDropdownListOptions = createSelector(
  [inclusionZonesList],
  (list) => list.map(({ id, name }) => ({ value: id, label: name })),
);

const inclusionZoneById = (
  state: RootState,
  id: number | null,
): InclusionZone | null => (id ? state.inclusionZones.storage[id] : null);

export const InclusionZonesSelectors = {
  inclusionZonesStorage,
  hasLoaded,
  inclusionZonesList,
  inclusionZoneById,
  inclusionZonesDropdownListOptions,
};
