import { RootState } from 'modules/main/redux';
import { LayersVersions, Layers } from '../consts';

export const layersToDownloadSelector = (state: RootState): string[] =>
  Object.entries(state.mapLayers.mapLayersVersions)
    .filter(
      ([layer, version]) => version < LayersVersions[layer as Layers] || 0,
    )
    .map(([layer]) => layer);

export const areMapLayersDownloadedSelector = (state: RootState): boolean =>
  !layersToDownloadSelector(state).length;
