import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { useMemo } from 'react';

import { hourOptions, minutesOptions } from '../consts';
import { getUtcDate } from '../utils';

export const useGetTimeOptions = () => {
  const datesOptions = useMemo(() => {
    let today = getUtcDate();
    const hours = today.getHours();
    if (hours < 5) {
      today = addDays(today, -1);
    }
    return [0, 1, 2, 3, 4, 5, 6].map((dayOffset) => {
      const date = addDays(today, dayOffset);
      return {
        value: format(date, 'yyyy-MM-dd'),
        label: format(date, 'EEE dd'),
      };
    });
  }, []);

  const todayHoursOptions = useMemo(() => {
    let todayHour = new Date().getUTCHours();
    if (todayHour < 5) {
      todayHour = todayHour - 5 + 24;
    } else {
      todayHour -= 5;
    }
    const options = hourOptions.filter((value, index) => index >= todayHour);
    return options.length ? options : hourOptions;
  }, []);
  const todayMinutesOptions = useMemo(() => {
    const todayMinutes = new Date().getUTCMinutes();
    const options = minutesOptions.filter(
      (value, index) => index * 5 >= todayMinutes,
    );
    return options.length ? options : hourOptions;
  }, []);

  return {
    datesOptions,
    todayHoursOptions,
    todayMinutesOptions,
  };
};
