import React, { useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import logo from 'modules/ui/assets/images/logo.png';
import ArsoMeteo from 'modules/ui/assets/images/arsoMeteo.png';
import { TextInput, Button, InputError } from 'modules/ui/components';
import { HighlightedSm, RegularSm } from 'modules/ui/typography';
import { Routes } from 'Routes';
import { validateEmail, validatePassword } from 'modules/form';
import { AuthImageContainer } from '../../components';
import { useLogInUser } from '../../hooks';
import {
  FormContainer,
  BroughtByContainer,
  LogoImage,
  DownloadLink,
  RegisterContainer,
} from './styledComponents';

const ChromeRegex = /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\\.]+)(:?\s|$)/;

const LoginPage: React.FC = () => {
  const { error, handleLogin, loading } = useLogInUser();
  const onSubmit = useCallback(
    async (data) => {
      const isChrome = !!ChromeRegex.test(navigator.userAgent);
      if (
        isChrome ||
        (!isChrome &&
          // eslint-disable-next-line no-alert
          window.confirm(
            'Dorado has been tested and optimized to work best in Google Chrome. As you are not using Chrome, you might not have the best experience using the app. Are you sure you want to continue?',
          ))
      ) {
        await handleLogin(data);
      }
    },
    [handleLogin],
  );

  return (
    <AuthImageContainer>
      <FormContainer>
        <LogoImage src={logo} alt="Dorado sail" />
        <h1>Log in</h1>
        <Form
          onSubmit={onSubmit}
          initialValues={{ email: '', password: '' }}
          render={({
            handleSubmit,
            invalid,
            dirtySinceLastSubmit,
          }): React.ReactNode => (
            <form>
              <div className="field-container">
                <Field
                  name="email"
                  type="email"
                  component={TextInput}
                  width={320}
                  label="Email"
                  placeholder="example@mail.com"
                  validate={validateEmail}
                />
              </div>
              <div className="field-container">
                <Field
                  name="password"
                  type="password"
                  component={TextInput}
                  width={320}
                  label="Password"
                  validate={validatePassword}
                />
                {!invalid && !dirtySinceLastSubmit && error !== null && (
                  <InputError>{error}</InputError>
                )}
              </div>
              <Button
                disabled={invalid}
                stretch
                label="Login"
                isLoading={loading}
                clickHandler={handleSubmit}
                type="submit"
              />
              <RegisterContainer>
                <RegularSm>
                  Don’t have an account?{' '}
                  <Link to={Routes.Register}>
                    <HighlightedSm>Register here</HighlightedSm>
                  </Link>
                  .
                </RegularSm>
              </RegisterContainer>
            </form>
          )}
        />
        <BroughtByContainer>
          <RegularSm>In partnership with</RegularSm>
          <DownloadLink href="https://meteo.arso.gov.si/" target="_blank">
            <img src={ArsoMeteo} alt="Arso Meteo" />
          </DownloadLink>
        </BroughtByContainer>
      </FormContainer>
    </AuthImageContainer>
  );
};

export default React.memo(LoginPage);
