import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'Routes';

import { AuthApi } from '../api';
import { RegisterApiBody } from '../types';

export const useRegisterUser = (): {
  error: string;
  loading: boolean;
  handleRegister: (values: RegisterApiBody) => Promise<void>;
} => {
  const history = useHistory();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleRegister = useCallback(
    async (data: RegisterApiBody) => {
      try {
        setLoading(true);
        await AuthApi.registerUser(data);
        history.push(Routes.RegistrationSuccess);
      } catch (err) {
        setError(
          'There has been an error. Click contact us below to find more details.',
        );
      } finally {
        setLoading(false);
      }
    },
    [history],
  );
  return { error, handleRegister, loading };
};
