import React from 'react';

import { Modal, Button, ModalFooter } from 'modules/ui/components';
import { RegularBase } from 'modules/ui/typography';
import { useDeleteExclusionZone } from 'modules/exclusionZones/hooks';
import { ExclusionZone } from 'modules/exclusionZones/types';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  exclusionZone: ExclusionZone;
  onDelete: () => void;
};

const DeleteExclusionZoneModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  exclusionZone,
  onDelete,
}) => {
  const { handleDelete, loading } = useDeleteExclusionZone(
    onDelete,
    exclusionZone,
  );
  return (
    <Modal
      isOpen={isOpen}
      width={600}
      title="Delete exclusion zone"
      closeModal={closeModal}
      onRequestClose={closeModal}
    >
      <>
        <div style={{ padding: 16 }}>
          <RegularBase>
            Are you sure you want to delete this exclusion zone?
          </RegularBase>
        </div>
        <ModalFooter>
          <Button isSecondary label="Cancel" clickHandler={closeModal} />
          <Button
            label="Delete"
            isLoading={loading}
            clickHandler={handleDelete}
          />
        </ModalFooter>
      </>
    </Modal>
  );
};

export default React.memo(DeleteExclusionZoneModal);
