import { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthApi } from '../api';

export const useVerifyUser = (): {
  error: string;
  loading: boolean;
} => {
  const { search } = useLocation();
  const { otp } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      otp: params.get('token'),
    };
  }, [search]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const handleVerification = useCallback(async () => {
    try {
      if (!otp) {
        setError('Check again your link in the email');
        return;
      }
      await AuthApi.verifyAccount({ otp });
    } catch (err) {
      setError('Error while verifying your account');
    } finally {
      setLoading(false);
    }
  }, [otp]);

  useEffect(() => {
    handleVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { error, loading };
};
