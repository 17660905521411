import styled from 'styled-components';

export const Subtitle = styled.span<{ addTopPadding?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #676a7c;
  margin-bottom: 12px;
  line-height: 14px;
  text-transform: uppercase;
  padding-top: ${({ addTopPadding }): string =>
    addTopPadding ? '32px' : 'none'};
`;
