import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications';
import { ExclusionZonesApi } from '../api';
import { ExclusionZoneActions } from '../redux';
import { UploadFromFileForm } from '../types';

export const useBatchCreateExclusionZones = (
  onSuccess: () => void,
): {
  loading: boolean;
  handleBatchCreate: (values: UploadFromFileForm) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleBatchCreate = useCallback(
    async (values: UploadFromFileForm) => {
      setLoading(true);
      try {
        const returnedRoutes = await ExclusionZonesApi.createBatchExclusionZones(
          values,
        );
        dispatch(ExclusionZoneActions.setExclusionZones(returnedRoutes));
        dispatch(
          addNotification({
            type: NotificationTypes.Success,
            message: `${returnedRoutes.length} exclusion zone has been created successfully.`,
          }),
        );
        onSuccess();
      } catch (err) {
        dispatch(
          addNotification({
            type: NotificationTypes.Error,
            message: `Error while creating exclusion zones.`,
          }),
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, onSuccess],
  );

  return {
    loading,
    handleBatchCreate,
  };
};
