import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { H2 } from 'modules/ui/typography';

export const ContentRow = styled.div<{ marginTop?: number }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  > div:nth-child(n) {
    margin-right: 16px;
  }
  > label:first-child {
    margin-right: 16px;
  }
  margin-top: ${({ marginTop }): number => marginTop || 0}px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  flex: 1;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
`;

export const FooterContainer = styled.div`
  height: 72px;
  background: ${Colors.LightGray};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  > button:nth-child(1) {
    margin-right: 16px;
  }
`;

export const Title = styled(H2)<{ noMargin?: boolean }>`
  margin-bottom: ${({ noMargin }): number => (noMargin ? 0 : 16)}px;
`;

export const Separator = styled.div`
  min-height: 1px;
  width: 100%;
  background: ${Colors.Gray};
  margin: 24px 0 16px 0;
`;

export const CoordinatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const TimePickersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
`;

export const TimePickersValues = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ active }): number => (active ? 1 : 0.5)};
  pointer-events: ${({ active }): string | undefined =>
    active ? undefined : 'none'};
  & > div {
    margin-right: 8px;
  }
`;

export const CheckboxContainer = styled.div<{ hideMargin?: boolean }>`
  margin-top: ${({ hideMargin }): number => (hideMargin ? 0 : 22)}px;
  display: flex;
  flex-direction: row;
`;
