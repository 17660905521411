import bboxPolygon from '@turf/bbox-polygon';
import { Feature, Properties, Polygon, polygon } from '@turf/helpers';
import booleanDisjoint from '@turf/boolean-disjoint';

import db from 'modules/localForage/db';
import { ThunkAction } from 'modules/main/redux';
import {
  deleteMultipleFromLocalForage,
  storeToLocalForage,
} from 'modules/localForage/utils';
import { addDownload, updateDownload } from 'modules/downloads';

import { DownloadType } from 'modules/downloads/consts';
import { roundCoordinates } from 'modules/coordinates';
import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications';
import { apiClient } from 'modules/apiClient';

import {
  addToMeteoQueue,
  createEcmwfMeteoModelDataSet,
  downloadedMeteoComponent,
  addToEcmwfMeteoModelDataSet,
  createGfsMeteoModelDataSet,
  addToGfsMeteoModelDataSet,
  createWamMeteoModelDataSet,
  addToWamMeteoModelDataSet,
  createNemoMeteoModelDataSet,
  addToNemoMeteoModelDataSet,
  removeEcmwfMeteoModelDataSet,
  removeGfsMeteoModelDataSet,
  removeWamMeteoModelDataSet,
  removeNemoMeteoModelDataSet,
  createGfsWamMeteoModelDataSet,
  addToGfsWamMeteoModelDataSet,
  removeGfsWamMeteoModelDataSet,
} from './actions';
import {
  TimeResolutionHourDistance,
  generateDownloadLink,
  MeteoDataTypes,
  MeteoSources,
  generateLastGribMetadataLink,
  MeteoProviders,
} from '../consts';
import {
  ecmwfDataSetByIdSelector,
  gfsDataSetByIdSelector,
  nemoDataSetByIdSelector,
  wamDataSetByIdSelector,
  gfsWamDataSetByIdSelector,
} from './selectors';
import { DownloadQueueItem, MeteoForm, StoredLocalFile } from '../types';
import { filterTimeResolution, formatISO } from '../utils/time';
import { splineMslpLines } from '../utils/mslp';
import { getModelMetadataApi } from '../api';

export const startDownloadMeteoDataThunk = (
  values: MeteoForm,
  inputMaxLat: number,
  inputMinLat: number,
  inputMaxLng: number,
  inputMinLng: number,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  const downloads: Array<DownloadQueueItem> = [];
  const ecmwfModelId = `ecmwf-${new Date().getTime()}`;
  const gfsModelId = `gfs-${new Date().getTime()}`;
  const wamModelId = `wam-${new Date().getTime()}`;
  const gfsWamModelId = `gfs-wam-${new Date().getTime()}`;
  const nemoModelId = `nemo-${new Date().getTime()}`;
  const downloadId = `download-${new Date().getTime()}`;
  const downloadName = `Meteo update ${formatISO(new Date())}`;

  const maxLat = roundCoordinates(inputMaxLat, true);
  const minLat = roundCoordinates(inputMinLat, false);
  const maxLng = roundCoordinates(inputMaxLng, true);
  const minLng = roundCoordinates(inputMinLng, false);
  const selectedAreaBbox = bboxPolygon([minLng, minLat, maxLng, maxLat]);

  const ecmwfDataRequested =
    values.ecmwf.wind.checked ||
    values.ecmwf.MSLP.checked ||
    values.ecmwf.weather.checked;

  const gfsDataRequested =
    values.gfs.wind.checked ||
    values.gfs.MSLP.checked ||
    values.gfs.weather.checked;

  const ecmwfWamDataRequested =
    values.ecmwfwam.significantWaves.checked ||
    values.ecmwfwam.swellWaves.checked ||
    values.ecmwfwam.windWaves.checked;

  const gfsWamDataRequested =
    values.gfswam.significantWaves.checked ||
    values.gfswam.swellWaves.checked ||
    values.gfswam.windWaves.checked;

  const nemoDataRequested = values.nemo.checked;

  const ecmwfWindTimestampLink = generateLastGribMetadataLink(
    MeteoProviders.ECMWF,
    MeteoDataTypes.Wind,
  );
  const gfsWindTimestampLink = generateLastGribMetadataLink(
    MeteoProviders.NCEP,
    MeteoDataTypes.Wind,
  );
  const ecmwfWaveTimestampLink = generateLastGribMetadataLink(
    MeteoProviders.ECMWF,
    MeteoDataTypes.Wave,
  );
  const gfsWaveTimestampLink = generateLastGribMetadataLink(
    MeteoProviders.NCEP,
    MeteoDataTypes.Wave,
  );
  const nemoOceanTimestampLink = generateLastGribMetadataLink(
    MeteoProviders.Mercator,
    MeteoDataTypes.Ocean,
  );

  let ecmwfTime = '';
  let gfsTime = '';
  let ecmwfWamTime = '';
  let gfsWamTime = '';
  let nemoTime = '';

  let ecmwfBbox: Feature<Polygon, Properties> = polygon([]);
  let gfsBbox: Feature<Polygon, Properties> = polygon([]);
  let ecmwfWamBbox: Feature<Polygon, Properties> = polygon([]);
  let gfsWamBbox: Feature<Polygon, Properties> = polygon([]);
  let nemoBbox: Feature<Polygon, Properties> = polygon([]);

  let isEcmwfAreaValid = false;
  let isGfsAreaValid = false;
  let isEcmwfWamAreaValid = false;
  let isGfsWamAreaValid = false;
  let isNemoAreaValid = false;

  try {
    const [
      ecmwfResponse,
      gfsResponse,
      ecmwfWamResponse,
      gfsWamResponse,
      nemoResponse,
    ] = await Promise.all([
      getModelMetadataApi(ecmwfWindTimestampLink),
      getModelMetadataApi(gfsWindTimestampLink),
      getModelMetadataApi(ecmwfWaveTimestampLink),
      getModelMetadataApi(gfsWaveTimestampLink),
      getModelMetadataApi(nemoOceanTimestampLink),
    ]);
    const {
      time: ecmwfResponseTime,
      maxLat: ecmwfMaxLat,
      maxLon: ecmwfMaxLon,
      minLat: ecmwfMinLat,
      minLon: ecmwfMinLon,
    } = ecmwfResponse;
    const {
      time: gfsResponseTime,
      maxLat: gfsMaxLat,
      maxLon: gfsMaxLon,
      minLat: gfsMinLat,
      minLon: gfsMinLon,
    } = gfsResponse;
    const {
      time: ecmwfWamResponseTime,
      maxLat: ecmwfWamMaxLat,
      maxLon: ecmwfWamMaxLon,
      minLat: ecmwfWamMinLat,
      minLon: ecmwfWamMinLon,
    } = ecmwfWamResponse;
    const {
      time: gfsWamResponseTime,
      maxLat: gfsWamMaxLat,
      maxLon: gfsWamMaxLon,
      minLat: gfsWamMinLat,
      minLon: gfsWamMinLon,
    } = gfsWamResponse;
    const {
      time: nemoResponseTime,
      maxLat: nemoMaxLat,
      maxLon: nemoMaxLon,
      minLat: nemoMinLat,
      minLon: nemoMinLon,
    } = nemoResponse;
    ecmwfTime = ecmwfResponseTime;
    gfsTime = gfsResponseTime;
    ecmwfWamTime = ecmwfWamResponseTime;
    gfsWamTime = gfsWamResponseTime;
    nemoTime = nemoResponseTime;
    ecmwfBbox = bboxPolygon([
      ecmwfMinLon,
      ecmwfMinLat,
      ecmwfMaxLon,
      ecmwfMaxLat,
    ]);
    gfsBbox = bboxPolygon([gfsMinLon, gfsMinLat, gfsMaxLon, gfsMaxLat]);
    ecmwfWamBbox = bboxPolygon([
      ecmwfWamMinLon,
      ecmwfWamMinLat,
      ecmwfWamMaxLon,
      ecmwfWamMaxLat,
    ]);
    gfsWamBbox = bboxPolygon([
      gfsWamMinLon,
      gfsWamMinLat,
      gfsWamMaxLon,
      gfsWamMaxLat,
    ]);
    nemoBbox = bboxPolygon([nemoMinLon, nemoMinLat, nemoMaxLon, nemoMaxLat]);
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'Meteo data currently not available',
      }),
    );
    return;
  }

  isEcmwfAreaValid = !booleanDisjoint(selectedAreaBbox, ecmwfBbox);
  isGfsAreaValid = !booleanDisjoint(selectedAreaBbox, gfsBbox);
  isEcmwfWamAreaValid = !booleanDisjoint(selectedAreaBbox, ecmwfWamBbox);
  isGfsWamAreaValid = !booleanDisjoint(selectedAreaBbox, gfsWamBbox);
  isNemoAreaValid = !booleanDisjoint(selectedAreaBbox, nemoBbox);

  const isAnythingForDownload =
    isEcmwfAreaValid ||
    isGfsAreaValid ||
    isEcmwfWamAreaValid ||
    isGfsWamAreaValid ||
    isNemoAreaValid;

  if (!isEcmwfAreaValid && ecmwfDataRequested) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'ECWMF data not available for selected area',
      }),
    );
  }
  if (!isGfsAreaValid && gfsDataRequested) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'GFS data not available for selected area',
      }),
    );
  }
  if (!isEcmwfWamAreaValid && ecmwfWamDataRequested) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'ECMWF WAM data not available for selected area',
      }),
    );
  }
  if (!isGfsWamAreaValid && gfsWamDataRequested) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'GFS Wavewatch III data not available for selected area',
      }),
    );
  }
  if (!isNemoAreaValid && nemoDataRequested) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'NEMO data not available for selected area',
      }),
    );
  }

  if (ecmwfDataRequested && isEcmwfAreaValid) {
    dispatch(
      createEcmwfMeteoModelDataSet({
        id: ecmwfModelId,
        minLat,
        maxLat,
        minLng,
        maxLng,
        wind: [],
        mslp: [],
        timestamp: ecmwfTime,
        weather: [],
        windResolution: values.ecmwf.wind.spatial,
        weatherResolution: values.ecmwf.weather.spatial,
        windTime: values.ecmwf.wind.resolution,
        weatherTime: values.ecmwf.weather.resolution,
        mslpTime: values.ecmwf.MSLP.resolution,
      }),
    );
  }
  if (gfsDataRequested && isGfsAreaValid) {
    dispatch(
      createGfsMeteoModelDataSet({
        id: gfsModelId,
        minLat,
        maxLat,
        minLng,
        maxLng,
        wind: [],
        mslp: [],
        timestamp: gfsTime,
        weather: [],
        windResolution: values.gfs.wind.spatial,
        weatherResolution: values.gfs.weather.spatial,
        windTime: values.gfs.wind.resolution,
        weatherTime: values.gfs.weather.resolution,
        mslpTime: values.gfs.MSLP.resolution,
      }),
    );
  }
  if (ecmwfWamDataRequested && isEcmwfWamAreaValid) {
    dispatch(
      createWamMeteoModelDataSet({
        id: wamModelId,
        minLat,
        maxLat,
        minLng,
        maxLng,
        significantWaves: [],
        swellWaves: [],
        timestamp: ecmwfWamTime,
        windWaves: [],
        windWavesResolution: values.ecmwfwam.windWaves.spatial,
        significantWavesResolution: values.ecmwfwam.significantWaves.spatial,
        swellWavesResolution: values.ecmwfwam.swellWaves.spatial,
        windWavesTime: values.ecmwfwam.windWaves.time,
        significantWavesTime: values.ecmwfwam.significantWaves.time,
        swellWavesTime: values.ecmwfwam.swellWaves.time,
      }),
    );
  }
  if (gfsWamDataRequested && isGfsWamAreaValid) {
    dispatch(
      createGfsWamMeteoModelDataSet({
        id: gfsWamModelId,
        minLat,
        maxLat,
        minLng,
        maxLng,
        significantWaves: [],
        swellWaves: [],
        timestamp: gfsWamTime,
        windWaves: [],
        windWavesResolution: values.gfswam.windWaves.spatial,
        significantWavesResolution: values.gfswam.significantWaves.spatial,
        swellWavesResolution: values.gfswam.swellWaves.spatial,
        windWavesTime: values.gfswam.windWaves.time,
        significantWavesTime: values.gfswam.significantWaves.time,
        swellWavesTime: values.gfswam.swellWaves.time,
      }),
    );
  }
  if (nemoDataRequested && isNemoAreaValid) {
    dispatch(
      createNemoMeteoModelDataSet({
        id: nemoModelId,
        minLat,
        maxLat,
        minLng,
        maxLng,
        current: [],
        timestamp: nemoTime,
        currentResolution: values.nemo.spatial,
        currentTime: values.nemo.time,
      }),
    );
  }
  if (isEcmwfAreaValid) {
    if (values.ecmwf.wind.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.ecmwf.wind.resolution],
        values.ecmwf.wind.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Wind,
            MeteoSources.ECMWF,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.ecmwf.wind.spatial,
          ),
          time,
          type: MeteoDataTypes.Wind,
          model: MeteoSources.ECMWF,
          modelId: ecmwfModelId,
          downloadId,
        });
      });
    }
    if (values.ecmwf.weather.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.ecmwf.weather.resolution],
        values.ecmwf.weather.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Meteo,
            MeteoSources.ECMWF,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.ecmwf.weather.spatial,
          ),
          time,
          type: MeteoDataTypes.Meteo,
          model: MeteoSources.ECMWF,
          modelId: ecmwfModelId,
          downloadId,
        });
      });
    }
    if (values.ecmwf.MSLP.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.ecmwf.MSLP.resolution],
        values.ecmwf.MSLP.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Mslp,
            MeteoSources.ECMWF,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.ecmwf.MSLP.spatial,
          ),
          time,
          type: MeteoDataTypes.Mslp,
          model: MeteoSources.ECMWF,
          modelId: ecmwfModelId,
          downloadId,
        });
      });
    }
  }

  if (isGfsAreaValid) {
    if (values.gfs.wind.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.gfs.wind.resolution],
        values.gfs.wind.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Wind,
            MeteoSources.GFS,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.gfs.wind.spatial,
          ),
          time,
          type: MeteoDataTypes.Wind,
          model: MeteoSources.GFS,
          modelId: gfsModelId,
          downloadId,
        });
      });
    }
    if (values.gfs.weather.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.gfs.weather.resolution],
        values.gfs.weather.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Meteo,
            MeteoSources.GFS,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.gfs.weather.spatial,
          ),
          time,
          type: MeteoDataTypes.Meteo,
          model: MeteoSources.GFS,
          modelId: gfsModelId,
          downloadId,
        });
      });
    }
    if (values.gfs.MSLP.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.gfs.MSLP.resolution],
        values.gfs.MSLP.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Mslp,
            MeteoSources.GFS,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.gfs.MSLP.spatial,
          ),
          time,
          type: MeteoDataTypes.Mslp,
          model: MeteoSources.GFS,
          modelId: gfsModelId,
          downloadId,
        });
      });
    }
  }

  if (isEcmwfWamAreaValid) {
    if (values.ecmwfwam.swellWaves.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.ecmwfwam.swellWaves.resolution],
        values.ecmwfwam.swellWaves.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Swave,
            MeteoSources.ECMWFWAM,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.ecmwfwam.swellWaves.spatial,
          ),
          time,
          type: MeteoDataTypes.Swave,
          model: MeteoSources.ECMWFWAM,
          modelId: wamModelId,
          downloadId,
        });
      });
    }
    if (values.ecmwfwam.windWaves.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.ecmwfwam.windWaves.resolution],
        values.ecmwfwam.windWaves.time > 7 ? 7 : values.ecmwfwam.windWaves.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Wwave,
            MeteoSources.ECMWFWAM,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.ecmwfwam.windWaves.spatial,
          ),
          time,
          type: MeteoDataTypes.Wwave,
          model: MeteoSources.ECMWFWAM,
          modelId: wamModelId,
          downloadId,
        });
      });
    }
    if (values.ecmwfwam.significantWaves.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.ecmwfwam.significantWaves.resolution],
        values.ecmwfwam.significantWaves.time > 7
          ? 7
          : values.ecmwfwam.significantWaves.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Wave,
            MeteoSources.ECMWFWAM,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.ecmwfwam.significantWaves.spatial,
          ),
          time,
          type: MeteoDataTypes.Wave,
          model: MeteoSources.ECMWFWAM,
          modelId: wamModelId,
          downloadId,
        });
      });
    }
  }

  if (isGfsWamAreaValid) {
    if (values.gfswam.swellWaves.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.gfswam.swellWaves.resolution],
        values.gfswam.swellWaves.time > 7 ? 7 : values.gfswam.swellWaves.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Swave,
            MeteoSources.GFSWAM,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.gfswam.swellWaves.spatial,
          ),
          time,
          type: MeteoDataTypes.Swave,
          model: MeteoSources.GFSWAM,
          modelId: gfsWamModelId,
          downloadId,
        });
      });
    }
    if (values.gfswam.windWaves.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.gfswam.windWaves.resolution],
        values.gfswam.windWaves.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Wwave,
            MeteoSources.GFSWAM,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.gfswam.windWaves.spatial,
          ),
          time,
          type: MeteoDataTypes.Wwave,
          model: MeteoSources.GFSWAM,
          modelId: gfsWamModelId,
          downloadId,
        });
      });
    }
    if (values.gfswam.significantWaves.checked) {
      filterTimeResolution(
        TimeResolutionHourDistance[values.gfswam.significantWaves.resolution],
        values.gfswam.significantWaves.time,
      ).forEach((time) => {
        downloads.push({
          link: generateDownloadLink(
            MeteoDataTypes.Wave,
            MeteoSources.GFSWAM,
            time,
            maxLat,
            maxLng,
            minLat,
            minLng,
            values.gfswam.significantWaves.spatial,
          ),
          time,
          type: MeteoDataTypes.Wave,
          model: MeteoSources.GFSWAM,
          modelId: gfsWamModelId,
          downloadId,
        });
      });
    }
  }

  if (values.nemo.checked && isNemoAreaValid) {
    filterTimeResolution(
      TimeResolutionHourDistance[values.nemo.resolution],
      values.nemo.time,
    ).forEach((time) => {
      downloads.push({
        link: generateDownloadLink(
          MeteoDataTypes.Ocean,
          MeteoSources.NEMO,
          time,
          maxLat,
          maxLng,
          minLat,
          minLng,
          values.nemo.spatial,
        ),
        time,
        type: MeteoDataTypes.Ocean,
        model: MeteoSources.NEMO,
        modelId: nemoModelId,
        downloadId,
      });
    });
  }
  if (isAnythingForDownload && downloads.length) {
    dispatch(
      addDownload({
        downloaded: false,
        id: downloadId,
        name: downloadName,
        type: DownloadType.Meteo,
        totalFiles: downloads.length,
        modelIds: [
          { source: MeteoSources.ECMWF, id: ecmwfModelId },
          { source: MeteoSources.GFS, id: gfsModelId },
          { source: MeteoSources.ECMWFWAM, id: wamModelId },
          { source: MeteoSources.GFSWAM, id: gfsWamModelId },
          { source: MeteoSources.NEMO, id: nemoModelId },
        ],
      }),
    );
    dispatch(addToMeteoQueue(downloads));
  }
};

export const downloadMeteoDataThunk = (
  downloadItem: DownloadQueueItem,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  const key = `meteo-${new Date().getTime()}`;
  let isResponseValid = true;
  let noResponse = false;
  let contentSize = 0;
  if (downloadItem.type === MeteoDataTypes.Mslp) {
    try {
      const response = await apiClient.get(downloadItem.link);
      if (response.status < 300) {
        const octetSize = response.headers['content-length'];
        if (octetSize) {
          contentSize = Number((Number(octetSize) / 1024).toFixed(2));
        }
        const json = await response.data;
        await storeToLocalForage(db.meteo, key, splineMslpLines(json));
        isResponseValid = true;
        noResponse = false;
      } else {
        isResponseValid = false;
        noResponse = false;
      }
    } catch (error) {
      isResponseValid = false;
      noResponse = true;
      // dispatch(addNotification({ type: 'error', message: error.message }));
    }
  } else {
    try {
      const response = await apiClient.get(downloadItem.link, {
        responseType: 'arraybuffer',
      });
      if (response.status < 300) {
        const octetSize = response.headers['content-length'];
        if (octetSize) {
          contentSize = Number((Number(octetSize) / 1024).toFixed(2));
        }
        const fileBuffer = await response.data;
        await storeToLocalForage(db.meteo, key, fileBuffer);
        isResponseValid = true;
        noResponse = false;
      } else {
        isResponseValid = false;
        noResponse = false;
      }
    } catch (error) {
      isResponseValid = false;
      noResponse = true;
      // dispatch(addNotification({ type: 'error', message: error.message }));
    }
  }
  dispatch(downloadedMeteoComponent(downloadItem.link));
  if (noResponse && (downloadItem.numberOfRetries || 0) <= 1) {
    dispatch(
      addToMeteoQueue([
        {
          ...downloadItem,
          numberOfRetries: (downloadItem.numberOfRetries || 0) + 1,
        },
      ]),
    );
  } else {
    dispatch(
      updateDownload({ id: downloadItem.downloadId, itemSize: contentSize }),
    );
  }
  if (isResponseValid) {
    if (downloadItem.model === MeteoSources.ECMWF) {
      dispatch(
        addToEcmwfMeteoModelDataSet({
          id: downloadItem.modelId,
          storageKey: key,
          time: downloadItem.time,
          type: downloadItem.type as MeteoDataTypes,
        }),
      );
    }
    if (downloadItem.model === MeteoSources.GFS) {
      dispatch(
        addToGfsMeteoModelDataSet({
          id: downloadItem.modelId,
          storageKey: key,
          time: downloadItem.time,
          type: downloadItem.type as MeteoDataTypes,
        }),
      );
    }
    if (downloadItem.model === MeteoSources.ECMWFWAM) {
      dispatch(
        addToWamMeteoModelDataSet({
          id: downloadItem.modelId,
          storageKey: key,
          time: downloadItem.time,
          type: downloadItem.type as MeteoDataTypes,
        }),
      );
    }
    if (downloadItem.model === MeteoSources.GFSWAM) {
      dispatch(
        addToGfsWamMeteoModelDataSet({
          id: downloadItem.modelId,
          storageKey: key,
          time: downloadItem.time,
          type: downloadItem.type as MeteoDataTypes,
        }),
      );
    }
    if (downloadItem.model === MeteoSources.NEMO) {
      dispatch(
        addToNemoMeteoModelDataSet({
          id: downloadItem.modelId,
          storageKey: key,
          time: downloadItem.time,
        }),
      );
    }
  }
};

const getStorageKeys = (sources: StoredLocalFile[]): string[] =>
  sources.map((s) => s.storageKey);

export const deleteModelDataSet = (
  modelId: string,
  modelSource: MeteoSources,
): ThunkAction<void> => async (dispatch, getState): Promise<void> => {
  const state = getState();
  let keysForDelete: string[] = [];
  if (modelSource === MeteoSources.ECMWF) {
    const ecmwfDeleteData = ecmwfDataSetByIdSelector(state, modelId);
    if (ecmwfDeleteData) {
      keysForDelete = [
        ...getStorageKeys(ecmwfDeleteData.mslp),
        ...getStorageKeys(ecmwfDeleteData.weather),
        ...getStorageKeys(ecmwfDeleteData.wind),
      ];
      dispatch(removeEcmwfMeteoModelDataSet(modelId));
    }
  }
  if (modelSource === MeteoSources.GFS) {
    const gfsDeleteData = gfsDataSetByIdSelector(state, modelId);
    if (gfsDeleteData) {
      keysForDelete = [
        ...getStorageKeys(gfsDeleteData.mslp),
        ...getStorageKeys(gfsDeleteData.weather),
        ...getStorageKeys(gfsDeleteData.wind),
      ];
      dispatch(removeGfsMeteoModelDataSet(modelId));
    }
  }
  if (modelSource === MeteoSources.ECMWFWAM) {
    const wamDeleteData = wamDataSetByIdSelector(state, modelId);
    if (wamDeleteData) {
      keysForDelete = [
        ...getStorageKeys(wamDeleteData.swellWaves),
        ...getStorageKeys(wamDeleteData.significantWaves),
        ...getStorageKeys(wamDeleteData.windWaves),
      ];
      dispatch(removeWamMeteoModelDataSet(modelId));
    }
  }
  if (modelSource === MeteoSources.GFSWAM) {
    const wamDeleteData = gfsWamDataSetByIdSelector(state, modelId);
    if (wamDeleteData) {
      keysForDelete = [
        ...getStorageKeys(wamDeleteData.swellWaves),
        ...getStorageKeys(wamDeleteData.significantWaves),
        ...getStorageKeys(wamDeleteData.windWaves),
      ];
      dispatch(removeGfsWamMeteoModelDataSet(modelId));
    }
  }
  if (modelSource === MeteoSources.NEMO) {
    const nemoDeleteData = nemoDataSetByIdSelector(state, modelId);
    if (nemoDeleteData) {
      keysForDelete = [...getStorageKeys(nemoDeleteData.current)];
      dispatch(removeNemoMeteoModelDataSet(modelId));
    }
  }
  // TODO: Check is meteo related table
  await deleteMultipleFromLocalForage(db.meteo, keysForDelete);
};
