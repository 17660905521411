import styled from 'styled-components';

const size = '12px';

export default styled.div<{ color: string }>`
  width: ${size};
  height: ${size};
  border-radius: ${size};
  background-color: ${({ color }): string => color};
`;
