import React from 'react';

import { DoneIcon } from 'modules/ui/assets';
import { HistoryModel } from '../../types';
import { formatDownloadSize } from '../../utils';
import {
  RowContainer,
  FileName,
  Status,
  ColumContainer,
} from '../DownloadItem/styledComponents';

type Props = {
  item: HistoryModel;
};

export const HistoryItem: React.FC<Props> = ({ item }) => {
  return (
    <RowContainer>
      <DoneIcon />
      <ColumContainer>
        <FileName>{item.name}</FileName>
        <Status>
          {`Downloaded ${item.size ? formatDownloadSize(item.size) : ''}`}
        </Status>
      </ColumContainer>
    </RowContainer>
  );
};
