export const MeteogramTypes: ObjectWithStrings = {
  cdir: 'Ocean current direction [°]',
  cspeed: 'Ocean current speed [kts]',
  shww: 'Wind waves height [m]', // wind
  mpww: 'Mean period of wind waves [s]', // wind
  mdww: 'Mean direction of wind waves [°]', // wind
  shts: 'Swell waves height [m]', // swell
  mpts: 'Mean period of total swell [s]', // swell
  mdts: 'Mean direction of total swell [°]', // swell
  msl: 'Mean sea level pressure [hPa]',
  mwd: ' Mean weave direction [°]',
  sst: 'Sea surface temperature[°C]',
  swh: 'Significant wave height [m]',
  t2m: 'Temperature at 2m [°C]',
  tcc: 'Total cloud cover [%]',
  tp: 'Total 1-hour precipitation [mm/h]',
  wc: 'Whitecapping',
  wdir: 'Wind direction [°]',
  ws: 'Wind speed [kts]',
  fg10: 'Wind gusts at 10m [kts]',
};

export enum ChartNames {
  CDIR = 'Ocean current direction [°]',
  CSPEED = 'Ocean current speed [kts]',
  SHWW = 'Wind waves height [m]',
  MPWW = 'Mean period of wind waves [s]',
  MDWW = 'Mean direction of wind waves [°]',
  SHTS = 'Swell waves height [m]',
  MPTS = 'Mean period of total swell [s]',
  MDTS = 'Mean direction of total swell [°]',
  MSL = 'Mean sea level pressure [hPa]',
  MWD = 'Mean weave direction [°]',
  SST = 'Sea surface temperature[°C]',
  SWH = 'Significant wave height [m]',
  T2M = 'Temperature at 2m [°C]',
  TCC = 'Total cloud cover [%]',
  TP = 'Total 1-hour precipitation [mm/h]',
  WC = 'Whitecapping',
  WDIR = 'Wind direction [°]',
  WS = 'Wind speed [kts]',
  FG10 = 'Wind gusts at 10m [kts]',
}

export type ObjectWithStrings = {
  [key: string]: string;
};

export type ChartGroupsType = {
  [key: string]: string[];
};

export const CHART_HEIGHT = 280;
export const CHART_WIDTH = window.innerWidth;

export const ChartGroups: ChartGroupsType = {
  WIND: ['ws', 'fg10', 'wdir'],
  PRESSURE: ['msl'],
  CLOUDS: ['tcc'],
  PRECIPITATION: ['tp'],
  TEMPERATURE: ['t2m', 'sst'],
  SWELL_WAVES: ['mdts', 'mpts', 'shts'],
  WIND_WAVES: ['mdww', 'mpww', 'shww'],
  WAVE: ['swh', 'wc'],
  CURRENTS: ['cdir', 'cspeed'],
};

export const OfflineChartGroups: ChartGroupsType = {
  WIND: ['ws', 'fg10', 'wdir'],
  CLOUDS: ['tcc'],
  PRECIPITATION: ['tp'],
  TEMPERATURE: ['t2m', 'sst'],
  SWELL_WAVES: ['mdts', 'mpts', 'shts'],
  WIND_WAVES: ['mdww', 'mpww', 'shww'],
  WAVE: ['swh', 'wc'],
  CURRENTS: ['cdir', 'cspeed'],
};

export enum MeteoSets {
  ECMWF = 'ecmwf',
  WAM = 'wam',
  GFS = 'gfs',
  NEMO = 'nemo',
}

export enum ChartColors {
  Cerulean = '#0886E0',
  RedOrange = '#FF4F31',
  Scorpion = '#595959',
  JapaneseLaurel = '#008000',
  PeriwinkleGray = '#CDD8EB',
  RegentBlue = '#A1DDE6',
  CarrotOrange = '#F39B29',
  Ming = '#316778',
  NavyBlue = '#000097',
  Malibu = '#52AFFF',
  Solitude = '#E8F5FF',
  MineralGreen = '#425E55',
  Shamrock = '#29CF8A',
  Iceberg = '#E4F6F2',
  CatskillkWhite = '#EDF0F7',
  EastBay = '#3C5A7C',
  Victoria = '#5E4386',
  Heliotrope = '#A856FF',
  Silver = '#B8B8B8',
}

export enum ChartType {
  Scatter = 'scatter',
  None = '',
  Bar = 'bar',
}

export enum ChartMode {
  Lines = 'lines',
  NONE = 'none',
}

export enum MeteogramName {
  Full = 'Full Meteogram in point: ',
  Offline = 'Meteogram in point: ',
  Route = 'Meteogram along the route: ',
}
