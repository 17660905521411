import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 40px;
  top: 40px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  padding: 16px;
`;
