/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import DeterministicChart from '../DeterministicChart';
import { Container } from './styledComponents';

type Props = {
  data: any;
  charts: any;
  name: string;
  routeName?: string;
};

const Meteogram: React.FC<Props> = ({ data, charts }) => {
  const [, setState] = useState({});
  const reRender = useCallback(() => {
    setState({});
  }, []);

  useEffect(() => {
    window.addEventListener('focus', reRender);

    return (): void => {
      window.removeEventListener('focus', reRender);
    };
  });
  return (
    <Container>
      {charts.map((type: string) => {
        return <DeterministicChart key={type} data={data.steps} type={type} />;
      })}
    </Container>
  );
};

export default React.memo(Meteogram);
