import styled from 'styled-components';

const SideMenu = styled.div`
  position: fixed;
  left: 12px;
  top: 12px;
  width: 380px;
  > div {
    margin-bottom: 12px;
  }
`;

export default SideMenu;
