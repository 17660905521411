import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularSm } from 'modules/ui/typography';

export const MultiRow = styled.div<{
  showTopBorder?: boolean;
  alignLeft?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ alignLeft }): string | undefined =>
    alignLeft ? undefined : 'space-evenly'};
  flex: 1;
  align-items: center;
  padding: 8px 0 8px 0;
  border-top: ${({ showTopBorder }): string =>
    showTopBorder ? ' 1px solid #EBECF0' : 'none'};
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  > div:nth-child(1) {
    margin-right: 16px;
  }
  > div:nth-child(2) {
    margin-right: 16px;
  }
`;

export const SpatialLabelContainer = styled.div`
  width: 104px;
  margin-right: 16px;
`;

export const TimeStepResolutionLabelContainer = styled.div`
  width: 208px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  flex: 1;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0px 24px 24px 24px;
  box-sizing: border-box;
`;

export const FooterContainer = styled.div`
  height: 72px;
  background: ${Colors.LightGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

export const MetaInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MetaInfoText = styled(RegularSm)`
  color: ${Colors.SolidGray};
`;

export const AreaCoordinatesContainer = styled(RegularSm)`
  color: ${Colors.SolidGray};
  margin: -16px 0px 16px 24px;
`;

export const SpatialLabel = styled(RegularSm)`
  padding-left: 16px;
`;

export const ButtonsContainer = styled.div`
  > button:first-child {
    margin-right: 16px;
  }
`;
