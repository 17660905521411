const logicalProcessors = window.navigator.hardwareConcurrency;

let numberOfThreads: number;

if (logicalProcessors > 4) {
  numberOfThreads = 3;
} else if (logicalProcessors > 2) {
  numberOfThreads = 2;
} else {
  numberOfThreads = 1;
}

export const workers = new Array(numberOfThreads)
  .fill(null)
  .map(
    () =>
      new Worker('./meteo.worker.ts', { name: 'meteoWorker', type: 'module' }),
  );

type ReturnValue = {
  fillGeoJson1: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  barbGeoJson1: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  fillGeoJson2: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  barbGeoJson2: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
};

type ResolveMapValues = ReturnValue & {
  count: number;
  resolve: (value: ReturnValue) => void;
};

const resolveMap: {
  [key: string]: ResolveMapValues;
} = {};

export const addToResolveMap = (
  storageKey: string,
  values: ResolveMapValues,
): void => {
  resolveMap[storageKey] = { ...values };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const onMessageHandler = (e: MessageEvent<any>): void => {
  const { storageKey, size, i } = e.data;
  resolveMap[storageKey].count += 1;
  if (i % 2) {
    resolveMap[storageKey].fillGeoJson1.features.push(...e.data.fillGeoJson);
    resolveMap[storageKey].barbGeoJson1.features.push(...e.data.barbGeoJson);
  } else {
    resolveMap[storageKey].fillGeoJson2.features.push(...e.data.fillGeoJson);
    resolveMap[storageKey].barbGeoJson2.features.push(...e.data.barbGeoJson);
  }
  if (resolveMap[storageKey].count === size) {
    resolveMap[storageKey].resolve({
      fillGeoJson1: resolveMap[storageKey].fillGeoJson1,
      fillGeoJson2: resolveMap[storageKey].fillGeoJson2,
      barbGeoJson1: resolveMap[storageKey].barbGeoJson1,
      barbGeoJson2: resolveMap[storageKey].barbGeoJson2,
    });
    delete resolveMap[storageKey];
  }
};

workers.forEach((worker) => {
  worker.onmessage = onMessageHandler;
});

export const getWorker = (i: number): Worker => workers[i % numberOfThreads];
