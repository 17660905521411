import { createAction } from '@reduxjs/toolkit';

export const toggleShowMeteoDataFlag = createAction(
  'TOGGLE_SHOW_METEO_DATA_FLAG',
);

export const turnOnShowMeteoDataFlag = createAction(
  'TURN_ON_SHOW_METEO_DATA_FLAG',
);

export const toggleShowRoutingDataFlag = createAction(
  'TOGGLE_SHOW_ROUTING_DATA_FLAG',
);

export const turnOnShowRoutingDataFlag = createAction(
  'TURN_ON_SHOW_ROUTING_DATA_FLAG',
);

export const hideAllUi = createAction('HIDE_ALL_UI');

export const showAllUi = createAction('SHOW_ALL_UI');

export const setIsOnlineFlag = createAction<boolean>('SET_IS_ONLINE_FLAG');

export const setIsLiveBoatDataOk = createAction<boolean>(
  'SET_IS_LIVE_BOAT_DATA_OK',
);

export const setAppVersionInfoSeen = createAction<string>(
  'SET_APP_VERSION_INFO_SEEN',
);
