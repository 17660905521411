import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { SignOutIcon } from 'modules/ui/assets';
import { useLogOutUser } from 'modules/auth';
import {
  MetadataSelectors,
  useTrackInternetConnection,
} from 'modules/metadata';
import { StatusItem } from '../StatusItem';
import { Subtitle } from '../Subtitle';
import { ActionItem } from '../ActionItem';

const ConnectionSideMenuSectionUi: React.FC = () => {
  const isOnline = useSelector(MetadataSelectors.isOnline);
  const isLiveBoatDataOk = useSelector(MetadataSelectors.isLiveBoatDataOk);
  const { handleLogout } = useLogOutUser();
  useTrackInternetConnection();

  return (
    <>
      <Subtitle addTopPadding>Connections</Subtitle>
      <StatusItem isOk={isLiveBoatDataOk} label="Live boat data" />
      <StatusItem isOk={isOnline} label="Internet" />
      <ActionItem
        onClick={handleLogout}
        Icon={<SignOutIcon />}
        label="Log out"
      />
    </>
  );
};

export const ConnectionSideMenuSection = memo(ConnectionSideMenuSectionUi);
