import {
  WaveDataWithConstraints,
  SourcesForFiltering,
  WaveDataSources,
  WindDataWithConstraints,
  WindDataSources,
} from '../types';
import {
  findRightTimestampData,
  isTimestampAfterMaxWithTrashold,
} from './time';

export const getWaveDataSourceKeys = (
  dataSetWithConstraints: WaveDataWithConstraints,
  timeOffset: number,
  timeOffsetCorrection: number,
): WaveDataSources => {
  const { dataSet, constraints } = dataSetWithConstraints;
  const calculatedTimeOffset = timeOffset + timeOffsetCorrection;

  const significantWavesSources = dataSet
    .map((data, index) => {
      const fileName = data.significantWaves?.find((d) =>
        findRightTimestampData(
          d,
          calculatedTimeOffset,
          data.significantWavesTime,
        ),
      )?.storageKey;
      if (fileName) {
        return {
          fileName,
          constraints: constraints[index]?.significantWaves
            .map((w) =>
              isTimestampAfterMaxWithTrashold(
                calculatedTimeOffset,
                w.maxTime,
                data.significantWavesTime,
              )
                ? null
                : w.bbox,
            )
            .filter((_) => _),
        };
      }
      return undefined;
    })
    .filter((_) => !!_) as Array<SourcesForFiltering>;
  const swellWavesSources = dataSet
    .map((data, index) => {
      const fileName = data.swellWaves?.find((d) =>
        findRightTimestampData(d, calculatedTimeOffset, data.swellWavesTime),
      )?.storageKey;
      if (fileName) {
        return {
          fileName,
          constraints: constraints[index]?.swellWaves
            .map((w) =>
              isTimestampAfterMaxWithTrashold(
                calculatedTimeOffset,
                w.maxTime,
                data.swellWavesTime,
              )
                ? null
                : w.bbox,
            )
            .filter((_) => _),
        };
      }
      return undefined;
    })
    .filter((_) => !!_) as Array<SourcesForFiltering>;
  const windWavesSources = dataSet
    .map((data, index) => {
      const fileName = data.windWaves?.find((d) =>
        findRightTimestampData(d, calculatedTimeOffset, data.windWavesTime),
      )?.storageKey;
      if (fileName) {
        return {
          fileName,
          constraints: constraints[index]?.windWaves
            .map((w) =>
              isTimestampAfterMaxWithTrashold(
                calculatedTimeOffset,
                w.maxTime,
                data.windWavesTime,
              )
                ? null
                : w.bbox,
            )
            .filter((_) => _),
        };
      }
      return undefined;
    })
    .filter((_) => !!_) as Array<SourcesForFiltering>;
  return {
    significantWavesSources,
    swellWavesSources,
    windWavesSources,
  };
};

export const getWindDataSourceKeys = (
  dataSetWithConstraints: WindDataWithConstraints,
  timeOffset: number,
  timeOffsetCorrection: number,
): WindDataSources => {
  const { dataSet, constraints } = dataSetWithConstraints;
  const calculatedTimeOffset = timeOffset + timeOffsetCorrection;

  const windSources = dataSet
    .map((data, index) => {
      const fileName = data.wind?.find((d) =>
        findRightTimestampData(d, calculatedTimeOffset, data.windTime),
      )?.storageKey;
      if (fileName) {
        return {
          fileName,
          constraints: constraints[index]?.wind
            .map((w) =>
              isTimestampAfterMaxWithTrashold(
                calculatedTimeOffset,
                w.maxTime,
                data.windTime,
              )
                ? null
                : w.bbox,
            )
            .filter((_) => _),
        };
      }
      return undefined;
    })
    .filter((_) => !!_) as Array<SourcesForFiltering>;
  const weatherSources = dataSet
    .map((data, index) => {
      const fileName = data.weather?.find((d) =>
        findRightTimestampData(d, calculatedTimeOffset, data.weatherTime),
      )?.storageKey;
      if (fileName) {
        return {
          fileName,
          constraints: constraints[index]?.weather
            .map((w) =>
              isTimestampAfterMaxWithTrashold(
                calculatedTimeOffset,
                w.maxTime,
                data.weatherTime,
              )
                ? null
                : w.bbox,
            )
            .filter((_) => _),
        };
      }
      return undefined;
    })
    .filter((_) => !!_) as Array<SourcesForFiltering>;
  const mslpSources = dataSet
    .map(
      (data) =>
        data.mslp?.find((d) =>
          findRightTimestampData(d, calculatedTimeOffset, data.mslpTime),
        )?.storageKey,
    )
    .filter((_) => !!_) as string[];
  return {
    windSources,
    weatherSources,
    mslpSources,
  };
};
