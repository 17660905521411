import ky from 'ky';
import db from 'modules/localForage/db';
import { ThunkAction } from 'modules/main/redux';
import { Layers, LayersVersions } from '../consts';
import { setMapLayerDownloaded } from './actions';
import { layersToDownloadSelector } from './selectors';

export const downloadMapLayers = (
  setCount: (count: number) => void,
  setProgress: (count: number, index: number) => void,
): ThunkAction<void> => async (dispatch, getState): Promise<void> => {
  const state = getState();
  const layersToDownload = layersToDownloadSelector(state);
  setCount(layersToDownload.length);
  const handleLayerDownload = async (
    layerName: Layers,
    index: number,
  ): Promise<void> => {
    const data = await ky
      .get(`data/${layerName}.geojson`, {
        onDownloadProgress: (progress) => {
          setProgress(progress.percent, index);
        },
      })
      .json();
    await db.map.setItem(layerName, data);
    dispatch(
      setMapLayerDownloaded({ layerName, version: LayersVersions[layerName] }),
    );
  };
  await Promise.all(
    layersToDownload.map((layerName, index) =>
      handleLayerDownload(layerName as Layers, index),
    ),
  );
};
