import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

import db from 'modules/localForage/db';
import { deleteFromObject, listToObject } from 'modules/main/service';
import { setRoutes, deleteRoute } from './actions';
import { Route } from '../types';

const storage = createReducer({} as { [key: number]: Route }, (builder) => {
  builder
    .addCase(setRoutes, (state, action) => listToObject(action.payload, state))
    .addCase(deleteRoute, (state, action) =>
      deleteFromObject(state, action.payload),
    );
});

const hasLoaded = createReducer(false, (builder) => {
  builder.addCase(setRoutes, () => true);
});

const reducer = combineReducers({
  storage,
  hasLoaded,
});

const routesReducerConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'route',
  storage: db.app,
  whitelist: ['storage'],
};

export const routesReducer = persistReducer(routesReducerConfig, reducer);
