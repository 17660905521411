export const addMidnightProperty = (
  timedRoute: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > | null,
  isEnsemble = false,
): GeoJSON.FeatureCollection<
  GeoJSON.Geometry,
  GeoJSON.GeoJsonProperties
> | null => {
  if (!timedRoute) return null;

  return {
    ...timedRoute,
    features: timedRoute?.features.map((f) => ({
      ...f,
      properties: {
        ...f.properties,
        isMidnight: f.properties?.DT.endsWith('T00:00:00') || false,
        isEnsemble: isEnsemble || undefined,
      },
    })),
  };
};

export const addEnsembleProperties = (
  allRoutes: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > | null,
) => {
  if (!allRoutes) return null;

  let modifiedAllRoutes = allRoutes;
  const allEndingTimes = allRoutes?.features.map(
    (f) => (f.properties?.etime as string) || '',
  );
  if (allEndingTimes && allRoutes) {
    allEndingTimes.sort();
    const fastest = allEndingTimes.shift();
    const slowest = allEndingTimes.pop();
    const step = allEndingTimes.length > 25 ? 1 : 2;
    const medianIndex = Math.round(allEndingTimes.length / 2);
    const firstGradientValue = 0 - medianIndex * step;
    modifiedAllRoutes = {
      ...allRoutes,
      features: allRoutes.features.map((f) => {
        let gradient = 0;
        let isMedian = false;
        const isSlowest = f.properties?.etime === slowest;
        const isFastest = f.properties?.etime === fastest;
        if (isSlowest) {
          gradient = 50;
        } else if (isFastest) {
          gradient = 50;
        } else {
          const foundIndex = allEndingTimes.findIndex(
            (v) => v === f.properties?.etime,
          );
          gradient = firstGradientValue + foundIndex * step;
          if (foundIndex === medianIndex) {
            isMedian = true;
            gradient = 50;
          }
        }

        return {
          ...f,
          id: f.properties?.name,
          properties: {
            ...f.properties,
            name: Number(f.properties?.name),
            gradient,
            isMedian,
            isSlowest,
            isFastest,
            isDashed: isSlowest || isFastest || isMedian,
            isEnsemble: true,
          },
        };
      }),
    };
  }
  return modifiedAllRoutes;
};
