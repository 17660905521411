import React from 'react';

import { H2 } from 'modules/ui/typography';
import { Spinner } from 'modules/ui/components';
import { AuthImageContainer } from '../../components';
import { useVerifyUser } from '../../hooks';

import { ErrorText } from './styledComponents';

const AccountVerificationPageUi: React.FC = () => {
  const { loading, error } = useVerifyUser();

  if (loading) {
    return (
      <AuthImageContainer>
        <Spinner />
      </AuthImageContainer>
    );
  }

  if (error) {
    return (
      <AuthImageContainer>
        <ErrorText>{error}</ErrorText>
      </AuthImageContainer>
    );
  }

  return (
    <AuthImageContainer>
      <H2>
        You have successfully verified your Dorado account.
        <br />
        You will receive an email when your account gets approved.
      </H2>
    </AuthImageContainer>
  );
};

export const AccountVerificationPage = React.memo(AccountVerificationPageUi);
