import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  position: fixed;
  left: calc(50% - 141px);
  bottom: 40px;
  display: flex;
  flex-direction: row;
  > button:not(:last-child) {
    margin-right: 16px;
  }
  > button {
    width: 135px;
  }
`;

export const ExtraButtonsContainer = styled.div`
  position: fixed;
  right: 40px;
  top: 40px;
  display: flex;
  flex-direction: column;
  > button:not(:last-child) {
    margin-bottom: 8px;
  }
  > button {
    width: 135px;
  }
`;
