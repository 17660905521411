import { createAction } from '@reduxjs/toolkit';
import { DownloadedRoute, Route } from '../types';

type SettingsAttribute = {
  name: string;
  value: boolean;
};

export const toogleActiveRoutings = createAction<string>(
  'TOGGLE_ACTIVE_ROUTINGS',
);
export const updateRouteSettings = createAction<SettingsAttribute>(
  'UPDATE_ROUTE_SETTINGS',
);
export const addServerRoutes = createAction<{ list: Route[]; time: number }>(
  'ADD_SERVER_ROUTE',
);

export const removeServerRouting = createAction<string>(
  'REMOVE_SERVER_ROUTING',
);

export const addDownloadedRouting = createAction<DownloadedRoute>(
  'ADD_DOWNLOADED_ROUTING',
);

export const removeDownloadedRouting = createAction<string>(
  'REMOVE_DOWNLOADED_ROUTING',
);

export const toggleSideMenuRouting = createAction<string>(
  'TOGGLE_SIDE_MENU_ROUTING',
);

export const toggleRoutingIsochrones = createAction<string>(
  'TOGGLE_SIDE_MENU_ROUTING_ISOCHRONES',
);

export const clearRoutingIsochrones = createAction<string>(
  'CLEAR_ROUTING_ISOCHRONES',
);

export const setHasNewServerRouteForRefresh = createAction(
  'SET_HAS_NEW_SERVER_ROUTE',
);
