import React from 'react';
import { Map } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { isUiVisibleSelector, whatsNewCountSelector } from 'modules/metadata';
import {
  CompassIcon,
  ZoomMinusIcon,
  ZoomPlusIcon,
  GridIcon,
  TargetIcon,
  ExclusionIcon,
  NewsIcon,
  FlatPlus,
} from 'modules/ui/assets';
import { MapControlButton, MapSoloButton, Modal } from 'modules/ui/components';
import { useModal, useToggle } from 'modules/main';
import { Colors } from 'modules/ui/theme/colors';
import { RegularBase } from 'modules/ui/typography';
import { DownloadButtonIndicator } from 'modules/downloads/components';
import {
  useDisplayExclusionZones,
  useGetExclusionZones,
} from 'modules/exclusionZones';
import {
  useDisplayInclusionZones,
  useGetInclusionZones,
} from 'modules/inclusionZones';
import WhatsNewModal from '../WhatsNewModal';
import ZonesMenu from '../ZonesMenu';
import {
  useGridVisibility,
  useMapMovementsControls,
  useMeasurementTool,
} from '../../hooks';

import { Container, MapControlsContainer } from './styledComponents';

type Props = {
  map: Map;
};

const MapControl: React.FC<Props> = ({ map }) => {
  const isUiVisible = useSelector(isUiVisibleSelector);
  const whatsNewCount = useSelector(whatsNewCountSelector);
  const [isWhatsNewOpened, , openWhatsNewModal, closeWhatsNewModal] = useModal(
    false,
  );
  const { isGridOn, toggleGridVisibility } = useGridVisibility(map);
  const [isZonesSubmenuOpen, toggleIsZonesSubmenuOpen] = useToggle();
  useGetExclusionZones();
  useGetInclusionZones();
  const {
    isExclusionZoneVisibilityOn,
    toggleExclusionZoneVisibility,
    hideExclusionZones,
    showExclusionZones,
  } = useDisplayExclusionZones(map);
  const {
    isInclusionZoneVisibilityOn,
    toggleInclusionZoneVisibility,
    hideInclusionZones,
    showInclusionZones,
  } = useDisplayInclusionZones(map);

  const { handleMessurementToggle, isMeasurementOn } = useMeasurementTool(map);
  const {
    handleZoomIn,
    handleZoomOut,
    handleCenterMap,
  } = useMapMovementsControls(map);

  // if (!isUiVisible) return null;

  return (
    <>
      <Modal
        isOpen={isWhatsNewOpened}
        width={720}
        title="What's new"
        closeModal={closeWhatsNewModal}
      >
        <WhatsNewModal />
      </Modal>
      <Container isVisible={isUiVisible}>
        <DownloadButtonIndicator />
        <MapControlsContainer>
          <MapControlButton
            data-tip="Exclusion zones"
            isActive={isExclusionZoneVisibilityOn}
            onClick={toggleExclusionZoneVisibility}
          >
            <ExclusionIcon fill={Colors.Destructive} />
          </MapControlButton>
          <MapControlButton
            data-tip="Inclusion zones"
            isActive={isInclusionZoneVisibilityOn}
            onClick={toggleInclusionZoneVisibility}
          >
            <FlatPlus
              fill={
                isInclusionZoneVisibilityOn ? Colors.White : Colors.SolidGray
              }
            />
          </MapControlButton>
          <div style={{ display: 'flex' }}>
            <MapControlButton onClick={toggleIsZonesSubmenuOpen}>
              <RegularBase style={{ paddingBottom: 8 }}>...</RegularBase>
            </MapControlButton>
            <ZonesMenu
              hideExclusionZones={hideExclusionZones}
              hideInclusionZones={hideInclusionZones}
              showExclusionZones={showExclusionZones}
              showInclusionZones={showInclusionZones}
              isExclusionZoneVisibilityOn={isExclusionZoneVisibilityOn}
              isInclusionZoneVisibilityOn={isInclusionZoneVisibilityOn}
              isOpen={isZonesSubmenuOpen}
              map={map}
            />
          </div>
        </MapControlsContainer>
        <MapControlsContainer>
          <MapControlButton data-tip="Zoom in" onClick={handleZoomIn}>
            <ZoomPlusIcon />
          </MapControlButton>
          <MapControlButton data-tip="Zoom out" onClick={handleZoomOut}>
            <ZoomMinusIcon />
          </MapControlButton>
          <MapControlButton data-tip="Locate me" onClick={handleCenterMap}>
            <TargetIcon />
          </MapControlButton>
          <MapControlButton
            data-tip="Measurement tool"
            onClick={handleMessurementToggle}
            isActive={isMeasurementOn}
          >
            <CompassIcon
              fill={isMeasurementOn ? Colors.White : Colors.SolidGray}
            />
          </MapControlButton>
          <MapControlButton
            data-tip="Grid lines"
            isActive={isGridOn}
            onClick={toggleGridVisibility}
          >
            <ReactTooltip effect="solid" multiline />
            <GridIcon fill={isGridOn ? Colors.White : Colors.SolidGray} />
          </MapControlButton>
        </MapControlsContainer>
        <MapSoloButton
          tooltipText="What’s new"
          indicatorText={whatsNewCount}
          handleClick={openWhatsNewModal}
        >
          <NewsIcon />
        </MapSoloButton>
      </Container>
    </>
  );
};

export default React.memo(MapControl);
