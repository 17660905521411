import { useCallback } from 'react';
import { Map } from 'mapbox-gl';

import { useToggle } from 'modules/main';
import { Layers } from 'modules/mapLayers';

export const useGridVisibility = (
  map: Map,
): {
  isGridOn: boolean;
  toggleGridVisibility: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
} => {
  const [isGridOn, toggleIsGridOn] = useToggle();
  const toggleGridVisibility = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      const visibility = map.getLayoutProperty(
        Layers.Graticules1,
        'visibility',
      );
      if (visibility === 'visible') {
        map.setLayoutProperty(Layers.Graticules1, 'visibility', 'none');
      } else {
        map.setLayoutProperty(Layers.Graticules1, 'visibility', 'visible');
      }
      toggleIsGridOn();
    },
    [map, toggleIsGridOn],
  );

  return {
    isGridOn,
    toggleGridVisibility,
  };
};
