import React, { memo, useCallback } from 'react';

import { Button } from 'modules/ui/components';
import { RegularXs } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';

import { ExclusionZone } from '../../types';
import { Container, ButtonContainer } from './styledComponents';

type Props = {
  id: number;
  enabled: boolean;
  onEditClick: (value: number) => void;
  hidePopup: () => void;
  handleUpdateOrCreate: (values: Partial<ExclusionZone>) => Promise<void>;
};

const EditControls = ({
  id,
  onEditClick,
  hidePopup,
  enabled,
  handleUpdateOrCreate,
}: Props): JSX.Element => {
  const onEdit = useCallback(() => {
    hidePopup();
    onEditClick(id);
  }, [onEditClick, id, hidePopup]);
  const onEnable = useCallback(async () => {
    handleUpdateOrCreate({ id, enabled: !enabled });
  }, [handleUpdateOrCreate, id, enabled]);
  return (
    <Container>
      <RegularXs style={{ color: Colors.Destructive }}>
        EXCLUSION ZONE
      </RegularXs>
      <ButtonContainer>
        <Button label="Edit" clickHandler={onEdit} />
        <Button
          label={enabled ? 'Disable' : 'Enable'}
          clickHandler={onEnable}
        />
      </ButtonContainer>
    </Container>
  );
};

export default memo(EditControls);
