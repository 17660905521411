import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BoatsSelectors } from 'modules/boats';
import { ExclusionZonesApi } from '../api';
import { ExclusionZoneActions, ExclusionZonesSelectors } from '../redux';

export const useGetExclusionZones = (): void => {
  const dispatch = useDispatch();
  const hasLoaded = useSelector(ExclusionZonesSelectors.hasLoaded);
  const boatsHasLoaded = useSelector(BoatsSelectors.hasLoaded);

  const handleGetExclusionZones = useCallback(async () => {
    try {
      const exclusionZones = await ExclusionZonesApi.getExclusionZones();
      dispatch(ExclusionZoneActions.setExclusionZones(exclusionZones));
    } catch (error) {
      console.log('Error while getting exclusion zones');
    }
  }, [dispatch]);

  useEffect(() => {
    if (boatsHasLoaded && !hasLoaded) {
      handleGetExclusionZones();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded, boatsHasLoaded]);
};
