import { parseExifTagsAndMetadata } from 'modules/exif';
import {
  OceanMetadata,
  SWaveMetadata,
  WaveMetadata,
  WeatherMetadata,
  WindMetadata,
  WwaveMetadata,
  MeteoDataBaseMetadata,
} from '../types';

export const getLatLngSteps = (
  metadata: MeteoDataBaseMetadata,
): {
  lngStep: number;
  latStep: number;
} => {
  const lngStep = (metadata.maxLng - metadata.minLng) / metadata.width;
  const latStep = (metadata.maxLat - metadata.minLat) / metadata.height;
  return {
    lngStep,
    latStep,
  };
};

export const getOceanMetadataFromExif = (
  fileBuffer: ArrayBuffer,
): OceanMetadata => {
  const { height, width, metadata } = parseExifTagsAndMetadata(fileBuffer);
  const maxU = metadata.maxU as number;
  const minU = metadata.minU as number;
  const maxV = metadata.maxV as number;
  const minV = metadata.minV as number;
  const maxSST = metadata.maxSST as number;
  const minSST = metadata.minSST as number;
  const maxLat = metadata.maxLat as number;
  const minLng = metadata.minLon as number;
  const minLat = metadata.minLat as number;
  const maxLng = metadata.maxLon as number;
  return {
    height,
    width,
    maxU,
    minU,
    maxV,
    minV,
    maxSST,
    minSST,
    maxLat,
    minLng,
    minLat,
    maxLng,
  };
};

export const getSWaveMetadataFromExif = (
  fileBuffer: ArrayBuffer,
): SWaveMetadata => {
  const { height, width, metadata } = parseExifTagsAndMetadata(fileBuffer);
  const maxU = metadata.maxSHTSU as number;
  const minU = metadata.minSHTSU as number;
  const maxV = metadata.maxSHTSV as number;
  const minV = metadata.minSHTSV as number;
  const maxMPTS = metadata.maxMPTS as number;
  const minMPTS = metadata.minMPTS as number;
  const maxLat = metadata.maxLat as number;
  const minLng = metadata.minLon as number;
  const minLat = metadata.minLat as number;
  const maxLng = metadata.maxLon as number;
  return {
    height,
    width,
    maxU,
    minU,
    maxV,
    minV,
    maxMPTS,
    minMPTS,
    maxLat,
    minLng,
    minLat,
    maxLng,
  };
};

export const getWaveMetadataFromExif = (
  fileBuffer: ArrayBuffer,
): WaveMetadata => {
  const { height, width, metadata } = parseExifTagsAndMetadata(fileBuffer);
  const maxU = metadata.maxSWHU as number;
  const minU = metadata.minSWHU as number;
  const maxV = metadata.maxSWHV as number;
  const minV = metadata.minSWHV as number;
  const maxWC = metadata.maxWC as number;
  const minWC = metadata.minWC as number;
  const maxLat = metadata.maxLat as number;
  const minLng = metadata.minLon as number;
  const minLat = metadata.minLat as number;
  const maxLng = metadata.maxLon as number;
  return {
    height,
    width,
    maxU,
    minU,
    maxV,
    minV,
    maxWC,
    minWC,
    maxLat,
    minLng,
    minLat,
    maxLng,
  };
};

export const getWeatherMetadataFromExif = (
  fileBuffer: ArrayBuffer,
): WeatherMetadata => {
  const { height, width, metadata } = parseExifTagsAndMetadata(fileBuffer);
  const maxTCC = metadata.maxTCC as number;
  const minTCC = metadata.minTCC as number;
  const maxRR = metadata.maxRR as number;
  const minRR = metadata.minRR as number;
  const maxT2M = metadata.maxT2M as number;
  const minT2M = metadata.minT2M as number;
  const maxLat = metadata.maxLat as number;
  const minLng = metadata.minLon as number;
  const minLat = metadata.minLat as number;
  const maxLng = metadata.maxLon as number;
  return {
    height,
    width,
    maxTCC,
    minTCC,
    maxRR,
    minRR,
    maxT2M,
    minT2M,
    maxLat,
    minLng,
    minLat,
    maxLng,
  };
};

export const getWindMetadataFromExif = (
  fileBuffer: ArrayBuffer,
): WindMetadata => {
  const { height, width, metadata } = parseExifTagsAndMetadata(fileBuffer);
  const maxU = metadata.maxU as number;
  const minU = metadata.minU as number;
  const maxV = metadata.maxV as number;
  const minV = metadata.minV as number;
  const maxGusts = metadata.maxFG10 as number;
  const minGusts = metadata.minFG10 as number;
  const maxLat = metadata.maxLat as number;
  const minLng = metadata.minLon as number;
  const minLat = metadata.minLat as number;
  const maxLng = metadata.maxLon as number;
  return {
    height,
    width,
    maxU,
    minU,
    maxV,
    minV,
    maxGusts,
    minGusts,
    maxLat,
    minLng,
    minLat,
    maxLng,
  };
};

export const getWwaveMetadataFromExif = (
  fileBuffer: ArrayBuffer,
): WwaveMetadata => {
  const { height, width, metadata } = parseExifTagsAndMetadata(fileBuffer);
  const maxU = metadata.maxSHWWU as number;
  const minU = metadata.minSHWWU as number;
  const maxV = metadata.maxSHWWV as number;
  const minV = metadata.minSHWWV as number;
  const maxMPWW = metadata.maxMPWW as number;
  const minMPWW = metadata.minMPWW as number;
  const maxLat = metadata.maxLat as number;
  const minLng = metadata.minLon as number;
  const minLat = metadata.minLat as number;
  const maxLng = metadata.maxLon as number;
  return {
    height,
    width,
    maxU,
    minU,
    maxV,
    minV,
    maxMPWW,
    minMPWW,
    maxLat,
    minLng,
    minLat,
    maxLng,
  };
};
