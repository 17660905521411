import styled from 'styled-components';

import { RegularSm } from 'modules/ui/typography';

export const Container = styled.div`
  position: fixed;
  right: 20px;
  bottom: 27px;
  width: 300px;
  text-align: right;
  color: #232c3d;
  font-size: 14px;
`;

export const CoordinatesText = styled(RegularSm)`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
`;
