type ParsedVersion = {
  major: number;
  minor: number;
  patch: number;
};

export const parseVersion = (version: string): ParsedVersion => {
  const [major, minor, patch] = version.split('.');
  return {
    major: Number(major),
    minor: Number(minor),
    patch: Number(patch),
  };
};

export const isVersionLower = (
  leftVersion: ParsedVersion,
  rightVersion: ParsedVersion,
): boolean => {
  return (
    leftVersion.major < rightVersion.major ||
    (leftVersion.major === rightVersion.major &&
      leftVersion.minor < rightVersion.minor) ||
    (leftVersion.major === rightVersion.major &&
      leftVersion.minor === rightVersion.minor &&
      leftVersion.patch < rightVersion.patch)
  );
};
