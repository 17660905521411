import { apiClient } from 'modules/apiClient';
import { Route, NewRouteForm } from '../types';

const getRoutes = async (): Promise<Route[]> => {
  const response = await apiClient.get('/v1/routes');
  return response.data;
};

const createRoute = async ({ route, name }: NewRouteForm): Promise<Route> => {
  const response = await apiClient.post(`/v1/routes/`, { route, name });
  return response.data;
};

const updateRoute = async ({
  route,
  name,
  id,
}: NewRouteForm): Promise<Route> => {
  const response = await apiClient.patch(`/v1/routes/${id}`, { route, name });
  return response.data;
};

const deleteRoute = async ({ id }: { id: number }): Promise<void> =>
  apiClient.delete(`/v1/routes/${id}`);

export const RoutesApi = { getRoutes, createRoute, updateRoute, deleteRoute };
