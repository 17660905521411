import React, { useCallback } from 'react';
import { Map } from 'mapbox-gl';
import { useSelector } from 'react-redux';

import { isUiVisibleSelector } from 'modules/metadata';
import { useToggle } from 'modules/main';
import { toggleDarkMode as toggleDarkModeOnMap } from 'modules/mapLayers';
import { MoonIcon, SunIcon } from 'modules/ui/assets';

import { Colors } from 'modules/ui/theme/colors';
import { Container } from './styledComponents';

type Props = {
  map: Map;
  onChange: (isOn: boolean) => void;
};

const GridToggleButton: React.FC<Props> = ({ map, onChange }) => {
  const isUiVisible = useSelector(isUiVisibleSelector);
  const [isOn, toggleDarkMode] = useToggle(false);
  const toggleVisibility = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      onChange(!isOn);
      toggleDarkModeOnMap(map, !isOn);
      toggleDarkMode();
    },
    [map, onChange, isOn, toggleDarkMode],
  );
  // Uncomment once night mode is implemented
  // eslint-disable-next-line no-constant-condition
  if (!isUiVisible || true) return null;

  return (
    <Container
      onClick={toggleVisibility}
      buttonColor={isOn ? `${Colors.Destructive}CC` : `${Colors.White}CC`}
    >
      {isOn ? <SunIcon /> : <MoonIcon />}
    </Container>
  );
};

export default React.memo(GridToggleButton);
