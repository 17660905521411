import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setToken,
  setRequestInterceptors,
  setResponseInterceptors,
} from 'modules/apiClient';
import { AuthActions, AuthSelectors } from '../redux';
import { AuthApi } from '../api';

export const useRefreshToken = (): { initialized: boolean } => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const refreshToken = useSelector(AuthSelectors.refreshToken);
  const refreshTokenRef = useRef<string>();

  useEffect(() => {
    refreshTokenRef.current = refreshToken;
  }, [refreshToken]);

  const accessToken = useSelector(AuthSelectors.accessToken);
  // eslint-disable-next-line consistent-return
  const handleRefreshToken = useCallback(async () => {
    try {
      const tokens = await AuthApi.refreshToken({
        token: refreshTokenRef.current || '',
      });
      setToken(tokens.accessToken);
      dispatch(AuthActions.refreshTokens(tokens));
      return tokens.accessToken;
    } catch (error) {
      setToken('');
      dispatch(AuthActions.logOutUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setToken(accessToken);
    setRequestInterceptors();
    setResponseInterceptors(handleRefreshToken);
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    initialized,
  };
};
