import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Container, SelectContainer } from './styledComponents';

type Props = {
  options: Array<{ value: string | number; label: string }>;
  width?: number;
  change?: (field: string, value: number | string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & FieldRenderProps<any, HTMLElement>;

const SelectInput: React.FC<Props> = ({ options, width, input, change }) => {
  useEffect(() => {
    const isOptionsPresent = options.find((o) => o.value === input.value);
    if (!isOptionsPresent && change && options[0]) {
      change(input.name, options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  return (
    <Container>
      <SelectContainer width={width} {...input}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectContainer>
    </Container>
  );
};
export default React.memo(SelectInput);
