import React from 'react';

import { HighlightedBase } from 'modules/ui/typography';
import { ProgressBar } from '..';

import { Container, ProgressBarContainer } from './styledComponents';

type Props = {
  title: string;
};

const LoadingTitledComponent: React.FC<Props> = ({ title }) => (
  <Container>
    <HighlightedBase>{title}</HighlightedBase>
    <ProgressBarContainer>
      <ProgressBar
        type="indeterminate"
        backgroundColor=" linear-gradient(90deg, #0B83D7 13.54%, #89C72C 76.56%, #FFE500 100%)"
        percentage={0}
      />
    </ProgressBarContainer>
  </Container>
);

export default React.memo(LoadingTitledComponent);
