import { combineReducers, createReducer } from '@reduxjs/toolkit';

import db from 'modules/localForage/db';
import { persistReducer, PersistConfig } from 'redux-persist';
import { MeteoDataTypes } from '../consts';

import {
  DownloadQueueItem,
  WindMeteoDataSet,
  WaveMeteoDataSet,
  OceanMeteoDataSet,
} from '../types';

import {
  addToMeteoQueue,
  createEcmwfMeteoModelDataSet,
  downloadedMeteoComponent,
  addToEcmwfMeteoModelDataSet,
  setDataSetTimeOffset,
  setEcmwfTimeSet,
  createGfsMeteoModelDataSet,
  addToGfsMeteoModelDataSet,
  setGfsTimeSet,
  createWamMeteoModelDataSet,
  setWamTimeSet,
  addToWamMeteoModelDataSet,
  removeEcmwfMeteoModelDataSet,
  removeGfsMeteoModelDataSet,
  removeWamMeteoModelDataSet,
  createNemoMeteoModelDataSet,
  addToNemoMeteoModelDataSet,
  removeNemoMeteoModelDataSet,
  setNemoTimeSet,
  removeFromMeteoQueue,
  incrementDataSetTimeOffset,
  addToGfsWamMeteoModelDataSet,
  createGfsWamMeteoModelDataSet,
  removeGfsWamMeteoModelDataSet,
  setGfsWamTimeSet,
} from './actions';

const initialState: { list: DownloadQueueItem[] } = { list: [] };

const downloadQueue = createReducer(initialState, (builder) => {
  builder
    .addCase(addToMeteoQueue, (state, action) => {
      state.list = [...state.list, ...action.payload];
      return state;
    })
    .addCase(downloadedMeteoComponent, (state, action) => {
      state.list = state.list.filter((items) => items.link !== action.payload);
      return state;
    })
    .addCase(removeFromMeteoQueue, (state, action) => {
      state.list = state.list.filter(
        (items) => items.downloadId !== action.payload,
      );
      return state;
    });
});

const downloadQueueConfig: PersistConfig<ReturnType<typeof downloadQueue>> = {
  key: 'meteo:downloadQueue',
  storage: db.app,
};

const downloadQueueReducer = persistReducer(downloadQueueConfig, downloadQueue);

const ecmwf = createReducer(
  {} as { [key: string]: WindMeteoDataSet },
  (builder) => {
    builder
      .addCase(createEcmwfMeteoModelDataSet, (state, action) => {
        state[action.payload.id] = action.payload;
        return state;
      })
      .addCase(addToEcmwfMeteoModelDataSet, (state, action) => {
        const { id, type, time, storageKey } = action.payload;
        if (!state[id]) return state;
        if (type === MeteoDataTypes.Wind) {
          state[id].wind.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Meteo) {
          state[id].weather.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Mslp) {
          state[id].mslp.push({ time, storageKey });
        }
        return state;
      })
      .addCase(removeEcmwfMeteoModelDataSet, (state, action) => {
        delete state[action.payload];
        return state;
      });
  },
);

const ecmwfConfig: PersistConfig<ReturnType<typeof ecmwf>> = {
  key: 'meteo:ecmwf',
  storage: db.app,
};

const ecmwfReducer = persistReducer(ecmwfConfig, ecmwf);

const gfs = createReducer(
  {} as { [key: string]: WindMeteoDataSet },
  (builder) => {
    builder
      .addCase(createGfsMeteoModelDataSet, (state, action) => {
        state[action.payload.id] = action.payload;
        return state;
      })
      .addCase(addToGfsMeteoModelDataSet, (state, action) => {
        const { id, type, time, storageKey } = action.payload;
        if (!state[id]) return state;
        if (type === MeteoDataTypes.Wind) {
          state[id].wind.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Meteo) {
          state[id].weather.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Mslp) {
          state[id].mslp.push({ time, storageKey });
        }
        return state;
      })
      .addCase(removeGfsMeteoModelDataSet, (state, action) => {
        delete state[action.payload];
        return state;
      });
  },
);

const gfsConfig: PersistConfig<ReturnType<typeof gfs>> = {
  key: 'meteo:gfs',
  storage: db.app,
};

const gfsReducer = persistReducer(gfsConfig, gfs);

const wam = createReducer(
  {} as { [key: string]: WaveMeteoDataSet },
  (builder) => {
    builder
      .addCase(createWamMeteoModelDataSet, (state, action) => {
        state[action.payload.id] = action.payload;
        return state;
      })
      .addCase(addToWamMeteoModelDataSet, (state, action) => {
        const { id, type, time, storageKey } = action.payload;
        if (!state[id]) return state;
        if (type === MeteoDataTypes.Swave) {
          state[id].swellWaves.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Wwave) {
          state[id].windWaves.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Wave) {
          state[id].significantWaves.push({ time, storageKey });
        }
        return state;
      })
      .addCase(removeWamMeteoModelDataSet, (state, action) => {
        delete state[action.payload];
        return state;
      });
  },
);

const wamConfig: PersistConfig<ReturnType<typeof wam>> = {
  key: 'meteo:wam',
  storage: db.app,
};

const wamReducer = persistReducer(wamConfig, wam);

const gfsWam = createReducer(
  {} as { [key: string]: WaveMeteoDataSet },
  (builder) => {
    builder
      .addCase(createGfsWamMeteoModelDataSet, (state, action) => {
        state[action.payload.id] = action.payload;
        return state;
      })
      .addCase(addToGfsWamMeteoModelDataSet, (state, action) => {
        const { id, type, time, storageKey } = action.payload;
        if (!state[id]) return state;
        if (type === MeteoDataTypes.Swave) {
          state[id].swellWaves.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Wwave) {
          state[id].windWaves.push({ time, storageKey });
        }
        if (type === MeteoDataTypes.Wave) {
          state[id].significantWaves.push({ time, storageKey });
        }
        return state;
      })
      .addCase(removeGfsWamMeteoModelDataSet, (state, action) => {
        delete state[action.payload];
        return state;
      });
  },
);

const gfsWamConfig: PersistConfig<ReturnType<typeof gfsWam>> = {
  key: 'meteo:gfsWam',
  storage: db.app,
};

const gfsWamReducer = persistReducer(gfsWamConfig, gfsWam);

const nemo = createReducer(
  {} as { [key: string]: OceanMeteoDataSet },
  (builder) => {
    builder
      .addCase(createNemoMeteoModelDataSet, (state, action) => {
        state[action.payload.id] = action.payload;
        return state;
      })
      .addCase(addToNemoMeteoModelDataSet, (state, action) => {
        const { id, time, storageKey } = action.payload;
        if (!state[id]) return state;
        state[id].current.push({ time, storageKey });
        return state;
      })
      .addCase(removeNemoMeteoModelDataSet, (state, action) => {
        delete state[action.payload];
        return state;
      });
  },
);

const nemoConfig: PersistConfig<ReturnType<typeof nemo>> = {
  key: 'meteo:nemo',
  storage: db.app,
};

const nemoReducer = persistReducer(nemoConfig, nemo);

const timeOffset = createReducer(0, (builder) => {
  builder
    .addCase(setDataSetTimeOffset, (state, action) => action.payload)
    .addCase(incrementDataSetTimeOffset, (state) => state + 1);
});

const ecmwfTimeSet = createReducer({ value: '' }, (builder) => {
  builder.addCase(setEcmwfTimeSet, (state, action) => {
    state.value = action.payload;
    return state;
  });
});

const ecmwfTimeSetConfig: PersistConfig<ReturnType<typeof ecmwfTimeSet>> = {
  key: 'meteo:ecmwfTimeSet',
  storage: db.app,
};

const ecmwfTimeSetReducer = persistReducer(ecmwfTimeSetConfig, ecmwfTimeSet);

const gfsTimeSet = createReducer({ value: '' }, (builder) => {
  builder.addCase(setGfsTimeSet, (state, action) => {
    state.value = action.payload;
    return state;
  });
});

const gfsTimeSetConfig: PersistConfig<ReturnType<typeof gfsTimeSet>> = {
  key: 'meteo:gfsTimeSet',
  storage: db.app,
};

const gfsTimeSetReducer = persistReducer(gfsTimeSetConfig, gfsTimeSet);

const wamTimeSet = createReducer({ value: '' }, (builder) => {
  builder.addCase(setWamTimeSet, (state, action) => {
    state.value = action.payload;
    return state;
  });
});

const wamTimeSetConfig: PersistConfig<ReturnType<typeof wamTimeSet>> = {
  key: 'meteo:wamTimeSet',
  storage: db.app,
};

const wamTimeSetReducer = persistReducer(wamTimeSetConfig, wamTimeSet);

const gfsWamTimeSet = createReducer({ value: '' }, (builder) => {
  builder.addCase(setGfsWamTimeSet, (state, action) => {
    state.value = action.payload;
    return state;
  });
});

const gfsWamTimeSetConfig: PersistConfig<ReturnType<typeof gfsWamTimeSet>> = {
  key: 'meteo:gfsWamTimeSet',
  storage: db.app,
};

const gfsWamTimeSetReducer = persistReducer(gfsWamTimeSetConfig, gfsWamTimeSet);

const nemoTimeSet = createReducer({ value: '' }, (builder) => {
  builder.addCase(setNemoTimeSet, (state, action) => {
    state.value = action.payload;
    return state;
  });
});

const nemoTimeSetConfig: PersistConfig<ReturnType<typeof nemoTimeSet>> = {
  key: 'meteo:nemoTimeSet',
  storage: db.app,
};

const nemoTimeSetReducer = persistReducer(nemoTimeSetConfig, nemoTimeSet);

export default combineReducers({
  downloadQueue: downloadQueueReducer,
  ecmwf: ecmwfReducer,
  timeOffset,
  ecmwfTimeSet: ecmwfTimeSetReducer,
  gfs: gfsReducer,
  gfsTimeSet: gfsTimeSetReducer,
  wam: wamReducer,
  wamTimeSet: wamTimeSetReducer,
  gfsWam: gfsWamReducer,
  gfsWamTimeSet: gfsWamTimeSetReducer,
  nemo: nemoReducer,
  nemoTimeSet: nemoTimeSetReducer,
});
