import { removeFromMeteoQueue } from 'modules/meteo';
import { deleteModelDataSet } from 'modules/meteo/redux/thunks';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ProgressBar } from 'modules/ui/components';
import { removeDownload } from '../../redux';
import { DownloadModel } from '../../types';
import {
  ActiveItem,
  FileName,
  RowContainer,
  ProgressContainer,
  Status,
  ColumContainer,
  Cancel,
} from './styledComponents';

type Props = {
  item: DownloadModel;
};

const DownloadItem: React.FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const cancelDownload = useCallback(() => {
    dispatch(removeFromMeteoQueue(item.id));
    dispatch(removeDownload({ id: item.id }));
    if (item.modelIds) {
      item.modelIds.forEach((modelItem) => {
        dispatch(deleteModelDataSet(modelItem.id, modelItem.source));
      });
    }
  }, [dispatch, item]);
  const percentage = Number(
    (
      item.progress ||
      ((item.downloadedFiles || 0) / (item.totalFiles || 0)) * 100 ||
      0
    ).toFixed(2),
  );
  return (
    <ActiveItem>
      <RowContainer>
        <ColumContainer>
          <FileName>{item.name}</FileName>
          <Status>{`${percentage}%`}</Status>
        </ColumContainer>
        <Cancel onClick={cancelDownload}>Cancel</Cancel>
      </RowContainer>
      <ProgressContainer>
        <ProgressBar percentage={percentage} type={item.type} />
      </ProgressContainer>
    </ActiveItem>
  );
};

export default DownloadItem;
