import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';

import db from 'modules/localForage/db';
import {
  toggleShowMeteoDataFlag,
  turnOnShowMeteoDataFlag,
  toggleShowRoutingDataFlag,
  turnOnShowRoutingDataFlag,
  hideAllUi,
  showAllUi,
  setIsOnlineFlag,
  setIsLiveBoatDataOk,
  setAppVersionInfoSeen,
} from './actions';

const showMeteoData = createReducer(true, (builder) => {
  builder
    .addCase(toggleShowMeteoDataFlag, (state) => !state)
    .addCase(turnOnShowMeteoDataFlag, () => true);
});

const showRoutingData = createReducer(true, (builder) => {
  builder
    .addCase(toggleShowRoutingDataFlag, (state) => !state)
    .addCase(turnOnShowRoutingDataFlag, () => true);
});

const uiVisible = createReducer(true, (builder) => {
  builder.addCase(hideAllUi, () => false).addCase(showAllUi, () => true);
});

const isOnline = createReducer(navigator.onLine, (builder) => {
  builder.addCase(setIsOnlineFlag, (state, action) => action.payload);
});

const isLiveBoatDataOk = createReducer(false, (builder) => {
  builder.addCase(setIsLiveBoatDataOk, (state, action) => action.payload);
});

const appVersionInfoSeen = createReducer('0.0.0', (builder) => {
  builder.addCase(setAppVersionInfoSeen, (state, action) => action.payload);
});

const reducer = combineReducers({
  showMeteoData,
  showRoutingData,
  uiVisible,
  isOnline,
  isLiveBoatDataOk,
  appVersionInfoSeen,
});

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'metadata',
  storage: db.app,
  whitelist: ['appVersionInfoSeen'],
};

export const metadataReducer = persistReducer(persistConfig, reducer);
