export const getFileFromLocalForage = async (
  table: LocalForage,
  storageKey: string,
  fileName: string,
): Promise<File | null> => {
  const buffer: ArrayBuffer | null = await table.getItem(storageKey);
  if (!buffer) return null;
  return new File([buffer], fileName);
};

export const getArrayBufferFromLocalForage = async (
  table: LocalForage,
  storageKey: string,
): Promise<ArrayBuffer | null> => {
  const buffer: ArrayBuffer | null = await table.getItem(storageKey);
  if (!buffer) return null;
  return buffer;
};

export const getGeoJsonFromLocalForage = async (
  table: LocalForage,
  storageKey: string,
): Promise<GeoJSON.FeatureCollection<GeoJSON.Geometry> | null> =>
  table.getItem(storageKey);

export const storeToLocalForage = async (
  table: LocalForage,
  storageKey: string,
  data: unknown,
): Promise<void> => {
  await table.setItem(storageKey, data);
};

export const deleteFromLocalForage = async (
  table: LocalForage,
  storageKey: string,
): Promise<void> => {
  await table.removeItem(storageKey);
};

export const deleteMultipleFromLocalForage = async (
  table: LocalForage,
  storageKeys: string[],
): Promise<void> => {
  await Promise.all(
    storageKeys.map((key) => deleteFromLocalForage(table, key)),
  );
};
