import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularSm } from 'modules/ui/typography';

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
`;

export const LinkLabelText = styled(RegularSm)`
  color: ${Colors.Primary};
  margin-left: 6px;
`;
