import React from 'react';

import { Label } from './styledComponents';

type Props = {
  text?: string;
};

const EmptyDataComponent: React.FC<Props> = ({ text }) => (
  <Label>{text || 'No data downloaded yet.'}</Label>
);

export default React.memo(EmptyDataComponent);
