import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { RoutingTablePage } from 'modules/table';
import {
  LoginPage,
  PrivateRoute,
  RegisterPage,
  AuthRoute,
  RegistrationSuccess,
  AccountVerificationPage,
  useRefreshToken,
} from 'modules/auth';
import {
  FullMeteogramInPoint,
  OfflineMeteogramInPoint,
  RoutingMeteogram,
} from 'modules/meteogram';
import App from './App';
import { Routes } from './Routes';

export const AppRouter: React.FC = () => {
  useRefreshToken();
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path={Routes.App} component={App} />
        <PrivateRoute
          exact
          path={Routes.OfflineMeteogramInPoint}
          component={OfflineMeteogramInPoint}
        />
        <PrivateRoute path={Routes.Table} component={RoutingTablePage} />
        <PrivateRoute
          exact
          path={Routes.FullMeteogramInPoint}
          component={FullMeteogramInPoint}
        />
        <PrivateRoute
          exact
          path={Routes.RoutingMeteogram}
          component={RoutingMeteogram}
        />
        <AuthRoute exact path={Routes.Login} component={LoginPage} />
        <AuthRoute exact path={Routes.Register} component={RegisterPage} />
        <AuthRoute
          exact
          path={Routes.RegistrationSuccess}
          component={RegistrationSuccess}
        />
        <AuthRoute
          exact
          path={Routes.AccountVerification}
          component={AccountVerificationPage}
        />
        <AuthRoute component={LoginPage} />
      </Switch>
    </Router>
  );
};
