import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularBase } from 'modules/ui/typography';

export const Footer = styled.div`
  height: 64px;
  min-height: 64px;
  background-color: ${Colors.LightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
`;

export const IconAndLabelText = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  & > svg {
    margin-left: 24px;
    margin-right: 16px;
  }
`;

export const FooterLabel = styled(RegularBase)`
  color: ${Colors.SolidGray};
`;
