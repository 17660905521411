import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RegularBase } from 'modules/ui/typography';
import { Tooltip, Spinner } from 'modules/ui/components';
import { DownloadIcon, TrashBinIcon } from 'modules/ui/assets';
import { RoutingStatusCodes } from 'modules/routing/consts';
import { Route } from '../../types';
import {
  downloadServerRoutingFilesThunk,
  deleteServerRouteThunk,
} from '../../redux/thunk';
import { formatRoutingSubtitle } from '../../utils/formater';

import {
  Container,
  InfoContainer,
  Subtitle,
  ProcessingLabel,
  ErrorLabel,
  StatusContainer,
} from './styledComponents';

type Props = {
  route: Route;
};

const RowUi: React.FC<Props> = ({ route }) => {
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const onMouseEnter = useCallback(() => setIsHovering(true), []);
  const onMouseExit = useCallback(() => setIsHovering(false), []);
  const onFinish = useCallback(() => {
    setIsDownloading(false);
  }, []);
  const downloadRoute = useCallback(() => {
    setIsDownloading(true);
    dispatch(downloadServerRoutingFilesThunk(route, onFinish));
  }, [route, dispatch, onFinish]);
  const deleteRoute = useCallback(() => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you want to delete the ${route.name} from the server?`,
      )
    ) {
      dispatch(deleteServerRouteThunk(route));
    }
  }, [route, dispatch]);
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}>
      <InfoContainer>
        <RegularBase>{route.name}</RegularBase>
        <Subtitle>
          {formatRoutingSubtitle(
            route.model,
            route.type,
            route.polarEfficiency,
            route.polarSpread,
            route.distance,
            route.startTime,
          )}
        </Subtitle>
      </InfoContainer>
      {isDownloading && (
        <StatusContainer>
          <Spinner />
        </StatusContainer>
      )}
      {!isDownloading && (
        <StatusContainer>
          {isHovering && <TrashBinIcon onClick={deleteRoute} />}

          {route.statusCode === RoutingStatusCodes.Error && !!route.status && (
            <Tooltip place="left" text={route.status} multiline />
          )}
          {route.statusCode === RoutingStatusCodes.Done && (
            <DownloadIcon onClick={downloadRoute} />
          )}
          {route.statusCode === RoutingStatusCodes.Processing && (
            <ProcessingLabel>Processing... </ProcessingLabel>
          )}
          {route.statusCode === RoutingStatusCodes.Error && (
            <ErrorLabel>Failed</ErrorLabel>
          )}

          {(route.statusCode === undefined ||
            route.statusCode === RoutingStatusCodes.Unknown) &&
            route.status === 'Done!' && (
              <DownloadIcon onClick={downloadRoute} />
            )}
          {(route.statusCode === undefined ||
            route.statusCode === RoutingStatusCodes.Unknown) &&
            (route.status.includes('Error') ||
              route.status.includes('error')) && (
              <ErrorLabel>Failed</ErrorLabel>
            )}
          {(route.statusCode === undefined ||
            route.statusCode === RoutingStatusCodes.Unknown) &&
            route.status.includes('Processing') && (
              <ProcessingLabel>Processing... </ProcessingLabel>
            )}
        </StatusContainer>
      )}
    </Container>
  );
};

export default React.memo(RowUi);
