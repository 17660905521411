/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  OceanMetadata,
  SWaveMetadata,
  WaveMetadata,
  WeatherMetadata,
  WindMetadata,
  WwaveMetadata,
} from '../types';

export const metersPerSecondsToKnots = (value: number): number => value * 1.944;

export const getWindValueGetters = (metadata: WindMetadata) => {
  const getUValue = (value: number): number =>
    value * ((metadata.maxU - metadata.minU) / 255) + metadata.minU;
  const getVValue = (value: number): number =>
    value * ((metadata.maxV - metadata.minV) / 255) + metadata.minV;
  const getGustsValue = (value: number): number =>
    value * ((metadata.maxGusts - metadata.minGusts) / 255) + metadata.minGusts;
  return {
    getUValue,
    getVValue,
    getGustsValue,
  };
};

type WindGetter = ReturnType<typeof getWindValueGetters>;

export const getWindValues = (
  getters: WindGetter,
  pixelData: Uint8ClampedArray,
): {
  g: number;
  ws: number;
  wd: number;
} => {
  const uComponent = getters.getUValue(pixelData[0]);
  const vComponent = getters.getVValue(pixelData[1]);
  const windSpeed = Math.sqrt(uComponent ** 2 + vComponent ** 2);
  const windDirection = Math.round(
    180 + (180 / Math.PI) * Math.atan2(uComponent, vComponent),
  );
  const gustsComponent = getters.getGustsValue(pixelData[2]);
  const values = {
    g: Number(metersPerSecondsToKnots(gustsComponent).toFixed(1)),
    ws: Number(metersPerSecondsToKnots(windSpeed).toFixed(1)),
    wd: windDirection,
  };
  return values;
};

export const getWeatherValueGetters = (metadata: WeatherMetadata) => {
  const getTCC = (value: number) =>
    value >= 50
      ? value * ((metadata.maxTCC - metadata.minTCC) / 255) + metadata.minTCC
      : 0;
  const getRR = (value: number) =>
    value >= 50
      ? value * ((metadata.maxRR - metadata.minRR) / 255) + metadata.minRR
      : 0;
  const getT2m = (value: number) =>
    value * ((metadata.maxT2M - metadata.minT2M) / 255) + metadata.minT2M;
  return {
    getTCC,
    getRR,
    getT2m,
  };
};

type WeatherGetter = ReturnType<typeof getWeatherValueGetters>;

export const getWeatherValues = (
  getters: WeatherGetter,
  pixelData: Uint8ClampedArray,
): {
  c: number;
  r: number;
  t: number;
} => {
  const TCC = Math.round(getters.getTCC(pixelData[0]));
  const RR = getters.getRR(pixelData[1]);
  const T2m = getters.getT2m(pixelData[2]);
  const values = {
    c: TCC,
    r: Number(RR.toFixed(1)),
    t: Number(T2m.toFixed(1)),
  };
  return values;
};

export const getSWaveValueGetters = (metadata: SWaveMetadata) => {
  const getUValue = (value: number): number =>
    value * ((metadata.maxU - metadata.minU) / 255) + metadata.minU;
  const getVValue = (value: number): number =>
    value * ((metadata.maxV - metadata.minV) / 255) + metadata.minV;
  const getMPTSValue = (value: number): number =>
    value * ((metadata.maxMPTS - metadata.minMPTS) / 255) + metadata.minMPTS;
  return {
    getUValue,
    getVValue,
    getMPTSValue,
  };
};

type SwaveGetter = ReturnType<typeof getSWaveValueGetters>;

export const getSWaveValues = (
  getters: SwaveGetter,
  pixelData: Uint8ClampedArray,
): {
  h: number;
  d: number;
  p: number;
} => {
  const uComponent = getters.getUValue(pixelData[0]);
  const vComponent = getters.getVValue(pixelData[1]);
  const SHTSValue = Math.sqrt(uComponent ** 2 + vComponent ** 2);
  const direction = Math.round(
    180 + (180 / Math.PI) * Math.atan2(uComponent, vComponent),
  );
  const MPTSValue = getters.getMPTSValue(pixelData[2]);
  const values = {
    h: Number(SHTSValue.toFixed(1)),
    d: direction,
    p: Number(MPTSValue.toFixed(1)),
  };
  return values;
};

export const getWaveValueGetters = (metadata: WaveMetadata) => {
  const getUValue = (value: number): number =>
    value * ((metadata.maxU - metadata.minU) / 255) + metadata.minU;
  const getVValue = (value: number): number =>
    value * ((metadata.maxV - metadata.minV) / 255) + metadata.minV;
  const getWCValue = (value: number): number =>
    value * ((metadata.maxWC - metadata.minWC) / 255) + metadata.minWC;
  return {
    getUValue,
    getVValue,
    getWCValue,
  };
};

type WaveGetter = ReturnType<typeof getWaveValueGetters>;

export const getWaveValues = (
  getters: WaveGetter,
  pixelData: Uint8ClampedArray,
): {
  h: number;
  d: number;
  c: number;
} => {
  const uComponent = getters.getUValue(pixelData[0]);
  const vComponent = getters.getVValue(pixelData[1]);
  const SWHSValue = Math.sqrt(uComponent ** 2 + vComponent ** 2);
  const direction = Math.round(
    180 + (180 / Math.PI) * Math.atan2(uComponent, vComponent),
  );
  const WCValue = getters.getWCValue(pixelData[2]);
  const values = {
    h: Number(SWHSValue.toFixed(1)),
    d: direction,
    c: Number(WCValue.toFixed(4)),
  };
  return values;
};

export const getWWaveValueGetters = (metadata: WwaveMetadata) => {
  const getUValue = (value: number): number =>
    value * ((metadata.maxU - metadata.minU) / 255) + metadata.minU;
  const getVValue = (value: number): number =>
    value * ((metadata.maxV - metadata.minV) / 255) + metadata.minV;
  const getMPWWValue = (value: number): number =>
    value * ((metadata.maxMPWW - metadata.minMPWW) / 255) + metadata.minMPWW;
  return {
    getUValue,
    getVValue,
    getMPWWValue,
  };
};

type WwaveGetter = ReturnType<typeof getWWaveValueGetters>;

export const getWWaveValues = (
  getters: WwaveGetter,
  pixelData: Uint8ClampedArray,
): {
  h: number;
  d: number;
  p: number;
} => {
  const uComponent = getters.getUValue(pixelData[0]);
  const vComponent = getters.getVValue(pixelData[1]);
  const SHWWValue = Math.sqrt(uComponent ** 2 + vComponent ** 2);
  const direction = Math.round(
    180 + (180 / Math.PI) * Math.atan2(uComponent, vComponent),
  );
  const MPWWValue = getters.getMPWWValue(pixelData[2]);
  const values = {
    h: Number(SHWWValue.toFixed(1)),
    d: direction,
    p: Number(MPWWValue.toFixed(1)),
  };
  return values;
};

export const getOceanValueGetters = (metadata: OceanMetadata) => {
  const getUValue = (value: number): number =>
    value * ((metadata.maxU - metadata.minU) / 255) + metadata.minU;
  const getVValue = (value: number): number =>
    value * ((metadata.maxV - metadata.minV) / 255) + metadata.minV;
  const getSSTValue = (value: number): number =>
    value * ((metadata.maxSST - metadata.minSST) / 255) + metadata.minSST;
  return {
    getUValue,
    getVValue,
    getSSTValue,
  };
};

type OceanGetter = ReturnType<typeof getOceanValueGetters>;

export const getOceanValues = (
  getters: OceanGetter,
  pixelData: Uint8ClampedArray,
): {
  t: number;
  s: number;
  d: number;
} => {
  const uComponent = getters.getUValue(pixelData[0]);
  const vComponent = getters.getVValue(pixelData[1]);
  const speed = Math.sqrt(uComponent ** 2 + vComponent ** 2);
  const direction = 180 + (180 / Math.PI) * Math.atan2(uComponent, vComponent);
  const t = getters.getSSTValue(pixelData[2]);
  const values = {
    t: Number(t.toFixed(1)),
    s: Number(metersPerSecondsToKnots(speed).toFixed(1)),
    d: Number(direction.toFixed(2)),
  };
  return values;
};

export const getWindComparisonValues = (
  getters: WindGetter[],
  pixelsData: Uint8ClampedArray[],
): {
  eSpeed: number;
  eDir: number;
  gSpeed: number;
  gDir: number;
  sDiff: number;
} => {
  const ecmwfUComponent = getters[0].getUValue(pixelsData[0][0]);
  const ecmwfVComponent = getters[0].getVValue(pixelsData[0][1]);
  const ecmwfWindSpeed = Math.sqrt(ecmwfUComponent ** 2 + ecmwfVComponent ** 2);
  const ecmwfWindDirection = Math.round(
    180 + (180 / Math.PI) * Math.atan2(ecmwfUComponent, ecmwfVComponent),
  );

  const gfsUComponent = getters[1].getUValue(pixelsData[1][0]);
  const gfsVComponent = getters[1].getVValue(pixelsData[1][1]);
  const gfsWindSpeed = Math.sqrt(gfsUComponent ** 2 + gfsVComponent ** 2);
  const gfsWindDirection = Math.round(
    180 + (180 / Math.PI) * Math.atan2(gfsUComponent, gfsVComponent),
  );
  const speedDiff = Math.abs(ecmwfWindSpeed - gfsWindSpeed);
  const barbValues = {
    eSpeed: Number(metersPerSecondsToKnots(ecmwfWindSpeed).toFixed(1)),
    eDir: ecmwfWindDirection,
    gSpeed: Number(metersPerSecondsToKnots(gfsWindSpeed).toFixed(1)),
    gDir: gfsWindDirection,
  };
  const values = {
    sDiff: Number(metersPerSecondsToKnots(speedDiff).toFixed(1)),
    ...barbValues,
  };
  return values;
};
