/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import mapboxgl, { Map } from 'mapbox-gl';
import db from 'modules/localForage/db';

import { Layers } from 'modules/mapLayers/consts';
import ContextMenu from 'modules/contextMenu';
import {
  MapColors,
  MapControl,
  Menu,
  CoordinatesInfoContainer,
  NightModeToggle,
} from 'modules/map';
import { Timeline } from 'modules/timeline';
import { Notifications } from 'modules/notifications';
import { FullMeteogramInPointData } from 'modules/meteogram/components';
// import { useUpdateServiceWorker } from 'modules/serviceWorker';

import {
  loadMapSources,
  addWindLayers,
  addWeatherLayers,
  addWaveLayers,
  addMeteogramIconsLayer,
  loadMeteogramIcon,
  loadWindBarbImages,
  addOceanLayers,
  addRoutingLayers,
  addBaseLayers,
  addTopLayers,
  addRoutesLayers,
} from 'modules/mapLayers';
import { preloadMapSources } from 'modules/mapLayers/utils/sources';

import './App.css';

const MapUi: React.FC = () => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  // const { updateServiceWorker } = useUpdateServiceWorker();

  const loadLayer = useCallback(async (map, storageKey) => {
    await db.map.getItem(storageKey, async (err, jsonData) => {
      if (!map) return;
      map
        .getSource(`${storageKey}-source`)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(jsonData);
    });
  }, []);

  // initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      center: { lat: 37, lng: -32 },
      zoom: 3,
      dragRotate: false,
      maxZoom: 9.8,
      minZoom: 3,
    });
    mapRef.current = map;

    preloadMapSources(map);
    addBaseLayers(map);

    map.on('load', () => {
      loadLayer(map, Layers.Coastline)
        .then(() => {
          return loadLayer(map, Layers.Ocean);
        })
        .then(() => {
          return loadLayer(map, Layers.Graticules1);
        })
        .then(() => {
          return loadLayer(map, Layers.MinorIslands);
        })
        .then(() => {
          return loadLayer(map, Layers.Land);
        })
        .then(() => {
          return loadLayer(map, Layers.IceZone);
        })
        .then(() => {
          loadMapSources(map);
          addWindLayers(map);
          addWeatherLayers(map);
          addWaveLayers(map);
          addMeteogramIconsLayer(map);
          addOceanLayers(map);
          addRoutingLayers(map);
          addRoutesLayers(map);
          addTopLayers(map);
          loadMeteogramIcon(map);
          loadWindBarbImages(map);
          setMapLoaded(true);
        });
    });

    // updateServiceWorker();

    map.on('click', 'meteograms', (e: any) => {
      const feature = e.features[0];
      window.open(`/meteogram/${feature.id}`, '_blank');
    });

    map.on('mouseenter', 'meteograms', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'meteograms', () => {
      map.getCanvas().style.cursor = '';
    });

    // clean up on unmount
    return (): void => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onModeChange = useCallback((isChecked: boolean): void => {
    if (!mapContainerRef.current || !mapRef.current) return;
    mapContainerRef.current.style.backgroundColor = !isChecked
      ? MapColors.LightLand
      : MapColors.DarkLand;
  }, []);

  return (
    <>
      <div
        id="map"
        style={{ backgroundColor: MapColors.LightLand }}
        ref={mapContainerRef}
      />

      {mapLoaded && mapRef.current && (
        <>
          <Notifications />
          <ContextMenu map={mapRef.current} />
          <FullMeteogramInPointData map={mapRef.current} />
          <Menu onModeChange={onModeChange} map={mapRef.current} />
          <MapControl map={mapRef.current} />
          <CoordinatesInfoContainer map={mapRef.current} />
          <NightModeToggle map={mapRef.current} onChange={onModeChange} />
          <Timeline map={mapRef.current} />
        </>
      )}
    </>
  );
};

export default React.memo(MapUi);
