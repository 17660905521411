import React from 'react';
import { useSelector } from 'react-redux';

import {
  areMapLayersDownloadedSelector,
  MapLayersDownloader,
} from 'modules/mapLayers';

import Map from './Map';
import './App.css';

const App: React.FC = () => {
  const isMapLayersDownloaded = useSelector(areMapLayersDownloadedSelector);
  return isMapLayersDownloaded ? <Map /> : <MapLayersDownloader />;
};

export default React.memo(App);
