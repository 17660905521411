import { PolarSpreadMetadata } from '../types';
import { formatIsoUtcTime, getDistance } from './time';

export const formatPolarSpreadTooltip = (
  startTime: string,
  polarMetadata: PolarSpreadMetadata | undefined,
): string | undefined => {
  if (!startTime || !polarMetadata) return undefined;
  let tooltip = `Start: ${formatIsoUtcTime(startTime)}`;
  Object.entries(polarMetadata).forEach(([value, time]) => {
    tooltip += `<br>${value}%: ${formatIsoUtcTime(time)} (${getDistance(
      startTime,
      time,
    )})`;
  });
  return tooltip;
};
