import styled from 'styled-components';

import { HighlightedBase, RegularSm } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(HighlightedBase)`
  margin-top: 100px;
  margin-bottom: 8px;
`;

export const Subtitle = styled(RegularSm)`
  margin: 0 40px 100px 40px;
  color: ${Colors.SolidGray};
  text-align: center;
`;
