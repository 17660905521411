import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';

import { Chips } from 'modules/ui/components';
import { ModelLayers } from 'modules/mapLayers/consts';
import { MeteoTabProperties } from 'modules/meteo/types';
import MeteoModelAccordion from '../MeteoModelAccordion';
import TimeOptions from '../TimeOptions';
import {
  infoNemoTimeOptionsSelector,
  nemoTimeSetSelector,
} from '../../redux/selectors';
import { setNemoTimeSet } from '../../redux/actions';
import { useNemoSourceAndLayers } from '../../hooks';
import ModelCountIndicator from '../ModelCountIndicator';
import EmptyDataComponent from '../EmptyDataComponent';

type Props = {
  map: Map;
  showMeteoData: boolean;
  addToScaleArray: (layer: ModelLayers) => void;
  removeFromScaleArray: (layer: ModelLayers) => void;
  turnOnShowMeteoData: () => void;
} & MeteoTabProperties;

const NemoMeteoData: React.FC<Props> = ({
  map,
  showMeteoData,
  addToScaleArray,
  removeFromScaleArray,
  turnOnShowMeteoData,
  isOpened,
  tabName,
  toggleTab,
}) => {
  const dispatch = useDispatch();
  const selectedTime = useSelector(nemoTimeSetSelector);
  const options = useSelector(infoNemoTimeOptionsSelector);

  const {
    componentsCount,
    isCurrentDirectionVisible,
    isCurrentSpeedVisible,
    isCurrentTemperatureVisible,
    setIsCurrentDirectionVisible,
    setIsCurrentSpeedVisible,
    setIsCurrentTemperatureVisible,
  } = useNemoSourceAndLayers(
    map,
    showMeteoData,
    addToScaleArray,
    removeFromScaleArray,
    turnOnShowMeteoData,
  );

  const handleTimeSetOption = useCallback(
    (value: string) => {
      dispatch(setNemoTimeSet(value));
    },
    [dispatch],
  );

  const handleToggleTab = useCallback(() => {
    toggleTab(tabName);
  }, [toggleTab, tabName]);

  return (
    <MeteoModelAccordion
      handleToggleTab={handleToggleTab}
      isOpened={isOpened}
      title="Mercator Ocean NEMO ocean model"
      titleComponent={
        componentsCount ? (
          <ModelCountIndicator>{componentsCount}</ModelCountIndicator>
        ) : undefined
      }
    >
      <TimeOptions
        options={options}
        selectedTime={selectedTime}
        handleTimeSetOption={handleTimeSetOption}
      />
      {!options.length && <EmptyDataComponent />}
      {!!options.length && (
        <div>
          <Chips
            value={showMeteoData && isCurrentSpeedVisible}
            name="currentSpeed"
            label="Speed"
            onClick={setIsCurrentSpeedVisible}
          />
          <Chips
            value={showMeteoData && isCurrentDirectionVisible}
            name="currentDirection"
            label="Direction"
            onClick={setIsCurrentDirectionVisible}
          />
          <Chips
            value={showMeteoData && isCurrentTemperatureVisible}
            name="currentTemperature"
            label="Temperature"
            onClick={setIsCurrentTemperatureVisible}
          />
        </div>
      )}
    </MeteoModelAccordion>
  );
};

export default React.memo(NemoMeteoData);
