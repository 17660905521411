import React from 'react';
import ReactModal from 'react-modal';

import { CloseIcon } from 'modules/ui/assets';
import { H1 } from 'modules/ui/typography';

import { Container, TitleContainer, ModalFooter } from './styledComponents';

ReactModal.setAppElement('#root');

const MODAL_STYLES = (isSmall: boolean, width: number): ReactModal.Styles => ({
  overlay: {
    backgroundColor: 'rgba(1,7,36, 0.75)',
    backdropFilter: 'blur(10px)',
    zIndex: 999,
  },
  content: {
    width: `${width}px`,
    maxHeight: 'calc(100% - 160px)',
    right: undefined,
    left: '50%',
    top: isSmall ? '50%' : '80px',
    transform: isSmall ? 'translate(-50%, -50%)' : 'translate(-50%)',
    overflowY: 'hidden',
    display: 'flex',
    padding: 0,
    bottom: undefined,
  },
});

type Props = {
  isOpen: boolean;
  width: number;
  onRequestClose?: () => void;
  children: React.ReactChild | React.ReactNodeArray;
  title: string;
  closeModal: () => void;
  isSmall?: boolean;
};

const Modal: React.FC<Props> = ({
  isOpen,
  width,
  children,
  onRequestClose,
  title,
  closeModal,
  isSmall,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={MODAL_STYLES(!!isSmall, width)}
      onAfterClose={onRequestClose}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <Container>
        <TitleContainer>
          <H1>{title}</H1>
          <CloseIcon onClick={closeModal} />
        </TitleContainer>
        {children}
      </Container>
    </ReactModal>
  );
};

export default React.memo(Modal);

export { ModalFooter };
