import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularSm } from 'modules/ui/typography';

export const Container = styled.div`
  position: fixed;
  right: 198px;
  left: 48px;
  bottom: 30px;
  color: #232c3d;
`;

export const DateSeparator = styled.div<{ step: number }>`
  width: 2px;
  margin-left: 2px;
  height: 6px;
  margin-top: 9px;
  background-color: ${Colors.White};
  position: absolute;
  left: ${({ step }): number => step * 10}%;
  z-index: 2;
`;

export const CurrentTimeIndicator = styled.div<{ offset: number }>`
  width: 4px;
  margin-left: 2px;
  height: 10px;
  margin-top: 7px;
  background-color: ${Colors.Destructive};
  position: absolute;
  left: ${({ offset }): number => offset * 100}%;
  z-index: 3;
`;

export const DateLabel = styled(RegularSm)<{ step: number }>`
  margin-top: 20px;
  position: absolute;
  width: 10%;
  text-align: center;
  left: ${({ step }): number => step * 10}%;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
`;

export const Slider = styled.input`
  overflow: hidden;
  width: 100%;
  -webkit-appearance: none;
  height: 6px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  background-color: ${Colors.PrimaryDark}66;
  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    color: ${`${Colors.Primary}FF`} !important;
  }
  ::-webkit-slider-thumb {
    box-shadow: -2000px 0 0 2000px ${`${Colors.Primary}FF`};
    width: 0px;
  }
  ::-ms-fill-lower {
    background-color: ${`${Colors.Primary}FF`};
  }
  ::-moz-range-progress {
    background-color: ${`${Colors.Primary}FF`};
  }
`;

export const LabelContainer = styled.div<{
  left: number;
  isVisible: boolean;
  dark?: boolean;
}>`
  min-width: ${({ dark }): string | undefined => (dark ? undefined : '90px')};
  display: ${({ isVisible }): string => (isVisible ? 'block' : 'none')};
  background: ${({ dark }): string => (dark ? Colors.Black : Colors.White)};
  color: white;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  transform: ${({ left }): string =>
    `translateX(-${Math.min(left * 300, 50)}%)`};
  left: ${({ left }): number => left * 100}%;
  top: -30px;
`;

export const LabelContainerText = styled(RegularSm)<{ dark?: boolean }>`
  color: ${({ dark }): string => (dark ? Colors.White : Colors.Black)};
`;

export const TimeControlContainer = styled.div`
  position: fixed;
  left: 12px;
  bottom: 22px;
  background: ${Colors.White};
  box-shadow: 0px 2px 6px rgba(1, 7, 36, 0.05);
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
