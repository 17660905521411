import React from 'react';
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import {
  Modal,
  Button,
  DragAndDrop,
  TextInput,
  ModalFooter,
} from 'modules/ui/components';
import { useCreateOrUpdateBoat, BoatsSelectors } from 'modules/boats';
import { validateRequiredString } from 'modules/form';

import { FormContainer, DragAndDropContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const SettingsModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const boat = useSelector(BoatsSelectors.firstBoat);

  const {
    boatExists,
    handleUpdateOrCreate,
    setPolarFile,
    setSailsFile,
    setWaveFile,
    initialName,
    polarsFile,
    sailsFile,
    waveFile,
  } = useCreateOrUpdateBoat(closeModal);

  return (
    <Modal
      isOpen={isOpen}
      width={600}
      title={boatExists ? 'Update boat' : 'Create a boat'}
      closeModal={closeModal}
      onRequestClose={closeModal}
    >
      <Form
        onSubmit={handleUpdateOrCreate}
        initialValues={{ name: initialName }}
        render={({ handleSubmit, invalid, submitting }): React.ReactNode => (
          <>
            <FormContainer>
              <Field
                name="name"
                label="Boat name *"
                type="text"
                component={TextInput}
                validate={validateRequiredString}
              />
              <DragAndDropContainer>
                <DragAndDrop
                  file={
                    polarsFile ||
                    (boat?.polarsFile &&
                      new File([], boat.polarsFile.fileName, {
                        lastModified: new Date(
                          boat.polarsFile.timeStamp,
                        ).getTime(),
                      }))
                  }
                  label="Polar *"
                  onChange={setPolarFile}
                />
              </DragAndDropContainer>
              <DragAndDropContainer>
                <DragAndDrop
                  file={
                    sailsFile ||
                    (boat?.sailsFile &&
                      new File([], boat.sailsFile.fileName, {
                        lastModified: new Date(
                          boat.sailsFile.timeStamp,
                        ).getTime(),
                      }))
                  }
                  label="Sail configuration table"
                  onChange={setSailsFile}
                />
              </DragAndDropContainer>

              <DragAndDropContainer>
                <DragAndDrop
                  file={
                    waveFile ||
                    (boat?.waveFile &&
                      new File([], boat.waveFile.fileName, {
                        lastModified: new Date(
                          boat.waveFile.timeStamp,
                        ).getTime(),
                      }))
                  }
                  label="Wave correction table"
                  onChange={setWaveFile}
                />
              </DragAndDropContainer>
            </FormContainer>
            <ModalFooter>
              <Button isSecondary label="Cancel" clickHandler={closeModal} />
              <Button
                isLoading={submitting}
                disabled={invalid || (!boatExists && !polarsFile) || submitting}
                label={boatExists ? 'Update' : 'Create'}
                clickHandler={handleSubmit}
              />
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  );
};

export default React.memo(SettingsModal);
