import styled from 'styled-components';

import { HighlightedSm } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';

export const Label = styled(HighlightedSm)<{ invalid: boolean }>`
  margin: 3px 0px;
  color: ${({ invalid }): string | undefined =>
    invalid ? Colors.Destructive : undefined};
`;
