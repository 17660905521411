import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Routes } from 'Routes';
import { AuthSelectors } from '../redux';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const isAuthorized = useSelector(AuthSelectors.isAuthorized);

  if (isAuthorized) {
    return <Route {...props} />;
  }

  const { location } = props;
  return (
    <Route {...props} render={undefined} component={undefined}>
      <Redirect
        to={{ pathname: Routes.Login, state: { redirectState: location } }}
      />
    </Route>
  );
};
