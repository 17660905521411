import { MeteoSources } from 'modules/meteo/consts';
import { SettingsWindSpeedCorrection } from 'modules/settings/types';
import { RoutingType } from '../consts';
import { formatIsoUtcTime } from './time';

export const formatWindSpeedCorrectionJson = (
  windSpeedCorrections: SettingsWindSpeedCorrection,
): string => {
  const ecmwf = [
    { TWS: 1, COR: windSpeedCorrections['1ktECMWF'] },
    { TWS: 5, COR: windSpeedCorrections['5ktECMWF'] },
    { TWS: 8, COR: windSpeedCorrections['8ktECMWF'] },
    { TWS: 12, COR: windSpeedCorrections['12ktECMWF'] },
    { TWS: 20, COR: windSpeedCorrections['20ktECMWF'] },
    { TWS: 25, COR: windSpeedCorrections['25ktECMWF'] },
    { TWS: 30, COR: windSpeedCorrections['30ktECMWF'] },
    { TWS: 40, COR: windSpeedCorrections['40ktECMWF'] },
    { TWS: 50, COR: windSpeedCorrections['50ktECMWF'] },
  ];
  const ncep = [
    { TWS: 1, COR: windSpeedCorrections['1ktGFS'] },
    { TWS: 5, COR: windSpeedCorrections['5ktGFS'] },
    { TWS: 8, COR: windSpeedCorrections['8ktGFS'] },
    { TWS: 12, COR: windSpeedCorrections['12ktGFS'] },
    { TWS: 20, COR: windSpeedCorrections['20ktGFS'] },
    { TWS: 25, COR: windSpeedCorrections['25ktGFS'] },
    { TWS: 30, COR: windSpeedCorrections['30ktGFS'] },
    { TWS: 40, COR: windSpeedCorrections['40ktGFS'] },
    { TWS: 50, COR: windSpeedCorrections['50ktGFS'] },
  ];
  return JSON.stringify({ ecmwf, ncep });
};

export const formatModelName = (
  model: string,
  type: RoutingType | string,
): string => {
  if (!model) return '';
  if (type === RoutingType.eps) {
    return model.toLowerCase() === MeteoSources.ECMWF
      ? 'ECMWF Ensemble'
      : 'GFS Ensemble';
  }
  return model.toLowerCase() === MeteoSources.ECMWF ? 'ECMWF' : 'GFS';
};

export const formatRoutingSubtitle = (
  model: string,
  type: RoutingType | string,
  polar: number,
  polarSpread: boolean,
  distance: number,
  startTime: string,
): string => {
  const modelName = formatModelName(model, type);
  const formattedPolarSpread = polar ? `, Polar ${polar}%` : '';
  const formatedDistance = distance ? `, ${distance.toFixed(0)} Nm` : '';
  const formatedStartTime = formatIsoUtcTime(startTime);
  return `${modelName}${formattedPolarSpread}${
    polarSpread ? ' + polar spread' : ''
  }${formatedDistance}, ${formatedStartTime}`;
};
