import { createAction } from '@reduxjs/toolkit';

import { ExclusionZone } from '../types';

export const setExclusionZones = createAction<ExclusionZone[]>(
  'SET_USER_EXCLUSION_ZONES',
);
export const deleteExclusionZone = createAction<number>(
  'DELETE_USER_EXCLUSION_ZONES',
);

export const ExclusionZoneActions = {
  setExclusionZones,
  deleteExclusionZone,
};
