import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point } from '@turf/helpers';
import { useMemo } from 'react';

import {
  convertFormCoordinatesToGeometryCoordinates,
  FormStartAndEndCoordinates,
} from 'modules/form';
import { ExclusionZone } from '../types';

export const useCheckCoordinatesInclusion = (
  zones: ExclusionZone[],
): {
  validateStartAndEndCoordinatesWithExclusionZones: (
    exclusionZones: boolean,
    coordinates: FormStartAndEndCoordinates,
  ) => undefined | string;
} => {
  const relevantZones = useMemo(() => {
    return zones
      .filter(({ enabled }) => enabled)
      .map(({ exclusionZone }) => exclusionZone);
  }, [zones]);

  const validateStartAndEndCoordinatesWithExclusionZones = (
    exclusionZones: boolean,
    coordinates: FormStartAndEndCoordinates,
  ): undefined | string => {
    const pointCoordinates = convertFormCoordinatesToGeometryCoordinates(
      coordinates,
    );
    const points = coordinates.route || [
      point(pointCoordinates[0]),
      point(pointCoordinates[1]),
    ];

    return !!exclusionZones &&
      (relevantZones.some((zone) => booleanPointInPolygon(points[0], zone)) ||
        relevantZones.some((zone) => booleanPointInPolygon(points[1], zone)))
      ? 'Routing start or end point are inside of an exclusion zone.\r\nPlease uncheck this checkbox to continue.'
      : undefined;
  };

  return { validateStartAndEndCoordinatesWithExclusionZones };
};
