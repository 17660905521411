import React, { useCallback } from 'react';

import { HighlightedBase } from 'modules/ui/typography';
import Spinner from '../Spinner';

import { Container } from './styledComponents';

export type Props = {
  label: string;
  clickHandler: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isSecondary?: boolean;
  isTertiary?: boolean;
  disabled?: boolean;
  stretch?: boolean;
  borderColor?: string;
  isSmall?: boolean;
  isLoading?: boolean;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset' | undefined;
  form?: string;
  stopPropagation?: boolean;
};

const ButtonUi: React.FC<Props> = ({
  clickHandler,
  label,
  isSecondary,
  disabled,
  stretch,
  borderColor,
  isSmall,
  isTertiary,
  isLoading,
  style,
  type,
  form,
  stopPropagation,
}) => {
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      clickHandler(e);
    },
    [clickHandler, stopPropagation],
  );
  return (
    <Container
      isSmall={!!isSmall}
      disabled={!!disabled}
      isSecondary={!!isSecondary}
      isTertiary={!!isTertiary}
      onClick={onClick}
      stretch={!!stretch}
      borderColor={borderColor}
      style={style}
      type={type || 'button'}
      form={form}
    >
      {isLoading && <Spinner />}
      {!isLoading && <HighlightedBase>{label}</HighlightedBase>}
    </Container>
  );
};

export default React.memo(ButtonUi);
