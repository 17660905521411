import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const MeteoComponentsContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  padding: 8px 0px;
`;
