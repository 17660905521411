import { BoatsSelectors } from 'modules/boats';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RoutesApi } from '../api';
import { RouteActions, RoutesSelectors } from '../redux';

const useGetRoutes = (): void => {
  const dispatch = useDispatch();
  const hasLoaded = useSelector(RoutesSelectors.hasLoaded);
  const boatsHasLoaded = useSelector(BoatsSelectors.hasLoaded);

  const handleGetRoutes = useCallback(async () => {
    try {
      const routes = await RoutesApi.getRoutes();
      dispatch(RouteActions.setRoutes(routes));
    } catch (error) {
      console.log('Error while getting routes');
    }
  }, [dispatch]);

  useEffect(() => {
    if (boatsHasLoaded && !hasLoaded) {
      handleGetRoutes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded, boatsHasLoaded]);
};

export default useGetRoutes;
