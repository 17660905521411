import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import {
  EventData,
  Map,
  MapboxGeoJSONFeature,
  MapMouseEvent,
  Popup,
} from 'mapbox-gl';

import { makeEmptyGeojson } from 'modules/geojson';
import { getNauticalMilesDistance } from 'modules/coordinates';
import { InteractionLayers, InteractionLayersSources } from 'modules/mapLayers';

const useMeasurementTool = (
  map: Map,
): {
  isMeasurementOn: boolean;
  handleMessurementToggle: () => void;
} => {
  const [isMeasurementOn, toggleIsMeasurementOn] = useState(false);
  const distance = useRef<number | null>(null);
  const geojson = useRef(makeEmptyGeojson());
  const isDragging = useRef(false);

  const popup = useMemo(
    () =>
      new Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    [],
  );

  const onMouseDown = useCallback(
    (ev: mapboxgl.MapboxEvent & mapboxgl.EventData) => {
      try {
        distance.current = null;
        isDragging.current = true;
        const point: GeoJSON.Feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [ev.lngLat.lng, ev.lngLat.lat],
          },
          properties: {},
        };
        const linestring: GeoJSON.Feature<GeoJSON.LineString> = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [[ev.lngLat.lng, ev.lngLat.lat]],
          },
          properties: {},
        };
        geojson.current.features[0] = point;
        geojson.current.features[1] = linestring;
        if (geojson.current.features.length > 2) geojson.current.features.pop();
        map
          .getSource(InteractionLayersSources.MeasurementTool)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(geojson.current);
        const innerHtml = `Distance: ${distance.current || 0} nm`;
        popup
          .setLngLat([ev.lngLat.lng, ev.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      } catch (error) {
        console.log(error);
      }
    },
    [map, popup],
  );

  const onMouseMove = useCallback(
    (ev: mapboxgl.MapboxEvent & mapboxgl.EventData) => {
      try {
        if (isDragging.current) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          geojson.current.features[1].geometry.coordinates[1] = [
            ev.lngLat.lng,
            ev.lngLat.lat,
          ];
          const point: GeoJSON.Feature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [ev.lngLat.lng, ev.lngLat.lat],
            },
            properties: {},
          };
          geojson.current.features[2] = point;
          map
            .getSource(InteractionLayersSources.MeasurementTool)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(geojson.current);
          const innerHtml = `Distance: ${distance.current || 0} nm`;
          popup
            .setLngLat([ev.lngLat.lng, ev.lngLat.lat])
            .setHTML(innerHtml)
            .addTo(map);
          distance.current = getNauticalMilesDistance(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            geojson.current.features[0].geometry.coordinates,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            geojson.current.features[2].geometry.coordinates,
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    [map, popup],
  );

  const onMouseUp = useCallback(() => {
    try {
      isDragging.current = false;
      distance.current = getNauticalMilesDistance(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        geojson.current.features[0].geometry.coordinates,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        geojson.current.features[2].geometry.coordinates,
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleMessurementToggle = useCallback(() => {
    toggleIsMeasurementOn((state) => {
      if (state) {
        map.getCanvas().style.cursor = '';
        map.dragPan.enable();
        map.off('mousedown', onMouseDown);
        map.off('mousemove', onMouseMove);
        map.off('mouseup', onMouseUp);
        map
          .getSource(InteractionLayersSources.MeasurementTool)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(makeEmptyGeojson());
        distance.current = null;
      } else {
        map.getCanvas().style.cursor = 'crosshair';
        map.dragPan.disable();
        map.on('mousedown', onMouseDown);
        map.on('mousemove', onMouseMove);
        map.on('mouseup', onMouseUp);
      }
      return !state;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const onMouseEnter = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (distance.current && !isDragging.current) {
        map.getCanvas().style.cursor = 'pointer';
        const innerHtml = `Distance: ${distance.current} nm`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseLeave = useCallback(() => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  }, [popup, map]);

  useEffect(() => {
    map.on('mouseenter', InteractionLayers.MeasurementToolPoints, onMouseEnter);
    map.on('mouseleave', InteractionLayers.MeasurementToolPoints, onMouseLeave);
    return (): void => {
      map.off(
        'mouseenter',
        InteractionLayers.MeasurementToolPoints,
        onMouseEnter,
      );
      map.off(
        'mouseleave',
        InteractionLayers.MeasurementToolPoints,
        onMouseLeave,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isMeasurementOn,
    handleMessurementToggle,
  };
};

export default useMeasurementTool;
