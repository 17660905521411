import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DownloadIcon } from 'modules/ui/assets';

import { MapSoloButton, Modal } from 'modules/ui/components';
import { isUiVisibleSelector } from 'modules/metadata';
import Downloads from '../DownloadsModal';
import DownloadFinishedListener from '../DownloadFinishedListener';
import {
  isDownloadModalOpenSelector,
  activeDownloadsCountSelector,
} from '../../redux/selectors';
import { setIsDownloadModalOpen } from '../../redux/actions';

const DownloadButtonIndicator: React.FC = () => {
  const dispatch = useDispatch();
  const isUiVisible = useSelector(isUiVisibleSelector);
  const activeDownloadsCount = useSelector(activeDownloadsCountSelector);
  const isModalOpen = useSelector(isDownloadModalOpenSelector);
  const handleCloseModal = useCallback(() => {
    dispatch(setIsDownloadModalOpen(false));
  }, [dispatch]);
  const handleOpenModal = useCallback(() => {
    dispatch(setIsDownloadModalOpen(true));
  }, [dispatch]);

  if (!isUiVisible) return null;

  return (
    <>
      <DownloadFinishedListener />
      <MapSoloButton
        handleClick={handleOpenModal}
        indicatorText={activeDownloadsCount}
        tooltipText="Downloads"
      >
        <DownloadIcon />
      </MapSoloButton>
      <Modal
        isOpen={isModalOpen}
        width={600}
        title="Downloads"
        closeModal={handleCloseModal}
      >
        <Downloads closeModal={handleCloseModal} />
      </Modal>
    </>
  );
};

export default React.memo(DownloadButtonIndicator);
