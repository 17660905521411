import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { addDays, differenceInMinutes } from 'date-fns';

import { TooltipInfoIcon } from 'modules/ui/components';

import {
  formatComparisonOption,
  formatDifferenceInHoursAndMinutes,
} from '../../utils/time';

const MeteoSourcesInfo: React.FC = () => {
  const now = useMemo(() => {
    const date = new Date();
    date.setFullYear(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
    );
    date.setHours(
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
    return date;
  }, []);
  const { ecmwfGribTime, ecmwfDifference } = useMemo(() => {
    const hours = now.getHours();
    const minutes = now.getMinutes();
    if (hours < 7 || (hours === 7 && minutes < 30)) {
      const comparisonDate = new Date(now);
      comparisonDate.setHours(7, 30);
      const difference = differenceInMinutes(comparisonDate, now);
      const date = addDays(now, -1);
      date.setHours(0);
      return { ecmwfGribTime: date, ecmwfDifference: difference };
    }
    if (hours < 19 || (hours === 19 && minutes < 30)) {
      const comparisonDate = new Date(now);
      comparisonDate.setHours(19, 30);
      const difference = differenceInMinutes(comparisonDate, now);
      const date = new Date(now);
      date.setHours(0);
      return { ecmwfGribTime: date, ecmwfDifference: difference };
    }
    const comparisonDate = new Date(now);
    comparisonDate.setHours(7, 30);
    const nextDate = addDays(comparisonDate, 1);
    const difference = differenceInMinutes(nextDate, now);
    const date = new Date(now);
    date.setHours(12);
    return { ecmwfGribTime: date, ecmwfDifference: difference };
  }, [now]);
  const { ncepGribTime, ncepDifference } = useMemo(() => {
    const hours = now.getHours();
    const minutes = now.getMinutes();
    if (hours < 4 || (hours === 4 && minutes < 50)) {
      const comparisonDate = new Date(now);
      comparisonDate.setHours(4, 50);
      const difference = differenceInMinutes(comparisonDate, now);
      const date = addDays(now, -1);
      date.setHours(0);
      return { ncepGribTime: date, ncepDifference: difference };
    }
    if (hours < 16 || (hours === 16 && minutes < 50)) {
      const comparisonDate = new Date(now);
      comparisonDate.setHours(16, 50);
      const difference = differenceInMinutes(comparisonDate, now);
      const date = new Date(now);
      date.setHours(0);
      return { ncepGribTime: date, ncepDifference: difference };
    }
    const comparisonDate = new Date(now);
    comparisonDate.setHours(4, 50);
    const nextDate = addDays(comparisonDate, 1);
    const difference = differenceInMinutes(nextDate, now);
    const date = new Date(now);
    date.setHours(12);
    return { ncepGribTime: date, ncepDifference: difference };
  }, [now]);
  const { nemoGribTime, nemoDifference } = useMemo(() => {
    const hours = now.getHours();
    const minutes = now.getMinutes();
    if (hours < 4 || (hours === 4 && minutes < 35)) {
      const comparisonDate = new Date(now);
      comparisonDate.setHours(4, 35);
      const difference = differenceInMinutes(comparisonDate, now);
      const date = addDays(now, -1);
      date.setHours(0);
      return { nemoGribTime: date, nemoDifference: difference };
    }
    const comparisonDate = new Date(now);
    comparisonDate.setHours(4, 50);
    const nextDate = addDays(comparisonDate, 1);
    const difference = differenceInMinutes(nextDate, now);
    const date = new Date(now);
    date.setHours(0);
    return { nemoGribTime: date, nemoDifference: difference };
  }, [now]);
  return (
    <span>
      <ReactTooltip effect="solid" multiline place="left" />
      <b>Available data:</b>
      {` ECWMF ${formatComparisonOption(ecmwfGribTime)} UTC`}
      <TooltipInfoIcon
        inline
        text={`ECMWF 00h UTC run available from 7:30 UTC.<br>ECMWF 12h UTC run available from 19:30 UTC.<br>Next run available in ${formatDifferenceInHoursAndMinutes(
          ecmwfDifference,
        )}.`}
      />
      {`GFS ${formatComparisonOption(ncepGribTime)} UTC`}
      <TooltipInfoIcon
        inline
        text={`GFS 00h UTC run available from 4:50 UTC<br>GFS 12h UTC run available from 16:50 UTC.<br>Next run available in ${formatDifferenceInHoursAndMinutes(
          ncepDifference,
        )}.`}
      />
      {`NEMO ${formatComparisonOption(nemoGribTime)} UTC`}
      <TooltipInfoIcon
        inline
        text={`NEMO 00h UTC run available from 4:35 UTC.<br>Next run available in ${formatDifferenceInHoursAndMinutes(
          nemoDifference,
        )}.`}
      />
    </span>
  );
};

export default React.memo(MeteoSourcesInfo);
