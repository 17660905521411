import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

const MapButton = styled.button<{ isActive?: boolean }>`
  width: 28px;
  height: 28px;
  background-color: ${({ isActive }): string =>
    isActive ? Colors.Primary : 'transparent'};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: ${({ isActive }): string =>
    isActive ? '0px 4px 12px rgba(1, 7, 36, 0.07)' : 'none'};
  border-radius: 4px;
  :active {
    background-color: ${Colors.PrimaryDark};
  }
  :hover {
    cursor: pointer;
  }
`;

export default MapButton;
