import { createAction } from '@reduxjs/toolkit';
import { MeteoDataTypes } from '../consts';

import {
  DownloadQueueItem,
  StoredLocalFile,
  WindMeteoDataSet,
  WaveMeteoDataSet,
  OceanMeteoDataSet,
} from '../types';

export const setDataSetTimeOffset = createAction<number>(
  'SET_DATA_SET_TIME_OFFSET',
);

export const incrementDataSetTimeOffset = createAction(
  'INCREMENT_DATA_SET_TIME_OFFSET',
);

// Meteo download queue
export const addToMeteoQueue = createAction<DownloadQueueItem[]>(
  'ADD_TO_METEO_QUEUE',
);

export const removeFromMeteoQueue = createAction<string>(
  'REMOVE_FROM_METEO_QUEUE',
);

export const downloadedMeteoComponent = createAction<string>(
  'DOWNLOADED_METEO_COMPONENT',
);

// ECMWF
export const createEcmwfMeteoModelDataSet = createAction<WindMeteoDataSet>(
  'CREATE_ECMWF_METEO_MODEL_DATA_SET',
);

export const addToEcmwfMeteoModelDataSet = createAction<
  StoredLocalFile & { type: MeteoDataTypes; id: string }
>('ADD_ECMWF_METEO_MODEL_DATA_SET');

export const removeEcmwfMeteoModelDataSet = createAction<string>(
  'REMOVE_ECMWF_METEO_MODEL_DATA_SET',
);

export const setEcmwfTimeSet = createAction<string>('SET_ECMWF_TIME_SET');

// GFS
export const createGfsMeteoModelDataSet = createAction<WindMeteoDataSet>(
  'CREATE_GFS_METEO_MODEL_DATA_SET',
);

export const addToGfsMeteoModelDataSet = createAction<
  StoredLocalFile & { type: MeteoDataTypes; id: string }
>('ADD_GFS_METEO_MODEL_DATA_SET');

export const removeGfsMeteoModelDataSet = createAction<string>(
  'REMOVE_GFS_METEO_MODEL_DATA_SET',
);

export const setGfsTimeSet = createAction<string>('SET_GFS_TIME_SET');

// ECMWF WAM
export const createWamMeteoModelDataSet = createAction<WaveMeteoDataSet>(
  'CREATE_WAM_METEO_MODEL_DATA_SET',
);

export const addToWamMeteoModelDataSet = createAction<
  StoredLocalFile & { type: MeteoDataTypes; id: string }
>('ADD_WAM_METEO_MODEL_DATA_SET');

export const removeWamMeteoModelDataSet = createAction<string>(
  'REMOVE_WAM_METEO_MODEL_DATA_SET',
);

export const setWamTimeSet = createAction<string>('SET_WAM_TIME_SET');

// GFS WAM
export const createGfsWamMeteoModelDataSet = createAction<WaveMeteoDataSet>(
  'CREATE_GFS_WAM_METEO_MODEL_DATA_SET',
);

export const addToGfsWamMeteoModelDataSet = createAction<
  StoredLocalFile & { type: MeteoDataTypes; id: string }
>('ADD_GFS_WAM_METEO_MODEL_DATA_SET');

export const removeGfsWamMeteoModelDataSet = createAction<string>(
  'REMOVE_GFS_WAM_METEO_MODEL_DATA_SET',
);

export const setGfsWamTimeSet = createAction<string>('SET_GFS_WAM_TIME_SET');

// NEMO
export const createNemoMeteoModelDataSet = createAction<OceanMeteoDataSet>(
  'CREATE_NEMO_METEO_MODEL_DATA_SET',
);

export const addToNemoMeteoModelDataSet = createAction<
  StoredLocalFile & { id: string }
>('ADD_NEMO_METEO_MODEL_DATA_SET');

export const removeNemoMeteoModelDataSet = createAction<string>(
  'REMOVE_NEMO_METEO_MODEL_DATA_SET',
);

export const setNemoTimeSet = createAction<string>('SET_NEMO_TIME_SET');
