import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

import db from 'modules/localForage/db';
import { emptyGeojson } from 'modules/mapLayers/consts';
import { setFleetBoatGeojson, setFleetLineGeojson } from './actions';

const fleetBoatData = createReducer(emptyGeojson, (builder) => {
  builder.addCase(setFleetBoatGeojson, (state, action) => action.payload);
});

const fleetLineData = createReducer(emptyGeojson, (builder) => {
  builder.addCase(setFleetLineGeojson, (state, action) => action.payload);
});

const lastUpdateTimestamp = createReducer(0, (builder) => {
  builder.addCase(setFleetLineGeojson, () => new Date().getTime());
});

const reducer = combineReducers({
  fleetBoatData,
  fleetLineData,
  lastUpdateTimestamp,
});

const fleetReducerConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'fleet',
  storage: db.app,
};

export default persistReducer(fleetReducerConfig, reducer);
