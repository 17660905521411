import React from 'react';

import { HighlightedSm, H2 } from 'modules/ui/typography';
import {
  MultiRow,
  CheckboxContainer,
  RightContent,
  SpatialLabelContainer,
  TimeStepResolutionLabelContainer,
} from './styledComponents';

type Props = {
  title: string;
};

const ModelHeader: React.FC<Props> = ({ title }) => (
  <MultiRow style={{ paddingBottom: 0 }}>
    <CheckboxContainer>
      <H2>{title}</H2>
    </CheckboxContainer>
    <RightContent>
      <SpatialLabelContainer>
        <HighlightedSm>Time range</HighlightedSm>
      </SpatialLabelContainer>
      <SpatialLabelContainer>
        <HighlightedSm>Spatial resolution</HighlightedSm>
      </SpatialLabelContainer>
      <TimeStepResolutionLabelContainer>
        <HighlightedSm>Time step resolution</HighlightedSm>
      </TimeStepResolutionLabelContainer>
    </RightContent>
  </MultiRow>
);

export default React.memo(ModelHeader);
