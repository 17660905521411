import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { H1 } from 'modules/ui/typography';

export const Table = styled.table`
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  th {
    position: sticky;
    top: 0;
    background-color: white;
    border-top: none;
  }
`;

export const TableRow = styled.tr<{ isMidnight: boolean; isNow: boolean }>`
  font-weight: ${({ isNow }): string | undefined =>
    isNow ? 'bold' : undefined};
  border-top: ${({ isMidnight }): string | undefined =>
    isMidnight ? '3px solid black' : undefined};
`;

export const DegreesCell = styled.td<{ value: number }>`
  background: ${`linear-gradient(90deg, ${Colors.Primary}99 0%, ${Colors.Primary}4D 100%)`};
  background-size: ${({ value }): string =>
    `${((typeof value === 'number' ? value : 0) / 360) * 100}%`};
  background-repeat: no-repeat;
`;

export const TwsCell = styled.td<{ color: string }>`
  background: ${({ color }): string =>
    `linear-gradient(90deg, ${color} 0%, ${color}99 100%)`};
`;

export const TwaCell = styled.td<{ value: number }>`
  color: ${({ value }): string =>
    value < 0 ? Colors.Destructive : Colors.Secondary};
`;

export const InfoTitle = styled(H1)`
  padding: 0 16px;
`;
