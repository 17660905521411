import React, { memo, useCallback } from 'react';

import { Button } from 'modules/ui/components';
import { RegularBase, RegularXs } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';

import { Container } from './styledComponents';

type Props = {
  id: number;
  name: string;
  onEditClick: (value: number) => void;
  hidePopup: () => void;
};

const EditControls = ({
  id,
  onEditClick,
  hidePopup,
  name,
}: Props): JSX.Element => {
  const onEdit = useCallback(() => {
    hidePopup();
    onEditClick(id);
  }, [onEditClick, id, hidePopup]);
  return (
    <Container>
      <RegularXs style={{ color: Colors.PrimaryLight }}>
        INCLUSION ZONE
      </RegularXs>
      <RegularBase>{name}</RegularBase>
      <Button label="Edit" clickHandler={onEdit} />
    </Container>
  );
};

export default memo(EditControls);
