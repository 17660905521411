import { point, multiLineString } from '@turf/helpers';
import { makeEmptyGeojson } from 'modules/geojson';

import { Route } from './types';

export const createRouteGeojsonFromRouteData = (
  data: Route,
): GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> => {
  const pointsFeature = data.route.map(({ coordinates }, index) =>
    point(coordinates, { index }),
  );
  const linesFeature = multiLineString([
    data.route.map(({ coordinates }) => coordinates),
  ]);
  const geojson = makeEmptyGeojson();
  geojson.features = [...pointsFeature, linesFeature];
  return geojson;
};
