import React from 'react';
import { Field } from 'react-final-form';

import { TextInput, FormCheckbox, SelectInput } from 'modules/ui/components';
import { parseFloat } from 'modules/main/formaters';
import { parseTimeOptions } from 'modules/form';
import ModelHeader from './ModelHeader';
import { SpatialResolutionOptions, TimeResolutionOptions } from '../../consts';

import { MultiRow, CheckboxContainer, RightContent } from './styledComponents';

type Props = {
  title: string;
  formObjectKey: string;
  isGfs?: boolean;
};

const timeEcmwfOptionParser = parseTimeOptions(10);
const timeGfsOptionParser = parseTimeOptions(7);

const WindWeatherForm: React.FC<Props> = ({ title, formObjectKey, isGfs }) => (
  <>
    <ModelHeader title={title} />
    <MultiRow showTopBorder>
      <CheckboxContainer>
        <Field
          name={`${formObjectKey}.swellWaves.checked`}
          component={FormCheckbox}
          type="checkbox"
          label="Swell waves (Height, Direction, Period)"
        />
      </CheckboxContainer>
      <RightContent>
        <Field
          name={`${formObjectKey}.swellWaves.time`}
          component={TextInput}
          rightInlineLabel="days"
          width={104}
          formatOnBlur
          format={isGfs ? timeGfsOptionParser : timeEcmwfOptionParser}
        />
        <Field
          name={`${formObjectKey}.swellWaves.spatial`}
          component={SelectInput}
          width={104}
          options={SpatialResolutionOptions}
          parse={parseFloat}
          type="number"
        />
        <Field
          name={`${formObjectKey}.swellWaves.resolution`}
          component={SelectInput}
          width={208}
          options={TimeResolutionOptions}
          parse={parseFloat}
        />
      </RightContent>
    </MultiRow>
    <MultiRow showTopBorder>
      <CheckboxContainer>
        <Field
          name={`${formObjectKey}.windWaves.checked`}
          component={FormCheckbox}
          type="checkbox"
          label="Wind waves (Height, Direction, Period)"
        />
      </CheckboxContainer>
      <RightContent>
        <Field
          name={`${formObjectKey}.windWaves.time`}
          component={TextInput}
          rightInlineLabel="days"
          width={104}
          formatOnBlur
          format={isGfs ? timeGfsOptionParser : timeEcmwfOptionParser}
        />
        <Field
          name={`${formObjectKey}.windWaves.spatial`}
          component={SelectInput}
          width={104}
          options={SpatialResolutionOptions}
          parse={parseFloat}
          type="number"
        />
        <Field
          name={`${formObjectKey}.windWaves.resolution`}
          component={SelectInput}
          width={208}
          options={TimeResolutionOptions}
          parse={parseFloat}
        />
      </RightContent>
    </MultiRow>
    <MultiRow showTopBorder>
      <CheckboxContainer>
        <Field
          name={`${formObjectKey}.significantWaves.checked`}
          component={FormCheckbox}
          type="checkbox"
          label={`Waves (Height, Direction${isGfs ? '' : ', Whitecapping'})`}
        />
      </CheckboxContainer>
      <RightContent>
        <Field
          name={`${formObjectKey}.significantWaves.time`}
          component={TextInput}
          rightInlineLabel="days"
          width={104}
          formatOnBlur
          format={isGfs ? timeGfsOptionParser : timeEcmwfOptionParser}
        />
        <Field
          name={`${formObjectKey}.significantWaves.spatial`}
          component={SelectInput}
          width={104}
          options={SpatialResolutionOptions}
          parse={parseFloat}
          type="number"
        />
        <Field
          name={`${formObjectKey}.significantWaves.resolution`}
          component={SelectInput}
          width={208}
          options={TimeResolutionOptions}
          parse={parseFloat}
        />
      </RightContent>
    </MultiRow>
  </>
);

export default React.memo(WindWeatherForm);
