import styled from 'styled-components';

import { RegularSm } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';

export const ChipsContainer = styled.div<{
  isChecked: boolean;
  disabled: boolean;
}>`
  height: 28px;
  border: ${({ isChecked }): string => (isChecked ? '1px' : '1.5px')} solid;
  display: inline-block;
  border-radius: 100px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: ${({ isChecked }): string =>
    isChecked ? 'rgba(239, 244, 255, 1)' : 'white'};
  border-color: ${({ isChecked }): string =>
    isChecked ? Colors.Primary : Colors.DarkGray};
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;
  padding-top: 2px;
  & > svg {
    padding-left: 12px;
    padding-right: 4px;
  }
`;
export const Label = styled(RegularSm)<{
  isChecked: boolean;
  disabled: boolean;
}>`
  user-select: none;
  color: ${({ isChecked, disabled }): string | undefined => {
    if (disabled) return `${Colors.SolidGray}80`;
    return isChecked ? Colors.Primary : undefined;
  }};
  padding: ${({ isChecked }): string =>
    isChecked ? '5px 12px 5px 0px' : '5px 12px'};
`;
