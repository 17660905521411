import { RootState } from 'modules/main/redux';

export const fleetBoatDataGeojsonSelector = (
  state: RootState,
): GeoJSON.FeatureCollection => state.fleet.fleetBoatData;

export const fleetLineDataGeojsonSelector = (
  state: RootState,
): GeoJSON.FeatureCollection => state.fleet.fleetLineData;

export const fleetLastUpdateTimestampSelector = (state: RootState): number =>
  state.fleet.lastUpdateTimestamp;
