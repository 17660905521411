import styled from 'styled-components';

export const Container = styled.div`
  border: 1.5px dashed #e3eaed;
  border-radius: 8px;
  height: 88px;
  display: flex;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
  margin: 8px 0 16px 0;
  > svg {
    margin-right: 24px;
  }
`;

export const UploadedFileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FileMetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 32px;
`;
