import React from 'react';

import { InnerCollapsibleContainer } from 'modules/ui/components';

type Props = {
  children?: React.ReactNode;
  title: string;
  isOpened: boolean;
  handleToggleTab: () => void;
  titleComponent?: React.ReactChild;
};

const CollapsibleContainer: React.FC<Props> = ({
  children,
  title,
  isOpened,
  handleToggleTab,
  titleComponent,
}) => {
  return (
    <InnerCollapsibleContainer
      isOpened={isOpened}
      toggleIsOpened={handleToggleTab}
      title={title}
      titleComponent={titleComponent}
    >
      {children}
    </InnerCollapsibleContainer>
  );
};

export default React.memo(CollapsibleContainer);
