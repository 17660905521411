import React, { memo } from 'react';
import { Field, Form } from 'react-final-form';

import { Button, Modal, ModalFooter, TextInput } from 'modules/ui/components';
import { H2 } from 'modules/ui/typography';
import { validateRequiredString } from 'modules/form';
import { useCreateOrUpdateInclusionZone } from '../../hooks';
import { InclusionZoneForm } from '../../types';

import { ContentRow, FormContainer } from './styledComponents';

type Props = {
  isOpen?: boolean;
  closeModal: () => void;
  initialZone: InclusionZoneForm;
  onSuccess: () => void;
};

const CreateInclusionZoneContent: React.FC<Props> = ({
  closeModal,
  initialZone,
  onSuccess,
}) => {
  const { handleUpdateOrCreate, loading } = useCreateOrUpdateInclusionZone(
    onSuccess,
  );
  return (
    <Form
      onSubmit={handleUpdateOrCreate}
      initialValues={initialZone}
      render={({ handleSubmit, invalid }): React.ReactNode => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <H2>Please enter the name for your inclusion zone:</H2>
            <ContentRow>
              <Field
                name="name"
                type="text"
                component={TextInput}
                validate={validateRequiredString}
                width={500}
                autoFocus
              />
            </ContentRow>
          </FormContainer>
          <ModalFooter>
            <Button isSecondary label="Cancel" clickHandler={closeModal} />
            <Button
              isLoading={loading}
              disabled={invalid || loading}
              label="Save"
              clickHandler={handleSubmit}
            />
          </ModalFooter>
        </form>
      )}
    />
  );
};

const CreateInclusionZoneModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  ...rest
}) => (
  <Modal
    isOpen={!!isOpen}
    width={712}
    title={
      rest.initialZone.id ? 'Editing inclusion zone' : 'New inclusion zone'
    }
    closeModal={closeModal}
    onRequestClose={closeModal}
  >
    <CreateInclusionZoneContent {...rest} closeModal={closeModal} />
  </Modal>
);

export default memo(CreateInclusionZoneModal);
