import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useToggle } from 'modules/main';
import { mergeGeoJSONs } from 'modules/geojson/utils';
import { ModelLayers, ModelLayersSources } from 'modules/mapLayers';
import { nemoDataSetsSourceKeysSelector } from '../redux/selectors';
import { SourcesForFiltering } from '../types';
import { getOceanDataFromJpeg } from '../utils/ocean';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useNemoSourceAndLayers = (
  map: Map,
  showMeteoData: boolean,
  addToScaleArray: (layer: ModelLayers) => void,
  removeFromScaleArray: (layer: ModelLayers) => void,
  turnOnShowMeteoData: () => void,
) => {
  const sources = useSelector(nemoDataSetsSourceKeysSelector);

  const [isCurrentSpeedVisible, setIsCurrentSpeedVisible] = useToggle();
  const [isCurrentDirectionVisible, setIsCurrentDirectionVisible] = useToggle();
  const [
    isCurrentTemperatureVisible,
    setIsCurrentTemperatureVisible,
  ] = useToggle();

  const handleOceanSources = useCallback(
    async (oceanSources: SourcesForFiltering[]) => {
      const jsons = await Promise.all(
        oceanSources.map(({ fileName, constraints }) =>
          getOceanDataFromJpeg(fileName, { constraints }),
        ),
      );
      const polygonGeojson1 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson1).filter((_) => _),
      );
      const polygonGeojson2 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson2).filter((_) => _),
      );
      const pointGeoJson1 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson1).filter((_) => _),
      );
      const pointGeoJson2 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson2).filter((_) => _),
      );
      map
        .getSource(ModelLayersSources.NemoOceanPoint1)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson1);
      map
        .getSource(ModelLayersSources.NemoOceanPoint2)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson2);
      map
        .getSource(ModelLayersSources.NemoOceanPolygon1)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson1);
      map
        .getSource(ModelLayersSources.NemoOceanPolygon2)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const changeLayerVisibility = useCallback(
    (hide: boolean, layerName: ModelLayers) => {
      if (hide) {
        removeFromScaleArray(layerName);
        map.setLayoutProperty(layerName, 'visibility', 'none');
      } else {
        addToScaleArray(layerName);
        map.setLayoutProperty(layerName, 'visibility', 'visible');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const sourceNeeded = useMemo(
    () =>
      showMeteoData &&
      (isCurrentDirectionVisible ||
        isCurrentTemperatureVisible ||
        isCurrentSpeedVisible),
    [
      showMeteoData,
      isCurrentDirectionVisible,
      isCurrentTemperatureVisible,
      isCurrentSpeedVisible,
    ],
  );
  useDeepCompareEffect(() => {
    if (sourceNeeded) {
      handleOceanSources(sources.currentSources);
    }
  }, [sources.currentSources, sourceNeeded, handleOceanSources]);
  useEffect(() => {
    changeLayerVisibility(
      !isCurrentSpeedVisible || !showMeteoData,
      ModelLayers.NemoOceanSpeed1,
    );
    changeLayerVisibility(
      !isCurrentSpeedVisible || !showMeteoData,
      ModelLayers.NemoOceanSpeed2,
    );
  }, [isCurrentSpeedVisible, showMeteoData, changeLayerVisibility]);
  useEffect(() => {
    if (isCurrentSpeedVisible) {
      turnOnShowMeteoData();
    }
  }, [isCurrentSpeedVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isCurrentDirectionVisible || !showMeteoData,
      ModelLayers.NemoOceanDirection1,
    );
    changeLayerVisibility(
      !isCurrentDirectionVisible || !showMeteoData,
      ModelLayers.NemoOceanDirection2,
    );
  }, [isCurrentDirectionVisible, showMeteoData, changeLayerVisibility]);
  useEffect(() => {
    if (isCurrentDirectionVisible) {
      turnOnShowMeteoData();
    }
  }, [isCurrentDirectionVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isCurrentTemperatureVisible || !showMeteoData,
      ModelLayers.NemoOceanTemperature1,
    );
    changeLayerVisibility(
      !isCurrentTemperatureVisible || !showMeteoData,
      ModelLayers.NemoOceanTemperature2,
    );
  }, [isCurrentTemperatureVisible, showMeteoData, changeLayerVisibility]);
  useEffect(() => {
    if (isCurrentTemperatureVisible) {
      turnOnShowMeteoData();
    }
  }, [isCurrentTemperatureVisible, turnOnShowMeteoData]);

  const componentsCount = useMemo(() => {
    if (!showMeteoData) return 0;
    return [
      isCurrentSpeedVisible,
      isCurrentDirectionVisible,
      isCurrentTemperatureVisible,
    ].filter((_) => _).length;
  }, [
    showMeteoData,
    isCurrentSpeedVisible,
    isCurrentDirectionVisible,
    isCurrentTemperatureVisible,
  ]);

  return {
    componentsCount,
    isCurrentSpeedVisible,
    setIsCurrentSpeedVisible,
    isCurrentDirectionVisible,
    setIsCurrentDirectionVisible,
    isCurrentTemperatureVisible,
    setIsCurrentTemperatureVisible,
  };
};

export default useNemoSourceAndLayers;
