import React, { useState } from 'react';
import { Map } from 'mapbox-gl';

import { SideMenu } from 'modules/ui/components';
import { HomeSideMenu } from 'modules/home';
import { MeteoSideMenu } from 'modules/meteo';
import { RoutingSideMenu } from 'modules/routing';
import { RouteSideMenu } from 'modules/route';
import { MapMenus } from '../../consts';

type Props = {
  onModeChange: (value: boolean) => void;
  map: Map;
};

const Menu: React.FC<Props> = ({ onModeChange, map }) => {
  const [openedMenu, setOpenedMenu] = useState<string | null>(null);
  return (
    <SideMenu>
      <HomeSideMenu
        id={MapMenus.General}
        isOpened={openedMenu === MapMenus.General}
        onModeChange={onModeChange}
        onVisibilityChange={setOpenedMenu}
        map={map}
      />
      <RouteSideMenu
        id={MapMenus.Routes}
        onVisibilityChange={setOpenedMenu}
        isOpened={openedMenu === MapMenus.Routes}
        map={map}
      />
      <MeteoSideMenu
        id={MapMenus.Meteo}
        map={map}
        isOpened={openedMenu === MapMenus.Meteo}
        onVisibilityChange={setOpenedMenu}
      />
      <RoutingSideMenu
        id={MapMenus.Routing}
        map={map}
        isOpened={openedMenu === MapMenus.Routing}
        onVisibilityChange={setOpenedMenu}
      />
    </SideMenu>
  );
};

export default React.memo(Menu);
