/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: 200px;
`;

export const ProgressBarContainer = styled.div`
  width: 480px;
  margin: 64px 0 32px 0;
`;

export const MainContainer = styled.div<{ sticky: boolean }>`
  h2 {
    margin-left: 20px;

    ${(props): any =>
      !!props.sticky &&
      css`
        position: sticky;
        top: 0;
        z-index: 9999;
        background: white;
        padding: 10px 0px;
      `}
  }

  .react-tabs {
    overflow-y: inherit;
  }
  .react-tabs__tab-panel {
    padding: 0px;
  }

  .react-tabs__tab-list {
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 9999;
    background: white;
    padding-top: 20px;
  }

  .disabled-tab {
    color: #e8eaf1;
  }
`;
