import React, { memo, useCallback, useState } from 'react';
import { Map } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import { feature } from '@turf/helpers';

import {
  useDrawCustomPolygon,
  useChangePolygonCreationColors,
} from 'modules/mapInteracting';
import { MapInstructions, MapSelectingControls } from 'modules/ui/components';
import { Colors } from 'modules/ui/theme/colors';
import { useModal } from 'modules/main';
import { RootState } from 'modules/main/redux';
import { InclusionZonesSelectors } from '../../redux';
import DeleteInclusionZoneModal from '../DeleteInclusionZoneModal';
import CreateInclusionZoneModal from '../CreateInclusionZoneModal';

type Props = {
  map: Map;
  onCancel: () => void;
  editedInclusionZoneId: number | null;
};

const CreateInclusionZoneFlow: React.FunctionComponent<Props> = ({
  map,
  onCancel,
  editedInclusionZoneId,
}) => {
  useChangePolygonCreationColors(map, Colors.PrimaryLight);
  const [showDeleteModal, , openDeleteModal, closeDeleteModal] = useModal(
    false,
  );
  const [
    showFinalStepModal,
    ,
    openFinalStepModal,
    closeFinalStepModal,
  ] = useModal(false);
  const [finalZone, setFinalZone] = useState<GeoJSON.Feature<
    GeoJSON.Polygon,
    GeoJSON.GeoJsonProperties
  > | null>(null);
  const onDeleteZone = useCallback(() => {
    onCancel();
    closeDeleteModal();
  }, [onCancel, closeDeleteModal]);
  const onCreateOrUpdate = useCallback(() => {
    closeFinalStepModal();
    setFinalZone(null);
    onCancel();
  }, [onCancel, closeFinalStepModal]);
  const editedZone = useSelector(
    (state: RootState) =>
      InclusionZonesSelectors.inclusionZoneById(state, editedInclusionZoneId),
    () => true,
  );
  const { handleUndo, getPolygon, isValid, canUndo } = useDrawCustomPolygon(
    map,
    editedZone?.inclusionZone.geometry.coordinates[0],
  );
  const onConfirm = useCallback(async () => {
    const polygon = getPolygon();
    const polygonFeature = feature(polygon);
    setFinalZone(polygonFeature);
    openFinalStepModal();
  }, [getPolygon, openFinalStepModal]);

  return (
    <>
      {editedZone && (
        <DeleteInclusionZoneModal
          isOpen={!!showDeleteModal}
          closeModal={closeDeleteModal}
          inclusionZone={editedZone}
          onDelete={onDeleteZone}
        />
      )}
      <CreateInclusionZoneModal
        isOpen={showFinalStepModal}
        closeModal={closeFinalStepModal}
        initialZone={{
          name: editedZone?.name,
          inclusionZone: finalZone || undefined,
          id: editedZone?.id,
        }}
        onSuccess={onCreateOrUpdate}
      />
      <MapSelectingControls
        onCancel={onCancel}
        onConfirm={onConfirm}
        undoDisabled={!canUndo}
        confirmDisabled={!isValid}
        onUndo={handleUndo}
        onDelete={editedInclusionZoneId ? openDeleteModal : undefined}
      />
      <MapInstructions
        title={
          editedInclusionZoneId
            ? 'Edit an inclusion zone'
            : 'New inclusion zone'
        }
        text={`${
          editedInclusionZoneId ? '' : 'Click to add points. '
        }Move points to adjust them.\n`}
      />
    </>
  );
};

export default memo(CreateInclusionZoneFlow);
