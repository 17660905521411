import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EventData,
  Map,
  MapboxGeoJSONFeature,
  MapMouseEvent,
  Popup,
} from 'mapbox-gl';
import debounce from 'lodash.debounce';

import { Button, CollapsibleContainer } from 'modules/ui/components';
import { useModal } from 'modules/main';
import { ModelLayers } from 'modules/mapLayers/consts';
import {
  isMeteoDataOnSelector,
  isUiVisibleSelector,
  turnOnShowMeteoDataFlag,
} from 'modules/metadata';
import { MeteoSources } from 'modules/meteo/consts';
import { MeteoScale } from 'modules/scales';
import DownloadNewMeteoDataModal from '../DownloadNewMeteoDataModal';
// import Header from './Header';
import MeteoDownloader from '../MeteoDownloader';
import MeteoDataCleaner from '../MeteoDataCleaner';
import EcmwfMeteoData from '../EcmwfMeteoData';
import GfsMeteoData from '../GfsMeteoData';
import WamMeteoData from '../WamMeteoData';
import GfsWamMeteoData from '../GfsWamMeteoData';
import NemoMeteoData from '../NemoMeteoData';
import WindComparisonData from '../WindComparisonData';

import { ButtonContainer, MeteoComponentsContainer } from './styledComponents';

type Props = {
  onVisibilityChange: (value: string | null) => void;
  id: string;
  isOpened: boolean;
  map: Map;
};

const MeteoSideMenu: React.FC<Props> = ({
  isOpened,
  onVisibilityChange,
  id,
  map,
}) => {
  const [
    isDownloadModalOpen,
    ,
    openDownloadModal,
    closeDownloadModal,
  ] = useModal(false);
  const dispatch = useDispatch();
  const [openedTab, setOpenedTab] = useState<MeteoSources | string | null>(
    null,
  );
  const isMeteoDataOn = useSelector(isMeteoDataOnSelector);
  const turnOnShowMeteoData = useCallback(() => {
    dispatch(turnOnShowMeteoDataFlag());
  }, [dispatch]);
  const [scaleArray, setScaleArray] = useState<ModelLayers[]>([]);
  const addToScaleArray = useCallback((layer: ModelLayers) => {
    setScaleArray((state) => [...state, layer]);
  }, []);
  const removeFromScaleArray = useCallback((layer: ModelLayers) => {
    setScaleArray((state) => state.filter((l) => l !== layer));
  }, []);
  const isUiVisible = useSelector(isUiVisibleSelector);

  const toggleTab = useCallback((tab: MeteoSources | string) => {
    setOpenedTab((state) => (state === tab ? null : tab));
  }, []);

  const popup = useMemo(
    () =>
      new Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    [],
  );

  const onMouseHover = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.lngLat) {
        popup.setLngLat([e.lngLat.lng, e.lngLat.lat]);
      }
    },
    [popup],
  );

  const anyLayerOnMap = useMemo(() => !!scaleArray.length, [scaleArray]);

  useEffect(() => {
    if (anyLayerOnMap) {
      map.on('mousemove', onMouseHover);
    } else {
      map.off('mousemove', onMouseHover);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anyLayerOnMap]);

  const onMouseEnter = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { level } = e.features[0].properties as { [key: string]: any };
        const innerHtml = `Pressure: ${level} hPa`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  // Refactor this when you have time
  const onMouseEnterWind = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { ws, g, wd } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Speed: ${ws} kts<br/>Gusts: ${g} kts<br/>Direction: ${wd}°`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterClouds = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { c } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Cloud coverage: ${c} %`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterRain = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { r } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Precipitation: ${r} mm/h`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterTemperature = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { t } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Temperature (T2m): ${t} °C`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterSwellHeight = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { h } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Swell height: ${h} m`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterSwellPeriod = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { p } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Swell period: ${p} s`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterWwaveHeight = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { h } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Wind waves height: ${h} m`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterWwavePeriod = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { p } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Wind waves period: ${p} s`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterWaveHeight = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { h } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Waves height: ${h} m`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterWaveCapping = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { c } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Waves white capping: ${c}`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterOceanTemp = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { t } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Sea surface temperature: ${t} °C`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterOceanSpeed = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { s } = e.features[0].properties as {
          [key: string]: any;
        };
        const innerHtml = `Current speed: ${s} kts`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseEnterWindComparsion = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      e.originalEvent.cancelBubble = true;
      e.originalEvent.stopPropagation();
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        const { sDiff, eSpeed, eDir, gSpeed, gDir } = e.features[0]
          .properties as {
          [key: string]: any;
        };
        const innerHtml = `Wind speed difference: ${sDiff} kts<br/>ECMWF Wind: ${eSpeed} kts (${eDir}°)<br/>GFS Wind: ${gSpeed} kts (${gDir}°)`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(innerHtml)
          .addTo(map);
      }
    },
    [popup, map],
  );

  const onMouseLeave = useCallback(() => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  }, [popup, map]);

  const onMouseLeaveDebounced = useCallback(debounce(onMouseLeave, 2000), [
    onMouseLeave,
  ]);

  useEffect(() => {
    map.on('mouseenter', ModelLayers.ECMWFMslp, onMouseEnter);
    map.on('mouseleave', ModelLayers.ECMWFMslp, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSMslp, onMouseEnter);
    map.on('mouseleave', ModelLayers.GFSMslp, onMouseLeave);

    // Wind comparison
    map.on(
      'mouseenter',
      ModelLayers.WindSpeedComparison1,
      onMouseEnterWindComparsion,
    );
    map.on('mouseleave', ModelLayers.WindSpeedComparison1, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.WindSpeedComparison2,
      onMouseEnterWindComparsion,
    );
    map.on('mouseleave', ModelLayers.WindSpeedComparison2, onMouseLeave);

    // OCEAN
    map.on(
      'mouseenter',
      ModelLayers.NemoOceanTemperature1,
      onMouseEnterOceanTemp,
    );
    map.on(
      'mouseleave',
      ModelLayers.NemoOceanTemperature1,
      onMouseLeaveDebounced,
    );
    map.on(
      'mouseenter',
      ModelLayers.NemoOceanTemperature2,
      onMouseEnterOceanTemp,
    );
    map.on(
      'mouseleave',
      ModelLayers.NemoOceanTemperature2,
      onMouseLeaveDebounced,
    );
    map.on('mouseenter', ModelLayers.NemoOceanSpeed1, onMouseEnterOceanSpeed);
    map.on('mouseleave', ModelLayers.NemoOceanSpeed1, onMouseLeave);
    map.on('mouseenter', ModelLayers.NemoOceanSpeed2, onMouseEnterOceanSpeed);
    map.on('mouseleave', ModelLayers.NemoOceanSpeed2, onMouseLeave);

    // GFS WAM
    map.on('mouseenter', ModelLayers.GFSWAMWaveHeight1, onMouseEnterWaveHeight);
    map.on('mouseleave', ModelLayers.GFSWAMWaveHeight1, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWAMWaveHeight2, onMouseEnterWaveHeight);
    map.on('mouseleave', ModelLayers.GFSWAMWaveHeight2, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMWwavePeriod1,
      onMouseEnterWwavePeriod,
    );
    map.on('mouseleave', ModelLayers.GFSWAMWwavePeriod1, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMWwavePeriod2,
      onMouseEnterWwavePeriod,
    );
    map.on('mouseleave', ModelLayers.GFSWAMWwavePeriod2, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMWwaveHeight1,
      onMouseEnterWwaveHeight,
    );
    map.on('mouseleave', ModelLayers.GFSWAMWwaveHeight1, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMWwaveHeight2,
      onMouseEnterWwaveHeight,
    );
    map.on('mouseleave', ModelLayers.GFSWAMWwaveHeight2, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMSwavePeriod1,
      onMouseEnterSwellPeriod,
    );
    map.on('mouseleave', ModelLayers.GFSWAMSwavePeriod1, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMSwavePeriod2,
      onMouseEnterSwellPeriod,
    );
    map.on('mouseleave', ModelLayers.GFSWAMSwavePeriod2, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMSwaveHeight1,
      onMouseEnterSwellHeight,
    );
    map.on('mouseleave', ModelLayers.GFSWAMSwaveHeight1, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.GFSWAMSwaveHeight2,
      onMouseEnterSwellHeight,
    );
    map.on('mouseleave', ModelLayers.GFSWAMSwaveHeight2, onMouseLeave);

    // WAM
    map.on(
      'mouseenter',
      ModelLayers.WAMWaveWhiteCapping1,
      onMouseEnterWaveCapping,
    );
    map.on('mouseleave', ModelLayers.WAMWaveWhiteCapping1, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.WAMWaveWhiteCapping2,
      onMouseEnterWaveCapping,
    );
    map.on('mouseleave', ModelLayers.WAMWaveWhiteCapping2, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMWaveHeight1, onMouseEnterWaveHeight);
    map.on('mouseleave', ModelLayers.WAMWaveHeight1, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMWaveHeight2, onMouseEnterWaveHeight);
    map.on('mouseleave', ModelLayers.WAMWaveHeight2, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMWwavePeriod1, onMouseEnterWwavePeriod);
    map.on('mouseleave', ModelLayers.WAMWwavePeriod1, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMWwavePeriod2, onMouseEnterWwavePeriod);
    map.on('mouseleave', ModelLayers.WAMWwavePeriod2, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMWwaveHeight1, onMouseEnterWwaveHeight);
    map.on('mouseleave', ModelLayers.WAMWwaveHeight1, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMWwaveHeight2, onMouseEnterWwaveHeight);
    map.on('mouseleave', ModelLayers.WAMWwaveHeight2, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMSwavePeriod1, onMouseEnterSwellPeriod);
    map.on('mouseleave', ModelLayers.WAMSwavePeriod1, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMSwavePeriod2, onMouseEnterSwellPeriod);
    map.on('mouseleave', ModelLayers.WAMSwavePeriod2, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMSwaveHeight1, onMouseEnterSwellHeight);
    map.on('mouseleave', ModelLayers.WAMSwaveHeight1, onMouseLeave);
    map.on('mouseenter', ModelLayers.WAMSwaveHeight2, onMouseEnterSwellHeight);
    map.on('mouseleave', ModelLayers.WAMSwaveHeight2, onMouseLeave);

    // GFS
    map.on('mouseenter', ModelLayers.GFSWeatherTemp1, onMouseEnterTemperature);
    map.on('mouseleave', ModelLayers.GFSWeatherTemp1, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWeatherTemp2, onMouseEnterTemperature);
    map.on('mouseleave', ModelLayers.GFSWeatherTemp2, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWeatherPerception1, onMouseEnterRain);
    map.on('mouseleave', ModelLayers.GFSWeatherPerception1, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWeatherPerception2, onMouseEnterRain);
    map.on('mouseleave', ModelLayers.GFSWeatherPerception2, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWeatherCloud1, onMouseEnterClouds);
    map.on('mouseleave', ModelLayers.GFSWeatherCloud1, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWeatherCloud2, onMouseEnterClouds);
    map.on('mouseleave', ModelLayers.GFSWeatherCloud2, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWindSpeed1, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.GFSWindSpeed1, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSWindSpeed2, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.GFSWindSpeed2, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSGustsSpeed1, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.GFSGustsSpeed1, onMouseLeave);
    map.on('mouseenter', ModelLayers.GFSGustsSpeed2, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.GFSGustsSpeed2, onMouseLeave);

    // ECMWF
    map.on(
      'mouseenter',
      ModelLayers.ECMWFWeatherTemp1,
      onMouseEnterTemperature,
    );
    map.on('mouseleave', ModelLayers.ECMWFWeatherTemp1, onMouseLeave);
    map.on(
      'mouseenter',
      ModelLayers.ECMWFWeatherTemp2,
      onMouseEnterTemperature,
    );
    map.on('mouseleave', ModelLayers.ECMWFWeatherTemp2, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFWeatherPerception1, onMouseEnterRain);
    map.on('mouseleave', ModelLayers.ECMWFWeatherPerception1, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFWeatherPerception2, onMouseEnterRain);
    map.on('mouseleave', ModelLayers.ECMWFWeatherPerception2, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFWeatherCloud1, onMouseEnterClouds);
    map.on('mouseleave', ModelLayers.ECMWFWeatherCloud1, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFWeatherCloud2, onMouseEnterClouds);
    map.on('mouseleave', ModelLayers.ECMWFWeatherCloud2, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFGustsSpeed1, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.ECMWFGustsSpeed1, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFGustsSpeed2, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.ECMWFGustsSpeed2, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFWindSpeed1, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.ECMWFWindSpeed1, onMouseLeave);
    map.on('mouseenter', ModelLayers.ECMWFWindSpeed2, onMouseEnterWind);
    map.on('mouseleave', ModelLayers.ECMWFWindSpeed2, onMouseLeave);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup, map]);

  return (
    <>
      {isDownloadModalOpen && (
        <DownloadNewMeteoDataModal
          isOpen={isDownloadModalOpen}
          closeModal={closeDownloadModal}
          map={map}
        />
      )}
      <MeteoDownloader />
      <MeteoScale isVisible={isUiVisible} scaleArray={scaleArray} />
      <MeteoDataCleaner />
      <CollapsibleContainer
        isVisible={isUiVisible}
        opacity={0.8}
        isOpened={isOpened}
        onVisibilityChange={onVisibilityChange}
        id={id}
        title="Meteo"
        // HeaderComponent={<Header />}
      >
        <MeteoComponentsContainer>
          <EcmwfMeteoData
            tabName={MeteoSources.ECMWF}
            isOpened={MeteoSources.ECMWF === openedTab}
            toggleTab={toggleTab}
            map={map}
            showMeteoData={isMeteoDataOn && !isDownloadModalOpen}
            addToScaleArray={addToScaleArray}
            removeFromScaleArray={removeFromScaleArray}
            turnOnShowMeteoData={turnOnShowMeteoData}
          />
          <GfsMeteoData
            tabName={MeteoSources.GFS}
            isOpened={MeteoSources.GFS === openedTab}
            toggleTab={toggleTab}
            map={map}
            showMeteoData={isMeteoDataOn && !isDownloadModalOpen}
            addToScaleArray={addToScaleArray}
            removeFromScaleArray={removeFromScaleArray}
            turnOnShowMeteoData={turnOnShowMeteoData}
          />
          <WindComparisonData
            tabName="WindComparison"
            isOpened={openedTab === 'WindComparison'}
            toggleTab={toggleTab}
            map={map}
            showMeteoData={isMeteoDataOn && !isDownloadModalOpen}
            addToScaleArray={addToScaleArray}
            removeFromScaleArray={removeFromScaleArray}
            turnOnShowMeteoData={turnOnShowMeteoData}
          />
          <WamMeteoData
            tabName={MeteoSources.ECMWFWAM}
            isOpened={MeteoSources.ECMWFWAM === openedTab}
            toggleTab={toggleTab}
            map={map}
            showMeteoData={isMeteoDataOn && !isDownloadModalOpen}
            addToScaleArray={addToScaleArray}
            removeFromScaleArray={removeFromScaleArray}
            turnOnShowMeteoData={turnOnShowMeteoData}
          />
          <GfsWamMeteoData
            tabName={MeteoSources.GFSWAM}
            isOpened={MeteoSources.GFSWAM === openedTab}
            toggleTab={toggleTab}
            map={map}
            showMeteoData={isMeteoDataOn && !isDownloadModalOpen}
            addToScaleArray={addToScaleArray}
            removeFromScaleArray={removeFromScaleArray}
            turnOnShowMeteoData={turnOnShowMeteoData}
          />
          <NemoMeteoData
            tabName={MeteoSources.NEMO}
            isOpened={MeteoSources.NEMO === openedTab}
            toggleTab={toggleTab}
            map={map}
            showMeteoData={isMeteoDataOn && !isDownloadModalOpen}
            addToScaleArray={addToScaleArray}
            removeFromScaleArray={removeFromScaleArray}
            turnOnShowMeteoData={turnOnShowMeteoData}
          />
          <ButtonContainer>
            <Button
              label="Download meteo visualizations"
              isSecondary
              clickHandler={openDownloadModal}
              stretch
            />
          </ButtonContainer>
        </MeteoComponentsContainer>
      </CollapsibleContainer>
    </>
  );
};

export default React.memo(MeteoSideMenu);
