import { useCallback, useEffect, useRef } from 'react';
import { Map } from 'mapbox-gl';

import { MapEvent } from '../types';

export const useHandleMovePoints = (
  map: Map,
  layer: string,
  onMove: (ev: MapEvent) => void,
  onMouseEnter: (ev: MapEvent) => void,
  onMouseUp?: () => void,
  isEditing?: boolean,
): void => {
  const isDragging = useRef(false);
  const handleMove = useCallback(
    (ev: MapEvent) => {
      isDragging.current = true;
      map.getCanvas().style.cursor = 'grabbing';
      onMove(ev);
    },
    [map, onMove],
  );

  const onUp = useCallback(() => {
    isDragging.current = false;
    map.off('mousemove', handleMove);
    map.getCanvas().style.cursor = isEditing ? '' : 'crosshair';
    if (onMouseUp) {
      onMouseUp();
    }
  }, [map, handleMove, onMouseUp, isEditing]);

  useEffect(() => {
    const mouseenterHandler = (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData,
    ): void => {
      if (isDragging.current) return;
      onMouseEnter(e);
      map.getCanvas().style.cursor = 'move';
    };
    map.on('mouseenter', layer, mouseenterHandler);

    const mouseleaveHandler = (): void => {
      map.getCanvas().style.cursor = isEditing ? '' : 'crosshair';
    };
    map.on('mouseleave', layer, mouseleaveHandler);

    const mousedownHandler = (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData,
    ): void => {
      // Prevent the default map drag behavior.
      e.preventDefault();

      map.getCanvas().style.cursor = 'grab';

      map.on('mousemove', handleMove);
      map.once('mouseup', onUp);
    };
    map.on('mousedown', layer, mousedownHandler);

    return (): void => {
      map.off('mouseenter', layer, mouseenterHandler);
      map.off('mouseleave', layer, mouseleaveHandler);
      map.off('mousedown', layer, mousedownHandler);
    };
  }, [map, handleMove, onUp, onMouseEnter, layer, isEditing]);
};
