// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import isOverlap from '@turf/boolean-overlap';
import bboxPolygon from '@turf/bbox-polygon';

import {
  TimedBboxConstraint,
  WaveMeteoDataSet,
  WaveDataWithConstraints,
  WindDataWithConstraints,
  WindMeteoDataSet,
} from '../types';
import { getMaxTimeOffset } from './time';

export const buildWaveDataSetConstraints = (
  dataSet: WaveMeteoDataSet[],
): WaveDataWithConstraints => {
  const boundingBoxes = dataSet.map((set) =>
    bboxPolygon([set.minLng, set.minLat, set.maxLng, set.maxLat]),
  );
  const constraints: Array<{
    windWaves: TimedBboxConstraint[];
    swellWaves: TimedBboxConstraint[];
    significantWaves: TimedBboxConstraint[];
  }> = [];
  for (let i = 1; i < dataSet.length; i += 1) {
    const boundaris = {
      windWaves: [] as TimedBboxConstraint[],
      swellWaves: [] as TimedBboxConstraint[],
      significantWaves: [] as TimedBboxConstraint[],
    };
    for (let j = 0; j < i; j += 1) {
      const isWindWavesPresent = !!dataSet[j].windWaves.length;
      const isSwellWavesPresent = !!dataSet[j].swellWaves.length;
      const isSignificantWavesPresent = !!dataSet[j].significantWaves.length;
      const maxWindWavesTimestep = getMaxTimeOffset(dataSet[j].windWaves);
      const maxSwellWavesTimestep = getMaxTimeOffset(dataSet[j].swellWaves);
      const maxSignificantWavesTimestep = getMaxTimeOffset(
        dataSet[j].significantWaves,
      );
      if (isOverlap(boundingBoxes[j], boundingBoxes[i])) {
        if (isWindWavesPresent) {
          boundaris.windWaves.push({
            bbox: boundingBoxes[j],
            maxTime: maxWindWavesTimestep,
          });
        }
        if (isSwellWavesPresent) {
          boundaris.swellWaves.push({
            bbox: boundingBoxes[j],
            maxTime: maxSwellWavesTimestep,
          });
        }
        if (isSignificantWavesPresent) {
          boundaris.significantWaves.push({
            bbox: boundingBoxes[j],
            maxTime: maxSignificantWavesTimestep,
          });
        }
      }
    }
    constraints[i] = boundaris;
  }
  return {
    dataSet,
    constraints,
  };
};

export const buildWindDataSetConstraints = (
  dataSet: WindMeteoDataSet[],
): WindDataWithConstraints => {
  const boundingBoxes = dataSet.map((set) =>
    bboxPolygon([set.minLng, set.minLat, set.maxLng, set.maxLat]),
  );
  const constraints: Array<{
    wind: TimedBboxConstraint[];
    weather: TimedBboxConstraint[];
  }> = [];
  for (let i = 1; i < dataSet.length; i += 1) {
    const boundaris = {
      wind: [] as TimedBboxConstraint[],
      weather: [] as TimedBboxConstraint[],
    };
    for (let j = 0; j < i; j += 1) {
      const isWindPresent = !!dataSet[j].wind.length;
      const isWeatherPresent = !!dataSet[j].weather.length;
      const maxWindTimestep = getMaxTimeOffset(dataSet[j].wind);
      const maxWeatherTimestep = getMaxTimeOffset(dataSet[j].weather);
      if (isOverlap(boundingBoxes[j], boundingBoxes[i])) {
        if (isWindPresent) {
          boundaris.wind.push({
            bbox: boundingBoxes[j],
            maxTime: maxWindTimestep,
          });
        }
        if (isWeatherPresent) {
          boundaris.weather.push({
            bbox: boundingBoxes[j],
            maxTime: maxWeatherTimestep,
          });
        }
      }
    }
    constraints[i] = boundaris;
  }
  return {
    dataSet,
    constraints,
  };
};
