import { useCallback, useEffect } from 'react';
import { Map } from 'mapbox-gl';

const useShowPointerOnHover = (map: Map, layer: string): void => {
  const onMouseEnter = useCallback(() => {
    map.getCanvas().style.cursor = 'pointer';
  }, [map]);
  const onMouseLeave = useCallback(() => {
    map.getCanvas().style.cursor = '';
  }, [map]);
  useEffect(() => {
    map.on('mouseenter', layer, onMouseEnter);
    map.on('mouseleave', layer, onMouseLeave);
    return (): void => {
      map.off('mouseenter', layer, onMouseEnter);
      map.off('mouseleave', layer, onMouseLeave);
    };
  }, [map, layer, onMouseEnter, onMouseLeave]);
};

export default useShowPointerOnHover;
