import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div`
  /* padding: 16px 24px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: left;
  background-color: white;
  width: 600px;
`;

export const TitleContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > h1 {
    margin: 0px;
  }
  & > svg:hover {
    cursor: pointer;
  }
`;

export const ModalFooter = styled.div`
  height: 72px;
  background: ${Colors.LightGray};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  > button:nth-child(1) {
    margin-right: 16px;
  }
`;
