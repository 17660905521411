import axios from 'axios';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 30 * 1000,
});

let waitForRefresh: Promise<void> | null = null;
let resolve:
  | ((value?: void | PromiseLike<void> | undefined) => void)
  | null = null;

export const setToken = (token: string): void => {
  apiClient.defaults.headers.Authorization = `Bearer ${token}`;
  if (resolve) {
    resolve();
  }
};

export const setRequestInterceptors = (): void => {
  apiClient.interceptors.request.use(async (config) => {
    if (waitForRefresh && !config.url?.includes('refresh')) {
      await waitForRefresh;
    }
    return config;
  });
};

export const setResponseInterceptors = (
  handleRefreshToken: () => Promise<string | undefined>,
): void => {
  apiClient.interceptors.response.use(undefined, async (error) => {
    if (error.response.status === 401) {
      waitForRefresh = new Promise((innerResolve) => {
        resolve = innerResolve;
      });
      const token = await handleRefreshToken();
      if (!token) {
        return Promise.reject(error);
      }
      const mergedConfig = {
        ...error.config,
        headers: { ...error.config.headers, Authorization: `Bearer ${token}` },
      };
      return new Promise((newResolve) =>
        setTimeout(() => newResolve(apiClient(mergedConfig)), 50),
      );
    }
    return Promise.reject(error);
  });
};
