import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isOnlineSelector } from 'modules/metadata';
import { downloadMeteoDataThunk } from 'modules/meteo/redux/thunks';
import { downloadQueueFirstItemSelector } from '../../redux/selectors';

const MeteoDownloader: React.FC = () => {
  const dispatch = useDispatch();
  const isOnline = useSelector(isOnlineSelector);
  const downloadItem = useSelector(downloadQueueFirstItemSelector);
  useEffect(() => {
    if (downloadItem && isOnline) {
      dispatch(downloadMeteoDataThunk(downloadItem));
    }
  }, [downloadItem?.link, isOnline]); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

export default React.memo(MeteoDownloader);
