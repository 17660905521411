import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

import db from 'modules/localForage/db';
import {
  toggleRoutingIsochrones,
  addServerRoutes,
  addDownloadedRouting,
  removeDownloadedRouting,
  toggleSideMenuRouting,
  toogleActiveRoutings,
  clearRoutingIsochrones,
  removeServerRouting,
  setHasNewServerRouteForRefresh,
} from './actions';
import { DownloadedRoute, Route } from '../types';

export const activeIsochronesStorageKey = createReducer('', (builder) => {
  builder
    .addCase(toggleRoutingIsochrones, (state, action) => {
      if (state === action.payload) return '';
      return action.payload;
    })
    .addCase(clearRoutingIsochrones, (state, action) => {
      if (state === action.payload) return '';
      return state;
    });
});

export const activeRoutingsIds = createReducer([] as string[], (builder) => {
  builder.addCase(toogleActiveRoutings, (state, action) => {
    if (state.includes(action.payload))
      return state.filter((id) => id !== action.payload);
    return [...state, action.payload];
  });
});

export const sideMenuRoutingIds = createReducer(
  { list: [] } as { list: string[] },
  (builder) => {
    builder
      .addCase(toggleSideMenuRouting, (state, action) => {
        const isPresent = state.list.includes(action.payload);
        if (isPresent) {
          state.list = state.list.filter((r) => r !== action.payload);
        } else {
          state.list = [...state.list, action.payload];
          if (state.list.length > 7) {
            state.list = state.list.slice(1, 8);
          }
        }
        return state;
      })
      .addCase(removeDownloadedRouting, (state, action) => {
        state.list = state.list.filter((id) => id !== action.payload);
        return state;
      });
  },
);

const sideMenuRoutingIdsConfig: PersistConfig<ReturnType<
  typeof sideMenuRoutingIds
>> = {
  key: 'routings:sideMenuRoutings',
  storage: db.app,
};

const sideMenuRoutingIdsReducer = persistReducer(
  sideMenuRoutingIdsConfig,
  sideMenuRoutingIds,
);

export const downloadedRoutings = createReducer(
  {} as { [key: string]: DownloadedRoute },
  (builder) => {
    builder
      .addCase(addDownloadedRouting, (state, action) => {
        state[action.payload.id] = action.payload;
        return state;
      })
      .addCase(removeDownloadedRouting, (state, action) => {
        delete state[action.payload];
        return state;
      });
  },
);

const downloadedRoutingsConfig: PersistConfig<ReturnType<
  typeof downloadedRoutings
>> = {
  key: 'routings:downloaded',
  storage: db.app,
};

const downloadedRoutingsReducer = persistReducer(
  downloadedRoutingsConfig,
  downloadedRoutings,
);

export const serverRoutings = createReducer(
  { list: [], lastTimestamp: 0, hasNew: false } as {
    list: Route[];
    lastTimestamp: number;
    hasNew: boolean;
  },
  (builder) => {
    builder
      .addCase(addServerRoutes, (state, action) => ({
        list: action.payload.list,
        lastTimestamp: action.payload.time,
        hasNew: false,
      }))
      .addCase(removeServerRouting, (state, action) => ({
        list: state.list.filter((item) => item.id !== action.payload),
        lastTimestamp: state.lastTimestamp,
        hasNew: state.hasNew,
      }))
      .addCase(setHasNewServerRouteForRefresh, (state) => {
        state.hasNew = true;
        return state;
      });
  },
);

const serverRoutingConfig: PersistConfig<ReturnType<typeof serverRoutings>> = {
  key: 'routings:server',
  storage: db.app,
};

const serverRoutingsReducer = persistReducer(
  serverRoutingConfig,
  serverRoutings,
);

export const reducer = combineReducers({
  activeIsochronesStorageKey,
  serverRoutings: serverRoutingsReducer,
  downloadedRoutings: downloadedRoutingsReducer,
  sideMenuRoutingIds: sideMenuRoutingIdsReducer,
  activeRoutingsIds,
});
