import React from 'react';
import { Field, Form } from 'react-final-form';

import { Modal, Button, ModalFooter, TextInput } from 'modules/ui/components';
import { H2, RegularBase } from 'modules/ui/typography';
import { validateRequiredString } from 'modules/form';

import { useCreateOrUpdateInclusionZone } from '../../../hooks';
import { FormContainer, ContentRow } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  values: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[];
};

const UploadModal: React.FC<Props> = ({ isOpen, closeModal, values }) => {
  const { handleUpdateOrCreate, loading } = useCreateOrUpdateInclusionZone(
    closeModal,
  );

  return (
    <Modal
      isOpen={isOpen}
      width={600}
      title="Upload GeoJSON with 1 inclusion zone"
      closeModal={closeModal}
    >
      <Form
        onSubmit={handleUpdateOrCreate}
        initialValues={{ inclusionZone: values[0] }}
        render={({ handleSubmit, invalid }): React.ReactNode => (
          <form onSubmit={handleSubmit}>
            <FormContainer>
              <RegularBase>
                The file was analyzed successfully and contained 1 inclusion
                zone.
              </RegularBase>
              <br />
              <br />
              <H2>Please enter the name for your new inclusion zone:</H2>
              <ContentRow>
                <Field
                  name="name"
                  type="text"
                  component={TextInput}
                  validate={validateRequiredString}
                  width={500}
                  autoFocus
                />
              </ContentRow>
            </FormContainer>
            <ModalFooter>
              <Button isSecondary label="Cancel" clickHandler={closeModal} />
              <Button
                isLoading={loading}
                disabled={invalid || loading}
                label="Upload"
                clickHandler={handleSubmit}
              />
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  );
};

export default React.memo(UploadModal);
