import { createAction } from '@reduxjs/toolkit';

export type Tokens = {
  accessToken: string;
  refreshToken: string;
};

export const logInUser = createAction<Tokens>('LOG_IN_USER');

export const refreshTokens = createAction<Tokens>('REFRESH_TOKENS');

export const logOutUser = createAction('LOG_OUT_USER');

export const AuthActions = {
  logInUser,
  logOutUser,
  refreshTokens,
};
