import * as ExifReader from 'exifreader';

export const parseExifTagsAndMetadata = (
  fileBuffer: ArrayBuffer,
): {
  width: number;
  height: number;
  metadata: Record<string, number | string>;
} => {
  const tags = ExifReader.load(fileBuffer);
  const height = Number.parseInt(tags['Image Height']?.value, 10);
  const width = Number.parseInt(tags['Image Width']?.value, 10);
  // TODO Let them fix this
  const metadata = JSON.parse(
    tags?.UserComment?.description?.replace(/'/g, '"') || '{}',
  );
  return {
    width,
    height,
    metadata,
  };
};
