import { Map } from 'mapbox-gl';
import { RoutesLayersSources } from '..';

import {
  emptyGeojson,
  getLayerSourceName,
  ModelLayers,
  ModelLayersSources,
  RoutingLayersSources,
  LayersSources,
  FleetLayersSources,
  InteractionLayersSources,
  ZoneLayersSources,
} from '../consts';

export const preloadMapSources = (map: Map): void => {
  map.addSource(LayersSources.Coastline, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(LayersSources.Graticules1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(LayersSources.Land, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(LayersSources.MinorIslands, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(LayersSources.Ocean, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(LayersSources.IceZone, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ZoneLayersSources.ExclusionZones, {
    type: 'geojson',
    data: emptyGeojson,
    generateId: true,
  });
  map.addSource(ZoneLayersSources.InclusionZones, {
    type: 'geojson',
    data: emptyGeojson,
    generateId: true,
  });
  map.addSource(InteractionLayersSources.MeasurementTool, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(InteractionLayersSources.RouteCreation, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(InteractionLayersSources.PolygonCreation, {
    type: 'geojson',
    data: emptyGeojson,
  });
};

const loadRoutingSources = (map: Map, index: number): void => {
  map.addSource(`${RoutingLayersSources.OptimalRoute}${index}`, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(`${RoutingLayersSources.RoutingPolarSpreadLine}${index}`, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(`${RoutingLayersSources.RoutingPolarSpreadPoints}${index}`, {
    type: 'geojson',
    data: emptyGeojson,
  });
};

const loadRoutesSources = (map: Map, index: number): void => {
  map.addSource(`${RoutesLayersSources.Route}${index}`, {
    type: 'geojson',
    data: emptyGeojson,
  });
};

export const loadMapSources = (map: Map): void => {
  map.addSource(ModelLayersSources.ECMWFWindPoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.ECMWFWindPoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWindPoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWindPoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.ECMWFWindPolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.ECMWFWindPolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWindPolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWindPolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.ECMWFWeather1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.ECMWFWeather2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWeather1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWeather2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(getLayerSourceName(ModelLayers.ECMWFMslp), {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(getLayerSourceName(ModelLayers.GFSMslp), {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMSwavePoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMSwavePoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMSwavePolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMSwavePolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWwavePoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWwavePoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWwavePolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWwavePolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWavePoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWavePoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWavePolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WAMWavePolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMSwavePoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMSwavePoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMSwavePolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMSwavePolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWwavePoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWwavePoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWwavePolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWwavePolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWavePoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWavePoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWavePolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.GFSWAMWavePolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.MeteogramPoint, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.NemoOceanPoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.NemoOceanPoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.NemoOceanPolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.NemoOceanPolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WindComparisonPoint1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WindComparisonPoint2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WindComparisonPolygon1, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(ModelLayersSources.WindComparisonPolygon2, {
    type: 'geojson',
    data: emptyGeojson,
  });
  loadRoutingSources(map, 0);
  loadRoutingSources(map, 1);
  loadRoutingSources(map, 2);
  loadRoutingSources(map, 3);
  loadRoutingSources(map, 4);
  loadRoutingSources(map, 5);
  loadRoutingSources(map, 6);
  map.addSource(RoutingLayersSources.Izochrones, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(RoutingLayersSources.CurrentBoatLocation, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(FleetLayersSources.FleetLastLocation, {
    type: 'geojson',
    data: emptyGeojson,
  });
  map.addSource(FleetLayersSources.FleetRoutes, {
    type: 'geojson',
    data: emptyGeojson,
  });
  loadRoutesSources(map, 0);
  loadRoutesSources(map, 1);
  loadRoutesSources(map, 2);
};
