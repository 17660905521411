import { formatDownloadSize } from 'modules/downloads/utils';
import { TimeResolutionHourDistance } from '../consts';
import { MeteoForm } from '../types';
import { filterTimeResolution } from './time';

const getImagesCount = (
  checked: boolean,
  resolution: number,
  time: number,
): number =>
  checked
    ? filterTimeResolution(TimeResolutionHourDistance[resolution], time).length
    : 0;

export const estimateDownload = (
  values: MeteoForm,
  squaredDegrees: number,
): string => {
  const ecmwfImagesSize =
    getImagesCount(
      values.ecmwf.wind.checked,
      values.ecmwf.wind.resolution,
      values.ecmwf.wind.time,
    ) *
      (squaredDegrees / values.ecmwf.wind.spatial ** 2) +
    getImagesCount(
      values.ecmwf.weather.checked,
      values.ecmwf.weather.resolution,
      values.ecmwf.weather.time,
    ) *
      (squaredDegrees / values.ecmwf.weather.spatial ** 2);

  const gfsImagesSize =
    getImagesCount(
      values.gfs.wind.checked,
      values.gfs.wind.resolution,
      values.gfs.wind.time,
    ) *
      (squaredDegrees / values.gfs.wind.spatial ** 2) +
    getImagesCount(
      values.gfs.weather.checked,
      values.gfs.weather.resolution,
      values.gfs.weather.time,
    ) *
      (squaredDegrees / values.gfs.weather.spatial ** 2);

  const ecmwfWamImagesSize =
    getImagesCount(
      values.ecmwfwam.swellWaves.checked,
      values.ecmwfwam.swellWaves.resolution,
      values.ecmwfwam.swellWaves.time,
    ) *
      (squaredDegrees / values.ecmwfwam.swellWaves.spatial ** 2) +
    getImagesCount(
      values.ecmwfwam.windWaves.checked,
      values.ecmwfwam.windWaves.resolution,
      values.ecmwfwam.windWaves.time,
    ) *
      (squaredDegrees / values.ecmwfwam.windWaves.spatial ** 2) +
    getImagesCount(
      values.ecmwfwam.significantWaves.checked,
      values.ecmwfwam.significantWaves.resolution,
      values.ecmwfwam.significantWaves.time,
    ) *
      (squaredDegrees / values.ecmwfwam.significantWaves.spatial ** 2);

  const gfsWamImagesSize =
    getImagesCount(
      values.gfswam.swellWaves.checked,
      values.gfswam.swellWaves.resolution,
      values.gfswam.swellWaves.time,
    ) *
      (squaredDegrees / values.gfswam.swellWaves.spatial ** 2) +
    getImagesCount(
      values.gfswam.windWaves.checked,
      values.gfswam.windWaves.resolution,
      values.gfswam.windWaves.time,
    ) *
      (squaredDegrees / values.gfswam.windWaves.spatial ** 2) +
    getImagesCount(
      values.gfswam.significantWaves.checked,
      values.gfswam.significantWaves.resolution,
      values.gfswam.significantWaves.time,
    ) *
      (squaredDegrees / values.gfswam.significantWaves.spatial ** 2);

  const nemoImagesCount =
    getImagesCount(
      values.nemo.checked,
      values.nemo.resolution,
      values.nemo.time,
    ) *
    (squaredDegrees / values.nemo.spatial ** 2);

  const mslpCount =
    getImagesCount(
      values.ecmwf.MSLP.checked,
      values.ecmwf.MSLP.resolution,
      values.ecmwf.MSLP.time,
    ) +
    getImagesCount(
      values.gfs.MSLP.checked,
      values.gfs.MSLP.resolution,
      values.gfs.MSLP.time,
    );
  let pixelMultiplier = 1;
  if (squaredDegrees > 600) {
    pixelMultiplier = 0.3;
  } else if (squaredDegrees > 400) {
    pixelMultiplier = 0.45;
  } else if (squaredDegrees > 300) {
    pixelMultiplier = 0.5;
  } else if (squaredDegrees > 200) {
    pixelMultiplier = 0.7;
  } else if (squaredDegrees > 100) {
    pixelMultiplier = 0.8;
  }
  const pixels =
    ecmwfImagesSize +
    gfsImagesSize +
    ecmwfWamImagesSize +
    gfsWamImagesSize +
    nemoImagesCount;
  const imagesSize = (pixels * pixelMultiplier) / 1024;
  const mslpSize = mslpCount * 0.5;
  return formatDownloadSize(imagesSize + mslpSize);
};
