import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RoutingsContainer = styled.div`
  margin: 8px;
  max-height: 50vh;
  overflow-y: auto;
`;
