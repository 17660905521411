import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularSm, H1 } from 'modules/ui/typography';

export const FormContainer = styled.div`
  width: 320px;
  background-color: ${Colors.White};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Title = styled(H1)`
  font-weight: bold;
  margin-bottom: 16px;
`;

export const LogoImage = styled.img`
  width: 105px;
`;

export const Error = styled(RegularSm)`
  color: red !important;
  margin-top: 10px;
`;

export const FormFields = styled.div`
  & > div {
    margin-bottom: 16px;
  }
`;

export const LinkContainer = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
