import styled from 'styled-components';

const TOP_OFFSET = 390;

export const Container = styled.div<{
  colorGradient: string;
  isVisible: boolean;
}>`
  display: ${({ isVisible }): string | undefined =>
    isVisible ? undefined : 'none'};
  width: 10px;
  border-radius: 100px;
  position: fixed;
  right: 12px;
  top: ${TOP_OFFSET}px;
  overflow: hidden;
  height: 224px;
  background: ${({ colorGradient }): string =>
    `linear-gradient(${colorGradient})`};
`;

export const ScaleContainer = styled.div<{
  isVisible: boolean;
}>`
  display: ${({ isVisible }): string | undefined =>
    isVisible ? undefined : 'none'};
  width: 45px;
  position: fixed;
  right: 28px;
  top: ${TOP_OFFSET}px;
  overflow: hidden;
  height: 224px;
`;

export const ScaleLabel = styled.div<{
  height: number;
}>`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #010724;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  height: ${({ height }): number => height}%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px;
  text-align: right;
`;
