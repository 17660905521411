import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useToggle } from 'modules/main';
import { mergeGeoJSONs } from 'modules/geojson/utils';
import {
  getLayerSourceName,
  ModelLayers,
  ModelLayersSources,
} from 'modules/mapLayers';
import { getGeoJsonFromLocalForage } from 'modules/localForage/utils';
import db from 'modules/localForage/db';
import { MeteoSources } from '../consts';
import {
  ecmwfDataSetsSourceKeysSelector,
  gfsDataSetsSourceKeysSelector,
} from '../redux/selectors';
import { SourcesForFiltering } from '../types';
import { getWindDataFromJpeg } from '../utils/wind';
import { getWeatherDataFromJpeg } from '../utils/weather';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useWindSourceAndLayers = (
  dataSource: MeteoSources,
  map: Map,
  showMeteoData: boolean,
  addToScaleArray: (layer: ModelLayers) => void,
  removeFromScaleArray: (layer: ModelLayers) => void,
  turnOnShowMeteoData: () => void,
) => {
  const isEcmwf = useMemo(() => dataSource === MeteoSources.ECMWF, [
    dataSource,
  ]);
  const sources = useSelector(
    isEcmwf ? ecmwfDataSetsSourceKeysSelector : gfsDataSetsSourceKeysSelector,
  );

  const [isWindVisible, setIsWindVisible] = useToggle();
  const [isGustsVisible, setIsGustsVisible] = useToggle();
  const [isMslpVisible, setIsMslpVisible] = useToggle();
  const [isCloudVisible, setIsCloudVisible] = useToggle();
  const [isTempVisible, setIsTempVisible] = useToggle();
  const [isPrecipitationVisible, setIsPrecipitationVisible] = useToggle();

  const handleWindSources = useCallback(
    async (windSources: SourcesForFiltering[]) => {
      const jsons = await Promise.all(
        windSources.map(({ fileName, constraints }) =>
          getWindDataFromJpeg(fileName, { constraints }),
        ),
      );
      const polygonGeojson1 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson1).filter((_) => _),
      );
      const polygonGeojson2 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson2).filter((_) => _),
      );
      const pointGeoJson1 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson1).filter((_) => _),
      );
      const pointGeoJson2 = mergeGeoJSONs(
        jsons.map((j) => j.barbGeoJson2).filter((_) => _),
      );
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.ECMWFWindPoint1
            : ModelLayersSources.GFSWindPoint1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.ECMWFWindPoint2
            : ModelLayersSources.GFSWindPoint2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(pointGeoJson2);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.ECMWFWindPolygon1
            : ModelLayersSources.GFSWindPolygon1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.ECMWFWindPolygon2
            : ModelLayersSources.GFSWindPolygon2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleMslpSources = useCallback(
    async (mslpSources: string[]) => {
      const jsons = await Promise.all(
        mslpSources.map((storageKey) =>
          getGeoJsonFromLocalForage(db.meteo, storageKey),
        ),
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const geojson = mergeGeoJSONs(jsons.filter((_) => _));
      map
        .getSource(getLayerSourceName(ModelLayers.ECMWFMslp))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(geojson);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleWeatherSources = useCallback(
    async (weatherSources: SourcesForFiltering[]) => {
      const jsons = await Promise.all(
        weatherSources.map(({ fileName, constraints }) =>
          getWeatherDataFromJpeg(fileName, { constraints }),
        ),
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const polygonGeojson1 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson1).filter((_) => _),
      );
      const polygonGeojson2 = mergeGeoJSONs(
        jsons.map((j) => j.fillGeoJson2).filter((_) => _),
      );
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.ECMWFWeather1
            : ModelLayersSources.GFSWeather1,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson1);
      map
        .getSource(
          isEcmwf
            ? ModelLayersSources.ECMWFWeather2
            : ModelLayersSources.GFSWeather2,
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(polygonGeojson2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const changeLayerVisibility = useCallback(
    (hide: boolean, layerName: ModelLayers) => {
      if (hide) {
        removeFromScaleArray(layerName);
        map.setLayoutProperty(layerName, 'visibility', 'none');
      } else {
        addToScaleArray(layerName);
        map.setLayoutProperty(layerName, 'visibility', 'visible');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const windSourceNeeded = useMemo(
    () => showMeteoData && (isWindVisible || isGustsVisible),
    [showMeteoData, isWindVisible, isGustsVisible],
  );
  useDeepCompareEffect(() => {
    if (windSourceNeeded) {
      handleWindSources(sources.windSources);
    }
  }, [sources.windSources, windSourceNeeded, handleWindSources]);

  const mslpSourceNeeded = useMemo(() => showMeteoData && isMslpVisible, [
    showMeteoData,
    isMslpVisible,
  ]);
  useDeepCompareEffect(() => {
    if (mslpSourceNeeded) {
      handleMslpSources(sources.mslpSources);
    }
  }, [sources.mslpSources, mslpSourceNeeded, handleMslpSources]);

  const weatherSourceNeeded = useMemo(
    () =>
      showMeteoData &&
      (isTempVisible || isCloudVisible || isPrecipitationVisible),
    [showMeteoData, isTempVisible, isCloudVisible, isPrecipitationVisible],
  );
  useDeepCompareEffect(() => {
    if (weatherSourceNeeded) {
      handleWeatherSources(sources.weatherSources);
    }
  }, [sources.weatherSources, weatherSourceNeeded, handleWeatherSources]);

  useEffect(() => {
    changeLayerVisibility(
      !isCloudVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWeatherCloud1 : ModelLayers.GFSWeatherCloud1,
    );
    changeLayerVisibility(
      !isCloudVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWeatherCloud2 : ModelLayers.GFSWeatherCloud2,
    );
  }, [isCloudVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isCloudVisible) {
      turnOnShowMeteoData();
    }
  }, [isCloudVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isMslpVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFMslp : ModelLayers.GFSMslp,
    );
  }, [isMslpVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isMslpVisible) {
      turnOnShowMeteoData();
    }
  }, [isMslpVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isTempVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWeatherTemp1 : ModelLayers.GFSWeatherTemp1,
    );
    changeLayerVisibility(
      !isTempVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWeatherTemp2 : ModelLayers.GFSWeatherTemp2,
    );
  }, [isTempVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isTempVisible) {
      turnOnShowMeteoData();
    }
  }, [isTempVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isPrecipitationVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.ECMWFWeatherPerception1
        : ModelLayers.GFSWeatherPerception1,
    );
    changeLayerVisibility(
      !isPrecipitationVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.ECMWFWeatherPerception2
        : ModelLayers.GFSWeatherPerception2,
    );
  }, [isPrecipitationVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isPrecipitationVisible) {
      turnOnShowMeteoData();
    }
  }, [isPrecipitationVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isWindVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWindSpeed1 : ModelLayers.GFSWindSpeed1,
    );
    changeLayerVisibility(
      !isWindVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWindDirection1 : ModelLayers.GFSWindDirection1,
    );
    changeLayerVisibility(
      !isWindVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWindSpeed2 : ModelLayers.GFSWindSpeed2,
    );
    changeLayerVisibility(
      !isWindVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFWindDirection2 : ModelLayers.GFSWindDirection2,
    );
  }, [isWindVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isWindVisible) {
      turnOnShowMeteoData();
    }
  }, [isWindVisible, turnOnShowMeteoData]);
  useEffect(() => {
    changeLayerVisibility(
      !isGustsVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.ECMWFGustsDirection1
        : ModelLayers.GFSGustsDirection1,
    );
    changeLayerVisibility(
      !isGustsVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFGustsSpeed1 : ModelLayers.GFSGustsSpeed1,
    );
    changeLayerVisibility(
      !isGustsVisible || !showMeteoData,
      isEcmwf
        ? ModelLayers.ECMWFGustsDirection2
        : ModelLayers.GFSGustsDirection2,
    );
    changeLayerVisibility(
      !isGustsVisible || !showMeteoData,
      isEcmwf ? ModelLayers.ECMWFGustsSpeed2 : ModelLayers.GFSGustsSpeed2,
    );
  }, [isGustsVisible, showMeteoData, changeLayerVisibility, isEcmwf]);
  useEffect(() => {
    if (isGustsVisible) {
      turnOnShowMeteoData();
    }
  }, [isGustsVisible, turnOnShowMeteoData]);
  const componentsCount = useMemo(() => {
    if (!showMeteoData) return 0;
    return [
      isWindVisible,
      isGustsVisible,
      isMslpVisible,
      isCloudVisible,
      isTempVisible,
      isPrecipitationVisible,
    ].filter((_) => _).length;
  }, [
    showMeteoData,
    isWindVisible,
    isGustsVisible,
    isMslpVisible,
    isCloudVisible,
    isTempVisible,
    isPrecipitationVisible,
  ]);
  return {
    componentsCount,
    isWindVisible,
    setIsWindVisible,
    isGustsVisible,
    setIsGustsVisible,
    isMslpVisible,
    setIsMslpVisible,
    isCloudVisible,
    setIsCloudVisible,
    isTempVisible,
    setIsTempVisible,
    isPrecipitationVisible,
    setIsPrecipitationVisible,
  };
};

export default useWindSourceAndLayers;
