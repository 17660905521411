import React, { useCallback } from 'react';

import { Colors } from 'modules/ui/theme/colors';

import { Label, Indicator, Input, ToggleLabel } from './styledComponents';

type Props = {
  checked?: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  toggleColor?: string;
};

const Switch: React.FC<Props> = ({ checked, onChange, label, toggleColor }) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.checked),
    [onChange],
  );

  return (
    <>
      <Label>
        <Input
          checked={!!checked}
          type="checkbox"
          onChange={handleChange}
          toggleColor={toggleColor || Colors.Secondary}
        />
        <Indicator />
      </Label>
      {label && <ToggleLabel>{label}</ToggleLabel>}
    </>
  );
};

export default React.memo(Switch);
