import { createElement, useCallback, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { Map, Popup } from 'mapbox-gl';

import { useShowPointerOnHover } from 'modules/map';
import { ZoneLayers, ZoneLayersSources } from 'modules/mapLayers';
import EditControls from '../components/EditControls';

export const useOnInclusionAreaClick = (
  map: Map,
  onEditClick: (value: number) => void,
  isEditing: boolean,
  isInclusionZoneVisibilityOn: boolean,
): void => {
  const popupRef = useRef<Popup | null>();
  useShowPointerOnHover(map, ZoneLayers.InclusionZones);
  const hidePopup = useCallback(() => {
    if (popupRef?.current) {
      popupRef.current.remove();
    }
  }, []);
  const clickHandler = useCallback(
    (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData,
    ) => {
      if (e.features && e.features[0]?.properties?.id) {
        const sourceFeatureId = e.features[0]?.id;
        setTimeout(() => {
          map.setFeatureState(
            {
              source: ZoneLayersSources.InclusionZones,
              id: sourceFeatureId,
            },
            { clicked: true },
          );
        }, [100]);
        const Controls = createElement(EditControls, {
          id: e.features[0]?.properties?.id,
          name: e.features[0]?.properties?.name,
          onEditClick,
          hidePopup,
        });
        const popupNode = document.createElement('div');
        ReactDOM.render(Controls, popupNode);
        const PopUp = new Popup({
          closeOnClick: true,
          closeButton: false,
          anchor: undefined,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map);
        PopUp.on('close', () => {
          map.removeFeatureState({
            source: ZoneLayersSources.InclusionZones,
            id: sourceFeatureId,
          });
        });
        popupRef.current = PopUp;
      }
    },
    [map, onEditClick, hidePopup],
  );
  useEffect(() => {
    if (isEditing) {
      map.off('click', ZoneLayers.InclusionZones, clickHandler);
    } else {
      map.on('click', ZoneLayers.InclusionZones, clickHandler);
    }
    return (): void => {
      if (!isEditing) {
        map.off('click', ZoneLayers.InclusionZones, clickHandler);
      }
    };
  }, [map, clickHandler, isEditing]);

  useEffect(() => {
    if (!isInclusionZoneVisibilityOn) {
      hidePopup();
    }
  }, [hidePopup, isInclusionZoneVisibilityOn]);
};
