import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div<{ isVisible: boolean }>`
  position: fixed;
  right: 12px;
  top: 12px;
  visibility: ${({ isVisible }): string => (isVisible ? 'visible' : 'hidden')};
`;

export const MapControlsContainer = styled.div`
  display: flex;
  width: 32px;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.White}CC;
  > button:not(:last-child) {
    margin-bottom: 8px;
  }
  > button:last-child {
    margin-bottom: 2px;
  }
  margin: 8px 0;
`;
