import { ModelLayers } from 'modules/mapLayers';
import { Units } from '../consts';

export const getLayerScaleUnit = (layer: ModelLayers): string | undefined => {
  if (
    layer === ModelLayers.WAMWaveWhiteCapping1 ||
    layer === ModelLayers.WAMWaveWhiteCapping2
  ) {
    return Units.Coverage;
  }
  if (
    layer === ModelLayers.NemoOceanSpeed1 ||
    layer === ModelLayers.NemoOceanSpeed2
  ) {
    return Units.Knots;
  }
  if (
    layer === ModelLayers.WAMSwaveHeight1 ||
    layer === ModelLayers.WAMSwaveHeight2 ||
    layer === ModelLayers.WAMWwaveHeight1 ||
    layer === ModelLayers.WAMWwaveHeight2 ||
    layer === ModelLayers.WAMWaveHeight1 ||
    layer === ModelLayers.WAMWaveHeight2
  ) {
    return Units.Height;
  }
  if (
    layer === ModelLayers.WAMSwavePeriod1 ||
    layer === ModelLayers.WAMSwavePeriod2 ||
    layer === ModelLayers.WAMWwavePeriod1 ||
    layer === ModelLayers.WAMWwavePeriod2
  ) {
    return Units.Period;
  }
  if (
    layer === ModelLayers.ECMWFWeatherPerception1 ||
    layer === ModelLayers.ECMWFWeatherPerception2 ||
    layer === ModelLayers.GFSWeatherPerception1 ||
    layer === ModelLayers.GFSWeatherPerception2
  ) {
    return Units.RainRatio;
  }
  if (
    layer === ModelLayers.ECMWFWeatherCloud1 ||
    layer === ModelLayers.ECMWFWeatherCloud2 ||
    layer === ModelLayers.GFSWeatherCloud1 ||
    layer === ModelLayers.GFSWeatherCloud2
  ) {
    return Units.Coverage;
  }
  if (
    layer === ModelLayers.ECMWFWeatherTemp1 ||
    layer === ModelLayers.ECMWFWeatherTemp2 ||
    layer === ModelLayers.GFSWeatherTemp1 ||
    layer === ModelLayers.GFSWeatherTemp2
  ) {
    return Units.Temperature;
  }
  if (
    layer === ModelLayers.NemoOceanTemperature1 ||
    layer === ModelLayers.NemoOceanTemperature2
  ) {
    return Units.Temperature;
  }
  if (
    layer === ModelLayers.ECMWFWindSpeed1 ||
    layer === ModelLayers.ECMWFWindSpeed2 ||
    layer === ModelLayers.GFSWindSpeed1 ||
    layer === ModelLayers.GFSWindSpeed2 ||
    layer === ModelLayers.ECMWFGustsSpeed1 ||
    layer === ModelLayers.ECMWFGustsSpeed2 ||
    layer === ModelLayers.GFSGustsSpeed1 ||
    layer === ModelLayers.GFSGustsSpeed2
  ) {
    return Units.Knots;
  }
  if (
    layer === ModelLayers.WindSpeedComparison1 ||
    layer === ModelLayers.WindSpeedComparison2
  ) {
    return Units.KnotsDiff;
  }
  return undefined;
};
