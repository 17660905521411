import { convertDMSToDD } from 'modules/coordinates';
import { FormStartAndEndCoordinates } from './type';

export const parseTimeOptions = (max: number) => (value: string): number => {
  const parsedNumber = parseInt(value, 10);
  if (parsedNumber < 0) return 1;
  if (parsedNumber > max) return max;
  return parsedNumber;
};

export const parseId = (value: string): number => parseInt(value, 10);

export const convertFormCoordinatesToGeometryCoordinates = (
  data: FormStartAndEndCoordinates,
): number[][] => {
  return [
    [
      convertDMSToDD(data.routeStartLng, data.routeStartLngDirection),
      convertDMSToDD(data.routeStartLat, data.routeStartLatDirection),
    ],
    [
      convertDMSToDD(data.routeEndLng, data.routeEndLngDirection),
      convertDMSToDD(data.routeEndLat, data.routeEndLatDirection),
    ],
  ];
};
