import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const WelcomeMessage = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  color: ${Colors.Black};
`;

export const ProgressBarContainer = styled.div`
  width: 480px;
  margin: 64px 0 32px 0;
`;
