import { RoutingTypes } from '../consts';
import { NewRouteForm } from '../types';

export const getModelsArray = (data: NewRouteForm): string[] => {
  const models = [];
  if (data.ecmwf) {
    models.push(RoutingTypes['ecmwf-fc']);
  }
  if (data.gfs) {
    models.push(RoutingTypes['ncep-fc']);
  }
  if (data.ecmwfEnsemble) {
    models.push(RoutingTypes['ecmwf-eps']);
  }
  if (data.gfsEnsemble) {
    models.push(RoutingTypes['ncep-eps']);
  }
  return models;
};
