/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import db from 'modules/localForage/db';
import { persistReducer, PersistConfig } from 'redux-persist';
import { addFullMeteogramInPoint, removeFullMeteogramInPoint } from './actions';

type PointData = {
  steps: any[];
  lat: number;
  lon: number;
  id: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Meteograms = {
  points: PointData[] | [];
};

const initialState: { points: PointData[] } = { points: [] };
export const meteogramsInPoint = createReducer(initialState, {
  [addFullMeteogramInPoint.type]: (state, action) => {
    action.payload.id = `${new Date().getTime()}`;
    state.points = [...state.points, action.payload];
    return state;
  },
  [removeFullMeteogramInPoint.type]: (state) => {
    const timestamp = new Date().getTime();
    const days3 = 259200000;

    state.points = state.points.filter((point) => timestamp - point.id < days3);
    return state;
  },
});

const meteogramsInPointConfig: PersistConfig<ReturnType<
  typeof meteogramsInPoint
>> = {
  key: 'meteogram:meteogramsInPoint',
  version: 2,
  storage: db.app,
  migrate: (state: any) => {
    if (!!state && state.points) {
      const newPoints = state.points.map((point: any) => {
        if (!point.gfs && !point.ecmwf) {
          return {
            gfs: null,
            ecmwf: point,
            id: point.id,
          };
        }

        return point;
      });

      state.points = newPoints;
    }
    return Promise.resolve(state);
  },
};

const meteogramsInPointReducer = persistReducer(
  meteogramsInPointConfig,
  meteogramsInPoint,
);

export const reducer = combineReducers({
  meteogramsInPoint: meteogramsInPointReducer,
});
