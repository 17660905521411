import React, { useEffect, useState } from 'react';
import { Map } from 'mapbox-gl';

import { toDegreesMinutesAndSeconds } from 'modules/coordinates';
import { Container, CoordinatesText } from './styledComponents';

type Props = {
  map: Map;
};

const CoordinatesInfoContainer: React.FC<Props> = ({ map }) => {
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  useEffect(() => {
    if (map) {
      map.on('mousemove', (e) => {
        setLat(e.lngLat.lat);
        setLng(e.lngLat.lng);
      });
    }
  }, [map]);
  return (
    <Container>
      <CoordinatesText>
        {`${toDegreesMinutesAndSeconds(lat, true)} ${toDegreesMinutesAndSeconds(
          lng,
        )}`}
      </CoordinatesText>
    </Container>
  );
};

export default React.memo(CoordinatesInfoContainer);
