import React, { memo } from 'react';

import { Button, Modal, ModalFooter } from 'modules/ui/components';
import { H2 } from 'modules/ui/typography';

import { Container } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  handleDelete: () => void;
  loading: boolean;
  text: string;
  title?: string;
};

const DeleteModal: React.FC<Props> = ({
  closeModal,
  loading,
  text,
  handleDelete,
  isOpen,
  title,
}) => (
  <Modal
    isOpen={isOpen}
    width={712}
    title={title || 'Delete modal'}
    closeModal={closeModal}
    onRequestClose={closeModal}
  >
    <Container>
      <H2>{text}</H2>
    </Container>
    <ModalFooter>
      <Button isSecondary label="Cancel" clickHandler={closeModal} />
      <Button
        isLoading={loading}
        disabled={loading}
        label="Delete"
        clickHandler={handleDelete}
      />
    </ModalFooter>
  </Modal>
);

export default memo(DeleteModal);
