import { ThunkAction } from 'modules/main/redux';

import {
  setSettingsDefaults,
  setSettingsWindSpeedCorrection,
  setTimeResolutionDefault,
} from './actions';
import { SettingsFormValues } from '../types';

export const saveSettingsThunk = (
  values: SettingsFormValues,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  dispatch(setSettingsDefaults({ values: values.defaults }));
  dispatch(
    setSettingsWindSpeedCorrection({ values: values.windSpeedCorrection }),
  );
  dispatch(setTimeResolutionDefault(values.timeResolution));
};
