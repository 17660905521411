import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setIsOnlineFlag } from '../redux/actions';

export const useTrackInternetConnection = (): void => {
  const dispatch = useDispatch();
  const onlineListenerHandler = useCallback(() => {
    dispatch(setIsOnlineFlag(true));
  }, [dispatch]);
  const offlineListenerHandler = useCallback(() => {
    dispatch(setIsOnlineFlag(false));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('online', onlineListenerHandler);
    window.addEventListener('offline', offlineListenerHandler);
    return (): void => {
      window.removeEventListener('online', onlineListenerHandler);
      window.removeEventListener('offline', offlineListenerHandler);
    };
  }, [onlineListenerHandler, offlineListenerHandler]);
};
