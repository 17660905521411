import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications';
import { BoatsApi } from '../api';
import { BoatsActions, BoatsSelectors } from '../redux';
import { Boat } from '../types';

export const useCreateOrUpdateBoat = (closeModal: () => void) => {
  const dispatch = useDispatch();
  const boat = useSelector(BoatsSelectors.firstBoat);
  const [polarsFile, setPolarFile] = useState<File | null>(null);
  const [sailsFile, setSailsFile] = useState<File | null>(null);
  const [waveFile, setWaveFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const handleUpdateOrCreate = useCallback(
    async ({ name }: { name: string }) => {
      setLoading(true);
      try {
        let updatedBoat: Boat = boat;
        if (boat) {
          updatedBoat = await BoatsApi.updateBoat({
            files: {
              polars: polarsFile,
              sails: sailsFile,
              wave: waveFile,
            },
            id: boat.id,
            name,
          });
        } else {
          updatedBoat = await BoatsApi.createBoat({
            files: {
              polars: polarsFile,
              sails: sailsFile,
              wave: waveFile,
            },
            name,
          });
        }

        dispatch(BoatsActions.setBoats([updatedBoat]));
        dispatch(
          addNotification({
            type: NotificationTypes.Success,
            message: `Boat has been ${
              boat ? 'updated' : 'created'
            } successfully.`,
          }),
        );
        closeModal();
      } catch (err) {
        dispatch(
          addNotification({
            type: NotificationTypes.Error,
            message: `Error while ${boat ? 'updating' : 'creating a'} boat.`,
          }),
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, polarsFile, sailsFile, waveFile, boat, closeModal],
  );

  return {
    loading,
    polarsFile,
    setPolarFile,
    sailsFile,
    setSailsFile,
    waveFile,
    setWaveFile,
    setLoading,
    handleUpdateOrCreate,
    boatExists: !!boat,
    initialName: boat?.name || '',
  };
};
