import React, { useCallback } from 'react';
import { Collapse } from 'react-collapse';

import { ArrowDownIcon } from 'modules/ui/assets';
import { H1 } from 'modules/ui/typography';

import {
  Container,
  TitleContainer,
  ContentContainer,
  RightTitleContent,
} from './styledComponents';

type Props = {
  HeaderComponent?: React.ReactNode;
  children?: React.ReactNode;
  title: string;
  isOpened: boolean;
  onVisibilityChange: (value: string | null) => void;
  id: string;
  opacity?: number;
  isVisible: boolean;
  RightTitleComponent?: React.ReactNode;
};

const CollapsibleContainer: React.FC<Props> = ({
  HeaderComponent,
  children,
  title,
  isOpened,
  onVisibilityChange,
  id,
  opacity,
  isVisible,
  RightTitleComponent,
}) => {
  const handleClick = useCallback(() => {
    onVisibilityChange(isOpened ? null : id);
  }, [isOpened, onVisibilityChange, id]);

  return (
    <Container
      showBottomPadding={!!HeaderComponent || isOpened}
      isVisible={isVisible}
      opacity={isOpened ? 1 : opacity}
    >
      <TitleContainer onClick={handleClick}>
        <H1>{title}</H1>
        <RightTitleContent>
          {RightTitleComponent}
          <ArrowDownIcon transform={isOpened ? 'rotate(180)' : undefined} />
        </RightTitleContent>
      </TitleContainer>
      {HeaderComponent}
      <Collapse isOpened={isOpened}>
        <ContentContainer showTopPadding={!!HeaderComponent}>
          {children}
        </ContentContainer>
      </Collapse>
    </Container>
  );
};

export default React.memo(CollapsibleContainer);
