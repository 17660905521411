import React from 'react';
import { useSelector } from 'react-redux';

import { ModalEmptyMessage } from 'modules/ui/components';
import { historyDownloadsSelector } from '../../redux/selectors';
import { HistoryItem } from '../HistoryItem';

const History: React.FC = () => {
  const historyDownloads = useSelector(historyDownloadsSelector);
  return (
    <>
      {!historyDownloads.length && (
        <ModalEmptyMessage
          title="No download history"
          subtitle="All past downloads will be shown here."
        />
      )}
      {historyDownloads.map((item) => (
        <HistoryItem item={item} key={item.name} />
      ))}
    </>
  );
};

export default React.memo(History);
