import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { setToken } from 'modules/apiClient';
import { AuthActions } from '../redux';
import { AuthApi } from '../api';

export const useLogInUser = (): {
  error: string;
  loading: boolean;
  handleLogin: (values: { email: string; password: string }) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleLogin = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        setLoading(true);
        const tokens = await AuthApi.login({ password, email });
        dispatch(AuthActions.logInUser(tokens));
        setToken(tokens.accessToken);
      } catch (err) {
        const errorMessage = ((err as unknown) as AxiosError<string>).response
          ?.data;
        if (errorMessage) {
          setError(errorMessage);
        } else {
          setError("Email and password doesn't match");
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );
  return { error, handleLogin, loading };
};
