import { Map } from 'mapbox-gl';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LocationSelectors } from 'modules/location';

export const useMapMovementsControls = (
  map: Map,
): {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleCenterMap: () => void;
} => {
  const currentLocation = useSelector(
    LocationSelectors.currentCoordinatesWithDefault,
  );
  const handleZoomIn = useCallback(() => {
    map.zoomIn();
  }, [map]);
  const handleZoomOut = useCallback(() => {
    map.zoomOut();
  }, [map]);

  const handleCenterMap = useCallback(() => {
    map.flyTo({ center: [currentLocation[0], currentLocation[1]] });
  }, [map, currentLocation]);

  return {
    handleZoomIn,
    handleZoomOut,
    handleCenterMap,
  };
};
