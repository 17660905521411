/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction } from '@reduxjs/toolkit';

export const addFullMeteogramInPoint = createAction<any>(
  'ADD_FULL_METEOGRAM_IN_POINT',
);

export const addOfflineMeteogramInPoint = createAction<any>(
  'ADD_OFFLINE_METEOGRAM_IN_POINT',
);

export const removeFullMeteogramInPoint = createAction(
  'REMOVE_FULL_METEOGRAM_IN_POINT',
);
