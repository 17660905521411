import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';

import { RootState } from 'modules/main/redux';
import { Modal } from 'modules/ui/components';
import { getGeoJsonFromLocalForage } from 'modules/localForage/utils';
import { useGetGeojsonData, Options } from 'modules/graph';
import db from 'modules/localForage/db';
import { downloadedRoutingByIdSelector } from '../../redux/selectors';
import { DownloadedRoute } from '../../types';

import { Container } from './styledComponents';

type Props = {
  closePlotModal: () => void;
  routingId: string | null;
};

const AsembleRoutingStatsModal: React.FC<Props> = ({
  routingId,
  closePlotModal,
}) => {
  const [geojson, setGeojson] = useState<GeoJSON.FeatureCollection | null>(
    null,
  );
  const routing = useSelector((state: RootState) =>
    downloadedRoutingByIdSelector(state, routingId || ''),
  );
  const getGeojson = useCallback(
    async (inputRoute: DownloadedRoute) => {
      const json = (await getGeoJsonFromLocalForage(
        db.routings,
        inputRoute?.allRoutingStorageId || '',
      )) as GeoJSON.FeatureCollection;
      setGeojson(json);
    },
    [setGeojson],
  );
  useEffect(() => {
    if (routing) {
      getGeojson(routing);
    }
  }, [routing, getGeojson]);
  const timeDifferenceOptions: Options = useMemo(
    () => ({
      isTimeDifference: true,
      startTime: routing?.metadata.Route.StartTime,
    }),
    [routing],
  );
  const distanceOptions: Options = useMemo(
    () => ({
      isDistance: true,
    }),
    [],
  );
  const [dtimeDifferenceData] = useGetGeojsonData(
    geojson,
    timeDifferenceOptions,
  );
  const [distanceData] = useGetGeojsonData(geojson, distanceOptions);
  return (
    <Modal
      isOpen={!!routingId}
      width={720}
      title=""
      closeModal={closePlotModal}
      onRequestClose={closePlotModal}
    >
      <Container>
        <Plot
          data={dtimeDifferenceData}
          layout={{
            title: 'Time distribution',
            xaxis: {
              ticksuffix: 'h',
              dtick: 1,
            },
            yaxis: {
              ticksuffix: 'x',
              dtick: 1,
            },
          }}
          config={{ displayModeBar: false, showTips: false }}
        />
        <Plot
          data={distanceData}
          layout={{
            title: 'Distance distribution',
            xaxis: {
              ticksuffix: 'km',
            },
            yaxis: {
              ticksuffix: 'x',
              dtick: 1,
            },
          }}
          config={{ displayModeBar: false, showTips: false }}
        />
      </Container>
    </Modal>
  );
};

export default memo(AsembleRoutingStatsModal);
