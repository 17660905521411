import styled from 'styled-components';

import SailsImage from 'modules/ui/assets/images/Sails.png';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  min-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${SailsImage});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
`;
