/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/main/redux';
import { Colors } from 'modules/ui/theme/colors';

export const fullMeteoEcmwfSelector = (
  state: RootState,
): RootState['meteogram']['meteogramsInPoint']['points'] =>
  state.meteogram.meteogramsInPoint.points;

export const fullMeteoEcmwfSourceSelector = createSelector(
  [fullMeteoEcmwfSelector],
  (data) => {
    const timestamp = new Date().getTime();
    const day = 86400000;
    const features = data
      .filter(
        // TODO Fix typings
        (source: any) =>
          timestamp - Number(source.id) < day * 3 &&
          (source.ecmwf || source.gfs),
      )
      .map((source: any) => {
        const lat = source.ecmwf ? source.ecmwf.lat : source.gfs.lat;
        const lon = source.ecmwf ? source.ecmwf.lon : source.gfs.lon;
        return {
          id: source.id,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lon, lat],
          },
          properties: {
            title: 'Full meteogram in point',
            color:
              timestamp - Number(source.id) < day ? Colors.PrimaryDark : 'gray',
          },
        };
      });

    return {
      type: 'FeatureCollection',
      features,
    };
  },
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getFullMeteoEcmwfSourceByIdSelector = (id: number) => {
  return createSelector([fullMeteoEcmwfSelector], (data) => {
    return data.find((meteogram) => meteogram.id === id);
  });
};
