import { point } from '@turf/helpers';
import { Map } from 'mapbox-gl';
import { RoutingLayersSources } from 'modules/mapLayers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { LocationSelectors } from '../redux';

export const useDisplayCurrentLocation = (map: Map): void => {
  const location = useSelector(LocationSelectors.lastLocation);
  useEffect(() => {
    if (
      location?.coordinates &&
      location?.coordinates[0] !== undefined &&
      location?.coordinates[1] !== undefined
    )
      map.flyTo({
        center: [location.coordinates[0], location.coordinates[1]],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    try {
      if (location.coordinates) {
        const geojson = point(location.coordinates, {
          heading: location.heading,
        });
        map
          .getSource(RoutingLayersSources.CurrentBoatLocation)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(geojson);
      }
    } catch (error) {
      console.log(error);
    }
  }, [location]);
};
