import React from 'react';

import {
  BarContainer,
  Container,
  IndeterminateBar,
  DeterminateBar,
} from './styledComponents';

type Props = {
  type?: string;
  percentage?: number;
  backgroundColor?: string;
  height?: number;
};

const ProgressBar: React.FC<Props> = ({
  type,
  percentage,
  backgroundColor,
  height,
}) => {
  return (
    <Container>
      <BarContainer height={height}>
        {type === 'indeterminate' ? (
          <IndeterminateBar
            backgroundColor={backgroundColor}
            role="progressbar"
          />
        ) : (
          <DeterminateBar
            backgroundColor={backgroundColor}
            percentage={percentage || 0}
            role="progressbar"
          />
        )}
      </BarContainer>
    </Container>
  );
};

export default React.memo(ProgressBar);
