export const WindSpeedScale = [
  0.0,
  '#73FCFD',
  5,
  '#80fac3',
  10,
  '#a1fb8f',
  15,
  '#cefb64',
  20,
  '#fffd53',
  25,
  '#f6c042',
  30,
  '#f08533',
  40,
  '#ec4e27',
  50,
  '#eb3323',
  60,
  '#b12418',
];

export const CloudCoverScale = [
  0,
  'transparent',
  40,
  '#d6e3e3',
  60,
  '#c2d5d5',
  80,
  '#acc5c6',
  100,
  '#9db3b7',
];

export const CloudCoverVisualizationScale = [
  40,
  '#d6e3e3',
  60,
  '#c2d5d5',
  80,
  '#acc5c6',
  100,
  '#9db3b7',
];

export const RainPrecipitationScale = [
  0,
  'transparent',
  1,
  '#65d1fb',
  5,
  '#64c0fa',
  10,
  '#66b3f9',
  15,
  '#6ca3f8',
  20,
  '#7393f7',
  30,
  '#7d86f7',
  40,
  '#8877f7',
  50,
  '#956af7',
];

export const RainPrecipitationVisualizationScale = [
  1,
  '#65d1fb',
  5,
  '#64c0fa',
  10,
  '#66b3f9',
  15,
  '#6ca3f8',
  20,
  '#7393f7',
  30,
  '#7d86f7',
  40,
  '#8877f7',
  50,
  '#956af7',
];

const getTemperatureScale = (isMap: boolean): Array<string | number | null> => [
  -24,
  '#001088',
  isMap ? -20 : null,
  '#001ab9',
  -16,
  '#0026f5',
  isMap ? -12 : null,
  '#0549f5',
  -8,
  '#31c0f6',
  isMap ? -4 : null,
  '#51bbf9',
  0,
  '#73FCFD',
  isMap ? 4 : null,
  '#80fac3',
  isMap ? 6 : null,
  '#a1fb8f',
  8,
  '#cefb64',
  isMap ? 12 : null,
  '#fffd53',
  16,
  '#f6c042',
  isMap ? 20 : null,
  '#f08533',
  24,
  '#ec4e27',
  isMap ? 28 : null,
  '#eb3323',
  32,
  '#b12418',
];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TemperatureScale: Array<string | number> = getTemperatureScale(
  true,
);

export const TemperatureVisualizationScale: Array<
  string | number | null
> = getTemperatureScale(false);

export const WaveHeightScale = [
  0,
  '#74fbe9',
  0.5,
  '#51bbf9',
  1,
  '#195df6',
  2,
  '#0026f5',
  3,
  '#5c2af4',
  4,
  '#b637f6',
  6,
  '#ea3cd3',
  8,
  '#eb3577',
];

export const WavePeriodScale = [
  0,
  '#0026f5',
  3.5,
  '#3181f5',
  5,
  '#7ca3f8',
  6.5,
  '#7af94c',
  8,
  '#fffc54',
  9.5,
  '#fad94a',
  11,
  '#f4b23e',
  12.5,
  '#f08633',
  14,
  '#eb3223',
  15.5,
  '#5f1709',
  17,
  '#333333',
];

export const SeaTemperatureScale = [
  0,
  '#678dbe',
  10,
  '#508e3e',
  20,
  '#a69d37',
  30,
  '#e29837',
  40,
  '#a34421',
];

export const CurrentSpeedScale = [
  0,
  '#0003a9',
  1,
  '#2873f6',
  2,
  '#a1fb8f',
  3,
  '#f3a43b',
  4,
  '#941c12',
];

export const WhiteCappingScale = [
  0,
  '#3e0f55',
  0.001,
  '#414683',
  0.002,
  '#3f768b',
  0.003,
  '#50a587',
  0.004,
  '#8ccd64',
  0.005,
  '#f9ee55',
];

export const WindComparisonScale = [
  0,
  '#ffffff',
  2,
  '#fffd53',
  4,
  '#f6c042',
  6,
  '#f08533',
  8,
  '#ec4e27',
  10,
  '#eb3323',
  15,
  '#b103fc',
];

export const WindComparisonVisualizationScale = [
  0,
  '#ffffff',
  2,
  '#fffd53',
  4,
  '#f6c042',
  6,
  '#f08533',
  8,
  '#ec4e27',
  10,
  '#eb3323',
];
