import { ExclusionZone } from 'modules/exclusionZones/types';

export const getExclusionZoneLabel = (zones: ExclusionZone[]): string => {
  const disabledZonesLength = zones.filter(({ enabled }) => !enabled).length;
  const enabledZonesLength = zones.length - disabledZonesLength;
  const numberOfZones = enabledZonesLength
    ? `${enabledZonesLength} zone${enabledZonesLength > 1 ? 's' : ''} active`
    : '';
  const numberOfDisabledZones = `${
    numberOfZones ? `, with ${disabledZonesLength}` : `${disabledZonesLength}`
  } disabled zone${disabledZonesLength > 1 ? 's' : ''} ignored`;
  return `Avoid all exclusion zones (${numberOfZones}${
    disabledZonesLength ? numberOfDisabledZones : ''
  })`;
};
