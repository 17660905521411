import React from 'react';

import { Modal, Button, ModalFooter } from 'modules/ui/components';
import { RegularBase } from 'modules/ui/typography';
import { useDeleteInclusionZone } from '../../hooks';
import { InclusionZone } from '../../types';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  inclusionZone: InclusionZone;
  onDelete: () => void;
};

const DeleteInclusionZoneModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  inclusionZone,
  onDelete,
}) => {
  const { handleDelete, loading } = useDeleteInclusionZone(
    onDelete,
    inclusionZone,
  );
  return (
    <Modal
      isOpen={isOpen}
      width={600}
      title="Delete inclusion zone"
      closeModal={closeModal}
      onRequestClose={closeModal}
    >
      <>
        <div style={{ padding: 16 }}>
          <RegularBase>
            {`Are you sure you want to delete the inclusion zone "${inclusionZone.name}" ?`}
          </RegularBase>
        </div>
        <ModalFooter>
          <Button isSecondary label="Cancel" clickHandler={closeModal} />
          <Button
            label="Delete"
            isLoading={loading}
            clickHandler={handleDelete}
          />
        </ModalFooter>
      </>
    </Modal>
  );
};

export default React.memo(DeleteInclusionZoneModal);
