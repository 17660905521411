const baseUrl = 'https://gea.arso.gov.si/vg2020-dev/';
// const baseUrl = 'https://vg2020.palmadecocco.si/';

const LocationModuleUrl = `${baseUrl}location/`;
export const VisdataModuleUrl = `${baseUrl}visdata/`;
const FleetModuleUrl = `${baseUrl}fleet/`;

export const LocationApiModule = {
  getCurrentLocation: `${LocationModuleUrl}getLoc`,
};

export const FleetApiModule = {
  getFleet: `${FleetModuleUrl}getFleet`,
};

export const meteoModuleBase = '/v1/meteo';

export const meteoModule = {
  Metadata: `${meteoModuleBase}/metadata`,
  Meteogram: `${meteoModuleBase}/meteogram`,
};

export const RoutingModuleBase = '/v1/routings';

export const RoutingModule = {
  Base: RoutingModuleBase,
  List: `${RoutingModuleBase}/list`,
};
