import React from 'react';
import { Link } from 'react-router-dom';

import { H2 } from 'modules/ui/typography';
import { Routes } from 'Routes';
import { AuthImageContainer } from '../../components';

const RegistrationSuccessUi: React.FC = () => {
  return (
    <AuthImageContainer>
      <H2>
        You have successfully register for a Dorado account.
        <br /> Please check your email inbox. Or{' '}
        <Link to={Routes.Login}>go to Login</Link>
      </H2>
    </AuthImageContainer>
  );
};

export const RegistrationSuccess = React.memo(RegistrationSuccessUi);
