/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fullMeteoEcmwfSourceSelector } from 'modules/meteogram/redux/selectors';
import { ModelLayersSources } from 'modules/mapLayers/consts';

type Props = {
  map: any;
};

const FullMeteogramInPointData: React.FC<Props> = ({ map }) => {
  const geoJson = useSelector(fullMeteoEcmwfSourceSelector);

  useEffect(() => {
    map.getSource(ModelLayersSources.MeteogramPoint).setData(geoJson);
  }, [map, geoJson]);

  return <></>;
};

export default React.memo(FullMeteogramInPointData);
