import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteModelDataSet } from 'modules/meteo/redux/thunks';
import {
  ecmwfDataSetsForDeleteSelector,
  gfsDataSetsForDeleteSelector,
  nemoDataSetsForDeleteSelector,
  wamDataSetsForDeleteSelector,
  gfsWamDataSetsForDeleteSelector,
} from '../../redux/selectors';
import { MeteoSources } from '../../consts';

const MeteoDataCleaner: React.FC = () => {
  const dispatch = useDispatch();
  const ecmfwDeleteData = useSelector(ecmwfDataSetsForDeleteSelector);
  const gfsDeleteData = useSelector(gfsDataSetsForDeleteSelector);
  const wamDeleteData = useSelector(wamDataSetsForDeleteSelector);
  const gfsWamDeleteData = useSelector(gfsWamDataSetsForDeleteSelector);
  const nemoDeleteData = useSelector(nemoDataSetsForDeleteSelector);
  const handleDeleteData = useCallback(
    async (id: string, type: MeteoSources) => {
      dispatch(deleteModelDataSet(id, type));
    },
    [dispatch],
  );
  useEffect(() => {
    if (ecmfwDeleteData[0]) {
      handleDeleteData(ecmfwDeleteData[0].id, MeteoSources.ECMWF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecmfwDeleteData[0]?.id]);
  useEffect(() => {
    if (gfsDeleteData[0]) {
      handleDeleteData(gfsDeleteData[0].id, MeteoSources.GFS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gfsDeleteData[0]?.id]);
  useEffect(() => {
    if (wamDeleteData[0]) {
      handleDeleteData(wamDeleteData[0].id, MeteoSources.ECMWFWAM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wamDeleteData[0]?.id]);
  useEffect(() => {
    if (gfsWamDeleteData[0]) {
      handleDeleteData(gfsWamDeleteData[0].id, MeteoSources.GFSWAM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gfsWamDeleteData[0]?.id]);
  useEffect(() => {
    if (nemoDeleteData[0]) {
      handleDeleteData(nemoDeleteData[0].id, MeteoSources.NEMO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nemoDeleteData[0]?.id]);

  return null;
};

export default React.memo(MeteoDataCleaner);
