import { meteoModule, meteoModuleBase } from 'modules/api';
import { MeteoForm } from './types';

export const SpatialResolution = {
  '0.25': 0.25,
  '0.5': 0.5,
};

export const SpatialResolutionOptions = [
  { value: SpatialResolution['0.25'], label: '0.25°' },
  { value: SpatialResolution['0.5'], label: '0.5°' },
];

export const TimeResolution = {
  high: 1,
  medium: 2,
  low: 3,
  superlow: 4,
};

export const TimeResolutionOptions = [
  { value: TimeResolution.high, label: 'High (1h / 3h / 6h)' },
  { value: TimeResolution.medium, label: 'Medium (3h / 3h / 6h)' },
  { value: TimeResolution.low, label: 'Low (3h / 6h / 12h)' },
  { value: TimeResolution.superlow, label: 'Superlow (12h / 12h / 12h)' },
];

const generateResolutionHours = (
  length: number,
  step: number,
  offset: number,
): number[] => Array.from({ length }, (_, i) => i * step + offset);

export const TimeResolutionHourDistance = {
  [TimeResolution.high]: [
    ...generateResolutionHours((24 / 1) * 2, 1, 1),
    ...generateResolutionHours((24 / 3) * 3, 3, 24 * 2 + 3),
    ...generateResolutionHours((24 / 6) * 5, 6, 24 * 5 + 6),
  ],
  [TimeResolution.medium]: [
    ...generateResolutionHours((24 / 3) * 2, 3, 3),
    ...generateResolutionHours((24 / 3) * 3, 3, 24 * 2 + 3),
    ...generateResolutionHours((24 / 6) * 5, 6, 24 * 5 + 6),
  ],
  [TimeResolution.low]: [
    ...generateResolutionHours((24 / 3) * 2, 3, 0 + 3),
    ...generateResolutionHours((24 / 6) * 3, 6, 24 * 2 + 6),
    ...generateResolutionHours((24 / 12) * 5, 12, 24 * 5 + 12),
  ],
  [TimeResolution.superlow]: [
    ...generateResolutionHours((24 / 12) * 2, 12, 0 + 12),
    ...generateResolutionHours((24 / 12) * 3, 12, 24 * 2 + 12),
    ...generateResolutionHours((24 / 12) * 5, 12, 24 * 5 + 12),
  ],
};

export const meteoFormInitialValues: MeteoForm = {
  gfs: {
    wind: {
      checked: false,
      time: 10,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    weather: {
      checked: false,
      time: 10,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    MSLP: {
      checked: false,
      time: 10,
      resolution: TimeResolution.medium,
      spatial: SpatialResolution['0.25'],
    },
  },
  ecmwf: {
    wind: {
      checked: false,
      time: 10,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    weather: {
      checked: false,
      time: 10,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    MSLP: {
      checked: false,
      time: 10,
      resolution: TimeResolution.medium,
      spatial: SpatialResolution['0.25'],
    },
  },
  ecmwfwam: {
    swellWaves: {
      checked: false,
      time: 10,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    significantWaves: {
      checked: false,
      time: 10,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    windWaves: {
      checked: false,
      time: 10,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
  },
  gfswam: {
    swellWaves: {
      checked: false,
      time: 7,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    significantWaves: {
      checked: false,
      time: 7,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
    windWaves: {
      checked: false,
      time: 7,
      resolution: TimeResolution.high,
      spatial: SpatialResolution['0.25'],
    },
  },
  nemo: {
    checked: false,
    time: 10,
    resolution: TimeResolution.high,
    spatial: SpatialResolution['0.25'],
  },
};

export enum MeteoProviders {
  ECMWF = 'ecmwf',
  NCEP = 'ncep',
  Mercator = 'mercator',
}

export enum MeteoSources {
  GFS = 'gfs',
  ECMWF = 'ecmwf',
  ECMWFWAM = 'ecmwfwam',
  GFSWAM = 'gfswam',
  NEMO = 'nemo',
}

export enum MeteoDataTypes {
  Wind = 'wind',
  Meteo = 'weather',
  Wwave = 'wwave',
  Swave = 'swave',
  Wave = 'wave',
  Mslp = 'mslp',
  Ocean = 'ocean',
  WindComparison = 'windComparison',
  LatestMetaData = 'latestMetaData',
}

export const meteoGribFiles: { [key in MeteoSources]: string } = {
  [MeteoSources.ECMWF]: 'latest-ecmwf',
  [MeteoSources.GFS]: 'latest-ncep',
  [MeteoSources.ECMWFWAM]: 'latest-ecmwf',
  [MeteoSources.GFSWAM]: 'latest-ncep',
  [MeteoSources.NEMO]: 'latest-mercator',
};

export const generateDownloadLink = (
  type: MeteoDataTypes,
  provider: MeteoSources,
  time: number,
  maxLat: number,
  maxLng: number,
  minLat: number,
  minLng: number,
  spatial: number,
): string =>
  `${meteoModuleBase}/${type}?provider=${provider}&timeStep=${time}&maxLat=${maxLat}&maxLon=${maxLng}&minLat=${minLat}&minLon=${minLng}&spatialResolution=${spatial}`;

export const generateLastGribMetadataLink = (
  model: MeteoProviders,
  gribType: MeteoDataTypes,
): string => `${meteoModule.Metadata}?model=${model}&type=${gribType}`;
