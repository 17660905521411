import { createSelector } from '@reduxjs/toolkit';

import { RootState, Storage } from 'modules/main/redux';

import { Boat } from '../types';

const boatsStorage = (state: RootState): Storage<Boat> => state.boats.storage;

const hasLoaded = (state: RootState): boolean => !!state.boats.hasLoaded;

const boatsList = createSelector([boatsStorage], (storage) =>
  Object.values(storage),
);

const firstBoat = createSelector([boatsList], (list) => list[0]);

const hasBoat = createSelector([firstBoat], (boat) => !!boat);

export const BoatsSelectors = {
  boatsStorage,
  hasLoaded,
  boatsList,
  firstBoat,
  hasBoat,
};
