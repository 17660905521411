import {
  configureStore,
  ThunkAction as LibThunkAction,
  Action,
  EnhancedStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistStore,
  Persistor,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { downloadReducer } from 'modules/downloads/redux';
import { reducer as RoutingReducer } from 'modules/routing';
import { mapLayersReducer } from 'modules/mapLayers/redux';
import { settingsReducer } from 'modules/settings';
import { meteoReducer } from 'modules/meteo';
import { reducer as meteogramReducer } from 'modules/meteogram/redux/reducer';
import { metadataReducer } from 'modules/metadata';
import { authReducer } from 'modules/auth';
import { notificationReducer } from 'modules/notifications';
import { locationReducer } from 'modules/location';
import { fleetReducer } from 'modules/fleet';
import { boatsReducer } from 'modules/boats';
import { routesReducer } from 'modules/route';
import { exclusionZonesReducer } from 'modules/exclusionZones';
import { inclusionZonesReducer } from 'modules/inclusionZones';
import { clearUserSpecificStores } from 'modules/localForage/db';

export type ThunkAction<R> = LibThunkAction<
  Promise<R>,
  RootState,
  null,
  Action
>;

export type Storage<T> = { [key: number]: T };

const appReducer = combineReducers({
  boats: boatsReducer,
  download: downloadReducer,
  routing: RoutingReducer,
  mapLayers: mapLayersReducer,
  settings: settingsReducer,
  meteo: meteoReducer,
  meteogram: meteogramReducer,
  metadata: metadataReducer,
  auth: authReducer,
  notifications: notificationReducer,
  location: locationReducer,
  fleet: fleetReducer,
  routes: routesReducer,
  exclusionZones: exclusionZonesReducer,
  inclusionZones: inclusionZonesReducer,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT_USER') {
    clearUserSpecificStores();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default (): {
  persistor: Persistor;
  store: EnhancedStore<RootState>;
} => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });
  const persistor = persistStore(store);
  return { store, persistor };
};
