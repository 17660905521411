import {
  FillPaint,
  LinePaint,
  Expression,
  SymbolLayout,
  LineLayout,
  CirclePaint,
  SymbolPaint,
} from 'mapbox-gl';

import { MapColors } from 'modules/map/colors';
import { Colors } from 'modules/ui/theme/colors';
import {
  CloudCoverScale,
  RainPrecipitationScale,
  TemperatureScale,
  WindSpeedScale,
  WaveHeightScale,
  WavePeriodScale,
  SeaTemperatureScale,
  CurrentSpeedScale,
  WhiteCappingScale,
  WindComparisonScale,
} from 'modules/scales';
import { RoutingColors } from './consts';

const FillOpacity = 0.5;

const polarSpreadColorInterpolation = (color: string): Expression => [
  'interpolate',
  ['linear'],
  ['get', 'polarSpread'],
  40,
  '#FFFFFF',
  100,
  color,
  160,
  '#000000',
];

const optimalRouteColorInterpolation = (color: string): Expression => [
  'let',
  'gradient',
  ['case', ['has', 'gradient'], ['get', 'gradient'], 0],
  [
    'interpolate',
    ['linear'],
    ['var', 'gradient'],
    -50,
    '#FFFFFF',
    0,
    color,
    50,
    '#000000',
  ],
];

const OptimalRouteDashedLine = [20, 10];

export const windSpeedFillColor = (property: string): Expression => [
  'interpolate',
  ['linear'],
  ['get', property],
  ...WindSpeedScale,
];

export const WindFillPaint: FillPaint = {
  'fill-antialias': true,
  'fill-color': windSpeedFillColor('ws'),
  'fill-opacity': FillOpacity,
};

export const GustsFillPaint: FillPaint = {
  'fill-antialias': true,
  'fill-color': windSpeedFillColor('g'),
  'fill-opacity': FillOpacity,
};

export const WindSpeedDiffFillPaint: FillPaint = {
  'fill-antialias': true,
  'fill-color': [
    'interpolate',
    ['linear'],
    ['get', 'sDiff'],
    ...WindComparisonScale,
  ],
  'fill-opacity': FillOpacity,
};

export const TemperatureFillPaint: FillPaint = {
  'fill-color': ['interpolate', ['linear'], ['get', 't'], ...TemperatureScale],
  'fill-opacity': FillOpacity,
};

export const SeaTemperatureFillPaint: FillPaint = {
  'fill-color': [
    'interpolate',
    ['linear'],
    ['get', 't'],
    ...SeaTemperatureScale,
  ],
  'fill-opacity': FillOpacity,
};

export const CloudFillPaint: FillPaint = {
  'fill-color': ['interpolate', ['linear'], ['get', 'c'], ...CloudCoverScale],
  'fill-opacity': FillOpacity,
};

export const RainFillPaint: FillPaint = {
  'fill-color': [
    'interpolate',
    ['linear'],
    ['get', 'r'],
    ...RainPrecipitationScale,
  ],
  'fill-opacity': FillOpacity,
};

export const WaveFillPaint: FillPaint = {
  'fill-color': ['interpolate', ['linear'], ['get', 'h'], ...WaveHeightScale],
  'fill-opacity': FillOpacity,
};

export const LineWidthInterpolation: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  0.5,
  10,
  5,
];

export const CircleRadiusInterpolation: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  3,
  3,
  10,
  8,
];

export const SmallCircleRadiusInterpolation: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  3,
  2,
  10,
  5,
];

export const RouteLineWidthInterpolation: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  2,
  10,
  5,
];

export const EnsembleRouteLineWidthInterpolation: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  0.8,
  10,
  2,
];

export const MslpLinePaint: LinePaint = {
  'line-color': '#000000',
  'line-width': LineWidthInterpolation,
};

export const MslpLineLayout: LineLayout = {
  'line-cap': 'round',
  'line-join': 'round',
};

const knotsToMetersPerSecond = (value: number): number => value / 1.944;

const WindBarbIconImage = (property: string, isKnots?: boolean): Expression => [
  'case',
  ['<', ['get', property], isKnots ? 1 : knotsToMetersPerSecond(1)],
  ['image', 'calm'],
  ['<', ['get', property], isKnots ? 2.5 : knotsToMetersPerSecond(2.5)],
  ['image', '1knots'],
  ['<', ['get', property], isKnots ? 7.5 : knotsToMetersPerSecond(7.5)],
  ['image', '5knots'],
  ['<', ['get', property], isKnots ? 12.5 : knotsToMetersPerSecond(12.5)],
  ['image', '10knots'],
  ['<', ['get', property], isKnots ? 17.5 : knotsToMetersPerSecond(17.5)],
  ['image', '15knots'],
  ['<', ['get', property], isKnots ? 22.5 : knotsToMetersPerSecond(22.5)],
  ['image', '20knots'],
  ['<', ['get', property], isKnots ? 27.5 : knotsToMetersPerSecond(27.5)],
  ['image', '25knots'],
  ['<', ['get', property], isKnots ? 32.5 : knotsToMetersPerSecond(32.5)],
  ['image', '30knots'],
  ['<', ['get', property], isKnots ? 37.5 : knotsToMetersPerSecond(37.5)],
  ['image', '35knots'],
  ['<', ['get', property], isKnots ? 42.5 : knotsToMetersPerSecond(42.5)],
  ['image', '40knots'],
  ['<', ['get', property], isKnots ? 47.5 : knotsToMetersPerSecond(47.5)],
  ['image', '45knots'],
  ['<', ['get', property], isKnots ? 52.5 : knotsToMetersPerSecond(52.5)],
  ['image', '50knots'],
  ['<', ['get', property], isKnots ? 57.5 : knotsToMetersPerSecond(57.5)],
  ['image', '55knots'],
  ['<', ['get', property], isKnots ? 62.5 : knotsToMetersPerSecond(62.5)],
  ['image', '60knots'],
  ['<', ['get', property], isKnots ? 67.5 : knotsToMetersPerSecond(67.5)],
  ['image', '65knots'],
  ['<', ['get', property], isKnots ? 72.5 : knotsToMetersPerSecond(72.5)],
  ['image', '70knots'],
  ['<', ['get', property], isKnots ? 77.5 : knotsToMetersPerSecond(77.5)],
  ['image', '75knots'],
  ['<', ['get', property], isKnots ? 82.5 : knotsToMetersPerSecond(82.5)],
  ['image', '80knots'],
  ['<', ['get', property], isKnots ? 87.5 : knotsToMetersPerSecond(87.5)],
  ['image', '85knots'],
  ['<', ['get', property], isKnots ? 92.5 : knotsToMetersPerSecond(92.5)],
  ['image', '90knots'],
  ['<', ['get', property], isKnots ? 97.5 : knotsToMetersPerSecond(97.5)],
  ['image', '95knots'],
  ['<', ['get', property], isKnots ? 102.5 : knotsToMetersPerSecond(102.5)],
  ['image', '100knots'],
  ['<', ['get', property], isKnots ? 107.5 : knotsToMetersPerSecond(107.5)],
  ['image', '105knots'],
  ['<', ['get', property], isKnots ? 112.5 : knotsToMetersPerSecond(122.5)],
  ['image', '110knots'],
  ['<', ['get', property], isKnots ? 117.5 : knotsToMetersPerSecond(117.5)],
  ['image', '115knots'],
  ['<', ['get', property], isKnots ? 122.5 : knotsToMetersPerSecond(122.5)],
  ['image', '120knots'],
  ['<', ['get', property], isKnots ? 127.5 : knotsToMetersPerSecond(127.5)],
  ['image', '125knots'],
  ['<', ['get', property], isKnots ? 132.5 : knotsToMetersPerSecond(132.5)],
  ['image', '130knots'],
  ['<', ['get', property], isKnots ? 137.5 : knotsToMetersPerSecond(137.5)],
  ['image', '135knots'],
  ['<', ['get', property], isKnots ? 142.5 : knotsToMetersPerSecond(142.5)],
  ['image', '140knots'],
  ['<', ['get', property], isKnots ? 147.5 : knotsToMetersPerSecond(147.5)],
  ['image', '145knots'],
  ['image', '150knots'],
];

export const windBarbsLayout = (
  speedPropertyName: string,
  directionPropertyName: string,
  isKnots?: boolean,
): SymbolLayout => ({
  'icon-image': WindBarbIconImage(speedPropertyName, isKnots),
  'icon-rotate': [
    'interpolate',
    ['linear'],
    ['get', directionPropertyName],
    0,
    -90,
    360,
    270,
  ],
  'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.4, 8, 1],
});

export const MeteoWindBarbLayout = windBarbsLayout('ws', 'wd', true);

export const RoutingWindBarbLayout: SymbolLayout = {
  ...windBarbsLayout('TWS', 'TWD', true),
  'icon-offset': [13, 3],
  'icon-allow-overlap': true,
  'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.8, 8, 1],
};

export const GustsBarbsLayout: SymbolLayout = {
  'icon-image': WindBarbIconImage('g', true),
  'icon-rotate': ['interpolate', ['linear'], ['get', 'wd'], 0, -90, 360, 270],
  'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.4, 8, 1],
};

export const MeteogramLayout: SymbolLayout = {
  'icon-image': 'meteogram',
  'icon-size': 0.7,
};

export const waveDirectionLayout = (imageName: string): SymbolLayout => ({
  'icon-image': imageName,
  'icon-rotate': ['interpolate', ['linear'], ['get', 'd'], 0, 0, 360, 360],
  'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.4, 8, 1],
  'icon-anchor': 'top',
  'icon-allow-overlap': true,
});

export const WaveDirectionLayout = waveDirectionLayout('arrow');

export const WaveDirectionBlueLayout = waveDirectionLayout('blueArrow');

export const WavePeriodFillPaint: FillPaint = {
  'fill-color': ['interpolate', ['linear'], ['get', 'p'], ...WavePeriodScale],
  'fill-opacity': FillOpacity,
};

export const CurrentSpeedPaint: FillPaint = {
  'fill-color': ['interpolate', ['linear'], ['get', 's'], ...CurrentSpeedScale],
  'fill-opacity': FillOpacity,
};

export const WhiteCappingPaint: FillPaint = {
  'fill-color': ['interpolate', ['linear'], ['get', 'c'], ...WhiteCappingScale],
  'fill-opacity': FillOpacity,
};

export const optimalRoutePaint = (
  color: string,
  isSmall?: boolean,
): LinePaint => ({
  'line-color': optimalRouteColorInterpolation(color),
  'line-opacity': isSmall ? 0.8 : 1,
  'line-width': [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    4,
    ['has', 'name'],
    1,
    isSmall ? 1 : 2,
    // ['to-number', EnsembleRouteLineWidthInterpolation],
    // ['to-number', RouteLineWidthInterpolation],
  ],
});

export const dashedOptimalRoutePaint = (
  color: string,
  isSmall?: boolean,
): LinePaint => ({
  ...optimalRoutePaint(color, isSmall),
  'line-dasharray': OptimalRouteDashedLine,
});

export const polarSpreadOptimalRoutePaint = (
  color: string,
  isSmall?: boolean,
): LinePaint => ({
  ...optimalRoutePaint(color, isSmall),
  'line-color': polarSpreadColorInterpolation(color),
});

export const timedOptimalRoutePaint = (
  color: string,
  isSmall?: boolean,
): CirclePaint => ({
  'circle-color': color,
  'circle-opacity': isSmall
    ? 0.8
    : [
        'case',
        ['has', 'isEnsemble'],
        ['case', ['boolean', ['feature-state', 'active'], false], 1, 0],
        1,
      ],
  'circle-stroke-color': 'black',
  'circle-stroke-opacity': [
    'case',
    ['has', 'isEnsemble'],
    ['case', ['boolean', ['feature-state', 'active'], false], 1, 0],
    1,
  ],
  'circle-stroke-width': [
    'case',
    ['==', ['get', 'isMidnight'], true],
    isSmall ? 1 : 3,
    0,
  ],
  'circle-radius': isSmall
    ? SmallCircleRadiusInterpolation
    : CircleRadiusInterpolation,
});

export const polarSpreadTimedOptimalRoutePaint = (
  color: string,
  isSmall?: boolean,
): CirclePaint => ({
  ...timedOptimalRoutePaint(color, isSmall),
  'circle-color': polarSpreadColorInterpolation(color),
});

export const IzochronesPaint: LinePaint = {
  'line-color': '#808391',
  'line-width': LineWidthInterpolation,
  'line-opacity': [
    'case',
    ['boolean', ['get', 'display'], false],
    1,
    ['boolean', ['feature-state', 'active'], false],
    1,
    0,
  ],
};

export const GraticulesLinePaint: LinePaint = {
  'line-color': '#D9D9D9',
  'line-width': LineWidthInterpolation,
};

export const LandFillPaint: FillPaint = {
  'fill-color': MapColors.LightLand,
};

export const OceanFillPaint: FillPaint = {
  'fill-color': MapColors.LightOcean,
};

export const CurrentBoatLocationSymbol: SymbolLayout = {
  'icon-image': 'boat',
  'icon-rotate': [
    'interpolate',
    ['linear'],
    ['get', 'heading'],
    0,
    -180,
    360,
    180,
  ],
  'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.4, 8, 0.8],
};

export const RoutingBoatLocationSymbol: SymbolLayout = {
  'icon-image': 'ghostBoat',
  'icon-rotate': ['interpolate', ['linear'], ['get', 'COG'], 0, -180, 360, 180],
  'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.4, 8, 0.8],
  'icon-allow-overlap': true,
};

export const IceZoneLineWidthInterpolation: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  1,
  10,
  2,
];

export const UserZonesLineWidthInterpolation: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  ['case', ['boolean', ['feature-state', 'clicked'], false], 3, 1],
  10,
  ['case', ['boolean', ['feature-state', 'clicked'], false], 6, 2],
];

export const ExclusionZoneLine: LinePaint = {
  'line-color': Colors.Destructive,
  'line-width': UserZonesLineWidthInterpolation,
  'line-opacity': ['case', ['boolean', ['get', 'enabled']], 1, 0.3],
};

export const InclusionZoneLine: LinePaint = {
  'line-color': Colors.PrimaryLight,
  'line-width': UserZonesLineWidthInterpolation,
  'line-opacity': ['case', ['boolean', ['get', 'enabled']], 1, 0.3],
};

export const IceZoneLine: LinePaint = {
  ...ExclusionZoneLine,
  'line-dasharray': [2, 2.5],
};

export const ExclusionZoneFill: FillPaint = {
  'fill-color': Colors.Destructive,
  'fill-opacity': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    0.3,
    ['boolean', ['get', 'enabled']],
    0.2,
    0.1,
  ],
};

export const InclusionZoneFill: FillPaint = {
  'fill-color': Colors.PrimaryLight,
  'fill-opacity': 0.2,
};

export const ExclusionZoneCreationFill: FillPaint = {
  'fill-color': Colors.Destructive,
  'fill-opacity': [
    'case',
    ['boolean', ['feature-state', 'clicked'], false],
    0.3,
    0.1,
  ],
};

export const CoastlineLinePaint: LinePaint = {
  'line-color': Colors.SolidGray,
};

const RouteColor: Expression = [
  'let',
  'colorIndex',
  ['%', ['get', 'rank'], 7],
  [
    'case',
    ['==', ['var', 'colorIndex'], 0],
    ['to-color', RoutingColors[0]],
    ['==', ['var', 'colorIndex'], 1],
    ['to-color', RoutingColors[1]],
    ['==', ['var', 'colorIndex'], 2],
    ['to-color', RoutingColors[2]],
    ['==', ['var', 'colorIndex'], 3],
    ['to-color', RoutingColors[3]],
    ['==', ['var', 'colorIndex'], 4],
    ['to-color', RoutingColors[4]],
    ['==', ['var', 'colorIndex'], 5],
    ['to-color', RoutingColors[5]],
    ['to-color', RoutingColors[6]],
  ],
];

export const FleetBoatSymbolLayout: SymbolLayout = {
  'icon-image': 'boat',
  'icon-rotate': [
    'interpolate',
    ['linear'],
    ['get', 'heading'],
    0,
    -180,
    360,
    180,
  ],
  'icon-allow-overlap': true,
  'icon-size': 0.3,
  visibility: 'none',
};

export const FleetBoatSymbolPaint: SymbolPaint = {
  'icon-color': RouteColor,
};

export const FleetRouteLine: LinePaint = {
  'line-color': RouteColor,
  'line-width': 1,
};

export const WindSpeedDiffEcmwfBarbLayout: SymbolLayout = {
  ...windBarbsLayout('eSpeed', 'eDir', true),
  'icon-anchor': 'top-left',
  'icon-allow-overlap': true,
};

export const WindSpeedDiffGfsBarbLayout: SymbolLayout = {
  ...windBarbsLayout('gSpeed', 'gDir', true),
  'icon-anchor': 'top-left',
  'icon-allow-overlap': true,
};
