import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: flex;
  height: 28px;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.White}CC;
  flex-direction: row-reverse;
  left: ${({ isOpen }): string => (isOpen ? '-154px' : '4px')};
  width: ${({ isOpen }): string => (isOpen ? '150px' : '0px')};
  transition: width 500ms ease-in-out, left 500ms ease-in-out;
  overflow: hidden;
  > button:not(:last-child) {
    margin-left: 8px;
  }
  > button:first-child {
    margin-right: 2px;
  }
  > button:first-child {
    margin-left: 2px;
  }
  > button:nth-child(1) {
    svg > path {
      fill: ${Colors.Destructive};
    }
  }
  > button:nth-child(2) {
    svg > path {
      fill: ${Colors.Destructive};
    }
  }

  > button:nth-child(3) {
    svg > path {
      fill: ${Colors.PrimaryLight};
    }
  }
  > button:nth-child(4) {
    svg > path {
      fill: ${Colors.PrimaryLight};
    }
  }
`;

export const VisibleFlows = styled.div`
  visibility: visible;
`;
