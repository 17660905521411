import { useCallback, useEffect } from 'react';
import { Map } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import { featureCollection, feature } from '@turf/helpers';

import { useToggle } from 'modules/main';
import { ZoneLayers, ZoneLayersSources } from 'modules/mapLayers';
import { InclusionZonesSelectors } from '../redux';

export const useDisplayInclusionZones = (
  map: Map,
): {
  toggleInclusionZoneVisibility: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isInclusionZoneVisibilityOn: boolean;
  hideInclusionZones: () => void;
  showInclusionZones: () => void;
} => {
  const inclusionZones = useSelector(
    InclusionZonesSelectors.inclusionZonesList,
  );

  useEffect(() => {
    const inclusionZonesFeatureCollection = featureCollection(
      inclusionZones.map(({ inclusionZone, id, name }) =>
        feature(inclusionZone.geometry, {
          ...inclusionZone.properties,
          id,
          name,
        }),
      ),
    );

    map
      .getSource(ZoneLayersSources.InclusionZones)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .setData(inclusionZonesFeatureCollection);
  }, [map, inclusionZones]);

  const [isInclusionZoneVisibilityOn, , setToggleValue] = useToggle();

  const hideInclusionZones = useCallback(() => {
    map.setLayoutProperty(ZoneLayers.InclusionZones, 'visibility', 'none');
    map.setLayoutProperty(
      `${ZoneLayers.InclusionZones}Outline`,
      'visibility',
      'none',
    );
    setToggleValue(false);
  }, [map, setToggleValue]);

  const showInclusionZones = useCallback(() => {
    map.setLayoutProperty(ZoneLayers.InclusionZones, 'visibility', 'visible');
    map.setLayoutProperty(
      `${ZoneLayers.InclusionZones}Outline`,
      'visibility',
      'visible',
    );
    setToggleValue(true);
  }, [map, setToggleValue]);

  const toggleInclusionZoneVisibility = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      const visibility = map.getLayoutProperty(
        ZoneLayers.InclusionZones,
        'visibility',
      );
      if (visibility === 'visible') {
        hideInclusionZones();
      } else {
        showInclusionZones();
      }
    },
    [map, showInclusionZones, hideInclusionZones],
  );

  return {
    toggleInclusionZoneVisibility,
    isInclusionZoneVisibilityOn,
    hideInclusionZones,
    showInclusionZones,
  };
};
