import React from 'react';

import {
  Container,
  ContentContainer,
  ImageContainer,
} from './styledComponents';

type Props = {
  children: React.ReactNode;
};

const AuthImageContainerUi: React.FC<Props> = ({ children }) => (
  <Container>
    <ContentContainer>{children}</ContentContainer>
    <ImageContainer />
  </Container>
);

export const AuthImageContainer = React.memo(AuthImageContainerUi);
