import { createAction } from '@reduxjs/toolkit';

import { TimestampedLocation } from '../types';

const addLocationToHistory = createAction<TimestampedLocation>(
  'ADD_LOCATION_TO_LIST',
);

export const LocationActions = {
  addLocationToHistory,
};
