import React, { memo, useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import { Button, Modal, ModalFooter, TextInput } from 'modules/ui/components';
import { H2 } from 'modules/ui/typography';
import { validateRequiredString } from 'modules/form';
import { InputRoute, NewRouteForm, Route } from '../../types';

import { ContentRow, FormContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  loading: boolean;
  handleUpdateOrCreate: (values: NewRouteForm) => Promise<void>;
  getRoute: () => InputRoute;
  initialRoute?: Route;
};

const RouteCreationModalContent: React.FC<Props> = ({
  closeModal,
  loading,
  getRoute,
  handleUpdateOrCreate,
  initialRoute,
}) => {
  const initialValues = useMemo(
    () => ({
      ...(initialRoute || { name: '' }),
      route: getRoute(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <Form
      onSubmit={handleUpdateOrCreate}
      initialValues={initialValues}
      render={({ handleSubmit, invalid }): React.ReactNode => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <H2>Please enter the name for your route:</H2>
            <ContentRow>
              <Field
                name="name"
                type="text"
                component={TextInput}
                validate={validateRequiredString}
                width={500}
                autoFocus
              />
            </ContentRow>
          </FormContainer>
          <ModalFooter>
            <Button isSecondary label="Cancel" clickHandler={closeModal} />
            <Button
              isLoading={loading}
              disabled={invalid || loading}
              label="Save"
              clickHandler={handleSubmit}
            />
          </ModalFooter>
        </form>
      )}
    />
  );
};

const RouteCreationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  ...rest
}) => (
  <Modal
    isOpen={isOpen}
    width={712}
    title={rest.initialRoute ? 'Editing route' : 'New route'}
    closeModal={closeModal}
    onRequestClose={closeModal}
  >
    <RouteCreationModalContent
      {...rest}
      closeModal={closeModal}
      isOpen={isOpen}
    />
  </Modal>
);

export default memo(RouteCreationModal);
