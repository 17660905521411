import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { RegularSm } from 'modules/ui/typography';

import { RadioButtonContainer } from './styledComponents';

type Props = {
  label?: string;
  onChange: (value: string) => void;
  isSecondary?: boolean;
  value: string;
  isChecked: boolean;
};

const RadioButton: React.FC<Props> = ({
  label,
  onChange,
  isSecondary,
  value,
  isChecked,
}) => {
  const eventHandler = useCallback((): void => {
    return onChange(value);
  }, [onChange, value]);

  return (
    <RadioButtonContainer isSecondary={!!isSecondary}>
      <input onChange={eventHandler} checked={isChecked} type="radio" />
      <span className="checkmark" />
      {label && <RegularSm>{label}</RegularSm>}
    </RadioButtonContainer>
  );
};

type FormProps = {
  label: string;
  isSecondary?: boolean;
} & FieldRenderProps<number, HTMLElement>;

const FormRadioButton: React.FC<FormProps> = ({
  label,
  isSecondary,
  input,
}) => {
  return (
    <RadioButtonContainer isSecondary={!!isSecondary}>
      <input
        name={input.name}
        type="radio"
        value={input.value}
        checked={input.checked}
        onChange={input.onChange}
      />
      <span className="checkmark" />
      <RegularSm>{label}</RegularSm>
    </RadioButtonContainer>
  );
};

export default React.memo(RadioButton);
export { FormRadioButton };
