import { RootState } from 'modules/main/redux';
import { TimestampedLocation } from '../types';

export const lastLocationSelector = (state: RootState): TimestampedLocation =>
  state.location.history[0] || {};

export const currentCoordinatesSelector = (
  state: RootState,
): number[] | undefined => lastLocationSelector(state).coordinates;

const currentCoordinatesWithDefault = (state: RootState): number[] =>
  lastLocationSelector(state).coordinates || [-1.7946464, 46.4967547];

export const LocationSelectors = {
  lastLocation: lastLocationSelector,
  currentCoordinates: currentCoordinatesSelector,
  currentCoordinatesWithDefault,
};
