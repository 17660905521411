import { format, parseISO, differenceInMinutes } from 'date-fns';

export const formatIsoTime = (date: string): string => {
  try {
    return format(parseISO(date), 'dd-LL HH:mm');
  } catch (error) {
    return 'Invalid date';
  }
};

export const formatIsoUtcTime = (date: string): string => {
  try {
    return format(parseISO(date), "dd-LL HH:mm 'UTC'");
  } catch (error) {
    return 'Invalid date';
  }
};

export const getDistance = (
  dateFrom: string,
  dateTo: string,
  noMinutes?: boolean,
): string => {
  const minutes = differenceInMinutes(parseISO(dateTo), parseISO(dateFrom));
  const minutesDifference = minutes % 60;
  const hourDifference = Math.floor(minutes / 60) % 24;
  const daysDifference = Math.floor(minutes / (60 * 24));
  return `${daysDifference} ${
    daysDifference === 1 ? 'day' : 'days'
  } ${hourDifference} ${hourDifference === 1 ? 'hour' : 'hours'}${
    noMinutes
      ? ''
      : ` ${minutesDifference} ${
          minutesDifference === 1 ? 'minute' : 'minutes'
        }`
  }`;
};

export const getUtcDate = (): Date => {
  const date = new Date();
  const nowUtc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
  return new Date(nowUtc);
};

export const formatDateHoverInfo = (date: string): string => {
  try {
    return format(parseISO(date), 'dd/MM/yyyy HH:mm');
  } catch (error) {
    return 'Invalid date';
  }
};
