import { apiClient } from 'modules/apiClient';

import { MeteoModelMetadataApiResponse } from '../types';

export const getModelMetadataApi = async (
  link: string,
): Promise<MeteoModelMetadataApiResponse> => {
  try {
    const response = await apiClient.get(link);
    return response.data;
  } catch (error) {
    return {
      time: '',
      maxLat: 0,
      maxLon: 0,
      minLat: 0,
      minLon: 0,
    };
  }
};
