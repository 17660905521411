import { Map } from 'mapbox-gl';

import Calm from 'modules/ui/assets/images/calm.png';
import Meteogram from 'modules/ui/assets/images/meteogram.png';
import Arrow from 'modules/ui/assets/images/arrow.png';
import BlueArrow from 'modules/ui/assets/images/blueArrow.png';
import Boat from 'modules/ui/assets/images/boat.png';
import GhostBoat from 'modules/ui/assets/images/ghostBoat.png';
import Kts1Image from 'modules/ui/assets/images/1kts.png';

export const loadWindBarbImages = (map: Map): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.loadImage(Calm, (error, mapImage) => {
    if (error) return;
    map.addImage('calm', mapImage);
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.loadImage(Arrow, (error, mapImage) => {
    if (error) return;
    map.addImage('arrow', mapImage);
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.loadImage(BlueArrow, (error, mapImage) => {
    if (error) return;
    map.addImage('blueArrow', mapImage);
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.loadImage(Boat, (error, mapImage) => {
    if (error) return;
    map.addImage('boat', mapImage, { sdf: true });
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.loadImage(GhostBoat, (error, mapImage) => {
    if (error) return;
    map.addImage('ghostBoat', mapImage);
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.loadImage(Kts1Image, (error, mapImage) => {
    if (error) return;
    map.addImage('1knots', mapImage);
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  new Array(30)
    .fill(null)
    .map((_, index) => (index + 1) * 5)
    .forEach((speed) => {
      import(`modules/ui/assets/images/${speed}kts.png`).then(
        ({ default: imageUrl }) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map.loadImage(imageUrl, (error, mapImage) => {
            if (error) return;
            map.addImage(`${speed}knots`, mapImage);
          });
        },
      );
    });
};

export const loadMeteogramIcon = (map: Map): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.loadImage(Meteogram, (error, mapImage) => {
    if (error) return;
    map.addImage('meteogram', mapImage, {
      sdf: true,
    });
  });
};
