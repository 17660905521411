import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { activeDownloadsSelector } from 'modules/downloads/redux/selectors';

import { ModalEmptyMessage } from 'modules/ui/components';
import DownloadItem from '../DownloadItem';

type Props = {
  closeModal: () => void;
};

const ActiveDownloads: React.FC<Props> = ({ closeModal }) => {
  const activeDownloads = useSelector(activeDownloadsSelector);
  const [openModalActiveDownloadsCount] = useState(activeDownloads.length);
  useEffect(() => {
    if (openModalActiveDownloadsCount && !activeDownloads.length) {
      closeModal();
    }
  }, [openModalActiveDownloadsCount, activeDownloads.length, closeModal]);
  return (
    <>
      {!activeDownloads.length && (
        <ModalEmptyMessage
          title="No active downloads"
          subtitle="Any new download progress will appear here"
        />
      )}
      {activeDownloads.map((item) => (
        <DownloadItem item={item} key={item.name} />
      ))}
    </>
  );
};

export default React.memo(ActiveDownloads);
