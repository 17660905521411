import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, add, parseISO, differenceInHours } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { Map } from 'mapbox-gl';

import {
  setDataSetTimeOffset,
  firstDataSetsDateSelector,
  incrementDataSetTimeOffset,
  timeOffsetSelector,
} from 'modules/meteo';
import { showTimelineSelector } from 'modules/metadata';
import { PlayIcon, PauseIcon } from 'modules/ui/assets';
import { RoutingLayers } from 'modules/mapLayers/consts';
import {
  Container,
  Slider,
  DateSeparator,
  DateLabel,
  LabelContainer,
  CurrentTimeIndicator,
  TimeControlContainer,
  LabelContainerText,
} from './styledComponents';

type Props = {
  map: Map;
};

const Timeline: React.FC<Props> = ({ map }) => {
  const dispatch = useDispatch();
  const showTimeline = useSelector(showTimelineSelector);
  const timeOffset = useSelector(timeOffsetSelector);
  const [hoverValue, setHoverValue] = useState<number>(0);
  const [intervalRef, setIntervalRef] = useState<number | null>(null);
  const firstDayString = useSelector(firstDataSetsDateSelector);
  const firstDay = useMemo(
    () =>
      firstDayString
        ? parseISO(firstDayString)
        : new Date().setUTCHours(0, 0, 0),
    [firstDayString],
  );
  const currentTimeOffset = differenceInHours(new Date(), firstDay);
  useEffect(() => {
    dispatch(setDataSetTimeOffset(currentTimeOffset));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = useCallback(
    (value: number) => {
      dispatch(setDataSetTimeOffset(value));
    },
    [dispatch],
  );
  const incrementTimestamp = useCallback(() => {
    dispatch(incrementDataSetTimeOffset());
  }, [dispatch]);
  const onChangeDebounced = useCallback(debounce(handleChange, 300), [
    handleChange,
  ]);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeDebounced(e.currentTarget.valueAsNumber);
    },
    [onChangeDebounced],
  );
  const handlePlay = useCallback(() => {
    const intervalNumber = setInterval(() => incrementTimestamp(), 2000);
    setIntervalRef(intervalNumber);
  }, [incrementTimestamp]);
  const handlePause = useCallback(() => {
    if (intervalRef) {
      clearInterval(intervalRef);
      setIntervalRef(null);
    }
  }, [intervalRef]);

  const handleTimelineHoverMove = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      setHoverValue(
        Math.floor(((e.clientX - 48) / e.currentTarget.offsetWidth) * 240),
      );
    },
    [],
  );

  const handleTimelineHoverExit = useCallback(() => {
    setHoverValue(0);
  }, []);

  const handleNowClick = useCallback(() => {
    dispatch(setDataSetTimeOffset(currentTimeOffset));
  }, [dispatch, currentTimeOffset]);

  const timelineDate = useMemo(() => add(firstDay, { hours: timeOffset }), [
    firstDay,
    timeOffset,
  ]);

  useEffect(() => {
    const filterValue = format(timelineDate, "yyyy-MM-dd'T'HH:mm:ss");
    const filter = ['==', ['get', 'DT'], filterValue];
    map.setFilter(`${RoutingLayers.RoutingTimedLocation}0`, filter);
    map.setFilter(`${RoutingLayers.RoutingTimedLocation}1`, filter);
    map.setFilter(`${RoutingLayers.RoutingTimedLocation}2`, filter);
    map.setFilter(`${RoutingLayers.RoutingTimedLocation}3`, filter);
    map.setFilter(`${RoutingLayers.RoutingTimedLocation}4`, filter);
    map.setFilter(`${RoutingLayers.RoutingTimedLocation}5`, filter);
    map.setFilter(`${RoutingLayers.RoutingTimedLocation}6`, filter);
    map.setFilter(`${RoutingLayers.RoutingPolarSpreadTimedLocation}0`, filter);
    map.setFilter(`${RoutingLayers.RoutingPolarSpreadTimedLocation}1`, filter);
    map.setFilter(`${RoutingLayers.RoutingPolarSpreadTimedLocation}2`, filter);
    map.setFilter(`${RoutingLayers.RoutingPolarSpreadTimedLocation}3`, filter);
    map.setFilter(`${RoutingLayers.RoutingPolarSpreadTimedLocation}4`, filter);
    map.setFilter(`${RoutingLayers.RoutingPolarSpreadTimedLocation}5`, filter);
    map.setFilter(`${RoutingLayers.RoutingPolarSpreadTimedLocation}6`, filter);
  }, [timelineDate, map]);

  if (!showTimeline) return null;

  return (
    <>
      <TimeControlContainer onClick={intervalRef ? handlePause : handlePlay}>
        {intervalRef ? <PauseIcon /> : <PlayIcon />}
      </TimeControlContainer>
      <Container>
        <CurrentTimeIndicator
          onClick={handleNowClick}
          offset={currentTimeOffset / 240}
        />
        <DateSeparator step={0} />
        <DateSeparator step={1} />
        <DateSeparator step={2} />
        <DateSeparator step={3} />
        <DateSeparator step={4} />
        <DateSeparator step={5} />
        <DateSeparator step={6} />
        <DateSeparator step={7} />
        <DateSeparator step={8} />
        <DateSeparator step={9} />
        <DateSeparator step={10} />
        <DateLabel step={0}>{format(firstDay, 'eee dd-LL')}</DateLabel>
        <DateLabel step={1}>
          {format(add(firstDay, { days: 1 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={2}>
          {format(add(firstDay, { days: 2 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={3}>
          {format(add(firstDay, { days: 3 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={4}>
          {format(add(firstDay, { days: 4 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={5}>
          {format(add(firstDay, { days: 5 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={6}>
          {format(add(firstDay, { days: 6 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={7}>
          {format(add(firstDay, { days: 7 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={8}>
          {format(add(firstDay, { days: 8 }), 'eee dd-LL')}
        </DateLabel>
        <DateLabel step={9}>
          {format(add(firstDay, { days: 9 }), 'eee dd-LL')}
        </DateLabel>
        <Slider
          onMouseMove={handleTimelineHoverMove}
          onMouseLeave={handleTimelineHoverExit}
          onChange={onChange}
          type="range"
          min="0"
          max="240"
          value={timeOffset}
        />
        <LabelContainer isVisible left={timeOffset / 240}>
          <LabelContainerText>
            {format(timelineDate, 'EEE dd - HH:mm')}
          </LabelContainerText>
        </LabelContainer>
        {!!hoverValue && (
          <LabelContainer left={hoverValue / 240} isVisible dark>
            <LabelContainerText dark>
              {format(add(firstDay, { hours: hoverValue }), 'HH:mm')}
            </LabelContainerText>
          </LabelContainer>
        )}
      </Container>
    </>
  );
};

export default React.memo(Timeline);
