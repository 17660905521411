/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useCallback, useState } from 'react';

const useContextMenu = (map: any) => {
  const [xPos, setX] = useState(0);
  const [yPos, setY] = useState(0);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [menu, showMenu] = useState(false);

  const handleContextMenu = useCallback(
    (e): void => {
      const { x, y } = e.point;

      setX(x);
      setY(y);
      setCoordinates(e.lngLat);
      showMenu(true);
    },
    [showMenu, setX, setY, setCoordinates],
  );

  const handleClick = useCallback(() => {
    showMenu(false);
  }, [showMenu]);

  useEffect(() => {
    map.on('contextmenu', handleContextMenu);
    document.addEventListener('click', handleClick);
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, [map, handleContextMenu, handleClick]);

  return { xPos, yPos, coordinates, menu };
};

export default useContextMenu;
