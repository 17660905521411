import { Map } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { RoutesLayersSources, RoutesLayers } from 'modules/mapLayers';
import { makeEmptyGeojson } from 'modules/geojson';
import { RoutesSelectors } from '../redux';
import { createRouteGeojsonFromRouteData } from '../utils';

const useDisplayRoutesOnMap = (
  map: Map,
): {
  activeIds: (number | null)[];
  toggleRouting: (routeId: number) => void;
  hideAll: () => void;
} => {
  const routesMap = useSelector(RoutesSelectors.routesStorage);
  const [activeIds, setActiveIds] = useState<Array<number | null>>([
    null,
    null,
    null,
  ]);

  const onRouteVisibilityChange = useCallback(
    (visible, routeId, index) => {
      const selectedRoute = routesMap[routeId];
      const sourceId = `${RoutesLayersSources.Route}${index}`;
      map.setLayoutProperty(
        `${RoutesLayers.RouteLines}${index}`,
        'visibility',
        visible ? 'visible' : 'none',
      );
      map.setLayoutProperty(
        `${RoutesLayers.RoutePoints}${index}`,
        'visibility',
        visible ? 'visible' : 'none',
      );
      if (!visible || !selectedRoute) {
        map
          .getSource(sourceId)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(makeEmptyGeojson());
        return;
      }

      const geojson = createRouteGeojsonFromRouteData(selectedRoute);
      map
        .getSource(sourceId)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(geojson);
    },
    [routesMap, map],
  );

  const toggleRouting = useCallback(
    (routeId: number) => {
      setActiveIds((state) => {
        if (state.includes(routeId)) {
          const index = state.findIndex((id) => id === routeId);
          const newState = [...state];
          newState[index] = null;
          onRouteVisibilityChange(false, routeId, index);
          return newState;
        }
        const firstEmptyIndex = state.findIndex((id) => id === null);
        if (firstEmptyIndex !== -1) {
          const newState = [...state];
          newState[firstEmptyIndex] = routeId;
          onRouteVisibilityChange(true, routeId, firstEmptyIndex);
          return newState;
        }
        return state;
      });
    },
    [onRouteVisibilityChange],
  );

  const hideAll = useCallback(() => {
    setActiveIds([null, null, null]);
    onRouteVisibilityChange(false, '', 0);
    onRouteVisibilityChange(false, '', 1);
    onRouteVisibilityChange(false, '', 2);
  }, [onRouteVisibilityChange]);

  return {
    activeIds,
    toggleRouting,
    hideAll,
  };
};

export default useDisplayRoutesOnMap;
