import { createAction } from '@reduxjs/toolkit';

import {
  LocalFile,
  SettingsDefaults,
  SettingsWindSpeedCorrection,
} from '../types';

export const setSettingsDefaults = createAction<{
  values: SettingsDefaults;
}>('SET_SETTINGS_DEFAULTS');

export const setSettingsWindSpeedCorrection = createAction<{
  values: SettingsWindSpeedCorrection;
}>('SET_WIND_SPEED_CORRECTION');

export const setTimeResolutionDefault = createAction<number>(
  'SET_TIME_RESOLUTION',
);

export const setPolarFile = createAction<LocalFile>('SET_POLAR_FILE');

export const setSailingConfigFile = createAction<LocalFile>(
  'SET_SAILING_CONFIG_FILE',
);

export const setWavePolarFile = createAction<
  LocalFile & {
    knots: number;
  }
>('SET_SAILING_CONFIG_FILE');
