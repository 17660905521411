import localforage from 'localforage';

type Database = {
  name: string;
  app: LocalForage;
  files: LocalForage;
  map: LocalForage;
  meteo: LocalForage;
  settings: LocalForage;
  routings: LocalForage;
};

export const db: Database = {
  name: 'dorado_db',
  app: localforage.createInstance({ name: 'dorado_db', storeName: 'app' }),
  files: localforage.createInstance({ name: 'dorado_db', storeName: 'files' }),
  map: localforage.createInstance({ name: 'dorado_db', storeName: 'map' }),
  meteo: localforage.createInstance({ name: 'dorado_db', storeName: 'meteo' }),
  settings: localforage.createInstance({
    name: 'dorado_db',
    storeName: 'settings',
  }),
  routings: localforage.createInstance({
    name: 'dorado_db',
    storeName: 'routings',
  }),
};

export const clearUserSpecificStores = (): void => {
  db.app.clear();
  db.files.clear();
  db.meteo.clear();
  db.settings.clear();
  db.routings.clear();
};

export const { app, files, map, meteo, settings } = db;
export default db;
