import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications';
import { InclusionZonesApi } from '../api';
import { InclusionZoneActions } from '../redux';
import { InclusionZone } from '../types';

export const useDeleteInclusionZone = (
  closeModal: () => void,
  inclusionZone: InclusionZone | null,
): {
  loading: boolean;
  handleDelete: () => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    if (!inclusionZone) return;
    setLoading(true);
    try {
      await InclusionZonesApi.deleteInclusionZone({ id: inclusionZone.id });
      dispatch(InclusionZoneActions.deleteInclusionZone(inclusionZone.id));
      dispatch(
        addNotification({
          type: NotificationTypes.Success,
          message: `Inclusion zone "${inclusionZone.name}" has been deleted successfully.`,
        }),
      );
      closeModal();
    } catch (err) {
      dispatch(
        addNotification({
          type: NotificationTypes.Error,
          message: `Error while deleting inclusion zone.`,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, closeModal, inclusionZone]);

  return {
    loading,
    handleDelete,
  };
};
