/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { HighlightedBase, H2 } from 'modules/ui/typography';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'modules/ui/components';
import {
  currentEcmwfDataSets,
  currentWamDataSets,
  currentNemoDataSets,
  currentGfsDataSets,
} from 'modules/meteo/redux/selectors';

import { getOfflineMeteogram } from 'modules/meteogram/utils/meteogramExtractor';
import { toDegreesMinutesAndSeconds } from 'modules/coordinates';
import {
  ProgressBarContainer,
  Container,
  MainContainer,
} from './styledComponents';

import Meteogram from '../components/Meteogram';
import { OfflineChartGroups, MeteogramName } from '../consts';

// eslint-disable-next-line no-restricted-globals
const query = new URLSearchParams(location.search);

const OfflineMeteogramInPoint: React.FC = () => {
  const [ecmwfData, setEcmwf] = useState<any | null>(null);
  const [gfsData, setGfs] = useState<any | null>(null);

  const emfwc = useSelector(currentEcmwfDataSets);
  const wam = useSelector(currentWamDataSets);
  const nemo = useSelector(currentNemoDataSets);
  const gfs = useSelector(currentGfsDataSets);

  useEffect(() => {
    document.title = `Meteogram`;
  }, []);

  const lat: any = query.get('lat');
  const lng: any = query.get('lng');
  const charts = Object.keys(OfflineChartGroups);

  const handler = useCallback(async (l, n, e, w, nemoSet, g) => {
    const coordinates = { lat: parseFloat(l), lng: parseFloat(n) };
    const ECMWF_METEOGRAMS = await getOfflineMeteogram(coordinates, [
      ...e,
      ...w,
      ...nemoSet,
    ]);

    const GFS_METEOGRAMS = await getOfflineMeteogram(coordinates, [...g]);

    if (ECMWF_METEOGRAMS) {
      setEcmwf(ECMWF_METEOGRAMS);
    }

    if (GFS_METEOGRAMS) {
      setGfs(GFS_METEOGRAMS);
    }
  }, []);

  useEffect(() => {
    handler(lat, lng, emfwc, wam, nemo, gfs);
  }, [lat, lng, emfwc, wam, nemo, gfs, handler]);

  if (ecmwfData && gfsData) {
    return (
      <MainContainer sticky={false}>
        <H2>
          {MeteogramName.Offline}
          {toDegreesMinutesAndSeconds(lat, true)}
          <span> - </span>
          {toDegreesMinutesAndSeconds(lng)}
        </H2>
        <Tabs>
          <TabList>
            <Tab>ECMWF</Tab>
            <Tab>GFS</Tab>
          </TabList>
          <TabPanel forceRender>
            {!!ecmwfData && (
              <Meteogram
                name={MeteogramName.Offline}
                data={ecmwfData}
                charts={charts}
              />
            )}
          </TabPanel>
          <TabPanel forceRender>
            {!!gfsData && (
              <Meteogram
                name={MeteogramName.Offline}
                data={gfsData}
                charts={charts}
              />
            )}
          </TabPanel>
        </Tabs>
      </MainContainer>
    );
  }

  if (ecmwfData) {
    return (
      <MainContainer sticky>
        <H2>
          {MeteogramName.Offline}
          {toDegreesMinutesAndSeconds(lat, true)}
          <span> - </span>
          {toDegreesMinutesAndSeconds(lng)}
          <span> (ECMWF)</span>
        </H2>
        <Meteogram name={MeteogramName.Full} data={ecmwfData} charts={charts} />
      </MainContainer>
    );
  }

  if (gfsData) {
    return (
      <MainContainer sticky>
        <H2>
          {MeteogramName.Offline}
          {toDegreesMinutesAndSeconds(lat, true)}
          <span> - </span>
          {toDegreesMinutesAndSeconds(lng)}
          <span> (GFS)</span>
        </H2>
        <Meteogram name={MeteogramName.Full} data={gfsData} charts={charts} />
      </MainContainer>
    );
  }

  return (
    <Container>
      <HighlightedBase>Collecting meteogram data.</HighlightedBase>
      <ProgressBarContainer>
        <ProgressBar
          type="indeterminate"
          backgroundColor=" linear-gradient(90deg, #0B83D7 13.54%, #89C72C 76.56%, #FFE500 100%)"
          percentage={0}
        />
      </ProgressBarContainer>
    </Container>
  );
};

export default React.memo(OfflineMeteogramInPoint);
