import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications';
import { ExclusionZonesApi } from '../api';
import { ExclusionZoneActions } from '../redux';
import { ExclusionZone } from '../types';

export const useDeleteExclusionZone = (
  closeModal: () => void,
  exclusionZone: ExclusionZone | null,
): {
  loading: boolean;
  handleDelete: () => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    if (!exclusionZone) return;
    setLoading(true);
    try {
      await ExclusionZonesApi.deleteExclusionZone({ id: exclusionZone.id });
      dispatch(ExclusionZoneActions.deleteExclusionZone(exclusionZone.id));
      dispatch(
        addNotification({
          type: NotificationTypes.Success,
          message: `Exclusion zone has been deleted successfully.`,
        }),
      );
      closeModal();
    } catch (err) {
      dispatch(
        addNotification({
          type: NotificationTypes.Error,
          message: `Error while deleting exclusion zone.`,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, closeModal, exclusionZone]);

  return {
    loading,
    handleDelete,
  };
};
