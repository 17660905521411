import React from 'react';
import Plot from 'react-plotly.js';
import { useLayout, useTraces } from 'modules/meteogram/hooks';

type Data = {
  [key: string]: {
    [key: string]: string;
  };
};

type Props = {
  data: Data;
  type: string;
};

const DeterministicChart: React.FC<Props> = ({ data, type }) => {
  const layout = useLayout(data, type);
  const traces = useTraces(data, type);

  if (traces.length) {
    return (
      <div>
        <Plot
          config={{ displayModeBar: false, responsive: true }}
          data={traces}
          layout={layout}
        />
      </div>
    );
  }

  return <></>;
};

export default React.memo(DeterministicChart);
