export const VersionString = '0.6.0';

export const WhatsNewVersions = [
  '0.1.0',
  '0.1.5',
  '0.2.0',
  '0.3.0',
  '0.4.0',
  '0.5.0',
  '0.6.0',
];
