import styled, { keyframes } from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export default styled.div`
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid ${Colors.Secondary};
  width: 17px;
  height: 17px;
  animation: ${spin} 2s linear infinite;
`;
