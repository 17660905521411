import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export default styled.div`
  min-height: 1px;
  height: 1px;
  width: 100%;
  background: ${Colors.Gray};
`;
