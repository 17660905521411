import styled from 'styled-components';

export const ActiveItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 20px 0px;
`;

export const FileName = styled.span`
  font-size: 14px;
  margin: 0px 0px 5px 0px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  & > svg {
    margin-right: 16px;
  }
`;

export const ColumContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
`;

export const Cancel = styled.span`
  padding: 10px;
  background: #e3ecf5;
  color: #3d7fc2;
  font-weight: 600;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
`;

export const Status = styled.span`
  font-size: 12px;
  color: gray;
`;

export const Icon = styled.div`
  color: green;
`;
