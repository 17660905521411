import { Map } from 'mapbox-gl';
import { Colors } from 'modules/ui/theme/colors';
import { RoutesLayers, RoutesLayersSources } from '..';

import {
  CloudFillPaint,
  MslpLinePaint,
  RainFillPaint,
  TemperatureFillPaint,
  WaveFillPaint,
  MeteoWindBarbLayout,
  WindFillPaint,
  MeteogramLayout,
  MslpLineLayout,
  GustsBarbsLayout,
  GustsFillPaint,
  WaveDirectionLayout,
  WavePeriodFillPaint,
  CurrentSpeedPaint,
  WhiteCappingPaint,
  SeaTemperatureFillPaint,
  optimalRoutePaint,
  IzochronesPaint,
  GraticulesLinePaint,
  LandFillPaint,
  OceanFillPaint,
  timedOptimalRoutePaint,
  RoutingWindBarbLayout,
  WaveDirectionBlueLayout,
  RoutingBoatLocationSymbol,
  IceZoneLine,
  CoastlineLinePaint,
  ExclusionZoneFill,
  FleetBoatSymbolPaint,
  FleetRouteLine,
  FleetBoatSymbolLayout,
  WindSpeedDiffFillPaint,
  WindSpeedDiffEcmwfBarbLayout,
  WindSpeedDiffGfsBarbLayout,
  polarSpreadTimedOptimalRoutePaint,
  polarSpreadOptimalRoutePaint,
  ExclusionZoneLine,
  dashedOptimalRoutePaint,
  CurrentBoatLocationSymbol,
  ExclusionZoneCreationFill,
  InclusionZoneFill,
  InclusionZoneLine,
} from '../configs';
import {
  getLayerSourceName,
  Layers,
  ModelLayers,
  ModelLayersSources,
  RoutingLayers,
  RoutingLayersSources,
  LayersSources,
  RoutingColors,
  FleetLayers,
  FleetLayersSources,
  InteractionLayers,
  InteractionLayersSources,
  ZoneLayers,
  ZoneLayersSources,
} from '../consts';

export const addBaseLayers = (map: Map): void => {
  map.addLayer({
    id: Layers.Ocean,
    source: LayersSources.Ocean,
    type: 'fill',
    paint: OceanFillPaint,
  });
  map.addLayer({
    id: Layers.MinorIslands,
    source: LayersSources.MinorIslands,
    type: 'fill',
    paint: LandFillPaint,
  });
  map.addLayer({
    id: Layers.Land,
    source: LayersSources.Land,
    type: 'fill',
    paint: LandFillPaint,
  });
  map.addLayer({
    id: Layers.IceZone,
    source: LayersSources.IceZone,
    type: 'line',
    layout: {
      visibility: 'none',
    },
    paint: IceZoneLine,
  });
  map.addLayer({
    id: ZoneLayers.ExclusionZones,
    source: ZoneLayersSources.ExclusionZones,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    paint: ExclusionZoneFill,
  });
  map.addLayer({
    id: `${ZoneLayers.ExclusionZones}Outline`,
    source: ZoneLayersSources.ExclusionZones,
    type: 'line',
    layout: {
      visibility: 'none',
    },
    paint: ExclusionZoneLine,
  });
  map.addLayer({
    id: ZoneLayers.InclusionZones,
    source: ZoneLayersSources.InclusionZones,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    paint: InclusionZoneFill,
  });
  map.addLayer({
    id: `${ZoneLayers.InclusionZones}Outline`,
    source: ZoneLayersSources.InclusionZones,
    type: 'line',
    layout: {
      visibility: 'none',
    },
    paint: InclusionZoneLine,
  });
};

export const addWindLayers = (map: Map): void => {
  map.addLayer({
    id: ModelLayers.ECMWFWindSpeed1,
    source: ModelLayersSources.ECMWFWindPolygon1,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: WindFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWindSpeed2,
    source: ModelLayersSources.ECMWFWindPolygon2,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: WindFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWindDirection1,
    source: ModelLayersSources.ECMWFWindPoint1,
    type: 'symbol',
    layout: MeteoWindBarbLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWindDirection2,
    source: ModelLayersSources.ECMWFWindPoint2,
    type: 'symbol',
    layout: MeteoWindBarbLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.ECMWFGustsSpeed1,
    source: ModelLayersSources.ECMWFWindPolygon1,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: GustsFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFGustsSpeed2,
    source: ModelLayersSources.ECMWFWindPolygon2,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: GustsFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFGustsDirection1,
    source: ModelLayersSources.ECMWFWindPoint1,
    type: 'symbol',
    layout: GustsBarbsLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.ECMWFGustsDirection2,
    source: ModelLayersSources.ECMWFWindPoint2,
    type: 'symbol',
    layout: GustsBarbsLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.GFSWindSpeed1,
    source: ModelLayersSources.GFSWindPolygon1,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: WindFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWindSpeed2,
    source: ModelLayersSources.GFSWindPolygon2,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: WindFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWindDirection1,
    source: ModelLayersSources.GFSWindPoint1,
    type: 'symbol',
    layout: MeteoWindBarbLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.GFSWindDirection2,
    source: ModelLayersSources.GFSWindPoint2,
    type: 'symbol',
    layout: MeteoWindBarbLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.GFSGustsSpeed1,
    source: ModelLayersSources.GFSWindPolygon1,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: GustsFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSGustsSpeed2,
    source: ModelLayersSources.GFSWindPolygon2,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: GustsFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSGustsDirection1,
    source: ModelLayersSources.GFSWindPoint1,
    type: 'symbol',
    layout: GustsBarbsLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.GFSGustsDirection2,
    source: ModelLayersSources.GFSWindPoint2,
    type: 'symbol',
    layout: GustsBarbsLayout,
    minzoom: 2,
  });
  map.addLayer({
    id: ModelLayers.WindSpeedComparison1,
    source: ModelLayersSources.WindComparisonPolygon1,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: WindSpeedDiffFillPaint,
  });
  map.addLayer({
    id: ModelLayers.WindSpeedComparison2,
    source: ModelLayersSources.WindComparisonPolygon2,
    type: 'fill',
    layout: {},
    minzoom: 2,
    paint: WindSpeedDiffFillPaint,
  });
  map.addLayer({
    id: ModelLayers.WindSpeedComparisonEcmwfDirection1,
    source: ModelLayersSources.WindComparisonPoint1,
    type: 'symbol',
    layout: WindSpeedDiffEcmwfBarbLayout,
    minzoom: 5,
  });
  map.addLayer({
    id: ModelLayers.WindSpeedComparisonEcmwfDirection2,
    source: ModelLayersSources.WindComparisonPoint2,
    type: 'symbol',
    layout: WindSpeedDiffEcmwfBarbLayout,
    minzoom: 5,
  });
  map.addLayer({
    id: ModelLayers.WindSpeedComparisonGfsDirection1,
    source: ModelLayersSources.WindComparisonPoint1,
    type: 'symbol',
    layout: WindSpeedDiffGfsBarbLayout,
    minzoom: 5,
    paint: {
      'icon-color': 'blue',
    },
  });
  map.addLayer({
    id: ModelLayers.WindSpeedComparisonGfsDirection2,
    source: ModelLayersSources.WindComparisonPoint2,
    type: 'symbol',
    layout: WindSpeedDiffGfsBarbLayout,
    minzoom: 5,
    paint: {
      'icon-color': 'blue',
    },
  });
};

export const addWeatherLayers = (map: Map): void => {
  map.addLayer({
    id: ModelLayers.ECMWFWeatherTemp1,
    source: ModelLayersSources.ECMWFWeather1,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: TemperatureFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWeatherTemp2,
    source: ModelLayersSources.ECMWFWeather2,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: TemperatureFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWeatherTemp1,
    source: ModelLayersSources.GFSWeather1,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: TemperatureFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWeatherTemp2,
    source: ModelLayersSources.GFSWeather2,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: TemperatureFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWeatherCloud1,
    source: ModelLayersSources.ECMWFWeather1,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: CloudFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWeatherCloud2,
    source: ModelLayersSources.ECMWFWeather2,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: CloudFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWeatherCloud1,
    source: ModelLayersSources.GFSWeather1,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: CloudFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWeatherCloud2,
    source: ModelLayersSources.GFSWeather2,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: CloudFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWeatherPerception1,
    source: ModelLayersSources.ECMWFWeather1,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: RainFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFWeatherPerception2,
    source: ModelLayersSources.ECMWFWeather2,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: RainFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWeatherPerception1,
    source: ModelLayersSources.GFSWeather1,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: RainFillPaint,
  });
  map.addLayer({
    id: ModelLayers.GFSWeatherPerception2,
    source: ModelLayersSources.GFSWeather2,
    type: 'fill',
    layout: {
      visibility: 'none',
    },
    minzoom: 2,
    paint: RainFillPaint,
  });
  map.addLayer({
    id: ModelLayers.ECMWFMslp,
    source: getLayerSourceName(ModelLayers.ECMWFMslp),
    type: 'line',
    layout: MslpLineLayout,
    minzoom: 2,
    paint: MslpLinePaint,
  });
  map.addLayer({
    id: ModelLayers.GFSMslp,
    source: getLayerSourceName(ModelLayers.GFSMslp),
    type: 'line',
    layout: MslpLineLayout,
    minzoom: 2,
    paint: MslpLinePaint,
  });
};

export const addWaveLayers = (map: Map): void => {
  map.addLayer(
    {
      id: ModelLayers.WAMSwaveHeight1,
      source: ModelLayersSources.WAMSwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMSwaveHeight2,
      source: ModelLayersSources.WAMSwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMSwavePeriod1,
      source: ModelLayersSources.WAMSwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMSwavePeriod2,
      source: ModelLayersSources.WAMSwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMSwaveDirection1,
      source: ModelLayersSources.WAMSwavePoint1,
      type: 'symbol',
      layout: WaveDirectionBlueLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMSwaveDirection2,
      source: ModelLayersSources.WAMSwavePoint2,
      type: 'symbol',
      layout: WaveDirectionBlueLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWwaveHeight1,
      source: ModelLayersSources.WAMWwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWwaveHeight2,
      source: ModelLayersSources.WAMWwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWwavePeriod1,
      source: ModelLayersSources.WAMWwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWwavePeriod2,
      source: ModelLayersSources.WAMWwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWwaveDirection1,
      source: ModelLayersSources.WAMWwavePoint1,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWwaveDirection2,
      source: ModelLayersSources.WAMWwavePoint2,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWaveHeight1,
      source: ModelLayersSources.WAMWavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWaveHeight2,
      source: ModelLayersSources.WAMWavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWaveWhiteCapping1,
      source: ModelLayersSources.WAMWavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WhiteCappingPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWaveWhiteCapping2,
      source: ModelLayersSources.WAMWavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WhiteCappingPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWaveDirection1,
      source: ModelLayersSources.WAMWavePoint1,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.WAMWaveDirection2,
      source: ModelLayersSources.WAMWavePoint2,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMSwaveHeight1,
      source: ModelLayersSources.GFSWAMSwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMSwaveHeight2,
      source: ModelLayersSources.GFSWAMSwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMSwavePeriod1,
      source: ModelLayersSources.GFSWAMSwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMSwavePeriod2,
      source: ModelLayersSources.GFSWAMSwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMSwaveDirection1,
      source: ModelLayersSources.GFSWAMSwavePoint1,
      type: 'symbol',
      layout: WaveDirectionBlueLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMSwaveDirection2,
      source: ModelLayersSources.GFSWAMSwavePoint2,
      type: 'symbol',
      layout: WaveDirectionBlueLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWwaveHeight1,
      source: ModelLayersSources.GFSWAMWwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWwaveHeight2,
      source: ModelLayersSources.GFSWAMWwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWwavePeriod1,
      source: ModelLayersSources.GFSWAMWwavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWwavePeriod2,
      source: ModelLayersSources.GFSWAMWwavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WavePeriodFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWwaveDirection1,
      source: ModelLayersSources.GFSWAMWwavePoint1,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWwaveDirection2,
      source: ModelLayersSources.GFSWAMWwavePoint2,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWaveHeight1,
      source: ModelLayersSources.GFSWAMWavePolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWaveHeight2,
      source: ModelLayersSources.GFSWAMWavePolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: WaveFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWaveDirection1,
      source: ModelLayersSources.GFSWAMWavePoint1,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.GFSWAMWaveDirection2,
      source: ModelLayersSources.GFSWAMWavePoint2,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
};

export const addMeteogramIconsLayer = (map: Map): void => {
  map.addLayer({
    id: ModelLayers.Meteograms,
    type: 'symbol',
    source: ModelLayersSources.MeteogramPoint,
    layout: MeteogramLayout,
    paint: {
      'icon-color': ['get', 'color'],
    },
  });
};

export const addOceanLayers = (map: Map): void => {
  map.addLayer(
    {
      id: ModelLayers.NemoOceanTemperature1,
      source: ModelLayersSources.NemoOceanPolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: SeaTemperatureFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.NemoOceanTemperature2,
      source: ModelLayersSources.NemoOceanPolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: SeaTemperatureFillPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.NemoOceanSpeed1,
      source: ModelLayersSources.NemoOceanPolygon1,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: CurrentSpeedPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.NemoOceanSpeed2,
      source: ModelLayersSources.NemoOceanPolygon2,
      type: 'fill',
      layout: {
        visibility: 'none',
      },
      minzoom: 2,
      paint: CurrentSpeedPaint,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.NemoOceanDirection1,
      source: ModelLayersSources.NemoOceanPoint1,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
  map.addLayer(
    {
      id: ModelLayers.NemoOceanDirection2,
      source: ModelLayersSources.NemoOceanPoint2,
      type: 'symbol',
      layout: WaveDirectionLayout,
      minzoom: 2,
    },
    Layers.Land,
  );
};

const makeRoutingLayers = (map: Map, index: number): void => {
  map.addLayer({
    id: `${RoutingLayers.OptimalRoute}${index}`,
    type: 'line',
    source: `${RoutingLayersSources.OptimalRoute}${index}`,
    paint: optimalRoutePaint(RoutingColors[index]),
    filter: ['!=', ['get', 'isDashed'], true],
  });
  map.addLayer({
    id: `${RoutingLayers.DashedOptimalRoute}${index}`,
    type: 'line',
    source: `${RoutingLayersSources.OptimalRoute}${index}`,
    paint: dashedOptimalRoutePaint(RoutingColors[index], false),
    filter: ['==', ['get', 'isDashed'], true],
  });
  map.addLayer({
    id: `${RoutingLayers.TimedOptimalRoute}${index}`,
    type: 'circle',
    source: `${RoutingLayersSources.TimedOptimalRoute}${index}`,
    paint: timedOptimalRoutePaint(RoutingColors[index]),
  });
  map.addLayer({
    id: `${RoutingLayers.RoutingPolarSpreadLine}${index}`,
    type: 'line',
    source: `${RoutingLayersSources.RoutingPolarSpreadLine}${index}`,
    paint: polarSpreadOptimalRoutePaint(RoutingColors[index], true),
  });
  map.addLayer({
    id: `${RoutingLayers.RoutingPolarSpreadPoints}${index}`,
    type: 'circle',
    source: `${RoutingLayersSources.RoutingPolarSpreadPoints}${index}`,
    paint: polarSpreadTimedOptimalRoutePaint(RoutingColors[index], true),
  });
  map.addLayer({
    id: `${RoutingLayers.TimedOptimalRouteBarbs}${index}`,
    type: 'symbol',
    source: `${RoutingLayersSources.TimedOptimalRoute}${index}`,
    layout: RoutingWindBarbLayout,
  });
  map.addLayer({
    id: `${RoutingLayers.RoutingTimedLocation}${index}`,
    type: 'symbol',
    source: `${RoutingLayersSources.TimedOptimalRoute}${index}`,
    layout: RoutingBoatLocationSymbol,
  });
  map.addLayer({
    id: `${RoutingLayers.RoutingPolarSpreadTimedLocation}${index}`,
    type: 'symbol',
    source: `${RoutingLayersSources.RoutingPolarSpreadPoints}${index}`,
    layout: RoutingBoatLocationSymbol,
  });
};

export const addRoutingLayers = (map: Map): void => {
  makeRoutingLayers(map, 0);
  makeRoutingLayers(map, 1);
  makeRoutingLayers(map, 2);
  makeRoutingLayers(map, 3);
  makeRoutingLayers(map, 4);
  makeRoutingLayers(map, 5);
  makeRoutingLayers(map, 6);
  map.addLayer({
    id: RoutingLayers.Izochrones,
    type: 'line',
    source: RoutingLayersSources.Izochrones,
    paint: IzochronesPaint,
  });
  map.addLayer({
    id: FleetLayers.FleetRoutes,
    type: 'line',
    layout: {
      visibility: 'none',
    },
    source: FleetLayersSources.FleetRoutes,
    paint: FleetRouteLine,
  });
  map.addLayer({
    id: FleetLayers.FleetLastLocation,
    type: 'symbol',
    source: FleetLayersSources.FleetLastLocation,
    layout: FleetBoatSymbolLayout,
    paint: FleetBoatSymbolPaint,
  });
  map.addLayer({
    id: RoutingLayers.CurrentBoatLocation,
    type: 'symbol',
    source: RoutingLayersSources.CurrentBoatLocation,
    layout: CurrentBoatLocationSymbol,
  });
};

export const addTopLayers = (map: Map): void => {
  map.addLayer({
    id: Layers.Coastline,
    source: LayersSources.Coastline,
    type: 'line',
    paint: CoastlineLinePaint,
  });
  map.addLayer({
    id: Layers.Graticules1,
    source: LayersSources.Graticules1,
    type: 'line',
    layout: {
      visibility: 'none',
    },
    paint: GraticulesLinePaint,
  });
  map.addLayer({
    id: InteractionLayers.MeasurementToolPoints,
    type: 'circle',
    source: InteractionLayersSources.MeasurementTool,
    paint: {
      'circle-radius': 5,
      'circle-color': Colors.Destructive,
    },
    filter: ['in', '$type', 'Point'],
  });
  map.addLayer({
    id: InteractionLayers.MeasurementToolLine,
    type: 'line',
    source: InteractionLayersSources.MeasurementTool,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': Colors.Destructive,
      'line-width': 2.5,
      'line-dasharray': [2, 2.5],
    },
    filter: ['in', '$type', 'LineString'],
  });
  map.addLayer({
    id: InteractionLayers.RouteCreationPoints,
    type: 'circle',
    source: InteractionLayersSources.RouteCreation,
    paint: {
      'circle-radius': 5,
      'circle-color': Colors.Destructive,
    },
    filter: ['in', '$type', 'Point'],
  });
  map.addLayer({
    id: InteractionLayers.RouteCreationLine,
    type: 'line',
    source: InteractionLayersSources.RouteCreation,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': Colors.Destructive,
      'line-width': 2.5,
      'line-dasharray': [2, 2.5],
    },
    filter: ['in', '$type', 'LineString'],
  });
  map.addLayer({
    id: InteractionLayers.PolygonCreationPoints,
    type: 'circle',
    source: InteractionLayersSources.PolygonCreation,
    paint: {
      'circle-radius': 5,
      'circle-color': Colors.Destructive,
    },
    filter: ['in', '$type', 'Point'],
  });
  map.addLayer({
    id: InteractionLayers.PolygonCreationLine,
    type: 'line',
    source: InteractionLayersSources.PolygonCreation,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': Colors.Destructive,
      'line-width': 2.5,
      'line-dasharray': [2, 2.5],
    },
    filter: ['in', '$type', 'LineString'],
  });
  map.addLayer({
    id: InteractionLayers.PolygonCreationFill,
    type: 'fill',
    source: InteractionLayersSources.PolygonCreation,
    paint: ExclusionZoneCreationFill,
    filter: ['in', '$type', 'Polygon'],
  });
};

const makeRouteLayers = (map: Map, index: number) => {
  map.addLayer({
    id: `${RoutesLayers.RoutePoints}${index}`,
    type: 'circle',
    source: `${RoutesLayersSources.Route}${index}`,
    paint: {
      'circle-radius': 5,
      'circle-color': RoutingColors[index],
    },
    filter: ['in', '$type', 'Point'],
  });
  map.addLayer({
    id: `${RoutesLayers.RouteLines}${index}`,
    type: 'line',
    source: `${RoutesLayersSources.Route}${index}`,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': RoutingColors[index],
      'line-width': 2.5,
      'line-dasharray': [2, 2.5],
    },
    filter: ['in', '$type', 'LineString'],
  });
};

export const addRoutesLayers = (map: Map): void => {
  makeRouteLayers(map, 0);
  makeRouteLayers(map, 1);
  makeRouteLayers(map, 2);
};
