import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Map } from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import { point, multiLineString } from '@turf/helpers';
import length from '@turf/length';

import { makeEmptyGeojson } from 'modules/geojson/utils';
import {
  useHandleClicksOnMap,
  useHandleMovePoints,
} from 'modules/mapInteracting';
import { InteractionLayersSources, InteractionLayers } from 'modules/mapLayers';
import { MapEvent } from 'modules/map/types';
import { hideAllUi, showAllUi } from 'modules/metadata';
import { Route } from '../types';

const useCreateRouteOnMap = (
  map: Map,
  distance: MutableRefObject<number>,
  initialRoute?: Route,
): {
  isNotSinglePoint: boolean;
  handleUndo: () => void;
  getRoute: () => Array<{ coordinates: [number, number] }>;
  isEmpty: boolean;
} => {
  const dispatch = useDispatch();
  const route = useRef<[number, number][]>(
    initialRoute
      ? initialRoute.route.map(({ coordinates }) => coordinates)
      : [],
  );
  const movingPointIndex = useRef<number | null>(0);
  const [isNotSinglePoint, setIsNotSinglePoint] = useState(!!initialRoute);
  const [isEmpty, setIsEmpty] = useState(!initialRoute);

  const getRoute = useCallback(() => {
    return route.current.map((coordinates) => ({ coordinates }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMap = useCallback((newRoute: number[][]) => {
    const pointsFeature = newRoute.map((step, index) => point(step, { index }));
    const linesFeature = multiLineString([newRoute]);
    const geojson = makeEmptyGeojson();
    geojson.features = [...pointsFeature, linesFeature];
    map
      .getSource(InteractionLayersSources.RouteCreation)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .setData(geojson);
    distance.current = length(linesFeature);
    setIsNotSinglePoint(newRoute.length > 1);
    setIsEmpty(newRoute.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUndo = useCallback(() => {
    const newRoute = [...route.current].slice(0, -1);
    updateMap(newRoute);
    route.current = newRoute;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMap]);

  const handleClick = useCallback(
    (ev: mapboxgl.MapboxEvent & mapboxgl.EventData) => {
      const newRoute = [
        ...route.current,
        [ev.lngLat.lng, ev.lngLat.lat] as [number, number],
      ];
      route.current = newRoute;
      updateMap(newRoute);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateMap],
  );

  const handleMove = useCallback(
    (ev: MapEvent) => {
      const newRoute = [...route.current];
      if (movingPointIndex.current !== null) {
        newRoute[movingPointIndex.current] = [ev.lngLat.lng, ev.lngLat.lat];
      }
      route.current = newRoute;
      updateMap(newRoute);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [updateMap],
  );

  const handleMouseEnter = useCallback((ev: MapEvent) => {
    movingPointIndex.current =
      (ev.features && ev.features[0].properties?.index) ?? null;
  }, []);
  useHandleMovePoints(
    map,
    InteractionLayers.RouteCreationPoints,
    handleMove,
    handleMouseEnter,
  );

  const handleCleanupMonitoringClick = useCallback(() => {
    map
      .getSource(InteractionLayersSources.MeasurementTool)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .setData(makeEmptyGeojson());
  }, [map]);
  useHandleClicksOnMap(map, handleClick, handleCleanupMonitoringClick);

  useEffect(() => {
    dispatch(hideAllUi());
    updateMap(route.current);
    return (): void => {
      dispatch(showAllUi());
      map.getCanvas().style.cursor = '';
      map
        .getSource(InteractionLayersSources.RouteCreation)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(makeEmptyGeojson());
    };
  }, [dispatch, map, updateMap]);
  return {
    isNotSinglePoint,
    handleUndo,
    getRoute,
    isEmpty,
  };
};

export default useCreateRouteOnMap;
