import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { HighlightedSm } from 'modules/ui/typography';

export const Button = styled.button`
  height: 32px;
  width: 32px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  :active {
    background-color: grey;
  }
  :hover {
    cursor: pointer;
  }
  border-radius: 4px;
  background-color: ${Colors.White}CC;
`;
export const CountIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: ${Colors.Secondary};
  position: relative;
  top: -40px;
  right: -24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IndicatorText = styled(HighlightedSm)`
  color: ${Colors.White};
`;
