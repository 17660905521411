import React from 'react';
import { useSelector } from 'react-redux';

import { ModalEmptyMessage } from 'modules/ui/components';
import { sortedServerRoutingsListSelector } from '../../redux/selectors';
import Row from './Row';

const ServerRoutingsList: React.FC = () => {
  const serverRoutings = useSelector(sortedServerRoutingsListSelector);
  return (
    <>
      {!serverRoutings.length && (
        <ModalEmptyMessage
          title="No new server routings in the last 7 days."
          subtitle="You might have already downloaded them all to the Downloaded routings tab. You can also create a new routing by right-clicking on the map."
        />
      )}
      {serverRoutings.map((r) => (
        <Row key={r.id} route={r} />
      ))}
    </>
  );
};

export default React.memo(ServerRoutingsList);
