import { createSelector } from '@reduxjs/toolkit';

import { RootState, Storage } from 'modules/main/redux';

import { ExclusionZone } from '../types';

const exclusionZonesStorage = (state: RootState): Storage<ExclusionZone> =>
  state.exclusionZones.storage;

const hasLoaded = (state: RootState): boolean =>
  !!state.exclusionZones.hasLoaded;

const exclusionZonesList = createSelector([exclusionZonesStorage], (storage) =>
  Object.values(storage),
);

const enabledZonesCount = createSelector(
  [exclusionZonesStorage],
  (storage) => Object.values(storage).filter(({ enabled }) => enabled).length,
);

const exclusionZoneById = (
  state: RootState,
  id: number | null,
): ExclusionZone | null => (id ? state.exclusionZones.storage[id] : null);

export const ExclusionZonesSelectors = {
  exclusionZonesStorage,
  hasLoaded,
  exclusionZonesList,
  exclusionZoneById,
  enabledZonesCount,
};
