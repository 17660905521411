import bezierSpline from '@turf/bezier-spline';
import { lineString } from '@turf/helpers';

export const splineMslpLines = (
  json: GeoJSON.FeatureCollection<
    GeoJSON.MultiLineString,
    GeoJSON.GeoJsonProperties
  >,
): GeoJSON.FeatureCollection<GeoJSON.LineString, GeoJSON.GeoJsonProperties> => {
  const lines = json.features
    .map((f) =>
      f.geometry.coordinates.map((c) => ({
        line: c,
        properties: f.properties,
      })),
    )
    .flat();
  const featureLines = lines.map((l) => lineString(l.line, l.properties));
  const splinedLines = featureLines.map((f) =>
    bezierSpline(f, { properties: f.properties }),
  );
  // newJson.features.map((f) => bezierSpline(f));
  return {
    type: 'FeatureCollection',
    features: splinedLines,
  };
};
