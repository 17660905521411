import { apiClient } from 'modules/apiClient';
import { ExclusionZone, ExclusionZoneForm, UploadFromFileForm } from '../types';

const getExclusionZones = async (): Promise<ExclusionZone[]> => {
  const response = await apiClient.get('/v1/exclusionZones');
  return response.data;
};

const createExclusionZone = async ({
  exclusionZone,
}: ExclusionZoneForm): Promise<ExclusionZone> => {
  const response = await apiClient.post(`/v1/exclusionZones`, {
    zone: exclusionZone,
  });
  return response.data;
};

const createBatchExclusionZones = async (
  exclusionZones: UploadFromFileForm,
): Promise<ExclusionZone[]> => {
  const response = await apiClient.post(`/v1/exclusionZones`, {
    zones: exclusionZones,
  });
  return response.data;
};

const updateExclusionZone = async ({
  exclusionZone,
  id,
  enabled,
}: ExclusionZoneForm): Promise<ExclusionZone> => {
  const response = await apiClient.patch(`/v1/exclusionZones/${id}`, {
    zone: exclusionZone,
    enabled,
  });
  return response.data;
};

const deleteExclusionZone = async ({ id }: { id: number }): Promise<void> =>
  apiClient.delete(`/v1/exclusionZones/${id}`);

export const ExclusionZonesApi = {
  getExclusionZones,
  deleteExclusionZone,
  createExclusionZone,
  updateExclusionZone,
  createBatchExclusionZones,
};
