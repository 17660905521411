/* eslint-disable @typescript-eslint/no-explicit-any */
import { getGeoJsonFromLocalForage } from 'modules/localForage/utils';
import { db } from 'modules/localForage/db';

export const normalizeRouteMeteogramData = async (
  key: string,
): Promise<any> => {
  const res = await getGeoJsonFromLocalForage(db.routings, key);

  const steps = res?.features.reduce((obj: any, item: any) => {
    const { properties } = item;
    obj[properties.time] = properties;
    return obj;
  }, {});

  return {
    lat: null,
    lng: null,
    steps,
  };
};
