export const SideMenuRoutesIndexes = [0, 1, 2, 3, 4, 5, 6];

export enum ReturnFileOptions {
  OptimalRoute = 'optRoute',

  TimedOptimalRoute = 'optRouteTi',
  InputRoute = 'in_route',

  Metadata = 'metadata',

  Izochrones = 'izochrones',
  Meteogram = 'meteogram',
  OptRouteGPX = 'optRouteGPX',
  OptRouteTiGPX = 'optRouteTiGPX',
  OptRouteTiCSV = 'optRouteTiCSV',
  AllRoutings = 'allRoutings',
  AllStatuses = 'allStatuses',
}

export enum RoutingType {
  fc = 'fc',
  eps = 'eps',
  multiEff = 'multiEff',
}

export enum RoutingStatusCodes {
  Processing = 0,
  Done = 1,
  Error = 2,
  Unknown = 99,
}

export const hourOptions = new Array(24).fill(null).map((i, index) => {
  const value = index < 10 ? `0${index}` : index.toString();
  return {
    label: value,
    value,
  };
});

export const minutesOptions = new Array(12).fill(null).map((i, index) => {
  const value = index * 5 < 10 ? `0${index * 5}` : (index * 5).toString();
  return {
    label: value,
    value,
  };
});

export enum RoutingTypes {
  'ecmwf-fc' = 'ecmwf-fc',
  'ecmwf-eps' = 'ecmwf-eps',

  'ecmwf-eff' = 'ecmwf-eff',
  'ncep-fc' = 'ncep-fc',
  'ncep-eps' = 'ncep-eps',
  'ncep-eff' = 'ncep-eff',
}
