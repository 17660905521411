export const getDeviceId = () => {
  const deviceId = localStorage.getItem('dorado:deviceId');
  if (!deviceId) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newDeviceId = window.crypto.randomUUID();
      localStorage.setItem('dorado:deviceId', newDeviceId);
      return newDeviceId;
    } catch (error) {
      const newDeviceId = Math.random().toString(36).slice(2);
      localStorage.setItem('dorado:deviceId', newDeviceId);
      return newDeviceId;
    }
  }
  return deviceId;
};
