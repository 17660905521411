import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { NotificationWithId } from '../types';

import { addNotification, removeNotification } from './actions';

const initialState: NotificationWithId[] = [];
const list = createReducer(initialState, (builder) => {
  builder
    .addCase(addNotification, (state, action) => {
      return [
        ...state,
        {
          ...action.payload,
          id: `notification-${new Date().getTime()}`,
        },
      ];
    })
    .addCase(removeNotification, (state, action) => {
      return state.filter((n) => n.id !== action.payload);
    });
});

const reducer = combineReducers({
  list,
});

export default reducer;
