import React from 'react';

import { Modal, Button, ModalFooter } from 'modules/ui/components';
import { RegularBase } from 'modules/ui/typography';

import { FormContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  goToFileChoice: () => void;
};

const ErrorModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  goToFileChoice,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      width={600}
      title="Upload GeoJSON with exclusion zones"
      closeModal={closeModal}
    >
      <FormContainer>
        <RegularBase>
          The selected file contained errors and was not processed successfully.
        </RegularBase>
        <br />
        <br />
        <RegularBase>Please check the format and try again.</RegularBase>
      </FormContainer>
      <ModalFooter>
        <Button isSecondary label="Back" clickHandler={goToFileChoice} />
        <Button label="Ok" clickHandler={closeModal} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(ErrorModal);
