import styled from 'styled-components';

import { RegularBase } from 'modules/ui/typography';

export const MinutesCoordinatesInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const CoordinatesLabel = styled(RegularBase)<{ addMargin?: boolean }>`
  margin-left: 4px;
  margin-right: 4px;
  margin-top: ${({ addMargin }): number => (addMargin ? 12 : 2)}px;
`;
