export const listToObject = <T extends { id: number }>(
  list: T[],
  initialState = {} as { [key: number]: T },
): { [key: number]: T } => {
  return list.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, initialState);
};

export const deleteFromObject = <T extends { id: number }>(
  object: { [key: number]: T },
  id: number,
): { [key: number]: T } => {
  const copy = { ...object };
  delete copy[id];
  return copy;
};
