import { useCallback, useEffect, useRef, useState } from 'react';
import { Map } from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import { point, multiLineString, polygon } from '@turf/helpers';

import { makeEmptyGeojson } from 'modules/geojson';
import { InteractionLayersSources, InteractionLayers } from 'modules/mapLayers';
import { hideAllUi, showAllUi } from 'modules/metadata';

import { useHandleClicksOnMap } from './useHandleClicksOnMap';
import { useHandleMovePoints } from './useHandleMovePoints';
import { MapEvent } from '../types';

export const useDrawCustomPolygon = (
  map: Map,
  initialCoordinates?: GeoJSON.Position[],
): {
  isValid: boolean;
  handleUndo: () => void;
  getPolygon: () => GeoJSON.Polygon;
  canUndo: boolean;
} => {
  const isEditing = !!initialCoordinates;
  const dispatch = useDispatch();
  const polygonCoordinates = useRef<GeoJSON.Position[]>(
    initialCoordinates?.slice(0, -1) || [],
  );
  const polygonCoordinatesHistory = useRef<GeoJSON.Position[][]>(
    initialCoordinates ? [initialCoordinates.slice(0, -1)] : [],
  );
  const movingPointIndex = useRef<number | null>(0);
  const [isValid, setIsValid] = useState(
    () => (initialCoordinates?.length || 0) > 2,
  );
  const [canUndo, setCanUndo] = useState(
    () => (initialCoordinates?.length || 0) > 1,
  );

  const getPolygon = useCallback(() => {
    return polygon([
      [...polygonCoordinates.current, polygonCoordinates.current[0]],
    ]).geometry;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToHistory = useCallback((newRoute: GeoJSON.Position[]) => {
    polygonCoordinatesHistory.current.push(newRoute);
  }, []);

  const addToHistoryOnDragStop = useCallback(() => {
    polygonCoordinatesHistory.current.push([...polygonCoordinates.current]);
    setCanUndo(polygonCoordinatesHistory.current.length > 1);
  }, []);

  const updateMap = useCallback((newPoints: GeoJSON.Position[]) => {
    if (newPoints.length === 0) return;

    const pointsFeature = newPoints.map((step, index) =>
      point(step, { index }),
    );
    const linesFeature = multiLineString([[...newPoints, newPoints[0]]]);
    const geojson = makeEmptyGeojson();
    geojson.features = [...pointsFeature, linesFeature];
    if (newPoints.length > 2) {
      const polygonFeature = polygon([[...newPoints, newPoints[0]]]);
      geojson.features.push(polygonFeature);
    }
    map
      .getSource(InteractionLayersSources.PolygonCreation)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .setData(geojson);
    setIsValid(newPoints.length > 2);
    setCanUndo(polygonCoordinatesHistory.current.length > 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUndo = useCallback(() => {
    polygonCoordinatesHistory.current.pop();
    const lastStep =
      polygonCoordinatesHistory.current[
        polygonCoordinatesHistory.current.length - 1
      ];
    if (lastStep) {
      updateMap(lastStep);
      polygonCoordinates.current = lastStep;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMap]);

  const handleClick = useCallback(
    (ev: mapboxgl.MapboxEvent & mapboxgl.EventData) => {
      const newRoute = [
        ...polygonCoordinates.current,
        [ev.lngLat.lng, ev.lngLat.lat] as GeoJSON.Position,
      ];
      addToHistory(newRoute);
      polygonCoordinates.current = newRoute;
      updateMap(newRoute);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateMap],
  );

  const handleMove = useCallback(
    (ev: MapEvent) => {
      const newCoordinates = [...polygonCoordinates.current];
      if (movingPointIndex.current !== null) {
        newCoordinates[movingPointIndex.current] = [
          ev.lngLat.lng,
          ev.lngLat.lat,
        ];
      }
      polygonCoordinates.current = newCoordinates;
      updateMap(newCoordinates);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [updateMap],
  );

  const handleMouseEnter = useCallback((ev: MapEvent) => {
    movingPointIndex.current =
      (ev.features && ev.features[0].properties?.index) ?? null;
  }, []);
  useHandleMovePoints(
    map,
    InteractionLayers.PolygonCreationPoints,
    handleMove,
    handleMouseEnter,
    addToHistoryOnDragStop,
    isEditing,
  );

  const handleCleanupMonitoringClick = useCallback(() => {
    map
      .getSource(InteractionLayersSources.PolygonCreation)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .setData(makeEmptyGeojson());
  }, [map]);
  useHandleClicksOnMap(
    map,
    handleClick,
    handleCleanupMonitoringClick,
    isEditing,
  );

  useEffect(() => {
    dispatch(hideAllUi());
    updateMap(polygonCoordinates.current);
    return (): void => {
      dispatch(showAllUi());
      map.getCanvas().style.cursor = '';
      map
        .getSource(InteractionLayersSources.PolygonCreation)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setData(makeEmptyGeojson());
    };
  }, [dispatch, map, updateMap]);
  return {
    isValid,
    handleUndo,
    getPolygon,
    canUndo,
  };
};
