import differenceInHours from 'date-fns/differenceInHours';

import { Options } from '../types';

const generateTimeDifferenceValues = (
  geojson: GeoJSON.FeatureCollection,
  startTime: string,
): number[] => {
  const startDate = new Date(startTime);
  return geojson.features.map(({ properties }) => {
    return differenceInHours(new Date(properties?.etime || ''), startDate);
  }) as number[];
};

const generateDistanceValues = (
  geojson: GeoJSON.FeatureCollection,
): number[] => {
  return geojson.features.map(({ properties }) => {
    return parseInt(properties?.distance || '0', 10);
  }) as number[];
};

export const generateGraphDataFromGeojson = (
  geojson: GeoJSON.FeatureCollection,
  options: Options,
): {
  x: number[];
  y: number[];
} => {
  let values = [] as number[];
  if (options.isTimeDifference) {
    values = generateTimeDifferenceValues(geojson, options.startTime || '');
  } else if (options.isDistance) {
    values = generateDistanceValues(geojson);
  }
  values = values.filter(Boolean);
  values.sort();
  const valuesObject = values.reduce((agg, curr) => {
    if (agg[curr]) {
      // eslint-disable-next-line operator-assignment
      agg[curr] = agg[curr] + 1;
      return agg;
    }
    agg[curr] = 1;
    return agg;
  }, {} as { [key: number]: number });
  return {
    x: Object.keys(valuesObject).map(Number),
    y: Object.values(valuesObject),
  };
};
