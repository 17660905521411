import { Map } from 'mapbox-gl';
import { useEffect } from 'react';

import { InteractionLayers, useChangeLayerPaint } from 'modules/mapLayers';
import { Colors } from 'modules/ui/theme/colors';

export const useChangePolygonCreationColors = (map: Map, color: Colors) => {
  const {
    changeFillColor,
    changeLineColor,
    changeCircleColor,
  } = useChangeLayerPaint(map);

  useEffect(() => {
    changeFillColor(color, InteractionLayers.PolygonCreationFill);
    changeLineColor(color, InteractionLayers.PolygonCreationLine);
    changeCircleColor(color, InteractionLayers.PolygonCreationPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
