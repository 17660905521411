import React from 'react';
import { Field } from 'react-final-form';

import { TextInput, FormCheckbox, SelectInput } from 'modules/ui/components';
import { parseFloat } from 'modules/main/formaters';
import { parseTimeOptions } from 'modules/form';
import ModelHeader from './ModelHeader';
import { SpatialResolutionOptions, TimeResolutionOptions } from '../../consts';

import {
  MultiRow,
  CheckboxContainer,
  RightContent,
  SpatialLabelContainer,
  SpatialLabel,
} from './styledComponents';

type Props = {
  title: string;
  formObjectKey: string;
};

const timeOptionParser = parseTimeOptions(10);

const WindWeatherForm: React.FC<Props> = ({ title, formObjectKey }) => (
  <>
    <ModelHeader title={title} />
    <MultiRow showTopBorder>
      <CheckboxContainer>
        <Field
          name={`${formObjectKey}.wind.checked`}
          component={FormCheckbox}
          type="checkbox"
          label="Wind (Speed, Direction, Gusts)"
        />
      </CheckboxContainer>
      <RightContent>
        <Field
          name={`${formObjectKey}.wind.time`}
          component={TextInput}
          rightInlineLabel="days"
          width={104}
          formatOnBlur
          format={timeOptionParser}
        />
        <Field
          name={`${formObjectKey}.wind.spatial`}
          component={SelectInput}
          width={104}
          options={SpatialResolutionOptions}
          parse={parseFloat}
          type="number"
        />
        <Field
          name={`${formObjectKey}.wind.resolution`}
          component={SelectInput}
          width={208}
          options={TimeResolutionOptions}
          parse={parseFloat}
        />
      </RightContent>
    </MultiRow>
    <MultiRow showTopBorder>
      <CheckboxContainer>
        <Field
          name={`${formObjectKey}.weather.checked`}
          component={FormCheckbox}
          type="checkbox"
          label="Weather (TCC, RR, T2m)"
        />
      </CheckboxContainer>
      <RightContent>
        <Field
          name={`${formObjectKey}.weather.time`}
          component={TextInput}
          rightInlineLabel="days"
          width={104}
          formatOnBlur
          format={timeOptionParser}
        />
        <SpatialLabelContainer>
          <SpatialLabel>0.5°</SpatialLabel>
        </SpatialLabelContainer>
        <Field
          name={`${formObjectKey}.weather.resolution`}
          component={SelectInput}
          width={208}
          options={TimeResolutionOptions}
          parse={parseFloat}
        />
      </RightContent>
    </MultiRow>
    <MultiRow showTopBorder>
      <CheckboxContainer>
        <Field
          name={`${formObjectKey}.MSLP.checked`}
          component={FormCheckbox}
          type="checkbox"
          label="MSLP"
        />
      </CheckboxContainer>
      <RightContent>
        <Field
          name={`${formObjectKey}.MSLP.time`}
          component={TextInput}
          rightInlineLabel="days"
          width={104}
          formatOnBlur
          format={timeOptionParser}
        />
        <SpatialLabelContainer />
        <Field
          name={`${formObjectKey}.MSLP.resolution`}
          component={SelectInput}
          width={208}
          options={TimeResolutionOptions.slice(1)}
          parse={parseFloat}
        />
      </RightContent>
    </MultiRow>
  </>
);

export default React.memo(WindWeatherForm);
