/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { downloadedRoutingByIdSelector } from 'modules/routing/redux/selectors';
import { RootState } from 'modules/main/redux';

import { normalizeRouteMeteogramData } from 'modules/meteogram/utils/routeMap';
import { HighlightedBase, H2 } from 'modules/ui/typography';
import { ProgressBar } from 'modules/ui/components';
import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications/consts';
import {
  MainContainer,
  ProgressBarContainer,
  Container,
} from './styledComponents';
import Meteogram from '../components/Meteogram';
import { ChartGroups, MeteogramName } from '../consts';

const RoutingMeteogram: React.FC = () => {
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState(null);
  const [routeName, setRouteName] = useState('');
  const route = useSelector((state: RootState) =>
    downloadedRoutingByIdSelector(state, id),
  );

  useEffect(() => {
    if (route?.metadata?.Internal?.routingName) {
      document.title = `Meteogram - ${route.metadata?.Internal?.routingName}`;
      setRouteName(route.metadata?.Internal?.routingName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.metadata?.Internal?.routingName]);

  const handler = useCallback(
    async (key: string) => {
      try {
        const normalized = await normalizeRouteMeteogramData(key);
        setChartData(normalized);
      } catch {
        dispatch(
          addNotification({
            type: NotificationTypes.Error,
            message: 'There is no data available for this route.',
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (route && route.meteogramStorageId) {
      handler(route.meteogramStorageId);
    }
  }, [route, handler]);

  const charts = Object.keys(ChartGroups);

  if (chartData) {
    return (
      <MainContainer sticky>
        <H2>
          {MeteogramName.Route}
          {routeName}
          {` (${
            route?.metadata?.Meteo?.Model === 'NCEP'
              ? 'GFS'
              : route?.metadata?.Meteo?.Model
          })`}
        </H2>
        <Meteogram
          name={MeteogramName.Route}
          routeName={routeName}
          data={chartData}
          charts={charts}
        />
      </MainContainer>
    );
  }

  return (
    <Container>
      <HighlightedBase>Collecting meteogram data.</HighlightedBase>
      <ProgressBarContainer>
        <ProgressBar
          type="indeterminate"
          backgroundColor=" linear-gradient(90deg, #0B83D7 13.54%, #89C72C 76.56%, #FFE500 100%)"
          percentage={0}
        />
      </ProgressBarContainer>
    </Container>
  );
};

export default React.memo(RoutingMeteogram);
