import { apiClient } from 'modules/apiClient';
import { InclusionZone, InclusionZoneForm } from '../types';

const getInclusionZones = async (): Promise<InclusionZone[]> => {
  const response = await apiClient.get('/v1/inclusionZones');
  return response.data;
};

const createInclusionZone = async ({
  inclusionZone,
  name,
}: InclusionZoneForm): Promise<InclusionZone> => {
  const response = await apiClient.post(`/v1/inclusionZones`, {
    zone: inclusionZone,
    name,
  });
  return response.data;
};

const updateInclusionZone = async ({
  inclusionZone,
  id,
  name,
}: InclusionZoneForm): Promise<InclusionZone> => {
  const response = await apiClient.patch(`/v1/inclusionZones/${id}`, {
    zone: inclusionZone,
    name,
  });
  return response.data;
};

const deleteInclusionZone = async ({ id }: { id: number }): Promise<void> =>
  apiClient.delete(`/v1/inclusionZones/${id}`);

export const InclusionZonesApi = {
  getInclusionZones,
  deleteInclusionZone,
  createInclusionZone,
  updateInclusionZone,
};
