import { Colors } from 'modules/ui/theme/colors';
import styled from 'styled-components';

export const CheckboxContainer = styled.label<{
  isSecondary: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;
  pointer-events: ${({ disabled }): string | undefined =>
    disabled ? 'none' : undefined};
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 1px solid rgba(132, 136, 173, 0.8);
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  &:hover input ~ .checkmark {
    background-color: #eeeef3;
  }

  input:checked ~ .checkmark {
    border: 1px solid #2178ff;
    /* background-color: rgba(132, 136, 173, 1); */
    background-color: ${({ isSecondary }): string =>
      isSecondary ? '#8488AD' : Colors.Primary};
  }
  span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #0c145a;
  }
`;
