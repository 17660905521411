import { useState, useCallback } from 'react';

const useToggle = (
  initialValue?: boolean,
): [boolean, () => void, (value: boolean) => void] => {
  const [isOpen, setIsOpen] = useState(initialValue || false);
  const toggle = useCallback(() => setIsOpen((state) => !state), []);
  return [isOpen, toggle, setIsOpen];
};

export default useToggle;
