/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { ChartGroups } from 'modules/meteogram/consts';
import {
  ChartOptions,
  convertToKnots,
  fixNumberToDecimal,
} from 'modules/meteogram/utils';

const useTraces = (data: any, type: string) => {
  const [traces, setTraces] = useState<any[]>([]);

  const convertToKnotsAndFixDecimal = (key: string, value: number) => {
    if (key === 'ws' || key === 'fg10' || key === 'cspeed') {
      return fixNumberToDecimal(convertToKnots(value), 1);
    }

    if (key === 'wdir' || key === 'mdts' || key === 'cdir' || key === 'mdww') {
      return fixNumberToDecimal(value, 0);
    }

    if (key === 'wc') {
      return fixNumberToDecimal(value, 4);
    }

    if (key === 'tcc') {
      return fixNumberToDecimal(value, 0);
    }

    return fixNumberToDecimal(value, 1);
  };

  useEffect(() => {
    const keys = Object.keys(data).sort((a, b) => a.localeCompare(b));
    const traceArray = ChartGroups[type].map((t: string) => {
      return {
        ...ChartOptions[t],
        x: keys.map((key: string) => key),
        y: keys.map((key: string) =>
          convertToKnotsAndFixDecimal(t, data[key][t]),
        ),
      };
    });

    setTraces(traceArray);
  }, [data, type]);

  return traces;
};

export default useTraces;
