import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularBase } from 'modules/ui/typography';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
  align-items: baseline;

  & > span {
    color: #172b4d;
    font-size: 14px;
  }
`;

export const Footer = styled.div`
  height: 64px;
  min-height: 64px;
  background-color: ${Colors.LightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0px 16px;
`;

export const FooterTimeText = styled(RegularBase)`
  color: ${Colors.SolidGray};
  width: 400px;
`;
