import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularBase } from 'modules/ui/typography';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  align-items: baseline;
  padding: 0px 24px 24px 24px;
`;

export const Footer = styled.div`
  height: 64px;
  min-height: 64px;
  background-color: ${Colors.LightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
`;

export const IconAndLabelText = styled.div`
  display: flex;
  flex-direction: row;
  & > svg {
    margin-left: 16px;
  }
`;

export const FooterLabel = styled(RegularBase)`
  color: ${Colors.SolidGray};
`;

export const MeteogramTypes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px 76px;

  label {
    margin: 3px 0px 3px 0px;
  }
`;
