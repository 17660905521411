import ky from 'ky';
import { LocationApiModule } from 'modules/api';

import { ThunkAction } from 'modules/main/redux';
import { setIsLiveBoatDataOk } from 'modules/metadata';
import { NotificationTypes } from 'modules/notifications';
import { addNotification } from 'modules/notifications/redux';
import { LocationActions } from './actions';

export const startTrackingLocation = (
  link: string,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  try {
    const response = await ky.get(link);
    const data = await response.json();
    const coordinates = data?.features[0]?.geometry?.coordinates;
    const heading = data?.features[0]?.properties?.heading;
    const timestamp = data?.features[0]?.properties?.time;
    if (coordinates && heading !== undefined && timestamp) {
      dispatch(
        setIsLiveBoatDataOk(link !== LocationApiModule.getCurrentLocation),
      );
      dispatch(
        LocationActions.addLocationToHistory({
          coordinates,
          heading,
          timestamp,
        }),
      );
    }
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'Error while getting boat location.',
      }),
    );
  }
};
