import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';

import db from 'modules/localForage/db';
import { TimestampedLocation } from '../types';

import { LocationActions } from './actions';

const history = createReducer([] as TimestampedLocation[], (builder) => {
  builder.addCase(LocationActions.addLocationToHistory, (state, action) => {
    const newList = [action.payload, ...state];
    const timeLimit = 60 * 12;
    if (newList.length > timeLimit) {
      return newList.slice(0, timeLimit);
    }
    return newList;
  });
});

const reducer = combineReducers({ history });

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'location',
  storage: db.app,
  whitelist: ['history'],
};

export default persistReducer(persistConfig, reducer);
