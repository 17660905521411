import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AuthActions } from '../redux';

export const useLogOutUser = (): {
  handleLogout: () => void;
} => {
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(AuthActions.logOutUser());
  }, [dispatch]);
  return { handleLogout };
};
