export enum Units {
  Knots = 'kts',
  Pressure = 'mbar',
  RainRatio = 'mm/h',
  Temperature = '°C',
  Height = 'm',
  Coverage = '',
  Period = 's',
  KnotsDiff = 'kts diff',
}
