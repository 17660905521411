import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { downloadBlobFile } from 'modules/downloads';
import { NotificationTypes } from 'modules/notifications';
import { addNotification } from 'modules/notifications/redux';

import { RoutingFilesApi } from '../api';

export const useDownloadRoutingCsv = (
  id: string,
): {
  handleDownloadCsv: () => Promise<void>;
  csvDownloading: boolean;
} => {
  const dispatch = useDispatch();
  const [csvDownloading, setCsvDownloading] = useState(false);
  const handleDownloadCsv = useCallback(async () => {
    try {
      setCsvDownloading(true);
      const {
        blob,
        contentDisposition,
      } = await RoutingFilesApi.downloadRoutingCsv(id);
      downloadBlobFile(blob, contentDisposition);
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationTypes.Error,
          message: `Error while exporting routing into CSV`,
        }),
      );
    } finally {
      setCsvDownloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return {
    handleDownloadCsv,
    csvDownloading,
  };
};
