import React, { useCallback } from 'react';

import { Modal, Button, ModalFooter } from 'modules/ui/components';
import { RegularBase } from 'modules/ui/typography';

import { useBatchCreateExclusionZones } from '../../../hooks';

import { FormContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  values: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[];
};

const UploadModal: React.FC<Props> = ({ isOpen, closeModal, values }) => {
  const { handleBatchCreate, loading } = useBatchCreateExclusionZones(
    closeModal,
  );

  const onUpload = useCallback(() => {
    handleBatchCreate(values);
  }, [handleBatchCreate, values]);

  return (
    <Modal
      isOpen={isOpen}
      width={600}
      title="Upload GeoJSON with exclusion zones"
      closeModal={closeModal}
    >
      <FormContainer>
        <RegularBase>
          The file was analyzed successfully and {values.length} exclusion zones
          have been found.
        </RegularBase>
        <br />
        <br />
        <RegularBase>
          Are you sure you want to upload and add them to your exclusion zones?
        </RegularBase>
      </FormContainer>
      <ModalFooter>
        <Button isSecondary label="Cancel" clickHandler={closeModal} />
        <Button label="Upload" isLoading={loading} clickHandler={onUpload} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(UploadModal);
