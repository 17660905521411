import { format, parseISO } from 'date-fns';

import { TimeResolution } from '../consts';
import { StoredLocalFile } from '../types';

export const formatISO = (date: Date): string =>
  format(date, "LL-dd HH:mm 'UTC'");

export const formatTimeOption = (date: string): string =>
  format(parseISO(date), "EEE dd-LL HH'h' 'UTC'");

export const formatDateAndHours = (date: string): string =>
  format(parseISO(date), 'dd-LL');

export const formatComparisonOption = (date: string | Date): string =>
  format(typeof date === 'string' ? parseISO(date) : date, "dd-LL HH'h'");

export const filterTimeResolution = (times: number[], days: number): number[] =>
  times.filter((t) => t <= days * 24);

const getTrashold = (time: number, timeResolution: number): number => {
  if (time <= 24 * 2) {
    if (timeResolution === TimeResolution.high) return 1;
    if (timeResolution === TimeResolution.medium) return 3;
    if (timeResolution === TimeResolution.low) return 3;
    return 12;
  }
  if (time <= 24 * 5) {
    if (timeResolution === TimeResolution.high) return 3;
    if (timeResolution === TimeResolution.medium) return 3;
    if (timeResolution === TimeResolution.low) return 6;
    return 12;
  }
  if (timeResolution === TimeResolution.high) return 6;
  if (timeResolution === TimeResolution.medium) return 6;
  return 12;
};

export const isTimeInTrashold = (
  time: number,
  compareTime: number,
  timeResolution: number,
): boolean => {
  const trashHold = getTrashold(time, timeResolution);

  return time > 0 && compareTime >= time && compareTime <= time + trashHold;
};

export const findRightTimestampData = (
  d: StoredLocalFile,
  time: number,
  timeResolution: number,
): boolean => {
  return isTimeInTrashold(time, d.time, timeResolution || TimeResolution.high);
};

export const isTimestampAfterMaxWithTrashold = (
  time: number,
  maxTime: number,
  timeResolution: number,
): boolean => {
  const trashHold = getTrashold(time, timeResolution);

  return time >= maxTime && time >= maxTime + trashHold;
};

export const getMaxTimeOffset = (sources: StoredLocalFile[]): number =>
  Math.max(...sources.map((s) => s.time), 0);

export const getMaxDaysFromSet = (sources: StoredLocalFile[]): number =>
  Math.round(getMaxTimeOffset(sources) / 24);

export const getMaxDaysFromMultipleSets = (
  sources: StoredLocalFile[][],
): number => Math.round(getMaxTimeOffset(sources.flat()) / 24);

export const formatDifferenceInHoursAndMinutes = (
  difference: number,
): string => {
  const hours = Math.floor(difference / 60);
  const minutes = difference % 60;
  return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${
    minutes !== 1 ? 's' : ''
  }`;
};
