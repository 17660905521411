export const downloadBlobFile = (
  blob: Blob,
  contentDisposition: string,
): void => {
  const url = window.URL.createObjectURL(blob);
  const filename = contentDisposition.split('filename=')[1].split(';')[0];
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
};
