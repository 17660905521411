/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form, Field } from 'react-final-form';
import { Map } from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import createBbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import { FormApi } from 'final-form';

import { MeteoForm } from 'modules/meteo/types';
import {
  roundCoordinates,
  toDegreesAndMinutes,
  clearCoordinatesForMeteoDownload,
} from 'modules/coordinates';
import {
  Modal,
  Button,
  FormCheckbox,
  TextInput,
  SelectInput,
  MapInstructions,
  MapSelectingControls,
} from 'modules/ui/components';
import { CheckedIcon, UncheckedIcon } from 'modules/ui/assets';
import { hideAllUi, showAllUi } from 'modules/metadata';
import {
  validateMeteoDataDownloadForm,
  allProvidersCheckedMeteoDataDownloadForm,
  parseTimeOptions,
} from 'modules/form';
import { startDownloadMeteoDataThunk } from '../../redux/thunks';
import { estimateDownload } from '../../utils/download';
import WindWeatherForm from './WindWeatherForm';
import WavesForm from './WavesForm';
import ModelHeader from './ModelHeader';
import MeteoSourcesInfo from './MeteoSourcesInfo';
import {
  SpatialResolutionOptions,
  TimeResolutionOptions,
  meteoFormInitialValues,
} from '../../consts';

import {
  Container,
  FooterContainer,
  FormContainer,
  MultiRow,
  CheckboxContainer,
  RightContent,
  MetaInfoContainer,
  MetaInfoText,
  ButtonsContainer,
  AreaCoordinatesContainer,
  SpatialLabelContainer,
  SpatialLabel,
  TimeStepResolutionLabelContainer,
} from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  map: Map;
};

const timeOptionParser = parseTimeOptions(10);

const DownloadNewMeteoDataModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  map,
}) => {
  const dispatch = useDispatch();
  const controlRef = useRef<MapboxDraw | null>(null);
  const [areaCoordinates, setAreaCoordinates] = useState<Array<number> | null>(
    null,
  );
  const [areaConfirmed, setAreaConfirmed] = useState(false);
  const onSubmit = useCallback(
    async (data) => {
      if (!areaCoordinates) return;
      dispatch(
        startDownloadMeteoDataThunk(
          data,
          areaCoordinates[0],
          areaCoordinates[1],
          areaCoordinates[2],
          areaCoordinates[3],
        ),
      );
      closeModal();
      dispatch(showAllUi());
    },
    [dispatch, areaCoordinates, closeModal],
  );

  useEffect(() => {
    dispatch(hideAllUi());
  }, [dispatch]);

  const onCreateArea = useCallback(() => {
    const data = controlRef.current.getAll();
    const coordinates = data.features[0]?.geometry?.coordinates;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const line = lineString(coordinates[0]);
    const bboxPolygon = createBbox(line);
    const [minLat, maxLat] = clearCoordinatesForMeteoDownload(
      bboxPolygon[0],
      bboxPolygon[2],
    );
    setAreaCoordinates([bboxPolygon[3], bboxPolygon[1], maxLat, minLat]);
  }, []);

  useEffect(() => {
    const { modes } = MapboxDraw;
    modes.draw_polygon = DrawRectangle;
    const Draw = new MapboxDraw({ displayControlsDefault: false });
    controlRef.current = Draw;
    map.addControl(Draw, 'bottom-left');
    Draw.changeMode('draw_polygon');
    map.on('draw.create', onCreateArea);
    map.on('draw.update', onCreateArea);
  }, [map, onCreateArea]);

  const onModalClose = useCallback(() => {
    map.removeControl(controlRef.current);
    map.off('draw.create', onCreateArea);
    map.off('draw.update', onCreateArea);
    dispatch(showAllUi());
  }, [map, onCreateArea, dispatch]);

  const cancelAreaSelection = useCallback(() => {
    setAreaCoordinates(null);
    closeModal();
    onModalClose();
    dispatch(showAllUi());
  }, [closeModal, onModalClose, dispatch]);

  const confirmAreaSelection = useCallback(() => {
    setAreaConfirmed(true);
  }, []);

  const coordinates = useMemo(
    () =>
      areaCoordinates
        ? `${toDegreesAndMinutes(
            areaCoordinates[0],
            true,
          )} ${toDegreesAndMinutes(
            areaCoordinates[3],
          )} to ${toDegreesAndMinutes(
            areaCoordinates[1],
            true,
          )} ${toDegreesAndMinutes(areaCoordinates[2])}`
        : '',
    [areaCoordinates],
  );

  const areaInDegrees = useMemo(() => {
    if (!areaCoordinates) return 0;
    const maxLat = roundCoordinates(areaCoordinates[0], true);
    const minLat = roundCoordinates(areaCoordinates[1], false);
    const maxLng = roundCoordinates(areaCoordinates[2], true);
    const minLng = roundCoordinates(areaCoordinates[3], false);
    return Math.abs((maxLat - minLat) * (maxLng - minLng));
  }, [areaCoordinates]);

  const selectAllHandler = (
    form: FormApi<MeteoForm, Partial<MeteoForm>>,
  ) => (): void => {
    // @ts-ignore
    form.change('ecmwf.MSLP.checked', true);
    // @ts-ignore
    form.change('ecmwf.wind.checked', true);
    // @ts-ignore
    form.change('ecmwf.weather.checked', true);
    // @ts-ignore
    form.change('gfs.MSLP.checked', true);
    // @ts-ignore
    form.change('gfs.wind.checked', true);
    // @ts-ignore
    form.change('gfs.weather.checked', true);
    // @ts-ignore
    form.change('ecmwf.MSLP.checked', true);
    // @ts-ignore
    form.change('ecmwfwam.swellWaves.checked', true);
    // @ts-ignore
    form.change('ecmwfwam.windWaves.checked', true);
    // @ts-ignore
    form.change('ecmwfwam.significantWaves.checked', true);
    // @ts-ignore
    form.change('gfswam.swellWaves.checked', true);
    // @ts-ignore
    form.change('gfswam.windWaves.checked', true);
    // @ts-ignore
    form.change('gfswam.significantWaves.checked', true);
    // @ts-ignore
    form.change('nemo.checked', true);
  };

  const deselectAllHandler = (
    form: FormApi<MeteoForm, Partial<MeteoForm>>,
  ) => (): void => {
    // @ts-ignore
    form.change('ecmwf.MSLP.checked', false);
    // @ts-ignore
    form.change('ecmwf.wind.checked', false);
    // @ts-ignore
    form.change('ecmwf.weather.checked', false);
    // @ts-ignore
    form.change('gfs.MSLP.checked', false);
    // @ts-ignore
    form.change('gfs.wind.checked', false);
    // @ts-ignore
    form.change('gfs.weather.checked', false);
    // @ts-ignore
    form.change('ecmwf.MSLP.checked', false);
    // @ts-ignore
    form.change('ecmwfwam.swellWaves.checked', false);
    // @ts-ignore
    form.change('ecmwfwam.windWaves.checked', false);
    // @ts-ignore
    form.change('ecmwfwam.significantWaves.checked', false);
    // @ts-ignore
    form.change('gfswam.swellWaves.checked', false);
    // @ts-ignore
    form.change('gfswam.windWaves.checked', false);
    // @ts-ignore
    form.change('gfswam.significantWaves.checked', false);
    // @ts-ignore
    form.change('nemo.checked', false);
  };

  return (
    <>
      <MapSelectingControls
        onCancel={cancelAreaSelection}
        confirmDisabled={!areaCoordinates}
        onConfirm={confirmAreaSelection}
      />
      <MapInstructions
        text={
          areaCoordinates
            ? `Selected area: ${coordinates}`
            : 'Select the area on the map'
        }
      />
      <Modal
        isOpen={isOpen && !!areaCoordinates && areaConfirmed}
        width={900}
        title="Download meteo visualizations"
        closeModal={closeModal}
        onRequestClose={onModalClose}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={meteoFormInitialValues}
          validate={validateMeteoDataDownloadForm}
          render={({
            handleSubmit,
            invalid,
            form,
            values,
          }): React.ReactNode => {
            const allSelected = allProvidersCheckedMeteoDataDownloadForm(
              values,
            );
            const windComparisonEligible =
              values.ecmwf.wind.checked &&
              values.gfs.wind.checked &&
              values.ecmwf.wind.spatial === values.gfs.wind.spatial;
            return (
              <>
                <AreaCoordinatesContainer>
                  {areaCoordinates && <b>Area:</b>}
                  {areaCoordinates && ` ${coordinates}. `}
                  <MeteoSourcesInfo />
                </AreaCoordinatesContainer>
                <Container>
                  <FormContainer>
                    <WindWeatherForm title="ECMWF" formObjectKey="ecmwf" />
                    <WindWeatherForm title="GFS" formObjectKey="gfs" />
                    <ModelHeader title="ECMWF/GFS wind comparison" />
                    <MultiRow showTopBorder alignLeft={!windComparisonEligible}>
                      {!windComparisonEligible && (
                        <>
                          <UncheckedIcon />
                          <SpatialLabel>
                            To enable visual wind comparison, please select both
                            the ECMWF Wind and GFS Wind with the same spatial
                            resolution.
                          </SpatialLabel>
                        </>
                      )}
                      {windComparisonEligible && (
                        <>
                          <CheckboxContainer>
                            <CheckedIcon />
                            <SpatialLabel>Wind (Speed, Direction)</SpatialLabel>
                          </CheckboxContainer>
                          <RightContent>
                            <SpatialLabelContainer>
                              <SpatialLabel>
                                {`${Math.min(
                                  values.ecmwf.wind.time,
                                  values.gfs.wind.time,
                                )} days`}
                              </SpatialLabel>
                            </SpatialLabelContainer>
                            <SpatialLabelContainer>
                              <SpatialLabel>
                                {`${values.ecmwf.wind.spatial}°`}
                              </SpatialLabel>
                            </SpatialLabelContainer>
                            <TimeStepResolutionLabelContainer>
                              <SpatialLabel>
                                {
                                  TimeResolutionOptions.find(
                                    (tr) =>
                                      tr.value === values.ecmwf.wind.resolution,
                                  )?.label
                                }
                              </SpatialLabel>
                            </TimeStepResolutionLabelContainer>
                          </RightContent>
                        </>
                      )}
                    </MultiRow>
                    <WavesForm
                      title="ECMWF WAM wave model"
                      formObjectKey="ecmwfwam"
                    />
                    <WavesForm
                      title="GFS Wavewatch III wave model"
                      formObjectKey="gfswam"
                      isGfs
                    />
                    <ModelHeader title="Mercator Ocean NEMO ocean model" />
                    <MultiRow showTopBorder>
                      <CheckboxContainer>
                        <Field
                          name="nemo.checked"
                          component={FormCheckbox}
                          type="checkbox"
                          label="Current (Speed, Direction) + SST"
                        />
                      </CheckboxContainer>
                      <RightContent>
                        <Field
                          name="nemo.time"
                          component={TextInput}
                          rightInlineLabel="days"
                          width={104}
                          formatOnBlur
                          format={timeOptionParser}
                        />
                        <Field
                          name="nemo.spatial"
                          component={SelectInput}
                          width={104}
                          options={SpatialResolutionOptions}
                          parse={parseFloat}
                          type="number"
                        />
                        <Field
                          name="nemo.resolution"
                          component={SelectInput}
                          width={208}
                          options={TimeResolutionOptions}
                          parse={parseFloat}
                        />
                      </RightContent>
                    </MultiRow>
                  </FormContainer>
                </Container>
                <FooterContainer>
                  <MetaInfoContainer>
                    <MetaInfoText>
                      {`Estimated download size: around 
                      ${estimateDownload(values, areaInDegrees)}`}
                    </MetaInfoText>
                  </MetaInfoContainer>
                  <ButtonsContainer>
                    <Button
                      isTertiary
                      label={allSelected ? 'Clear all' : 'Select all'}
                      clickHandler={
                        allSelected
                          ? deselectAllHandler(form)
                          : selectAllHandler(form)
                      }
                    />
                    <Button
                      disabled={invalid}
                      label="Start download"
                      clickHandler={handleSubmit}
                    />
                  </ButtonsContainer>
                </FooterContainer>
              </>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default React.memo(DownloadNewMeteoDataModal);
