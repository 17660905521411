/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import format from 'date-fns/format';

import { UploadIcon, CheckedIcon } from 'modules/ui/assets';
import { RegularSm } from 'modules/ui/typography';

import InputLabel from '../InputLabel';
import Button from '../Button';

import {
  Container,
  UploadedFileContainer,
  FileMetadataContainer,
} from './styledComponents';

type Props = {
  onChange: (files: File) => void;
  label: string;
  file: File | null;
  accept?: string;
};

const DragAndDrop: React.FC<Props> = ({ onChange, label, file, accept }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange(acceptedFiles && acceptedFiles[0]);
    },
    [onChange],
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
  });

  const replaceHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
      if (e) {
        e.stopPropagation();
      }
      open();
    },
    [open],
  );

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Container {...getRootProps()}>
        <input
          {...getInputProps()}
          accept={accept || '.csv, .pol, .tcs, .xls, .xsls, .xml, .text'}
        />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : !file ? (
          <>
            <UploadIcon />
            <p>Drag & drop your file here, or choose a file</p>
          </>
        ) : (
          <UploadedFileContainer>
            <CheckedIcon width={32} height={32} />
            <FileMetadataContainer>
              <RegularSm>{`Filename: ${file.name}`}</RegularSm>
              <RegularSm>
                {`Date added: ${format(
                  new Date(file.lastModified),
                  'EEEE, MMM dd, yyyy',
                )}`}
              </RegularSm>
            </FileMetadataContainer>
            <Button isSecondary label="Replace" clickHandler={replaceHandler} />
          </UploadedFileContainer>
        )}
      </Container>
    </>
  );
};

export default React.memo(DragAndDrop);
