import { createSelector } from '@reduxjs/toolkit';
import { parseISO, differenceInHours, addDays, addHours } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import isOverlap from '@turf/boolean-overlap';
import bboxPolygon from '@turf/bbox-polygon';

import { RootState } from 'modules/main/redux';
import {
  DownloadQueueItem,
  WaveMeteoDataSet,
  WindMeteoDataSet,
  OceanMeteoDataSet,
  SourcesForFiltering,
  TimedBboxConstraint,
  StoredLocalFile,
} from '../types';
import {
  findRightTimestampData,
  formatComparisonOption,
  getMaxTimeOffset,
  isTimestampAfterMaxWithTrashold,
  formatDateAndHours,
} from '../utils/time';
import {
  windTooltipMessage,
  waveTooltipMessage,
  oceanTooltipMessage,
  windComparisonTooltipMessage,
} from '../utils/tooltipMessages';
import {
  buildWaveDataSetConstraints,
  buildWindDataSetConstraints,
} from '../utils/dataSetConstraints';
import {
  getWaveDataSourceKeys,
  getWindDataSourceKeys,
} from '../utils/dataSetsSourceKeys';

export const downloadQueueFirstItemSelector = (
  state: RootState,
): DownloadQueueItem => state.meteo.downloadQueue.list[0];

export const ecmwfDataSetsSelector = (
  state: RootState,
): RootState['meteo']['ecmwf'] => state.meteo.ecmwf;

export const ecmwfDataSetsArraySelector = createSelector(
  [ecmwfDataSetsSelector],
  (persistedDataSets) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _persist, ...dataSets } = persistedDataSets;
    return Object.values(dataSets);
  },
);

export const ecmwfDataSetByIdSelector = (
  state: RootState,
  id: string,
): WindMeteoDataSet | undefined => state.meteo.ecmwf[id];

export const gfsDataSetsSelector = (
  state: RootState,
): RootState['meteo']['gfs'] => state.meteo.gfs;

export const gfsDataSetsArraySelector = createSelector(
  [gfsDataSetsSelector],
  (persistedDataSets) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _persist, ...dataSets } = persistedDataSets;
    return Object.values(dataSets);
  },
);

export const gfsDataSetByIdSelector = (
  state: RootState,
  id: string,
): WindMeteoDataSet | undefined => state.meteo.gfs[id];

export const wamDataSetsSelector = (
  state: RootState,
): RootState['meteo']['wam'] => state.meteo.wam;

export const wamDataSetByIdSelector = (
  state: RootState,
  id: string,
): WaveMeteoDataSet | undefined => state.meteo.wam[id];

export const gfsWamDataSetsSelector = (
  state: RootState,
): RootState['meteo']['gfsWam'] => state.meteo.gfsWam;

export const gfsWamDataSetByIdSelector = (
  state: RootState,
  id: string,
): WaveMeteoDataSet | undefined => state.meteo.gfsWam[id];

export const nemoDataSetsSelector = (
  state: RootState,
): RootState['meteo']['nemo'] => state.meteo.nemo;

export const nemoDataSetByIdSelector = (
  state: RootState,
  id: string,
): OceanMeteoDataSet | undefined => state.meteo.nemo[id];

export const timeOffsetSelector = (state: RootState): number =>
  state.meteo.timeOffset;

export const allEcmwfTimeSelector = createSelector(
  [ecmwfDataSetsSelector],
  (dataSets) => {
    const timestamps = Array.from(
      new Set(Object.values(dataSets).map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps;
  },
);

export const ecmwfTimeOptionsSelector = createSelector(
  [allEcmwfTimeSelector],
  (timestamps) => {
    return timestamps.slice(0, 2);
  },
);

export const infoEcmwfTimeOptionsSelector = createSelector(
  [ecmwfDataSetsSelector],
  (dataSets) => {
    const valuesArray = Object.values(dataSets);
    const timestamps = Array.from(
      new Set(valuesArray.map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    const options: Array<{ option: string; info: string }> = [];
    timestamps.forEach((value, index) => {
      if (index > 1) return;
      const sets = valuesArray.filter((set) => set.timestamp === value);
      const info = windTooltipMessage(sets);
      options.push({
        option: value,
        info,
      });
    });
    return options;
  },
);

export const ecmwfDataSetsForDeleteSelector = createSelector(
  [ecmwfDataSetsSelector, allEcmwfTimeSelector],
  (data, timestamps) => {
    const oldTimes = timestamps.slice(2);
    return Object.values(data).filter((dataSet) =>
      oldTimes.includes(dataSet.timestamp),
    );
  },
);

export const allGfsTimeSelector = createSelector(
  [gfsDataSetsSelector],
  (dataSets) => {
    const timestamps = Array.from(
      new Set(Object.values(dataSets).map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps;
  },
);

export const gfsTimeOptionsSelector = createSelector(
  [allGfsTimeSelector],
  (timestamps) => {
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps.slice(0, 2);
  },
);

export const infoGfsTimeOptionsSelector = createSelector(
  [gfsDataSetsSelector],
  (dataSets) => {
    const valuesArray = Object.values(dataSets);
    const timestamps = Array.from(
      new Set(valuesArray.map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    const options: Array<{ option: string; info: string }> = [];
    timestamps.forEach((value, index) => {
      if (index > 1) return;
      const sets = valuesArray.filter((set) => set.timestamp === value);
      const info = windTooltipMessage(sets);
      options.push({
        option: value,
        info,
      });
    });
    return options;
  },
);

export const gfsDataSetsForDeleteSelector = createSelector(
  [gfsDataSetsSelector, allGfsTimeSelector],
  (data, timestamps) => {
    const oldTimes = timestamps.slice(2);
    return Object.values(data).filter((dataSet) =>
      oldTimes.includes(dataSet.timestamp),
    );
  },
);

export const allWamTimeSelector = createSelector(
  [wamDataSetsSelector],
  (dataSets) => {
    const timestamps = Array.from(
      new Set(Object.values(dataSets).map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps;
  },
);

export const wamTimeOptionsSelector = createSelector(
  [allWamTimeSelector],
  (timestamps) => {
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps.slice(0, 2);
  },
);

export const infoWamTimeOptionsSelector = createSelector(
  [wamDataSetsSelector],
  (dataSets) => {
    const valuesArray = Object.values(dataSets);
    const timestamps = Array.from(
      new Set(valuesArray.map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    const options: Array<{ option: string; info: string }> = [];
    timestamps.forEach((value, index) => {
      if (index > 1) return;
      const sets = valuesArray.filter((set) => set.timestamp === value);
      const info = waveTooltipMessage(sets);
      options.push({
        option: value,
        info,
      });
    });
    return options;
  },
);

export const wamDataSetsForDeleteSelector = createSelector(
  [wamDataSetsSelector, allWamTimeSelector],
  (data, timestamps) => {
    const oldTimes = timestamps.slice(2);
    return Object.values(data).filter((dataSet) =>
      oldTimes.includes(dataSet.timestamp),
    );
  },
);

export const allGfsWamTimeSelector = createSelector(
  [gfsWamDataSetsSelector],
  (dataSets) => {
    const timestamps = Array.from(
      new Set(Object.values(dataSets).map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps;
  },
);

export const gfsWamTimeOptionsSelector = createSelector(
  [allGfsWamTimeSelector],
  (timestamps) => {
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps.slice(0, 2);
  },
);

export const infoGfsWamTimeOptionsSelector = createSelector(
  [gfsWamDataSetsSelector],
  (dataSets) => {
    const valuesArray = Object.values(dataSets);
    const timestamps = Array.from(
      new Set(valuesArray.map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    const options: Array<{ option: string; info: string }> = [];
    timestamps.forEach((value, index) => {
      if (index > 1) return;
      const sets = valuesArray.filter((set) => set.timestamp === value);
      const info = waveTooltipMessage(sets);
      options.push({
        option: value,
        info,
      });
    });
    return options;
  },
);

export const gfsWamDataSetsForDeleteSelector = createSelector(
  [gfsWamDataSetsSelector, allGfsWamTimeSelector],
  (data, timestamps) => {
    const oldTimes = timestamps.slice(2);
    return Object.values(data).filter((dataSet) =>
      oldTimes.includes(dataSet.timestamp),
    );
  },
);

export const allNemoTimeSelector = createSelector(
  [nemoDataSetsSelector],
  (dataSets) => {
    const timestamps = Array.from(
      new Set(Object.values(dataSets).map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps;
  },
);

export const nemoTimeOptionsSelector = createSelector(
  [allNemoTimeSelector],
  (timestamps) => {
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    return timestamps.slice(0, 2);
  },
);

export const infoNemoTimeOptionsSelector = createSelector(
  [nemoDataSetsSelector],
  (dataSets) => {
    const valuesArray = Object.values(dataSets);
    const timestamps = Array.from(
      new Set(valuesArray.map((item) => item.timestamp)),
    ).filter((_) => _);
    timestamps.sort((a, b) => (b > a ? 1 : -1));
    const options: Array<{ option: string; info: string }> = [];
    timestamps.forEach((value, index) => {
      if (index > 1) return;
      const sets = valuesArray.filter((set) => set.timestamp === value);
      const info = oceanTooltipMessage(sets);
      options.push({
        option: value,
        info,
      });
    });
    return options;
  },
);
export const nemoDataSetsForDeleteSelector = createSelector(
  [nemoDataSetsSelector, allNemoTimeSelector],
  (data, timestamps) => {
    const oldTimes = timestamps.slice(2);
    return Object.values(data).filter((dataSet) =>
      oldTimes.includes(dataSet.timestamp),
    );
  },
);

export const ecmwfTimeSetSelector = (state: RootState): string =>
  state.meteo.ecmwfTimeSet.value || ecmwfTimeOptionsSelector(state)[0];

export const gfsTimeSetSelector = (state: RootState): string =>
  state.meteo.gfsTimeSet.value || gfsTimeOptionsSelector(state)[0];

export const wamTimeSetSelector = (state: RootState): string =>
  state.meteo.wamTimeSet.value || wamTimeOptionsSelector(state)[0];

export const gfsWamTimeSetSelector = (state: RootState): string =>
  state.meteo.gfsWamTimeSet.value || gfsWamTimeOptionsSelector(state)[0];

export const nemoTimeSetSelector = (state: RootState): string =>
  state.meteo.nemoTimeSet.value || nemoTimeOptionsSelector(state)[0];

export const firstDataSetsDateSelector = createSelector(
  [
    ecmwfTimeOptionsSelector,
    gfsTimeOptionsSelector,
    wamTimeOptionsSelector,
    gfsWamTimeOptionsSelector,
    nemoTimeOptionsSelector,
  ],
  (ecmwfDates, gsfDates, wamDates, gfsWamDates, nemoDates) => {
    const dates = [
      ...ecmwfDates,
      ...gsfDates,
      ...gfsWamDates,
      ...wamDates,
      ...nemoDates,
    ];
    dates.sort((a, b) => (b > a ? 1 : -1));
    const date =
      dates[0] && (dates[0].endsWith('Z') ? dates[0] : dates[0].concat('Z'));
    // 2020-11-03T12:00:00
    return dates[0] && `${date.split('T')[0]}T00:00:00`;
  },
);

export const ecmwfTimeSetOffsetSelector = createSelector(
  [ecmwfTimeSetSelector, firstDataSetsDateSelector],
  (ecmwfTime, firstDate) => {
    return differenceInHours(parseISO(firstDate), parseISO(ecmwfTime));
  },
);

export const gfsTimeSetOffsetSelector = createSelector(
  [gfsTimeSetSelector, firstDataSetsDateSelector],
  (gfsTime, firstDate) => {
    return differenceInHours(parseISO(firstDate), parseISO(gfsTime));
  },
);

export const wamTimeSetOffsetSelector = createSelector(
  [wamTimeSetSelector, firstDataSetsDateSelector],
  (wamTime, firstDate) => {
    return differenceInHours(parseISO(firstDate), parseISO(wamTime));
  },
);

export const gfsWamTimeSetOffsetSelector = createSelector(
  [gfsWamTimeSetSelector, firstDataSetsDateSelector],
  (wamTime, firstDate) => {
    return differenceInHours(parseISO(firstDate), parseISO(wamTime));
  },
);

export const nemoTimeSetOffsetSelector = createSelector(
  [nemoTimeSetSelector, firstDataSetsDateSelector],
  (nemoTime, firstDate) => {
    return differenceInHours(parseISO(firstDate), parseISO(nemoTime));
  },
);

export const currentEcmwfDataSets = createSelector(
  [ecmwfDataSetsSelector, ecmwfTimeSetSelector],
  (ecmwfDataSets, ecmwfTimeSet) => {
    const values = Object.values(ecmwfDataSets).filter(
      (dataSet) => ecmwfTimeSet && dataSet.timestamp === ecmwfTimeSet,
    );
    values.sort((a, b) => a.windResolution - b.windResolution);
    return values;
  },
);

export const currentEcmwfDataSetsComponentsSelector = createSelector(
  [currentEcmwfDataSets],
  (dataSets) => {
    const isWindPresent = dataSets.some((ds) => ds.wind.length);
    const isWeatherPresent = dataSets.some((ds) => ds.weather.length);
    const isMslpPresent = dataSets.some((ds) => ds.mslp.length);
    return {
      isWindPresent,
      isWeatherPresent,
      isMslpPresent,
    };
  },
);

export const currentGfsDataSets = createSelector(
  [gfsDataSetsSelector, gfsTimeSetSelector],
  (gfsDataSets, gfsTimeSet) => {
    const values = Object.values(gfsDataSets).filter(
      (dataSet) => gfsTimeSet && dataSet.timestamp === gfsTimeSet,
    );
    values.sort((a, b) => a.windResolution - b.windResolution);
    return values;
  },
);

export const currentGfsDataSetsComponentsSelector = createSelector(
  [currentGfsDataSets],
  (dataSets) => {
    const isWindPresent = dataSets.some((ds) => ds.wind.length);
    const isWeatherPresent = dataSets.some((ds) => ds.weather.length);
    const isMslpPresent = dataSets.some((ds) => ds.mslp.length);
    return {
      isWindPresent,
      isWeatherPresent,
      isMslpPresent,
    };
  },
);

export const currentWamDataSets = createSelector(
  [wamDataSetsSelector, wamTimeSetSelector],
  (wamDataSets, wamTimeSet) =>
    Object.values(wamDataSets).filter(
      (dataSet) => wamTimeSet && dataSet.timestamp === wamTimeSet,
    ),
);

export const currentGfsWamDataSets = createSelector(
  [gfsWamDataSetsSelector, gfsWamTimeSetSelector],
  (wamDataSets, wamTimeSet) =>
    Object.values(wamDataSets).filter(
      (dataSet) => wamTimeSet && dataSet.timestamp === wamTimeSet,
    ),
);

export const currentWamDataSetsComponentsSelector = createSelector(
  [currentWamDataSets],
  (dataSets) => {
    const isSwellPresent = dataSets.some((ds) => ds.swellWaves.length);
    const isWwavePresent = dataSets.some((ds) => ds.windWaves.length);
    const isWavePresent = dataSets.some((ds) => ds.significantWaves.length);
    return {
      isSwellPresent,
      isWwavePresent,
      isWavePresent,
    };
  },
);

export const currentGfsWamDataSetsComponentsSelector = createSelector(
  [currentGfsWamDataSets],
  (dataSets) => {
    const isSwellPresent = dataSets.some((ds) => ds.swellWaves.length);
    const isWwavePresent = dataSets.some((ds) => ds.windWaves.length);
    const isWavePresent = dataSets.some((ds) => ds.significantWaves.length);
    return {
      isSwellPresent,
      isWwavePresent,
      isWavePresent,
    };
  },
);

export const currentNemoDataSets = createSelector(
  [nemoDataSetsSelector, nemoTimeSetSelector],
  (nemoDataSets, nemoTimeSet) =>
    Object.values(nemoDataSets).filter(
      (dataSet) => nemoTimeSet && dataSet.timestamp === nemoTimeSet,
    ),
);

export const currentEcmwfDataSetsWithConstraintsSelector = createSelector(
  [currentEcmwfDataSets],
  (dataSet) => buildWindDataSetConstraints(dataSet),
);

export const ecmwfDataSetsSourceKeysSelector = createSelector(
  [
    currentEcmwfDataSetsWithConstraintsSelector,
    timeOffsetSelector,
    ecmwfTimeSetOffsetSelector,
  ],
  (dataSetWithConstraints, timeOffset, timeOffsetCorrection) =>
    getWindDataSourceKeys(
      dataSetWithConstraints,
      timeOffset,
      timeOffsetCorrection,
    ),
);

export const currentGfsDataSetsWithConstraintsSelector = createSelector(
  [currentGfsDataSets],
  (dataSet) => buildWindDataSetConstraints(dataSet),
);

export const gfsDataSetsSourceKeysSelector = createSelector(
  [
    currentGfsDataSetsWithConstraintsSelector,
    timeOffsetSelector,
    gfsTimeSetOffsetSelector,
  ],
  (dataSetWithConstraints, timeOffset, timeOffsetCorrection) =>
    getWindDataSourceKeys(
      dataSetWithConstraints,
      timeOffset,
      timeOffsetCorrection,
    ),
);

export const currentWamDataSetsWithConstraintsSelector = createSelector(
  [currentWamDataSets],
  (dataSet) => buildWaveDataSetConstraints(dataSet),
);

export const wamDataSetsSourceKeysSelector = createSelector(
  [
    currentWamDataSetsWithConstraintsSelector,
    timeOffsetSelector,
    wamTimeSetOffsetSelector,
  ],
  (dataSetWithConstraint, timeOffset, timeOffsetCorrection) =>
    getWaveDataSourceKeys(
      dataSetWithConstraint,
      timeOffset,
      timeOffsetCorrection,
    ),
);

export const currentGfsWamDataSetsWithConstraintsSelector = createSelector(
  [currentGfsWamDataSets],
  (dataSet) => buildWaveDataSetConstraints(dataSet),
);

export const gfsWamDataSetsSourceKeysSelector = createSelector(
  [
    currentGfsWamDataSetsWithConstraintsSelector,
    timeOffsetSelector,
    gfsWamTimeSetOffsetSelector,
  ],
  (dataSetWithConstraint, timeOffset, timeOffsetCorrection) =>
    getWaveDataSourceKeys(
      dataSetWithConstraint,
      timeOffset,
      timeOffsetCorrection,
    ),
);

export const currentNemoDataSetsWithConstraintsSelector = createSelector(
  [currentNemoDataSets],
  (dataSet) => {
    const boundingBoxes = dataSet.map((set) =>
      bboxPolygon([set.minLng, set.minLat, set.maxLng, set.maxLat]),
    );
    const constraints: Array<TimedBboxConstraint[]> = [];
    for (let i = 1; i < dataSet.length; i += 1) {
      const boundaris = [] as TimedBboxConstraint[];
      for (let j = 0; j < i; j += 1) {
        const isCurrentPresent = !!dataSet[j].current.length;
        const maxCurrentTimestep = getMaxTimeOffset(dataSet[j].current);
        if (isOverlap(boundingBoxes[j], boundingBoxes[i])) {
          if (isCurrentPresent) {
            boundaris.push({
              bbox: boundingBoxes[j],
              maxTime: maxCurrentTimestep,
            });
          }
        }
      }
      constraints[i] = boundaris;
    }
    return {
      dataSet,
      constraints,
    };
  },
);

export const nemoDataSetsSourceKeysSelector = createSelector(
  [
    currentNemoDataSetsWithConstraintsSelector,
    timeOffsetSelector,
    nemoTimeSetOffsetSelector,
  ],
  ({ dataSet, constraints }, timeOffset, timeOffsetCorrection) => {
    const calculatedTimeOffset = timeOffset + timeOffsetCorrection;

    const currentSources = dataSet
      .map((data, index) => {
        const fileName = data.current?.find((d) =>
          findRightTimestampData(d, calculatedTimeOffset, data.currentTime),
        )?.storageKey;
        if (fileName) {
          return {
            fileName,
            constraints:
              constraints[index] &&
              constraints[index]
                .map((w) =>
                  isTimestampAfterMaxWithTrashold(
                    calculatedTimeOffset,
                    w.maxTime,
                    data.currentTime,
                  )
                    ? null
                    : w.bbox,
                )
                .filter((_) => _),
          };
        }
        return undefined;
      })
      .filter((_) => !!_) as Array<SourcesForFiltering>;
    return {
      currentSources,
    };
  },
);

export const windComparisonOptionsSelector = createSelector(
  [ecmwfDataSetsArraySelector, gfsDataSetsArraySelector],
  (ecmwfSets, gfsSets) => {
    const options: Array<
      {
        gfsSources: StoredLocalFile[];
        ecmwfSources: StoredLocalFile[];
        gfsOffset: number;
        ecmwfOffset: number;
        id: string;
        label: string;
        info: string;
        ecmwfTimeResolution: number;
        gfsTimeResolution: number;
      } & Pick<
        WindMeteoDataSet,
        'minLat' | 'maxLat' | 'maxLng' | 'minLng' | 'timestamp'
      >
    > = [];
    const now = new Date();
    const sortedEcmwfSets = [...ecmwfSets].sort((a, b) =>
      a.timestamp < b.timestamp ? 1 : -1,
    );
    sortedEcmwfSets.forEach((set) => {
      const gfsMatch = gfsSets.find(
        (gfsSet) =>
          gfsSet.maxLat === set.maxLat &&
          gfsSet.minLat === set.minLat &&
          gfsSet.maxLng === set.maxLng &&
          gfsSet.minLng === set.minLng,
      );
      if (gfsMatch) {
        const isEcmwfOlder = set.timestamp < gfsMatch.timestamp;
        const maxStartDate = isEcmwfOlder ? gfsMatch.timestamp : set.timestamp;
        const difference = Math.abs(
          differenceInHours(
            parseISO(gfsMatch.timestamp),
            parseISO(set.timestamp),
          ),
        );
        const ecmwfOffset = isEcmwfOlder ? difference : 0;
        const gfsOffset = isEcmwfOlder ? 0 : difference;
        const dateFrom = formatDateAndHours(maxStartDate);
        const timeOffsetTo = Math.min(
          getMaxTimeOffset(set.wind) - ecmwfOffset,
          getMaxTimeOffset(gfsMatch.wind) - gfsOffset,
        );
        if (timeOffsetTo < 0) return;
        const daysDifference = timeOffsetTo / 24;
        const daysToAdd = Math.floor(daysDifference);
        const hoursToAdd = timeOffsetTo % 24;
        const dateTo = addHours(
          addDays(parseISO(maxStartDate), daysToAdd),
          hoursToAdd,
        );
        if (now < dateTo) {
          const dateToString = formatComparisonOption(dateTo);
          const info = windComparisonTooltipMessage(
            set,
            gfsMatch,
            daysDifference,
          );
          options.push({
            id: `${set.id}-${gfsMatch.id}`,
            label: `${dateFrom} - ${dateToString}`,
            gfsSources: gfsMatch.wind,
            ecmwfSources: set.wind,
            timestamp: maxStartDate,
            minLat: set.minLat,
            maxLat: set.maxLat,
            minLng: set.minLng,
            maxLng: set.maxLng,
            ecmwfTimeResolution: set.windResolution,
            gfsTimeResolution: gfsMatch.windResolution,
            info,
            ecmwfOffset,
            gfsOffset,
          });
        }
      }
    });
    return options;
  },
);

export const windComparisonSourcesSelector = createSelector(
  [
    windComparisonOptionsSelector,
    firstDataSetsDateSelector,
    timeOffsetSelector,
  ],
  (sets, firstDate, timeOffset) => {
    return sets.map((set) => {
      const timeCorrection = differenceInHours(
        parseISO(firstDate),
        parseISO(set.timestamp),
      );
      const calculatedTimeOffset = timeOffset + timeCorrection;
      return {
        id: set.id,
        ecmwfStorageKey: set.ecmwfSources?.find((d) =>
          findRightTimestampData(
            d,
            calculatedTimeOffset + set.ecmwfOffset,
            set.ecmwfTimeResolution,
          ),
        )?.storageKey,
        gfsStorageKey: set.gfsSources?.find((d) =>
          findRightTimestampData(
            d,
            calculatedTimeOffset + set.gfsOffset,
            set.gfsTimeResolution,
          ),
        )?.storageKey,
      };
    });
  },
);
