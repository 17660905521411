/* eslint-disable @typescript-eslint/no-explicit-any */
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers';

import { getWeatherDataFromPixel } from 'modules/meteo/utils/weather';
import { getWindDataFromPixel } from 'modules/meteo/utils/wind';
import { getWwaveDataFromPixel } from 'modules/meteo/utils/wwave';
import { getSwaveDataFromPixel } from 'modules/meteo/utils/swave';
import { getWaveDataFromPixel } from 'modules/meteo/utils/wave';
import { getOceanDataFromPixel } from 'modules/meteo/utils/ocean';

import {
  Coordinates,
  TimeAndKey,
  EcmwfSet,
  WamSet,
  NemoSet,
  WindFeatures,
  WwaveFeatures,
  SwaveFeatures,
  WeatherFeatures,
  WaveFeatures,
  OceanFeatures,
  StorageInfo,
  AllEcmwfFeatures,
  AllWamFeatures,
  AllNemoFeatures,
} from 'modules/meteogram/types';
import { MeteoSets } from 'modules/meteogram/consts';

export const isRelatedPolygon = (
  box: EcmwfSet | WamSet | NemoSet,
  coords: Coordinates,
): boolean => {
  const { maxLat, minLng, minLat, maxLng } = box;
  const point = turfPoint([coords.lng, coords.lat]);
  const poly = turfPolygon([
    [
      [minLng, maxLat],
      [minLng, minLat],
      [maxLng, minLat],
      [maxLng, maxLat],
      [minLng, maxLat],
    ],
  ]);
  return booleanPointInPolygon(point, poly);
};

export const getRelatedSets = (
  sets: (EcmwfSet | WamSet | NemoSet)[],
  coords: Coordinates,
): (EcmwfSet | WamSet | NemoSet)[] => {
  return sets.filter((set: EcmwfSet | WamSet | NemoSet) =>
    isRelatedPolygon(set, coords),
  );
};

const getWindFeatures = async (
  data: TimeAndKey,
  coords: Coordinates,
): Promise<WindFeatures> => {
  const values = await getWindDataFromPixel(data.storageKey, {
    point: [coords.lat, coords.lng],
  });

  return {
    time: data.time,
    wdir: values.wd,
    ws: values.ws,
    fg10: values.g,
  };
};

const getWeatherFeatures = async (
  data: TimeAndKey,
  coords: Coordinates,
): Promise<WeatherFeatures> => {
  const values = await getWeatherDataFromPixel(data.storageKey, {
    point: [coords.lat, coords.lng],
  });
  return {
    time: data.time,
    t2m: values.t,
    tcc: values.c,
    tp: values.r,
  };
};

const getSWaveFeatures = async (
  data: TimeAndKey,
  coords: Coordinates,
): Promise<SwaveFeatures> => {
  const values = await getSwaveDataFromPixel(data.storageKey, {
    point: [coords.lat, coords.lng],
  });

  return {
    time: data.time,
    shts: values.h,
    mdts: values.d,
    mpts: values.p,
  };
};

const getWWaveFeatures = async (
  data: TimeAndKey,
  coords: Coordinates,
): Promise<WwaveFeatures> => {
  const values = await getWwaveDataFromPixel(data.storageKey, {
    point: [coords.lat, coords.lng],
  });

  return {
    time: data.time,
    mdww: values.d,
    mpww: values.p,
    shww: values.h,
  };
};

const getWaveFeatures = async (
  data: TimeAndKey,
  coords: Coordinates,
): Promise<WaveFeatures> => {
  const values = await getWaveDataFromPixel(data.storageKey, {
    point: [coords.lat, coords.lng],
  });

  return {
    time: data.time,
    swh: values.h,
    wc: values.c,
    mwd: values.d,
  };
};

const getOceanFeatures = async (
  data: TimeAndKey,
  coords: Coordinates,
): Promise<OceanFeatures> => {
  const values = await getOceanDataFromPixel(data.storageKey, {
    point: [coords.lat, coords.lng],
  });

  return {
    time: data.time,
    sst: values.t,
    cspeed: values.s,
    cdir: values.d,
  };
};

const getAllFeaturesFormSet = async (
  box: EcmwfSet | WamSet | NemoSet,
  coords: Coordinates,
): Promise<AllEcmwfFeatures | AllWamFeatures | AllNemoFeatures | undefined> => {
  const type: string = box.id.split('-')[0];

  if (type === MeteoSets.ECMWF || type === MeteoSets.GFS) {
    const { weather, wind } = box as EcmwfSet;

    return {
      time: box.timestamp,
      weather: await Promise.all(
        weather.map((w: StorageInfo) => getWeatherFeatures(w, coords)),
      ),
      wind: await Promise.all(
        wind.map((w: StorageInfo) => getWindFeatures(w, coords)),
      ),
    };
  }

  if (type === MeteoSets.WAM) {
    const { windWaves, swellWaves, significantWaves } = box as WamSet;

    return {
      time: box.timestamp,
      swave: await Promise.all(
        swellWaves.map((sw: StorageInfo) => getSWaveFeatures(sw, coords)),
      ),
      wwave: await Promise.all(
        windWaves.map((ww: StorageInfo) => getWWaveFeatures(ww, coords)),
      ),
      wave: await Promise.all(
        significantWaves.map((w: StorageInfo) => getWaveFeatures(w, coords)),
      ),
    };
  }

  if (type === MeteoSets.NEMO) {
    const { current } = box as NemoSet;
    return {
      time: box.timestamp,
      current: await Promise.all(
        current.map((c: StorageInfo) => getOceanFeatures(c, coords)),
      ),
    };
  }

  return undefined;
};

const generateTime = (hours: number, time: string): string => {
  const t = new Date(time).setHours(hours);
  return new Date(t).toISOString().split('.')[0];
};

const mapAttributes = (data: any, time: string): any => {
  return data.reduce((obj: any, item: any) => {
    const key = generateTime(item.time, time);
    delete item.time;
    if (obj[key]) {
      obj[key] = { ...obj[key], ...item };
    } else {
      obj[key] = {
        ...item,
      };
    }
    return obj;
  }, {});
};

export const getOfflineMeteogram = async (
  coordinates: Coordinates,
  sets: any,
): Promise<any> => {
  if (!sets.length) return null;

  const relatesSets = getRelatedSets(sets, coordinates);
  const features = await Promise.all(
    relatesSets.map((set) => getAllFeaturesFormSet(set, coordinates)),
  );

  if (!relatesSets.length) return null;
  const time = relatesSets[0].timestamp;

  const normalized = features.map((item: any) => {
    delete item.time;
    return Object.values(item);
  });

  const data = normalized.flat(2);
  const steps = mapAttributes(data, time);

  const sorted = Object.keys(steps)
    .sort((a, b) => a.localeCompare(b))
    .reduce((obj: any, key) => {
      obj[key] = steps[key];

      return obj;
    }, {});

  return {
    lat: coordinates.lat,
    lon: coordinates.lng,
    steps: sorted,
  };
};
