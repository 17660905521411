import { InfoIcon } from 'modules/ui/assets';
import React from 'react';
import ReactTooltip, { Place } from 'react-tooltip';

import { Info, TooltipContainer } from './styledComponents';

type Props = {
  text: string;
  place?: Place;
  multiline?: boolean;
  inline?: boolean;
};

const TooltipInfoIcon: React.FC<{
  text: string;
  marginLeft?: number;
  marginTop?: number;
  inline?: boolean;
}> = ({ text, marginLeft, inline, marginTop }) => (
  <Info
    inline={!!inline}
    marginLeft={marginLeft}
    marginTop={marginTop}
    data-tip={text}
  >
    <InfoIcon />
  </Info>
);

const Tooltip: React.FC<Props> = ({ text, place, multiline, inline }) => {
  return (
    <TooltipContainer>
      <TooltipInfoIcon inline={!!inline} text={text} />
      <ReactTooltip effect="solid" place={place} multiline={multiline} />
    </TooltipContainer>
  );
};

export default React.memo(Tooltip);
export { TooltipInfoIcon };
