import React from 'react';
import { Form, Field } from 'react-final-form';

import logo from 'modules/ui/assets/images/logo.png';
import { TextInput, Button } from 'modules/ui/components';
import {
  validatePassword,
  validateEmail,
  validateRepeatedPassword,
  validateRequiredString,
} from 'modules/form';
import { RegularSm, HighlightedSm } from 'modules/ui/typography';

import { AuthImageContainer } from '../../components';
import { useRegisterUser } from '../../hooks';
import {
  FormContainer,
  Error,
  LogoImage,
  Title,
  FormFields,
  LinkContainer,
} from './styledComponents';

const RegisterPageUi: React.FC = () => {
  const { error, handleRegister, loading } = useRegisterUser();

  return (
    <AuthImageContainer>
      <FormContainer>
        <LogoImage src={logo} alt="Dorado sail" />
        <div>
          <Title>Registration</Title>
          <Form
            onSubmit={handleRegister}
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              repeatedPassword: '',
              about: '',
              teamOrCompany: '',
            }}
            render={({ handleSubmit, invalid }): React.ReactNode => (
              <>
                <FormFields>
                  <Field
                    name="email"
                    type="email"
                    label="Email *"
                    component={TextInput}
                    width={320}
                    validate={validateEmail}
                  />
                  <Field
                    name="firstName"
                    label="First name *"
                    type="text"
                    component={TextInput}
                    width={320}
                    validate={validateRequiredString}
                  />
                  <Field
                    name="lastName"
                    label="Last name *"
                    type="text"
                    component={TextInput}
                    width={320}
                    validate={validateRequiredString}
                  />
                  <Field
                    name="password"
                    type="password"
                    component={TextInput}
                    width={320}
                    label="Password *"
                    validate={validatePassword}
                  />
                  <Field
                    name="repeatedPassword"
                    type="password"
                    component={TextInput}
                    width={320}
                    label="Repeated Password *"
                    validate={validateRepeatedPassword}
                  />
                  <Field
                    name="about"
                    type="text"
                    component={TextInput}
                    width={320}
                    label="About"
                  />
                  <Field
                    name="teamOrCompany"
                    type="text"
                    component={TextInput}
                    width={320}
                    label="Team/Company"
                  />
                </FormFields>
                {error !== null && <Error>{error}</Error>}
                <div className="field-container">
                  <Button
                    stretch
                    disabled={invalid}
                    isLoading={loading}
                    label="Register"
                    clickHandler={handleSubmit}
                  />
                </div>
                <LinkContainer>
                  <RegularSm>
                    Any questions?{' '}
                    <a href="mailto:contact@doradosail.com">
                      <HighlightedSm>Contact us.</HighlightedSm>
                    </a>
                  </RegularSm>
                </LinkContainer>
              </>
            )}
          />
        </div>
      </FormContainer>
    </AuthImageContainer>
  );
};

export const RegisterPage = React.memo(RegisterPageUi);
