import { meteoModule } from 'modules/api';
import { apiClient } from 'modules/apiClient';

type MeteogramBodyParams = {
  lat: number;
  lon: number;
  model: string;
  step: number;
};

const getMetegoram = async ({
  lat,
  lon,
  model,
  step,
}: MeteogramBodyParams): Promise<GeoJSON.FeatureCollection | null> => {
  try {
    const response = await apiClient.get(meteoModule.Meteogram, {
      params: {
        lat,
        lon,
        model,
        step,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const MeteogramApi = { getMetegoram };
