import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { RegularSm } from 'modules/ui/typography';

import { CheckboxContainer } from './styledComponents';

type Props = {
  label: string;
  onChange: () => void;
  isSecondary: boolean;
  value: boolean;
  name: string;
  disabled?: boolean;
};

const Checkbox: React.FC<Props> = ({
  label,
  onChange,
  isSecondary,
  value,
  name,
  disabled,
}) => {
  const eventHandler = useCallback((): void => {
    onChange();
  }, [onChange]);

  return (
    <CheckboxContainer isSecondary={isSecondary} disabled={!!disabled}>
      <input
        onChange={eventHandler}
        type="checkbox"
        checked={value}
        name={name}
      />
      <span className="checkmark" />
      <RegularSm>{label}</RegularSm>
    </CheckboxContainer>
  );
};

type FormProps = {
  label: string;
  isSecondary: boolean;
  disabled?: boolean;
} & FieldRenderProps<boolean, HTMLElement>;

const FormCheckbox: React.FC<FormProps> = React.memo(
  ({ label, isSecondary, input, disabled }) => {
    return (
      <CheckboxContainer isSecondary={isSecondary} disabled={!!disabled}>
        <input
          type="checkbox"
          {...input}
          value={undefined}
          checked={input.checked}
        />
        <span className="checkmark" />
        <RegularSm>{label}</RegularSm>
      </CheckboxContainer>
    );
  },
);

export default React.memo(Checkbox);
export { FormCheckbox };
