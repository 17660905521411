import React from 'react';
import { useSelector } from 'react-redux';

import { ModalEmptyMessage } from 'modules/ui/components';
import {
  sortedDownloadedRoutingsListSelector,
  sideMenuRoutingIdsSelector,
} from '../../redux/selectors';
import Row from './Row';
import { ListContainer } from './styledComponents';

const DownloadedRoutingsList: React.FC = () => {
  const downloadedRoutings = useSelector(sortedDownloadedRoutingsListSelector);
  const sideMenuIds = useSelector(sideMenuRoutingIdsSelector);
  return (
    <ListContainer>
      {!downloadedRoutings.length && (
        <ModalEmptyMessage
          title="No downloaded routings"
          subtitle="Any downloaded routings will appear here."
        />
      )}
      {downloadedRoutings.map((r) => {
        const includedInSideMenu = sideMenuIds.includes(r.id);
        return (
          <Row
            key={`${r.id}-${includedInSideMenu}`}
            route={r}
            includedInSideMenu={includedInSideMenu}
          />
        );
      })}
    </ListContainer>
  );
};

export default React.memo(DownloadedRoutingsList);
