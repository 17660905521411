import { format, getHours, parseISO } from 'date-fns';

export const interpolateColor = (
  lowerColor: string,
  upperColor: string,
  f: number,
): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const firstColor = lowerColor
    .substring(1)
    .match(/.{1,2}/g)
    .map((oct) => parseInt(oct, 16) * (1 - f));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const secondColor = upperColor
    .substring(1)
    .match(/.{1,2}/g)
    .map((oct) => parseInt(oct, 16) * f);
  const ci = [0, 1, 2].map((i) =>
    Math.min(Math.round(firstColor[i] + secondColor[i]), 255),
  );
  return `#${ci
    // eslint-disable-next-line no-bitwise
    .reduce((a, v) => (a << 8) + v, 0)
    .toString(16)
    .padStart(6, '0')}`;
};

export const formatUtcDate = (date: string): string =>
  format(parseISO(date), "yyyy-MM-dd HH:mm 'UTC'");

export const isMidnight = (date: string): boolean =>
  getHours(parseISO(date)) === 0;

export const formatUtcStartEnd = (start: string, end: string): string => {
  if (!start || !end) return '';
  try {
    return `${format(parseISO(start), 'dd-LL HH:mm')} - ${format(
      parseISO(end),
      "dd-LL HH:mm 'UTC.'",
    )}`;
  } catch (error) {
    return '';
  }
};
