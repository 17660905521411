import React, { useCallback, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';

import { Button } from 'modules/ui/components';
import ServerRoutingsList from '../ServerRoutingsList';
import DownloadedRoutingsList from '../DownloadedRoutingsList';
import { downloadServerRoutingsListThunk } from '../../redux/thunk';
import {
  serverRoutingsListLastRequestTimestampSelector,
  hasNewServerRoutingForRefreshSelector,
} from '../../redux/selectors';

import { Footer, FooterTimeText } from './styledComponents';

const AllRoutes: React.FC = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const lastRequestTime = useSelector(
    serverRoutingsListLastRequestTimestampSelector,
  );
  const hasNewServerRouteForRefresh = useSelector(
    hasNewServerRoutingForRefreshSelector,
  );
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const onRefreshEnd = useCallback(() => {
    setIsRefreshing(false);
  }, []);

  const refreshList = useCallback(() => {
    setIsRefreshing(true);
    dispatch(downloadServerRoutingsListThunk(onRefreshEnd));
  }, [dispatch, onRefreshEnd]);
  return (
    <>
      <Tabs onSelect={setActiveTab}>
        <TabList>
          <Tab>Server routings</Tab>
          <Tab>Downloaded routings</Tab>
        </TabList>

        <TabPanel style={{ padding: '0px 0px 20px 0px' }}>
          <ServerRoutingsList />
        </TabPanel>
        <TabPanel style={{ padding: '0px 0px 20px 0px' }}>
          <DownloadedRoutingsList />
        </TabPanel>
      </Tabs>

      <Footer>
        {activeTab === 0 && (
          <FooterTimeText>
            {hasNewServerRouteForRefresh
              ? 'You have recently submitted a new routing request. Try refreshing this list.'
              : !!lastRequestTime &&
                `Last update ${formatDistanceToNow(new Date(lastRequestTime))}`}
          </FooterTimeText>
        )}
        {activeTab === 0 && (
          <Button
            isLoading={isRefreshing}
            disabled={isRefreshing}
            isTertiary
            label="Refresh list"
            clickHandler={refreshList}
          />
        )}
      </Footer>
    </>
  );
};

export default React.memo(AllRoutes);
