import styled from 'styled-components';

import { RegularSm, RegularXs } from 'modules/ui/typography';
import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div`
  height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  :hover {
    background-color: ${Colors.Primary}12;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Subtitle = styled(RegularSm)`
  opacity: 0.5;
`;

export const ProcessingLabel = styled(RegularXs)`
  color: ${Colors.Secondary};
  margin-left: 16px;
`;

export const ErrorLabel = styled(RegularXs)`
  color: ${Colors.Destructive};
  margin-left: 16px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > svg {
    cursor: pointer;
  }
  & > svg:nth-child(2) {
    margin-left: 16px;
  }
`;
