import { useCallback, useRef, useState } from 'react';
import { Marker, Map } from 'mapbox-gl';

import { useCheckIfBoatExist } from 'modules/boats';
import PinImage from 'modules/ui/assets/images/pin.png';

const useSelectRoutePoints = (
  map: Map,
  coordinates: {
    lat: number;
    lng: number;
  },
  toggleNewRouting: (value: boolean) => void,
): {
  handleRoutFromClick: () => void;
  handleRoutToClick: () => void;
  coordinatesFrom: number[] | null;
  setCoordinatesFrom: (value: number[] | null) => void;
} => {
  const markerRef = useRef<Marker | null>(null);
  const [coordinatesFrom, setCoordinatesFrom] = useState<number[] | null>(null);

  const { checkIfBoatExists } = useCheckIfBoatExist();

  const handleRoutToClick = useCallback(() => {
    if (!checkIfBoatExists()) {
      return;
    }
    toggleNewRouting(true);
    if (markerRef.current) {
      markerRef.current.remove();
      markerRef.current = null;
    }
  }, [toggleNewRouting, checkIfBoatExists]);

  const handleRoutFromClick = useCallback(() => {
    if (!checkIfBoatExists()) {
      return;
    }
    setCoordinatesFrom([coordinates.lng, coordinates.lat]);
    if (markerRef.current) {
      markerRef.current.remove();
    }
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(${PinImage})`;

    const marker = new Marker({
      draggable: true,
      element: el,
      offset: [0, -14],
    }).setLngLat([coordinates.lng, coordinates.lat]);
    marker
      .on('dragend', () => {
        const lngLat = marker.getLngLat();
        setCoordinatesFrom([lngLat.lng, lngLat.lat]);
      })
      .addTo(map);
    markerRef.current = marker;
  }, [coordinates, map, checkIfBoatExists]);

  return {
    handleRoutFromClick,
    handleRoutToClick,
    coordinatesFrom,
    setCoordinatesFrom,
  };
};

export default useSelectRoutePoints;
