import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format, formatDistanceToNow, differenceInSeconds } from 'date-fns';

import { DownloadSmallIcon } from 'modules/ui/assets';
import {
  DownloadFleetDataModal,
  fleetLastUpdateTimestampSelector,
} from 'modules/fleet';
import { useModal } from 'modules/main';
import { StatusItem } from '../StatusItem';
import { Subtitle } from '../Subtitle';
import { ActionItem } from '../ActionItem';

const FleetSideMenuSectionUi: React.FC = () => {
  const fleetLastUpdateTimestamp = useSelector(
    fleetLastUpdateTimestampSelector,
  );
  const fleetLastUpdateDate = useMemo(
    () => new Date(fleetLastUpdateTimestamp),
    [fleetLastUpdateTimestamp],
  );
  const [
    isDownloadFleetDataModalOpen,
    ,
    openDownloadFleetDataModal,
    closeDownloadFleetDataModal,
  ] = useModal(false);
  return (
    <>
      {isDownloadFleetDataModalOpen && (
        <DownloadFleetDataModal
          isOpen={isDownloadFleetDataModalOpen}
          closeModal={closeDownloadFleetDataModal}
        />
      )}
      <Subtitle>Vendée Globe fleet data</Subtitle>
      <StatusItem
        isOk={!!fleetLastUpdateTimestamp}
        label={
          fleetLastUpdateTimestamp
            ? `Last update: ${format(
                fleetLastUpdateDate,
                "EEE LL-dd HH:mm 'UTC'",
              )} ${
                differenceInSeconds(new Date(), fleetLastUpdateDate) > 30
                  ? `(${formatDistanceToNow(fleetLastUpdateDate)} ago)`
                  : ''
              }`
            : 'No fleet data downloaded yet.'
        }
      />
      <ActionItem
        onClick={openDownloadFleetDataModal}
        Icon={<DownloadSmallIcon />}
        label={
          fleetLastUpdateTimestamp ? 'Update fleet data' : 'Get fleet data'
        }
      />
    </>
  );
};

export const FleetSideMenuSection = memo(FleetSideMenuSectionUi);
