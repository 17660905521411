import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid ${Colors.DarkGray};
`;

export const NameAndCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > label {
    margin: 0 0 16px 4px;
  }
`;

export const ButtonsLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > :first-child {
    margin-right: 8px;
  }
  & > :nth-child(2) {
    margin-right: 8px;
  }
  & > button {
    padding: 6px 8px !important;
  }
`;
