import React from 'react';

import { Title, Subtitle, Container } from './styledComponents';

type Props = {
  title: string;
  subtitle: string;
};

const ModalEmptyMessage: React.FC<Props> = ({ title, subtitle }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);

export default React.memo(ModalEmptyMessage);
