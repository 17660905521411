import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularXs } from 'modules/ui/typography';

export default styled(RegularXs)<{ respectWhiteSpace?: boolean }>`
  color: ${Colors.Destructive};
  white-space: ${({ respectWhiteSpace }): string | undefined =>
    respectWhiteSpace ? 'pre-line' : undefined};
`;
