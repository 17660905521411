import { createAction } from '@reduxjs/toolkit';
import { UpdateDownloadItemModel, DownloadModel, HistoryModel } from '../types';

export const addDownload = createAction<DownloadModel>('ADD_DOWNLOAD');
export const updateDownload = createAction<UpdateDownloadItemModel>(
  'UPDATE_DOWNLOAD',
);
export const removeDownload = createAction<UpdateDownloadItemModel>(
  'REMOVE_DOWNLOAD',
);

export const addToHistory = createAction<HistoryModel>(
  'ADD_DOWNLOAD_TO_HISTORY',
);
export const clearHistory = createAction('CLEAR_DOWNLOAD_HISTORY');

export const setIsDownloadModalOpen = createAction<boolean>(
  'SET_IS_DOWNLOAD_MODAL_OPEN',
);

export const addToDownloadHistoryCounter = createAction<number>(
  'ADD_TO_DOWNLOAD_HISTORY_COUNTER',
);
