import React from 'react';

import { Label } from './styledComponents';

type Props = {
  children: React.ReactNode;
  invalid?: boolean;
};

const InputLabel: React.FC<Props> = ({ children, invalid }) => (
  <Label invalid={!!invalid}>{children}</Label>
);

export default React.memo(InputLabel);
