import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BoatsApi } from '../api';
import { BoatsActions, BoatsSelectors } from '../redux';
import { Boat } from '../types';

export const useGetBoats = (): {
  boat: Boat | undefined;
} => {
  const dispatch = useDispatch();
  const hasLoaded = useSelector(BoatsSelectors.hasLoaded);
  const boat = useSelector(BoatsSelectors.firstBoat);

  const handleGetBoats = useCallback(async () => {
    try {
      const boats = await BoatsApi.getBoats();
      dispatch(BoatsActions.setBoats(boats));
    } catch (error) {
      console.log('Error while getting boats');
    }
  }, [dispatch]);

  useEffect(() => {
    if (!hasLoaded) {
      handleGetBoats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);

  return {
    boat,
  };
};
