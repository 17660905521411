import React from 'react';
import ReactTooltip from 'react-tooltip';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { RadioButton, TooltipInfoIcon } from 'modules/ui/components';

import { formatTimeOption } from '../../utils/time';

import { Container, InfoRadioButton } from './styledComponents';

type Props = {
  options: Array<{ option: string; info: string }>;
  handleTimeSetOption: (value: string) => void;
  selectedTime: string;
};

const TimeOptions: React.FC<Props> = ({
  options,
  handleTimeSetOption,
  selectedTime,
}) => {
  useDeepCompareEffect(() => {
    ReactTooltip.rebuild();
  }, [options]);
  return (
    <Container>
      {options.map(({ option, info }) => (
        <InfoRadioButton key={option}>
          <RadioButton
            key={option}
            isSecondary={false}
            label={formatTimeOption(option)}
            onChange={handleTimeSetOption}
            value={option}
            isChecked={selectedTime === option}
          />
          <TooltipInfoIcon text={info} />
        </InfoRadioButton>
      ))}
    </Container>
  );
};

export default React.memo(TimeOptions);
