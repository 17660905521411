import styled from 'styled-components';

export const Container = styled.button<{ buttonColor: string }>`
  position: fixed;
  right: 12px;
  top: 12px;
  height: 32px;
  width: 32px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  :active {
    background-color: grey;
  }
  border-radius: 4px;
  background-color: ${({ buttonColor }): string => buttonColor};
`;
