import styled from 'styled-components';

import { RegularBase } from 'modules/ui/typography';

export const Label = styled.label`
  cursor: pointer;
  display: flex;
`;

export const Indicator = styled.span`
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #eeeef3;
  border-radius: 12px;
  position: relative;
  transition: background-color 0.25s linear;
  ::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #ffffff;
    top: 2px;
    left: 2px;
    transform: translateX(0);
    transition: transform 0.25s ease-in-out;
  }
`;

export const Input = styled.input<{ toggleColor: string }>`
  opacity: 0;
  position: absolute;
  z-index: -1;
  :checked ~ ${Indicator}::before {
    transform: translateX(20px);
  }
  :checked ~ ${Indicator} {
    background-color: ${({ toggleColor }): string => toggleColor};
  }
`;

export const ToggleLabel = styled(RegularBase)`
  margin-left: 8px;
`;
