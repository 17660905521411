import React from 'react';

import { ModelLayers } from 'modules/mapLayers/consts';
import { useGetScaleVisualData } from '../../hooks';

import { Container, ScaleLabel, ScaleContainer } from './styledComponents';

type Props = {
  scaleArray: ModelLayers[];
  isVisible: boolean;
};

const MeteoScale: React.FC<Props> = ({ scaleArray, isVisible }) => {
  const {
    colorGradient,
    filteredBreakpoints,
    lastScale,
  } = useGetScaleVisualData(scaleArray);

  if (!lastScale) return null;
  return (
    <>
      <Container isVisible={isVisible} colorGradient={colorGradient} />
      <ScaleContainer isVisible={isVisible}>
        {filteredBreakpoints.map((value, index) => (
          <ScaleLabel
            height={100 / filteredBreakpoints.length}
            key={`${value}-${index}`}
          >
            {value}
          </ScaleLabel>
        ))}
      </ScaleContainer>
    </>
  );
};

export default React.memo(MeteoScale);
