import React, { memo } from 'react';
import { Field } from 'react-final-form';

import { TextInput, SelectInput } from 'modules/ui/components';
import {
  validateMinutesOrSeconds,
  validateLatitude,
  validateLongitude,
} from 'modules/form';
import { latOptions, lngOptions } from '../../const';
import {
  MinutesCoordinatesInputContainer,
  CoordinatesLabel,
} from './styledComponents';

type Props = {
  name: string;
  isLat?: boolean;
};

const parseFraction = (value: string): string => value.substring(0, 3);

const formatDecimal = (value: string): string => {
  if (value.length === 1) {
    return `${value}00`;
  }
  if (value.length === 2) {
    return `${value}0`;
  }
  return value;
};

const validateFraction = (value: string): string | undefined =>
  value !== undefined ? undefined : 'Required';

const CoordinatesInputUi: React.FC<Props> = ({ name, isLat }) => {
  return (
    <>
      <MinutesCoordinatesInputContainer>
        <Field
          name={`${name}[0]`}
          component={TextInput}
          width={60}
          parse={parseFloat}
          validate={isLat ? validateLatitude : validateLongitude}
        />
        <CoordinatesLabel>°</CoordinatesLabel>
      </MinutesCoordinatesInputContainer>
      <MinutesCoordinatesInputContainer>
        <Field
          name={`${name}[1]`}
          component={TextInput}
          width={60}
          parse={parseFloat}
          min={0}
          max={59}
          validate={validateMinutesOrSeconds}
        />
        <CoordinatesLabel addMargin>.</CoordinatesLabel>
        <Field
          name={`${name}[2]`}
          component={TextInput}
          width={60}
          format={formatDecimal}
          formatOnBlur
          parse={parseFraction}
          min={0}
          max={999}
          validate={validateFraction}
          forceFraction
        />
        <CoordinatesLabel>'</CoordinatesLabel>
      </MinutesCoordinatesInputContainer>
      <Field
        name={`${name}Direction`}
        options={isLat ? latOptions : lngOptions}
        component={SelectInput}
        width={70}
      />
      <div style={{ width: 26 }} />
    </>
  );
};

export const CoordinatesInput = memo(CoordinatesInputUi);
