import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: 200px;
`;

export const ProgressBarContainer = styled.div`
  width: 480px;
  margin: 64px 0 32px 0;
`;
