/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartColors, ChartType, ChartNames, ChartMode } from '../consts';

export const defaultLegend = {
  x: 0,
  y: 1.2,
  orientation: 'h',
};

export const defaultXaxis = {
  type: 'date',
  dtick: 86400000,
  tickmode: 'linear',
  showgrid: true,
  gridcolor: ChartColors.Silver,
  hoverformat: '%d-%m %H:%M UTC',
};

export const defaultHiddenYaxis = {
  visible: false,
  title: '',
  overlaying: 'y',
  side: 'right',
};

export const defaultAnnotation = {
  x: 0,
  y: 0,
  yshift: -80,
  xref: 'x',
  yref: 'paper',
  text: '',
  textangle: 0,
  showarrow: false,
  arrowwidth: 0,
  arrowhead: 0,
  font: {
    size: 30,
  },
};

const getUTCNow = (): Date => {
  const date = new Date();
  date.setFullYear(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
  );
  date.setHours(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  return date;
};

export const defaultLayout: any = {
  width: window.innerWidth,
  height: 400,
  showlegend: true,
  title: '',
  legend: defaultLegend,
  xaxis: defaultXaxis,
  yaxis: {
    showline: true,
  },
  annotations: [],
  yaxis2: defaultHiddenYaxis,
  autosize: true,
  connectgaps: true,
  shapes: [
    {
      type: 'line',
      x0: getUTCNow(),
      y0: 0,
      x1: getUTCNow(),
      yref: 'paper',
      y1: 1,
      line: {
        color: 'red',
        width: 1.5,
        dash: 'solid',
      },
    },
  ],
};

export type Layout = {
  width: any;
  height: number;
  showlegend: boolean;
  title: string;
  legend: any;
  xaxis: any;
  annotations: any[];
  yaxis2: any;
  autosize: boolean;
  connectgaps: boolean;
  shapes: any[];
};

export const ChartOptions: any = {
  cdir: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    yaxis: 'y2',
    name: ChartNames.CDIR,
    marker: { color: ChartColors.Victoria },
  },
  cspeed: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.CSPEED,
    marker: { color: ChartColors.Heliotrope },
  },
  shww: {
    type: ChartType.Bar,
    name: ChartNames.SHWW,
    marker: { color: ChartColors.Iceberg },
  },
  mpww: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.MPWW,
    marker: { color: ChartColors.Shamrock },
  },
  mdww: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    yaxis: 'y2',
    name: ChartNames.MDWW,
    marker: { color: ChartColors.MineralGreen },
  },
  shts: {
    type: ChartType.Bar,
    name: ChartNames.SHTS,
    marker: { color: ChartColors.Solitude },
  },
  mpts: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.MPTS,
    marker: { color: ChartColors.Malibu },
  },
  mdts: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    yaxis: 'y2',
    name: ChartNames.MDTS,
    marker: { color: ChartColors.NavyBlue },
  },
  msl: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.MSL,
    marker: { color: ChartColors.JapaneseLaurel },
  },
  sst: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.SST,
    marker: { color: ChartColors.Ming },
  },
  swh: {
    type: ChartType.Bar,
    name: ChartNames.SWH,
    marker: { color: ChartColors.CatskillkWhite },
  },
  t2m: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.T2M,
    marker: { color: ChartColors.CarrotOrange },
  },
  tcc: {
    type: ChartType.Bar,
    name: ChartNames.TCC,
    marker: { color: ChartColors.PeriwinkleGray },
  },
  tp: {
    type: ChartType.Bar,
    name: ChartNames.TP,
    marker: { color: ChartColors.RegentBlue },
  },
  wc: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    yaxis: 'y2',
    name: ChartNames.WC,
    marker: { color: ChartColors.EastBay },
  },
  wdir: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    yaxis: 'y2',
    name: ChartNames.WDIR,
    marker: { color: ChartColors.Scorpion },
  },
  ws: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.WS,
    marker: { color: ChartColors.Cerulean },
  },
  fg10: {
    type: ChartType.Scatter,
    mode: ChartMode.Lines,
    name: ChartNames.FG10,
    marker: { color: ChartColors.RedOrange },
  },
};
