import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import {
  RegularBase,
  RegularBaseCss,
  RegularSmCss,
} from 'modules/ui/typography';

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${({ disabled }): number | undefined =>
    disabled ? 0.5 : undefined};
`;

export const InputContainer = styled.div<{
  width?: number;
  invalid: boolean;
  active?: boolean;
}>`
  width: ${({ width }): string => `${width || 220}px`};
  height: 40px;
  min-height: 40px;
  border: 1px solid
    ${({ invalid, active }): string => {
      if (invalid) return Colors.Destructive;
      if (active) return Colors.Primary;
      return Colors.Gray;
    }};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 12px;
  box-sizing: border-box;
`;

export const Input = styled.input<{ isSmall?: boolean }>`
  background: transparent;
  outline: none;
  font-size: 12px;
  line-height: 24px;
  border: none;
  display: flex;
  flex: 1;
  width: 100%;
  color: ${Colors.Black};
  ${({ isSmall }): FlattenSimpleInterpolation =>
    isSmall ? RegularSmCss : RegularBaseCss};
  ::placeholder {
    color: ${Colors.SolidGray};
  }
`;

export const RightLabel = styled(RegularBase)`
  color: ${Colors.SolidGray};
`;

export const ErrorLabelContainer = styled.div`
  position: absolute;
  bottom: -18px;
`;
