/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import db from 'modules/localForage/db';
import { PersistConfig, persistReducer } from 'redux-persist';
import { DownloadModel, HistoryModel } from '../types';
import {
  addDownload,
  updateDownload,
  removeDownload,
  addToHistory,
  clearHistory,
  setIsDownloadModalOpen,
  addToDownloadHistoryCounter,
} from './actions';

const active = createReducer([] as DownloadModel[], (builder) => {
  builder
    .addCase(addDownload, (state, action) => [action.payload, ...state])
    .addCase(updateDownload, (state, action) => {
      const index = state.findIndex((v) => v.id === action.payload.id);
      if (state[index]) {
        const newDownloadedFiles = (state[index]?.downloadedFiles || 0) + 1;
        const newSize =
          (state[index]?.size || 0) + (action.payload.itemSize || 0);
        state[index] = {
          ...state[index],
          progress: action.payload.progress,
          downloadedFiles: newDownloadedFiles,
          downloaded: newDownloadedFiles === state[index].totalFiles,
          size: newSize,
        };
      }
      return state;
    })
    .addCase(removeDownload, (state, action) => {
      return state.filter((v) => v.id !== action.payload.id);
    });
});

const history = createReducer([] as HistoryModel[], (builder) => {
  builder
    .addCase(addToHistory, (state, action) => [action.payload, ...state])
    .addCase(clearHistory, () => []);
});

const isModalOpen = createReducer(false, (builder) => {
  builder.addCase(addDownload, (state, action) => {
    if (action.payload.type === 'meteogram') {
      return false;
    }

    return true;
  }); // payload return true
  builder.addCase(setIsDownloadModalOpen, (state, action) => action.payload);
});

const downloadedSize = createReducer(0, (builder) => {
  builder
    .addCase(
      updateDownload,
      (state, action) => (state || 0) + (action.payload.itemSize || 0),
    )
    .addCase(clearHistory, () => 0)
    .addCase(
      addToDownloadHistoryCounter,
      (state, action) => (state || 0) + (action.payload || 0),
    );
});

const reducer = combineReducers({
  active,
  history,
  isModalOpen,
  downloadedSize,
});

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'download',
  storage: db.app,
  whitelist: ['active', 'history', 'downloadedSize'],
};

export default persistReducer(persistConfig, reducer);
