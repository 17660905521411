import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectContainer = styled.select<{ width?: number }>`
  width: ${({ width }): string => `${width || 220}px`};
  height: 40px;
  border: 1px solid ${Colors.Gray};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 12px;
  outline: none;
  box-sizing: border-box;
`;
