import { toDegreesAndMinutes } from 'modules/coordinates';
import { TimeResolution } from '../consts';
import {
  OceanMeteoDataSet,
  WaveMeteoDataSet,
  WindMeteoDataSet,
} from '../types';
import { getMaxDaysFromSet, formatComparisonOption } from './time';

const formatResolution = (time: number): string => {
  if (time === TimeResolution.medium) return 'Medium';
  if (time === TimeResolution.low) return 'Low';
  if (time === TimeResolution.superlow) return 'Superlow';
  return 'High';
};

const formatComponentMessage = (
  component: string,
  days: number,
  resolution: number,
  time?: number,
): string =>
  days
    ? `${component}: ${days} day${days > 1 ? 's' : ''}, ${
        resolution ? `${resolution}°` : ''
      } ${time ? `, ${formatResolution(time)}` : ''}`
    : `${component}: No data downloaded`;

const formatMoreAreaMessage = (length: number): string =>
  length > 3
    ? `<br>(+ ${length - 3} more area${length - 3 > 1 ? 's' : ''})`
    : '';

export const windTooltipMessage = (sets: WindMeteoDataSet[]): string => {
  let info = '';
  sets.forEach((set, innerIndex) => {
    if (innerIndex > 2) return;
    const windDays = getMaxDaysFromSet(set.wind);
    const weatherDays = getMaxDaysFromSet(set.weather);
    const mslpDays = getMaxDaysFromSet(set.mslp);
    const componentInfo = `Area ${toDegreesAndMinutes(
      set.maxLat,
      true,
    )} ${toDegreesAndMinutes(set.minLng)} to ${toDegreesAndMinutes(
      set.minLat,
      true,
    )} ${toDegreesAndMinutes(set.maxLng)}<br>${formatComponentMessage(
      'Wind',
      windDays,
      set.windResolution,
      set.windTime,
    )}<br>${formatComponentMessage(
      'Weather',
      weatherDays,
      set.weatherResolution,
      set.weatherTime,
    )}<br>${formatComponentMessage('MSLP', mslpDays, 0, set.mslpTime)}<br><br>`;
    info += componentInfo;
  });
  info += formatMoreAreaMessage(sets.length);
  return info;
};

export const waveTooltipMessage = (sets: WaveMeteoDataSet[]): string => {
  let info = '';
  sets.forEach((set, innerIndex) => {
    if (innerIndex > 2) return;
    const swellWavesDays = getMaxDaysFromSet(set.swellWaves);
    const windWavesDays = getMaxDaysFromSet(set.windWaves);
    const wavesDays = getMaxDaysFromSet(set.significantWaves);
    const componentInfo = `Area ${toDegreesAndMinutes(
      set.maxLat,
      true,
    )} ${toDegreesAndMinutes(set.minLng)} to ${toDegreesAndMinutes(
      set.minLat,
      true,
    )} ${toDegreesAndMinutes(set.maxLng)}<br>${formatComponentMessage(
      'Swell Waves',
      swellWavesDays,
      set.swellWavesResolution,
      set.swellWavesTime,
    )}<br>${formatComponentMessage(
      'Wind waves',
      windWavesDays,
      set.windWavesResolution,
      set.windWavesTime,
    )}<br>${formatComponentMessage(
      'Wave',
      wavesDays,
      set.significantWavesResolution,
      set.significantWavesTime,
    )}<br><br>`;
    info += componentInfo;
  });
  info += formatMoreAreaMessage(sets.length);
  return info;
};

export const oceanTooltipMessage = (sets: OceanMeteoDataSet[]): string => {
  let info = '';
  sets.forEach((set, innerIndex) => {
    if (innerIndex > 2) return;
    const currentDays = getMaxDaysFromSet(set.current);
    const componentInfo = `Area ${toDegreesAndMinutes(
      set.maxLat,
      true,
    )} ${toDegreesAndMinutes(set.minLng)} to ${toDegreesAndMinutes(
      set.minLat,
      true,
    )} ${toDegreesAndMinutes(set.maxLng)}<br>${formatComponentMessage(
      'Current',
      currentDays,
      set.currentResolution,
      set.currentResolution,
    )}<br><br>`;
    info += componentInfo;
  });
  info += formatMoreAreaMessage(sets.length);
  return info;
};

export const windComparisonTooltipMessage = (
  ecmwfSet: WindMeteoDataSet,
  gfsSet: WindMeteoDataSet,
  daysToAdd: number,
): string => {
  let info = '';
  info += `ECMWF ${formatComparisonOption(
    ecmwfSet.timestamp,
  )} UTC - GFS ${formatComparisonOption(gfsSet.timestamp)} UTC<br>`;
  const componentInfo = `Area ${toDegreesAndMinutes(
    ecmwfSet.maxLat,
    true,
  )} ${toDegreesAndMinutes(ecmwfSet.minLng)} to ${toDegreesAndMinutes(
    ecmwfSet.minLat,
    true,
  )} ${toDegreesAndMinutes(ecmwfSet.maxLng)}<br>${formatComponentMessage(
    'Wind',
    daysToAdd,
    ecmwfSet.windResolution,
  )}`;
  info += componentInfo;
  return info;
};
