import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BoatsSelectors } from 'modules/boats';
import { InclusionZonesApi } from '../api';
import { InclusionZonesSelectors, InclusionZoneActions } from '../redux';

export const useGetInclusionZones = (): void => {
  const dispatch = useDispatch();
  const hasLoaded = useSelector(InclusionZonesSelectors.hasLoaded);
  const boatsHasLoaded = useSelector(BoatsSelectors.hasLoaded);

  const handleGetExclusionZones = useCallback(async () => {
    try {
      const inclusionZones = await InclusionZonesApi.getInclusionZones();
      dispatch(InclusionZoneActions.setInclusionZones(inclusionZones));
    } catch (error) {
      console.log('Error while getting inclusion zones');
    }
  }, [dispatch]);

  useEffect(() => {
    if (boatsHasLoaded && !hasLoaded) {
      handleGetExclusionZones();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded, boatsHasLoaded]);
};
