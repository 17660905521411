import { apiClient } from 'modules/apiClient';
import { RegisterApiBody } from '../types';

type LoginApiBody = {
  email: string;
  password: string;
};

type LoginApiResponse = {
  accessToken: string;
  refreshToken: string;
};

const login = async (data: LoginApiBody): Promise<LoginApiResponse> => {
  const response = await apiClient.post('/v1/auth/login', data);
  return response.data;
};

type RefreshApiBody = {
  token: string;
};

type RefreshApiResponse = {
  accessToken: string;
  refreshToken: string;
};

const refreshToken = async (
  data: RefreshApiBody,
): Promise<RefreshApiResponse> => {
  const response = await apiClient.post('/v1/auth/refresh', data);
  return response.data;
};

const registerUser = async (data: RegisterApiBody): Promise<void> => {
  await apiClient.post('/v1/auth/register', data);
};

type VerifyApiBody = {
  otp: string;
};

const verifyAccount = async (data: VerifyApiBody): Promise<void> => {
  await apiClient.post('/v1/auth/verify', data);
};

export const AuthApi = { login, refreshToken, registerUser, verifyAccount };
