import React from 'react';
import { Field } from 'react-final-form';

import { TextInput } from 'modules/ui/components';
import { HighlightedSm } from 'modules/ui/typography';
import { parseFloat } from 'modules/main/formaters';
import { validateCorrectionPercentage } from 'modules/form';

import { MultiRow } from '../SettingsModal/styledComponents';
import { Label } from './styledComponents';

const WindSpeedCorrections: React.FC = () => (
  <>
    <MultiRow marginRight={8} marginBottom={8}>
      <Label textAlign="left">Wind</Label>
      <Label>1 kt</Label>
      <Label>5 kts</Label>
      <Label>8 kts</Label>
      <Label>12 kts</Label>
      <Label>20 kts</Label>
      <Label>25 kts</Label>
      <Label>30 kts</Label>
      <Label>40 kts</Label>
      <Label>50 kts</Label>
    </MultiRow>
    <MultiRow marginRight={8} hideMarginTop marginBottom={8}>
      <HighlightedSm>ECMWF</HighlightedSm>
      <Field
        name="windSpeedCorrection.1ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.5ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.8ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.12ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.20ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.25ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.30ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.40ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.50ktECMWF"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
    </MultiRow>
    <MultiRow marginRight={8} hideMarginTop>
      <HighlightedSm>GFS</HighlightedSm>
      <Field
        name="windSpeedCorrection.1ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.5ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.8ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.12ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.20ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.25ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.30ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.40ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
      <Field
        name="windSpeedCorrection.50ktGFS"
        parse={parseFloat}
        component={TextInput}
        rightInlineLabel="%"
        width={60}
        isSmall
        validate={validateCorrectionPercentage}
      />
    </MultiRow>
  </>
);

export default React.memo(WindSpeedCorrections);
