import { RoutingModule } from 'modules/api';
import { apiClient } from 'modules/apiClient';

const downloadRoutingCsv = async (
  routingId: number | string,
): Promise<{
  blob: Blob;
  contentDisposition: string;
}> => {
  const response = await apiClient.get(
    `${RoutingModule.Base}/${routingId}/csv`,
    {
      responseType: 'blob',
    },
  );
  return {
    blob: response.data,
    contentDisposition: response.headers['content-disposition'],
  };
};

export const RoutingFilesApi = {
  downloadRoutingCsv,
};
