export enum Routes {
  App = '/',
  Table = '/table/:id',
  OfflineMeteogramInPoint = '/meteogram',
  FullMeteogramInPoint = '/meteogram/:id',
  RoutingMeteogram = '/meteogram/routing/:id',
  Login = '/login',
  Register = '/register',
  RegistrationSuccess = '/registrationSuccess',
  AccountVerification = '/verify-email',
}
