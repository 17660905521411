import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularSm } from 'modules/ui/typography';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > svg {
    margin-right: 12px;
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 32px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
`;

export const OfflineText = styled(RegularSm)`
  color: ${Colors.Destructive};
  margin-right: 16px;
`;

export const LinkLabelText = styled(RegularSm)`
  color: ${Colors.Primary};
  margin-left: 6px;
`;
