import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.div<{ isOpened: boolean }>`
  padding: 8px;
  border-bottom: 1px solid ${Colors.Gray};
  background-color: ${({ isOpened }): string | undefined =>
    isOpened ? `${Colors.LightGray}B3` : undefined};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
`;

export const TitleColorAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > div:first-child {
    margin-right: 16px;
  }
  align-items: center;
`;

export const ContentContainer = styled.div`
  background-color: ${Colors.LightGray}B3;
  display: flex;
  flex-direction: column;
`;
