import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';

import { Chips } from 'modules/ui/components';
import { ModelLayers } from 'modules/mapLayers/consts';
import { MeteoTabProperties } from 'modules/meteo/types';
import { MeteoSources } from 'modules/meteo/consts';
import MeteoModelAccordion from '../MeteoModelAccordion';
import TimeOptions from '../TimeOptions';
import ModelCountIndicator from '../ModelCountIndicator';
import {
  infoEcmwfTimeOptionsSelector,
  ecmwfTimeSetSelector,
  currentEcmwfDataSetsComponentsSelector,
} from '../../redux/selectors';
import { setEcmwfTimeSet } from '../../redux/actions';
import EmptyDataComponent from '../EmptyDataComponent';
import { useWindSourceAndLayers } from '../../hooks';

type Props = {
  map: Map;
  showMeteoData: boolean;
  addToScaleArray: (layer: ModelLayers) => void;
  removeFromScaleArray: (layer: ModelLayers) => void;
  turnOnShowMeteoData: () => void;
} & MeteoTabProperties;

const EcmwfMeteoData: React.FC<Props> = ({
  map,
  showMeteoData,
  addToScaleArray,
  removeFromScaleArray,
  turnOnShowMeteoData,
  isOpened,
  tabName,
  toggleTab,
}) => {
  const dispatch = useDispatch();
  const selectedTime = useSelector(ecmwfTimeSetSelector);
  const options = useSelector(infoEcmwfTimeOptionsSelector);
  const { isMslpPresent, isWeatherPresent, isWindPresent } = useSelector(
    currentEcmwfDataSetsComponentsSelector,
  );
  const {
    isWindVisible,
    setIsWindVisible,
    isGustsVisible,
    setIsGustsVisible,
    isMslpVisible,
    setIsMslpVisible,
    isCloudVisible,
    setIsCloudVisible,
    isTempVisible,
    setIsTempVisible,
    isPrecipitationVisible,
    setIsPrecipitationVisible,
    componentsCount,
  } = useWindSourceAndLayers(
    MeteoSources.ECMWF,
    map,
    showMeteoData,
    addToScaleArray,
    removeFromScaleArray,
    turnOnShowMeteoData,
  );

  const handleTimeSetOption = useCallback(
    (value: string) => {
      dispatch(setEcmwfTimeSet(value));
    },
    [dispatch],
  );

  const handleToggleTab = useCallback(() => {
    toggleTab(tabName);
  }, [toggleTab, tabName]);

  return (
    <MeteoModelAccordion
      handleToggleTab={handleToggleTab}
      title="ECMWF"
      isOpened={isOpened}
      titleComponent={
        componentsCount ? (
          <ModelCountIndicator>{componentsCount}</ModelCountIndicator>
        ) : undefined
      }
    >
      <TimeOptions
        options={options}
        selectedTime={selectedTime}
        handleTimeSetOption={handleTimeSetOption}
      />
      {!options.length && <EmptyDataComponent />}
      {!!options.length && (
        <div>
          <Chips
            value={showMeteoData && isWindVisible}
            name="wind"
            label="Wind"
            onClick={setIsWindVisible}
            disabled={!isWindPresent}
          />
          <Chips
            value={showMeteoData && isGustsVisible}
            name="gusts"
            label="Gusts"
            onClick={setIsGustsVisible}
            disabled={!isWindPresent}
          />
          <Chips
            value={showMeteoData && isMslpVisible}
            name="mslp"
            label="Pressure (MSLP)"
            onClick={setIsMslpVisible}
            disabled={!isMslpPresent}
          />
          <Chips
            value={showMeteoData && isCloudVisible}
            name="tcc"
            label="Clouds (TCC)"
            onClick={setIsCloudVisible}
            disabled={!isWeatherPresent}
          />
          <Chips
            value={showMeteoData && isPrecipitationVisible}
            name="rr"
            label="Precipitation (RR)"
            onClick={setIsPrecipitationVisible}
            disabled={!isWeatherPresent}
          />
          <Chips
            value={showMeteoData && isTempVisible}
            name="T2m"
            label="Temperature (T2m)"
            onClick={setIsTempVisible}
            disabled={!isWeatherPresent}
          />
        </div>
      )}
    </MeteoModelAccordion>
  );
};

export default React.memo(EcmwfMeteoData);
