import { RootState } from 'modules/main/redux';

const isAuthorized = (state: RootState): boolean => !!state.auth.accessToken;

const refreshToken = (state: RootState): string => state.auth.refreshToken;

const accessToken = (state: RootState): string => state.auth.accessToken;

export const AuthSelectors = {
  isAuthorized,
  refreshToken,
  accessToken,
};
