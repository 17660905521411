import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const Container = styled.button<{
  isSecondary: boolean;
  stretch?: boolean;
  borderColor?: string;
  isSmall: boolean;
  isTertiary: boolean;
}>`
  min-width: ${({ stretch }): string => (stretch ? '100%' : 'auto')};
  padding: ${({ isSmall }): string => (isSmall ? '6px 16px' : '10px 24px')};
  background-color: ${({ isSecondary, isTertiary }): string =>
    isSecondary || isTertiary ? 'transparent' : Colors.Primary};
  border: ${({ isSecondary, borderColor }): string =>
    isSecondary ? `1px solid ${borderColor || Colors.DarkGray}` : 'none'};
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  height: ${({ isSmall }): number => (isSmall ? 32 : 40)}px;
  min-height: ${({ isSmall }): number => (isSmall ? 32 : 40)}px;
  & > span {
    color: ${({ isSecondary, isTertiary }): string => {
      if (isTertiary) return Colors.Primary;
      return isSecondary ? Colors.Black : Colors.White;
    }};
  }
  ${({ isTertiary }): FlattenSimpleInterpolation | undefined =>
    !isTertiary
      ? css`
          :hover {
            background-color: ${Colors.PrimaryLight};
            & > span {
              color: ${Colors.White};
            }
          }
        `
      : undefined}
  ${({ isTertiary }): FlattenSimpleInterpolation | undefined =>
    !isTertiary
      ? css`
          :active {
            background-color: ${Colors.PrimaryDark};
          }
        `
      : undefined}
  :disabled {
    background-color: ${Colors.DarkGray};
  }
`;
