import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal } from 'modules/ui/components';
import { UploadIcon } from 'modules/ui/assets';
import { HighlightedBase } from 'modules/ui/typography';
import { getFleetDataThunk } from '../../redux/thunks';

import { Footer, IconAndLabelText, FooterLabel } from './styledComponents';

type Props = {
  closeModal: () => void;
  isOpen: boolean;
};

const DownloadMeteogram: React.FC<Props> = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const refreshFleetData = useCallback(() => {
    dispatch(getFleetDataThunk());
    closeModal();
  }, [dispatch, closeModal]);

  return (
    <Modal
      isOpen={isOpen}
      isSmall
      title="Download fleet data"
      width={448}
      closeModal={closeModal}
    >
      <IconAndLabelText>
        <UploadIcon transform="rotate(180)" />
        <HighlightedBase>Vendée Globe fleet data</HighlightedBase>
      </IconAndLabelText>
      <Footer>
        <FooterLabel>Estimated download size: 4 KB</FooterLabel>
        <Button label="Download" clickHandler={refreshFleetData} />
      </Footer>
    </Modal>
  );
};

export default React.memo(DownloadMeteogram);
