import React from 'react';
import { useSelector } from 'react-redux';

import RoutingItem from '../SideMenuRouteItem';
import { RoutesSelectors } from '../../redux';
import { Route } from '../../types';
import { MainContainer, RoutingsContainer } from './styledComponents';

type Props = {
  startRouteFlow: (route?: Route) => void;
  setNewRoutingRoute: (route: Route) => void;
  activeIds: Array<number | null>;
  toggleRouting: (routeId: number) => void;
};

const RouteList: React.FC<Props> = ({
  startRouteFlow,
  setNewRoutingRoute,
  activeIds,
  toggleRouting,
}) => {
  const routesList = useSelector(RoutesSelectors.routesList);

  return (
    <MainContainer>
      <RoutingsContainer>
        {routesList.map((route) => (
          <RoutingItem
            route={route}
            key={route.id}
            toggleRouting={toggleRouting}
            isActive={activeIds.includes(route.id)}
            sourceIndex={activeIds.findIndex((id) => id === route.id)}
            startRouteFlow={startRouteFlow}
            setNewRoutingRoute={setNewRoutingRoute}
          />
        ))}
      </RoutingsContainer>
    </MainContainer>
  );
};

export default React.memo(RouteList);
