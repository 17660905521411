/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getFullMeteoEcmwfSourceByIdSelector } from 'modules/meteogram/redux';
import { H2 } from 'modules/ui/typography';
import { toDegreesMinutesAndSeconds } from 'modules/coordinates';
import Meteogram from '../components/Meteogram';
import { ChartGroups, MeteogramName } from '../consts';
import { MainContainer } from './styledComponents';

const FullMeteogramInPoint: React.FC = () => {
  const { id } = useParams<any>();
  const data: any = useSelector(getFullMeteoEcmwfSourceByIdSelector(id));

  useEffect(() => {
    document.title = `Full meteogram`;
  });

  const charts = Object.keys(ChartGroups);

  const lat = data.ecmwf ? data.ecmwf.lat : data.gfs.lat;
  const lon = data.ecmwf ? data.ecmwf.lon : data.gfs.lon;

  if (!!data.ecmwf && !!data.gfs) {
    return (
      <MainContainer sticky={false}>
        <H2>
          {MeteogramName.Offline}
          {toDegreesMinutesAndSeconds(lat, true)}
          <span> - </span>
          {toDegreesMinutesAndSeconds(lon)}
        </H2>
        <Tabs>
          <TabList>
            <Tab>ECMWF</Tab>
            <Tab>GFS</Tab>
          </TabList>
          <TabPanel forceRender>
            {!!data.ecmwf && (
              <Meteogram
                name={MeteogramName.Full}
                data={data.ecmwf}
                charts={charts}
              />
            )}
          </TabPanel>
          <TabPanel forceRender>
            {!!data.gfs && (
              <Meteogram
                name={MeteogramName.Offline}
                data={data.gfs}
                charts={charts}
              />
            )}
          </TabPanel>
        </Tabs>
      </MainContainer>
    );
  }

  if (data.ecmwf) {
    return (
      <MainContainer sticky>
        <H2>
          {MeteogramName.Offline}
          {toDegreesMinutesAndSeconds(lat, true)}
          <span> - </span>
          {toDegreesMinutesAndSeconds(lon)}
          <span> (ECMWF)</span>
        </H2>
        <Meteogram
          name={MeteogramName.Full}
          data={data.ecmwf}
          charts={charts}
        />
      </MainContainer>
    );
  }

  if (data.gfs) {
    return (
      <MainContainer sticky>
        <H2>
          {MeteogramName.Offline}
          {toDegreesMinutesAndSeconds(lat, true)}
          <span> - </span>
          {toDegreesMinutesAndSeconds(lon)}
          <span> (GFS)</span>
        </H2>
        <Meteogram name={MeteogramName.Full} data={data.gfs} charts={charts} />
      </MainContainer>
    );
  }

  return <></>;
};

export default React.memo(FullMeteogramInPoint);
