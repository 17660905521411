import { FORM_ERROR } from 'final-form';
import isEmail from 'validator/lib/isEmail';

import { MeteoForm } from 'modules/meteo/types';

export const validateRequiredString = (value: string): string | undefined =>
  value ? undefined : 'Required';

export const validateEmail = (value: string): string | undefined =>
  isEmail(value || '') ? undefined : 'Email not valid';

export const validatePassword = (value: string): string | undefined =>
  value && value.length > 5
    ? undefined
    : 'Password must be at least 6 characters long';

export const validateRepeatedPassword = (
  value: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allValues: any,
): string | undefined =>
  value === allValues.password ? undefined : "Password doesn't match";

export const validateRequiredNumber = (value: number): string | undefined =>
  Number.isNaN(value) ? 'Required' : undefined;

export const validateCorrectionPercentage = (
  value: number,
): string | undefined => {
  return Number.isNaN(value) || value < 0 || value > 200
    ? 'Invalid'
    : undefined;
};

export const validateSpeedLimit = (value: number): string | undefined => {
  return !value || Number.isNaN(value) || (value > 0 && value <= 100)
    ? undefined
    : 'Invalid';
};

export const validateMinutesOrSeconds = (value: number): string | undefined => {
  return Number.isNaN(value) || value < 0 || value > 59 ? 'Invalid' : undefined;
};

export const validateLatitude = (value: number): string | undefined => {
  return Number.isNaN(value) || value < 0 || value > 89 ? 'Invalid' : undefined;
};

export const validateLongitude = (value: number): string | undefined => {
  return Number.isNaN(value) || value < 0 || value > 179
    ? 'Invalid'
    : undefined;
};

export const validateHeading = (value: number): string | undefined => {
  return Number.isNaN(value) || value < 0 || value > 359
    ? 'Invalid'
    : undefined;
};

export const validateMeteoDataDownloadForm = ({
  ecmwf,
  ecmwfwam,
  gfs,
  nemo,
  gfswam,
}: MeteoForm): undefined | { [FORM_ERROR]: string } => {
  return (ecmwf.wind.checked && ecmwf.wind.time) ||
    (ecmwf.weather.checked && ecmwf.weather.time) ||
    (ecmwf.MSLP.checked && ecmwf.MSLP.time) ||
    (gfs.wind.checked && gfs.wind.time) ||
    (gfs.weather.checked && gfs.weather.time) ||
    (gfs.MSLP.checked && gfs.MSLP.time) ||
    (ecmwfwam.windWaves.checked && ecmwfwam.windWaves.time) ||
    (ecmwfwam.swellWaves.checked && ecmwfwam.swellWaves.time) ||
    (ecmwfwam.significantWaves.checked && ecmwfwam.significantWaves.time) ||
    (gfswam.windWaves.checked && gfswam.windWaves.time) ||
    (gfswam.swellWaves.checked && gfswam.swellWaves.time) ||
    (gfswam.significantWaves.checked && gfswam.significantWaves.time) ||
    (nemo.checked && nemo.resolution)
    ? undefined
    : { [FORM_ERROR]: 'Error' };
};

export const allProvidersCheckedMeteoDataDownloadForm = ({
  ecmwf,
  ecmwfwam,
  gfs,
  nemo,
  gfswam,
}: MeteoForm): boolean => {
  return (
    ecmwf.wind.checked &&
    ecmwf.weather.checked &&
    ecmwf.MSLP.checked &&
    gfs.wind.checked &&
    gfs.weather.checked &&
    gfs.MSLP.checked &&
    ecmwfwam.windWaves.checked &&
    ecmwfwam.significantWaves.checked &&
    ecmwfwam.significantWaves.checked &&
    gfswam.windWaves.checked &&
    gfswam.significantWaves.checked &&
    gfswam.significantWaves.checked &&
    nemo.checked
  );
};
