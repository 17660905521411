import { Colors } from 'modules/ui/theme/colors';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

export const BarContainer = styled.div<{ height?: number }>`
  height: ${({ height }): number => height || 8}px;
  overflow: hidden;
  position: relative;
  background-color: rgb(220 220 220);
  margin: 0px 0px 3px 0px;
  border-radius: 4px;
`;

const indeterminated = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`;

export const IndeterminateBar = styled.div<{
  backgroundColor?: string;
}>`
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: auto;
  animation: ${indeterminated} 6.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: ${({ backgroundColor }): string | undefined =>
    backgroundColor ? undefined : Colors.Secondary};
  background: ${({ backgroundColor }): string | undefined => backgroundColor};
`;

export const DeterminateBar = styled.div<{
  percentage: number;
  backgroundColor?: string;
}>`
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  transform: ${({ percentage }): string => `translateX(-${100 - percentage}%)`};
  transition: transform 0.4s linear;
  transform-origin: left;
  background-color: ${({ backgroundColor }): string | undefined =>
    backgroundColor ? undefined : Colors.Secondary};
  background: ${({ backgroundColor }): string | undefined => backgroundColor};
`;
