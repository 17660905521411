import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point } from '@turf/helpers';

import {
  convertFormCoordinatesToGeometryCoordinates,
  FormStartAndEndCoordinates,
} from 'modules/form';
import { InclusionZone } from '../types';

export const useCheckCoordinatesInclusionInInclusionZone = (
  zones: InclusionZone[],
): {
  validateStartAndEndCoordinatesWithInclusionZones: (
    inclusionZoneId: number | undefined,
    coordinates: FormStartAndEndCoordinates & { stayInInclusionZone: boolean },
  ) => undefined | string;
} => {
  const validateStartAndEndCoordinatesWithInclusionZones = (
    inclusionZoneId: number | undefined,
    data: FormStartAndEndCoordinates & { stayInInclusionZone: boolean },
  ): undefined | string => {
    const relevantZone = zones.find(({ id }) => id === inclusionZoneId);
    const pointCoordinates = convertFormCoordinatesToGeometryCoordinates(data);
    const points = data.route || [
      point(pointCoordinates[0]),
      point(pointCoordinates[1]),
    ];
    return data.stayInInclusionZone &&
      relevantZone &&
      (!booleanPointInPolygon(points[0], relevantZone.inclusionZone) ||
        !booleanPointInPolygon(points[1], relevantZone.inclusionZone))
      ? 'Routing start or end point are not within the selected inclusion zone.\r\nPlease select another inclusion zone or uncheck this checkbox to continue.'
      : undefined;
  };

  return { validateStartAndEndCoordinatesWithInclusionZones };
};
