import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { downloadedPackagesSelector } from '../../redux/selectors';
import { removeDownload, addToHistory } from '../../redux/actions';

const DownloadFinishedListener: React.FC = () => {
  const dispatch = useDispatch();
  const downloadedItems = useSelector(downloadedPackagesSelector);

  useEffect(() => {
    if (downloadedItems[0]?.id) {
      dispatch(removeDownload({ id: downloadedItems[0].id }));
      dispatch(
        addToHistory({
          ...downloadedItems[0],
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadedItems[0]?.id]);

  return null;
};

export default React.memo(DownloadFinishedListener);
