import { combineReducers, createReducer } from '@reduxjs/toolkit';
import db from 'modules/localForage/db';
import { persistReducer, PersistConfig, createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { setMapLayerDownloaded } from './actions';
import { Layers } from '../consts';

type State = { [key in Layers]: number };

const initialState: State = {
  coastline: 0,
  minorIslands: 0,
  graticules1: 0,
  ocean: 0,
  land: 0,
  iceZone: 0,
};

export const mapLayersVersions = createReducer(initialState, (builder) => {
  builder.addCase(setMapLayerDownloaded, (state, action) => {
    state[action.payload.layerName] = action.payload.version;
    return state;
  });
});

const reducer = combineReducers({ mapLayersVersions });

const migrations = {
  1: (state: State): State => {
    return {
      ...state,
      iceZone: 0,
    };
  },
  2: (state: State): State => {
    return {
      ...state,
    };
  },
};

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'mapLayers',
  storage: db.app,
  whitelist: ['mapLayersVersions'],
  version: 2,
  stateReconciler: autoMergeLevel2,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  migrate: createMigrate(migrations, { debug: false }),
};

export default persistReducer(persistConfig, reducer);
