import { createSelector } from '@reduxjs/toolkit';

import { RootState, Storage } from 'modules/main/redux';

import { Route } from '../types';

const routesStorage = (state: RootState): Storage<Route> =>
  state.routes.storage;

const hasLoaded = (state: RootState): boolean => !!state.routes.hasLoaded;

const routesList = createSelector([routesStorage], (storage) =>
  Object.values(storage),
);

export const RoutesSelectors = {
  routesStorage,
  hasLoaded,
  routesList,
};
