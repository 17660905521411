import styled, { css } from 'styled-components';

export const H1 = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #010724;
`;

export const H2 = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #010724;
`;

export const HighlightedBase = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #010724;
`;

export const HighlightedSm = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #010724;
`;

export const RegularBaseCss = css`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const RegularBase = styled.span`
  ${RegularBaseCss};
  color: #010724;
`;

export const RegularSmCss = css`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const RegularSm = styled.span`
  ${RegularSmCss};
  color: #010724;
`;

export const RegularXs = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16.5px;
  color: #010724;
`;
