import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';

export const RadioButtonContainer = styled.label<{ isSecondary: boolean }>`
  display: flex;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid ${Colors.DarkGray};
    border-radius: 50%;
    :after {
      content: '';
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${Colors.White};
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: ${Colors.Primary};
    border: 1px solid ${Colors.Primary};
  }
`;
