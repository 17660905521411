import React, { memo, useCallback } from 'react';
import { Map } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import { feature } from '@turf/helpers';

import {
  useChangePolygonCreationColors,
  useDrawCustomPolygon,
} from 'modules/mapInteracting';
import { MapInstructions, MapSelectingControls } from 'modules/ui/components';
import { useModal } from 'modules/main';
import { RootState } from 'modules/main/redux';
import { Colors } from 'modules/ui/theme/colors';
import { useCreateOrUpdateExclusionZone } from '../../hooks';
import { ExclusionZonesSelectors } from '../../redux';
import DeleteExclusionZoneModal from '../DeleteExclusionZoneModal';

type Props = {
  map: Map;
  onCancel: () => void;
  editedExclusionZoneId: number | null;
};

export const CreateExclusionZoneFlow: React.FunctionComponent<Props> = ({
  map,
  onCancel,
  editedExclusionZoneId,
}) => {
  useChangePolygonCreationColors(map, Colors.Destructive);
  const [showDeleteModal, , openDeleteModal, closeDeleteModal] = useModal(
    false,
  );
  const onDeleteZone = useCallback(() => {
    onCancel();
    closeDeleteModal();
  }, [onCancel, closeDeleteModal]);
  const editedZone = useSelector(
    (state: RootState) =>
      ExclusionZonesSelectors.exclusionZoneById(state, editedExclusionZoneId),
    () => true,
  );
  const { handleUndo, getPolygon, isValid, canUndo } = useDrawCustomPolygon(
    map,
    editedZone?.exclusionZone.geometry.coordinates[0],
  );
  const { handleUpdateOrCreate } = useCreateOrUpdateExclusionZone(onCancel);
  const onConfirm = useCallback(async () => {
    const polygon = getPolygon();
    const polygonFeature = feature(polygon);
    await handleUpdateOrCreate({
      exclusionZone: polygonFeature,
      id: editedExclusionZoneId || undefined,
    });
  }, [getPolygon, handleUpdateOrCreate, editedExclusionZoneId]);

  return (
    <>
      {editedZone && (
        <DeleteExclusionZoneModal
          isOpen={!!showDeleteModal}
          closeModal={closeDeleteModal}
          exclusionZone={editedZone}
          onDelete={onDeleteZone}
        />
      )}
      <MapSelectingControls
        onCancel={onCancel}
        onConfirm={onConfirm}
        undoDisabled={!canUndo}
        confirmDisabled={!isValid}
        onUndo={handleUndo}
        onDelete={editedExclusionZoneId ? openDeleteModal : undefined}
      />
      <MapInstructions
        title={
          editedExclusionZoneId
            ? 'Edit an exclusion zone'
            : 'New exclusion zone'
        }
        text={`${
          editedExclusionZoneId ? '' : 'Click to add points. '
        }Move points to adjust them.\n`}
      />
    </>
  );
};

export default memo(CreateExclusionZoneFlow);
