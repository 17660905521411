import React from 'react';
import { useSelector } from 'react-redux';
import { notificationsSelector } from 'modules/notifications/redux';
import Notification from '../Item';
import { NotificationsContainer } from './styledComponents';

const Notifications: React.FC = () => {
  const notifications = useSelector(notificationsSelector);
  return (
    <>
      {notifications.map((n, i) => {
        return (
          <NotificationsContainer key={n.id}>
            <div style={{ position: 'fixed', top: `${50 * i}px` }}>
              <Notification
                key={n.id}
                message={n.message}
                type={n.type}
                id={n.id}
              />
            </div>
          </NotificationsContainer>
        );
      })}
    </>
  );
};

export default React.memo(Notifications);
