import { useMemo } from 'react';

import { getLayerScaleUnit } from 'modules/units';
import { ModelLayers } from 'modules/mapLayers';
import { getLayerScale } from '../utils';

export const useGetScaleVisualData = (scaleArray: ModelLayers[]) => {
  const lastLayer = [...scaleArray]
    .reverse()
    .find((layer) => !!getLayerScale(layer));
  const lastScale =
    ([...scaleArray]
      .map((layer) => getLayerScale(layer))
      .reverse()
      .find((_) => _) as Array<string | number>) || [];
  const unit = lastLayer && getLayerScaleUnit(lastLayer);
  const scaleWithUnits = [unit, lastScale[1], ...lastScale];
  const breakpoints = useMemo(
    () => (scaleWithUnits || []).filter((_, index) => !(index % 2)),
    [scaleWithUnits],
  );
  const colors = useMemo(
    () => (scaleWithUnits || []).filter((_, index) => index % 2),
    [scaleWithUnits],
  ) as string[];
  const colorGradient: string = useMemo(
    () =>
      colors
        .reduce(
          (agg, curr, index) =>
            `${agg}${curr} ${(100 / breakpoints.length + 1) * (index + 1)}%,`,
          '',
        )
        .slice(0, -1),
    [colors, breakpoints.length],
  );
  const filteredBreakpoints = useMemo(
    () => (breakpoints || []).filter((b) => b !== null),
    [breakpoints],
  );
  return {
    filteredBreakpoints,
    colorGradient,
    lastScale,
  };
};
